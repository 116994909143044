import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Snackbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Alert, Skeleton } from "@material-ui/lab";
import { Facebook, SentimentDissatisfied } from "@material-ui/icons";
import Confetti from "react-confetti";

import WhatsAppLogo from "../../static/WhatsAppLogo.svg";
import AiSensyLogo from "../../static/full_logo.png";

import { timeZones } from "../../config/timezone";
import { partnerURL, URL, APP_ID } from "../../config/config";
import { checkFbConnectRateLimit } from "../../helpers/rateLimit";
import { connect } from "react-redux";
import { fbInit } from "../../helpers/metaHelper";

export class ConnectCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      data: {},
      snackbar: {},
      readTnC: false,
      fbLoading: false,
      runConfetti: false,
      disableButton: false,
    };
  }

  validateUser = async () => {
    try {
      const { id } = this.props.match.params;
      const { data: resData } = await axios.post(
        `${partnerURL}/api/verify-connect-catalog-link/${id}`
      );
      this.setState({ validated: true, data: resData });
    } catch (error) {
      if (error.response?.data?.message) {
        this.setState({
          snackbar: {
            message: error.response.data.message,
            severity: "error",
            code: error.response?.status,
          },
        });
      }
      console.log(error);
    }
  };

  componentDidMount = () => {
    this.validateUser();
    fbInit({ APP_ID });
  };

  closeSnackbar = () => {
    this.setState({ snackbar: {} });
  };

  launchWhatsAppSignup = async () => {
    const { id } = this.props.match.params;
    this.setState({ fbLoading: true });

    const rateLimitBreached = await checkFbConnectRateLimit(
      this.state?.data?.assistantId,
      (data) => this.setState(data)
    );
    if (rateLimitBreached) return;

    window.launchWhatsAppSignup && window.launchWhatsAppSignup();
    window.FB?.login &&
      window.FB.login(
        (response) => {
          if (response.authResponse) {
            const { accessToken, userID } = response.authResponse;
            axios
              .post(
                partnerURL + "/api/submit-facebook-access-token-for-catalog",
                {
                  accessToken,
                  userID,
                  key: id,
                }
              )
              .then((data) => {
                if (data.data?.success) {
                  this.setState({
                    snackbar: {
                      message: "Meta Catalog integration completed!",
                      severity: "success",
                      alert: true,
                    },
                    runConfetti: true,
                    disableButton: true,
                  });
                } else {
                  this.setState({
                    snackbar: {
                      message:
                        "Meta Catalog integration in progress! Please Check after 10 minutes",
                      severity: "info",
                      alert: true,
                    },
                  });
                }
              })
              .catch((err) => {
                console.log(err);
                this.setState({
                  snackbar: {
                    message:
                      err?.response?.data?.message || "Something went wrong!",
                    severity: "error",
                    alert: true,
                  },
                });
              })
              .finally(() => {
                this.setState({
                  fbLoading: false,
                });
              });
          } else {
            this.setState({
              snackbar: {
                message: "Connection Failed!",
                severity: "error",
                alert: true,
              },
              fbLoading: false,
            });
          }
        },
        {
          scope:
            "user_messenger_contact,catalog_management,business_management,whatsapp_business_management",
          auth_type: "rerequest",
          return_scopes: true,
        }
      );
  };

  render() {
    const {
      validated,
      snackbar,
      readTnC,
      data,
      fbLoading,
      runConfetti,
      disableButton,
    } = this.state;
    const { classes } = this.props;
    const {
      partnerLogo,
      partnerName,
      assistantName,
      businessCompany,
      businessName,
      businessEmail,
    } = data || {};

    const confettiCount = window.innerHeight < 767 ? 150 : 500;

    return (
      <div className={classes.root}>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <img
                src={partnerLogo}
                alt={partnerName}
                style={{ maxHeight: 40 }}
              />
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  maxWidth: "45%",
                  textAlign: "center",
                  height: "40px",
                  overflow: "hidden",
                  fontWeight: 600,
                }}
              >
                {Object.keys(this.props.tenantDetails).length
                  ? this.props.tenantDetails.name
                  : "AiSensy"}{" "}
                is a business Solution Provider and has been given the right by
                Facebook/WhatsApp to grant their clients access to the WhatsApp
                Business Platform.
              </Typography>
              <img src={WhatsAppLogo} alt="whatsapp" style={{ height: 40 }} />
            </Box>
          </Grid>
        </Grid>
        <Grid container justify="center" style={{ margin: "3em 0" }}>
          {validated ? (
            <Grid item xs={12} md={6}>
              <Box className={classes.container} textAlign="center">
                <div>
                  <img
                    src={partnerLogo}
                    alt={partnerName}
                    style={{ maxHeight: 60 }}
                  />
                  <Box textAlign="left" px={2} py={3}>
                    <div className={classes.containerSegment}>
                      <Typography variant="h4" color="primary" gutterBottom>
                        Manage Facebook Catalog with your WhatsApp Business
                        Platform Account
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        Please verify the information about your company and the
                        phone number that you would like to use to manage
                        Facebook Catalog with your WhatsApp Business Account.
                        Please make sure that your company follows Facebook's
                        compliance policy.
                      </Typography>
                    </div>
                    <div className={classes.containerSegment}>
                      <Typography variant="h4" color="primary" gutterBottom>
                        BUSINESS INFORMATION
                      </Typography>
                      <Typography
                        className={classes.infoWrapper}
                        variant="body1"
                        color="textSecondary"
                      >
                        <div>
                          <b>Name :</b>
                          <span className={classes.info}>{businessName}</span>
                        </div>
                        <div>
                          <b>Email :</b>
                          <span className={classes.info}>{businessEmail}</span>
                        </div>
                        <div>
                          <b>Company :</b>
                          <span className={classes.info}>
                            {businessCompany}
                          </span>
                        </div>
                        <div>
                          <b>Project :</b>
                          <span className={classes.info}>{assistantName}</span>
                        </div>
                      </Typography>
                    </div>
                    {!Object.keys(this.props.tenantDetails).length && (
                      <div>
                        <Typography variant="h5" color="primary" gutterBottom>
                          Terms & Conditions
                        </Typography>
                        <FormControlLabel
                          style={{ margin: 0 }}
                          control={
                            <Checkbox
                              checked={readTnC}
                              onChange={(e) => {
                                this.setState({ readTnC: e.target.checked });
                              }}
                              color="primary"
                              style={{ padding: 0 }}
                            />
                          }
                          label={
                            <Typography
                              className={classes.info}
                              color="textSecondary"
                              variant="body2"
                            >
                              I agree to the{" "}
                              <a
                                href="https://m.aisensy.com/tos/"
                                rel="noopener noreferrer"
                                target="_blank"
                                style={{
                                  cursor: "pointer",
                                  borderBottom: "solid 1px",
                                }}
                              >
                                AiSensy Terms of Service
                              </a>
                            </Typography>
                          }
                        />
                      </div>
                    )}
                  </Box>
                  <Button
                    size="large"
                    disabled={
                      Object.keys(this.props.tenantDetails).length
                        ? false
                        : !readTnC || fbLoading || disableButton
                    }
                    startIcon={<Facebook />}
                    onClick={this.launchWhatsAppSignup}
                    style={{
                      fontSize: 18,
                      color: "white",
                      background:
                        Object.keys(this.props.tenantDetails).length ||
                        (readTnC && !fbLoading && !disableButton)
                          ? "#1877F2"
                          : "lightgrey",
                      fontWeight: 600,
                    }}
                    variant="contained"
                    color="primary"
                    endIcon={
                      fbLoading ? (
                        <CircularProgress
                          size={18}
                          style={{ color: "white" }}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    Connect to Facebook
                  </Button>
                </div>
              </Box>
            </Grid>
          ) : snackbar?.code ? (
            <Grid container justify="center">
              <Box textAlign="center" className={classes.errorContainer}>
                <div className={classes.errorSmile}>
                  <SentimentDissatisfied style={{ fontSize: "8.1875em" }} />
                  <Typography variant="h2" style={{ fontWeight: "normal" }}>
                    {"ERROR " + snackbar.code}
                  </Typography>
                </div>
                <div className={classes.errorMessage}>
                  <Typography variant="h2">{snackbar.message}</Typography>
                </div>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box className={classes.container} textAlign="center">
                <div>
                  <Skeleton
                    variant="rounded"
                    width={60}
                    height={60}
                    className={classes.skeleton}
                  />
                  <Box textAlign="left" px={2} py={3}>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="60%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      <Skeleton variant="rounded" height={60} />
                    </div>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="40%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      {[1, 2, 3, 4].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={16}
                          width="40%"
                          style={{ marginBottom: "0.3em", marginLeft: "2.5em" }}
                        />
                      ))}
                    </div>
                    <div>
                      {[1, 2].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={18}
                          width="40%"
                          style={{ marginBottom: "0.4em" }}
                        />
                      ))}
                    </div>
                  </Box>
                  <Skeleton
                    variant="rounded"
                    width={230}
                    height={50}
                    className={classes.skeleton}
                  />
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
        {!Object.keys(this.props.tenantDetails).length && (
          <Box position="fixed" bottom="5px" right="20px" textAlign="center">
            <Typography variant="body2" color="textSecondary">
              Powered⚡ by
            </Typography>
            <Grid container alignItems="center">
              <Grid item>
                <img alt="AiSensy" src={AiSensyLogo} className={classes.logo} />
              </Grid>
            </Grid>
          </Box>
        )}

        <Snackbar
          open={snackbar.alert}
          onClose={this.closeSnackbar}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={this.closeSnackbar} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
        <Confetti
          run={runConfetti}
          numberOfPieces={confettiCount}
          gravity={0.3}
          initialVelocityY={{ min: 12, max: 30 }}
          recycle={false}
          onConfettiComplete={() => this.setState({ runConfetti: false })}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#e8ece1",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "fixed",
    left: 0,
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 70,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "#0a474c",
  },
  container: {
    background: "white",
    borderRadius: 8,
    padding: "2em",
  },
  errorContainer: {
    height: "27em",
    border: "solid #db5046 0.5em",
    borderRadius: "0.5em",
    width: "20em",
  },
  errorSmile: {
    background: "#db5046",
    height: "11em",
    padding: "2em",
    color: "white",
  },
  errorMessage: {
    height: "12em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#db5046",
  },
  containerSegment: {
    marginBottom: "1.5em",
  },
  infoWrapper: {
    marginLeft: "2em",
  },
  info: {
    marginLeft: "0.5em",
  },
  logo: {
    height: 40,
    verticalAlign: "middle",
  },
  skeleton: {
    margin: "auto",
  },
});

const connectCatalog = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(ConnectCatalog);

export default withStyles(styles)(withRouter(connectCatalog));
