import axios from "axios";
import moment from "moment";
import AffiliateTable from "./AffiliateTable";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  TablePagination,
  Dialog,
  Tab,
  Tabs,
} from "@material-ui/core";
import { NavigateNext, NavigateBefore, Clear } from "@material-ui/icons";
import { URL } from "../../config/config";
import AddIcon from "@material-ui/icons/AddOutlined";
import AddAffiliate from "./AddAffiliate";

const createInitialState = () => ({
  rowsPerPage: 15,
  totalCount: 0,
  timeStamp: new Date(),
  search: "",
  partners: [],
  err: null,
  isLoading: false,
  applied: {
    createdAt: {
      startDate: null,
      endDate: null,
      focus: null,
    },
  },
  openAddPartnerDialog: false,
  tier0: {
    partners: [],
    totalCount: 0,
    skip: 0,
    page: 0,
  },
  tier1: {
    partners: [],
    totalCount: 0,
    skip: 0,
    page: 0,
  },
  tier2: {
    partners: [],
    totalCount: 0,
    skip: 0,
    page: 0,
  },
  tabValue: 0,
});

class AffiliatePage extends Component {
  constructor(props) {
    super(props);
    this.state = createInitialState();
  }

  componentDidMount() {
    this.fetchPartners();
  }

  closeAddPartnerDialog = () => this.setState({ openAddPartnerDialog: false });

  onAddedPartnerDialog = () => {
    this.closeAddPartnerDialog();
    this.setState(createInitialState(), () => {
      this.fetchPartners();
    });
  };

  openAddPartnerDialog = () => this.setState({ openAddPartnerDialog: true });

  fetchPartners = async () => {
    this.setState({ isLoading: true, err: null });
    try {
      const { search, applied, rowsPerPage, tabValue } = this.state;
      const tierState = this.getTierState(tabValue);
      const { skip } = tierState;
      let filter = {};
      if (search) {
        filter["search"] = search;
      }
      if (applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: applied.createdAt.startDate,
          $lte: applied.createdAt.endDate,
        };
      }

      const queryObj = {
        skip,
        rowsPerPage,
        search,
        allFilters: JSON.stringify(filter),
        totalCount: this.state.totalCount,
        tier: `tier${tabValue}`,
      };

      const response = await axios.get(`${URL}/superadmin/get-affiliates`, {
        params: queryObj,
      });

      const { affiliates, newSkip, totalCount } = response.data;

      this.setState((prevState) => {
        const tierKey = `tier${tabValue}`;
        const updatedTier = {
          ...prevState[tierKey],
          partners: [...prevState[tierKey].partners, ...affiliates],
          totalCount,
          skip: newSkip,
        };

        return {
          isLoading: false,
          [tierKey]: updatedTier,
        };
      });
    } catch (err) {
      this.setState({ err, isLoading: false });
    }
  };

  getTierState = (tabValue) => {
    if (tabValue === 0) return this.state.tier0;
    if (tabValue === 1) return this.state.tier1;
    if (tabValue === 2) return this.state.tier2;
  };

  getPageRows = () => {
    const { rowsPerPage, tabValue } = this.state;
    const tierState = this.getTierState(tabValue);
    return tierState.partners.slice(
      tierState.page * rowsPerPage,
      (tierState.page + 1) * rowsPerPage
    );
  };

  handleChangePage = (event, newPage) => {
    const { tabValue, rowsPerPage } = this.state;
    const tierState = this.getTierState(tabValue);

    this.setState((prevState) => ({
      [`tier${tabValue}`]: {
        ...tierState,
        page: newPage,
        skip: newPage * rowsPerPage,
      },
    }), () => {
      if (newPage * rowsPerPage >= this.state[`tier${tabValue}`].partners.length) {
        this.fetchPartners();
      }
    });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      this.setState(
        {
          timeStamp: new Date(),
          skip: 0,
          totalCount: null,
          partners: [],
          isLoading: true,
          err: null,
        },
        () => {
          this.searchPartners();
        }
      );
    }
  };

  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };

  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (!s.startDate || !s.endDate) {
      this.clearDate(filterKey);
    }
  };

  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };

  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };

  applyFilter = () => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        totalCount: null,
        partners: [],
        isLoading: true,
        err: null,
      },
      () => {
        this.fetchPartners();
      }
    );
  };

  handleTabs = (event, newValue) => {
    this.setState({ tabValue: newValue, page: 0 }, () => {
      const tierState = this.getTierState(newValue);
      if (tierState.partners.length === 0) {
        this.fetchPartners();
      }
    });
  };

  searchPartners = async () => {
    this.setState({ isLoading: true, err: null });
    try {
      const { search, applied } = this.state;
      let filter = {};
      if (search) {
        filter["search"] = search;
      }
      if (applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: applied.createdAt.startDate,
          $lte: applied.createdAt.endDate,
        };
      }

      const queryObj = {
        skip: 0,
        rowsPerPage: 1000, // Fetch a large number of records to ensure all matches are retrieved
        search,
        allFilters: JSON.stringify(filter),
        totalCount: this.state.totalCount,
      };

      const response = await axios.get(`${URL}/superadmin/get-affiliates`, {
        params: queryObj,
      });

      const { affiliates } = response.data;

      const tier0 = affiliates.filter((partner) => partner.affiliatePlan === "tier0");
      const tier1 = affiliates.filter((partner) => partner.affiliatePlan === "tier1");
      const tier2 = affiliates.filter((partner) => partner.affiliatePlan === "tier2");

      let tabValue = this.state.tabValue;
      if (search) {
        if (tier0.length > 0) {
          tabValue = 0;
        } else if (tier1.length > 0) {
          tabValue = 1;
        } else if (tier2.length > 0) {
          tabValue = 2;
        }
      }

      this.setState({
        tier0: { ...this.state.tier0, partners: tier0, totalCount: tier0.length, skip: 0 },
        tier1: { ...this.state.tier1, partners: tier1, totalCount: tier1.length, skip: 0 },
        tier2: { ...this.state.tier2, partners: tier2, totalCount: tier2.length, skip: 0 },
        tabValue,
        isLoading: false,
      });
    } catch (err) {
      this.setState({ err, isLoading: false });
    }
  };

  render() {
    const { classes, agent } = this.props;
    const { tabValue, rowsPerPage } = this.state;
    const tierState = this.getTierState(tabValue);
    return (
      <div className={classes.root}>
        <Grid
          container
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3">Affiliates</Typography>
          </Grid>
          <Grid item>
            <TextField
              name="search"
              placeholder="Search Affiliate"
              className={classes.textField}
              onChange={this.handleInput}
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <DateFilter
              filterKey="createdAt"
              filterName="Created At"
              classes={classes}
              applied={this.state.applied}
              setDate={this.setDate}
              setFocus={this.setFocus}
              checkDateInput={this.checkDateInput}
              clearDate={this.clearDate}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={this.applyFilter}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            {agent.power <= 10 ? (
              <></>
            ) : (
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={this.openAddPartnerDialog}
              >
                Add Affiliate
              </Button>
            )}
          </Grid>
        </Grid>
        <Box my={3} />
        <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={10}>
            <div className={classes.tableContainer}>
              <Tabs
                value={tabValue}
                onChange={this.handleTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label={`Regular (20%) (${this.state.tier0.totalCount})`} />
                <Tab label={`Growth (25%) (${this.state.tier1.totalCount})`} />
                <Tab label={`Premium (30%) (${this.state.tier2.totalCount})`} />
              </Tabs>
              <AffiliateTable
                partners={this.getPageRows()}
                isLoading={this.state.isLoading}
                setPartner={this.setPartner}
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.fixedBottomContainer}>
          <TablePagination
            component="div"
            count={tierState.totalCount}
            page={tierState.page}
            onPageChange={this.handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[15]}
          />
        </div>

        <Dialog open={this.state.openAddPartnerDialog}>
          <AddAffiliate
            onClose={this.closeAddPartnerDialog}
            onSuccess={this.onAddedPartnerDialog}
          />
        </Dialog>
      </div>
    );
  }
}

function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box className={classes.filterTypeContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {A.startDate && A.endDate ? (
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Typography variant="body1">{filterName}</Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = (theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      background: "rgb(249,249,249)",
      boxSizing: "border-box",
      overflowX: "hidden",
      position: "relative",
    },
    pageTitleContainer: {
      position: "sticky",
      zIndex: 100,
      top: 0,
      height: 60,
      boxSizing: "border-box",
      alignItems: "center",
      padding: "0 60px",
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    fullWidth: {
      background: "white",
    },
    container: {
      background: "white",
      borderRadius: "8px",
    },
    fixedBottomContainer: {
      position: "fixed",
      height: "60px",
      borderTop: "1px solid lightgrey",
      background: "white",
      bottom: "0",
      left: "71px",
      right: "0",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        height: "50px",
      },
    },
    tableContainer: {
      top: "60px",
      height: "calc(100vh - 70px - 60px - 40px)",
      width: "100%",
      overflow: "hidden",
      boxSizing: "border-box",
      position: "sticky",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 70px - 50px - 10px - 50px)",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxSizing: "border-box",
      },
    },
    textField: {
      width: 300,
    },
  });

const connectedPartnersPage = connect((state) => ({
  agent: state.login.user,
}))(AffiliatePage);

export default withStyles(styles)(connectedPartnersPage);
