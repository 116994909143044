import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Snackbar,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import QontoStepIcon from "../../commons/Stepper/QontoStepIcon";
import QontoConnector from "../../commons/Stepper/QontoConnector";
import axios from "axios";
import { URL } from "../../config/config";

const STEPS = ["Pricing Details", "Create Plan"];
const PLANS = [
  {
    name: "Basic Monthly",
    key: "BASIC_MONTHLY",
  },
  {
    name: "Basic Yearly",
    key: "BASIC_YEARLY",
  },
  {
    name: "Pro Monthly",
    key: "PRO_MONTHLY",
  },
  {
    name: "Pro Yearly",
    key: "PRO_YEARLY",
  },
  {
    name: "Enterprise Monthly",
    key: "ENTERPRISE_MONTHLY",
  },
  {
    name: "Enterprise Yearly",
    key: "ENTERPRISE_YEARLY",
  },
];

class CreatePlanFamily extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aisensyPlan: {
        ...JSON.parse(
          JSON.stringify(
            this.props.aisensyPlan || {
              ...SAMPLE_PLAN,
              partnerId: this.props.partnerId || "627ea4876340e14cf7081778",
            }
          )
        ),
      },
      isLoading: false,
      isAisensyPlanLoading: false,
      showEnterprisePlans:
        this.props.aisensyPlan?.tiers?.tier1?.plans?.ENTERPRISE_MONTHLY,
    };
  }

  enableEnterprisePlans = () => {
    const { aisensyPlan, showEnterprisePlans } = this.state;
    console.log({ showEnterprisePlans, aisensyPlan });
    if (
      !showEnterprisePlans &&
      !aisensyPlan?.tiers?.tier1?.plans?.ENTERPRISE_MONTHLY
    ) {
      aisensyPlan.tiers.tier1.plans.ENTERPRISE_MONTHLY = {
        name: "Enterprise Monthly",
        duration: 1,
        INR: 500000000,
        USD: 11400000,
      };
      aisensyPlan.tiers.tier1.plans.ENTERPRISE_YEARLY = {
        name: "Enterprise Yearly",
        duration: 12,
        INR: 6000000000,
        USD: 136800000,
      };
    }

    this.setState({ aisensyPlan, showEnterprisePlans: true });
  };

  getRoundedTiers = (tiers) => {
    for (const tier of Object.keys(tiers)) {
      const { plans } = tiers[tier];
      for (const planKey of Object.keys(plans)) {
        const plan = plans[planKey];
        plan.INR = Math.ceil(plan.INR);
        plan.USD = Math.ceil(plan.USD);
      }
    }

    return tiers;
  };

  createPlanFamily = ({ name, plans }) => {
    this.setState({ isAisensyPlanLoading: true });
    const { partnerId, assistantId } = this.props;
    axios
      .post(URL + "/superadmin/aisensy-plan-family", {
        partnerId,
        assistantId,
        tiers: this.getRoundedTiers(JSON.parse(JSON.stringify(plans.tiers))),
      })
      .then((res) => {
        this.setState({ isAisensyPlanLoading: false });
        console.log(res);
        this.props.toggleDialog();
        this.props.fetchFamilies();
        // this.setState({});
      })
      .catch((error) => {
        console.log(error);
        this.props.showErrorMessage("Error in create aiseny plan family");
        this.setState({ isAisensyPlanLoading: false });
      });
  };

  updateFamily = () => {
    this.setState({ isAisensyPlanLoading: true });
    const { planFamilyId } = this.props;
    const { tiers, partnerId } = this.state.aisensyPlan;
    axios
      .patch(URL + "/superadmin/aisensy-plan-family/" + planFamilyId, {
        tiers: this.getRoundedTiers(JSON.parse(JSON.stringify(tiers))),
        partnerId,
      })
      .then((res) => {
        this.props.showSuccessMessage("Plan updated successfully!");
        this.setState({
          isAisensyPlanLoading: false,
        });
        this.props.toggleDialog();
        this.props.fetchFamilies();
      })
      .catch((error) => {
        const message =
          error?.response?.data?.error?.message || "Something went wrong!";
        this.setState({
          isAisensyPlanLoading: false,
        });
        this.props.showErrorMessage(message);
      });
  };

  render() {
    const {
      classes,
      convertPrice,
      openDialog,
      toggleDialog,
      disableFields,
      planFamilyId,
      familyName,
    } = this.props;
    const { name, isLoading, aisensyPlan, snackbar } = this.state;
    const tiers = aisensyPlan?.tiers
      ? Object.keys(aisensyPlan.tiers)
      : ["tier1"];
    const currency = this.props.currency || "INR";

    return isLoading ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog
          open={openDialog}
          closeDialog={toggleDialog}
          maxWidth={"sm"}
          fullWidth
        >
          <DialogTitle>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">
                {planFamilyId ? "Update Plan Family" : "Create Plan Family"}
              </Typography>
              <IconButton
                color="primary"
                size="small"
                variant="filled"
                onClick={toggleDialog}
              >
                <Clear
                  style={{
                    position: "fixed",
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          {!planFamilyId && (
            <Grid item xs={12} alignItems="center">
              <Stepper
                alternativeLabel
                activeStep={this.state.activeStep}
                connector={<QontoConnector />}
              >
                {STEPS.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Grid>
          )}
          <Box
            style={{ padding: "8px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <DialogContent>
              {tiers.map((tier) => {
                const plans = aisensyPlan?.tiers?.[tier]?.plans;
                return (
                  <>
                    <Grid
                      container
                      alignItems="center"
                      className={classes.content}
                      spacing={0}
                    >
                      {tiers.length > 1 && (
                        <Grid item md={5} container alignItems="center">
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                            style={{
                              fontWeight: 1000,
                              padding: "16px 0px",
                              color: "gray",
                            }}
                          >
                            {tier.toUpperCase()}
                          </Typography>
                        </Grid>
                      )}

                      <Grid container>
                        <Grid item md={5} container alignItems="center">
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 1000 }}
                          >
                            AiSensy Plans
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={5} container alignItems="center">
                          <Typography
                            required
                            className={classes.typo}
                            variant="h5"
                            align="center"
                            style={{ fontWeight: 1000 }}
                          >
                            AiSensy Prices
                          </Typography>
                        </Grid>
                      </Grid>
                      {PLANS.map(({ key, name }) => {
                        return (key == "ENTERPRISE_MONTHLY" ||
                          key === "ENTERPRISE_YEARLY") &&
                          !this.state.showEnterprisePlans ? (
                          <></>
                        ) : (
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              md={5}
                              container
                              alignItems="center"
                            >
                              <Typography
                                required
                                className={classes.typo}
                                variant="h5"
                                align="center"
                              >
                                {name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={5}
                              container
                              alignItems="center"
                            >
                              <TextField
                                style={{
                                  width: "100%",
                                  padding: "3px 0px",
                                  // marginRight: 10,
                                }}
                                type="Number"
                                variant="outlined"
                                placeholder="0"
                                disabled={disableFields}
                                onChange={(e) => {
                                  const value =
                                    Number(e.target.value) * 100000 ?? 0;
                                  const plan = plans[key];
                                  plan[currency] = value;
                                  this.setState({
                                    aisensyPlan: {
                                      ...aisensyPlan,
                                      tiers: {
                                        ...aisensyPlan.tiers,
                                        [tier]: {
                                          ...aisensyPlan.tiers[tier],
                                          plans: {
                                            ...aisensyPlan.tiers[tier].plans,
                                            [key]: plan,
                                          },
                                        },
                                      },
                                    },
                                  });
                                }}
                                className={classes.input}
                                error={this.state.displayNameError}
                                margin="dense"
                                value={plans?.[key]?.[currency] / convertPrice}
                                InputProps={{
                                  startAdornment:
                                    currency === "USD" ? "$" : "₹",
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                      {!disableFields && !this.state.showEnterprisePlans && (
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          onClick={this.enableEnterprisePlans}
                        >
                          Enable Enterprise Plans
                        </Button>
                      )}
                    </Grid>
                  </>
                );
              })}
            </DialogContent>

            <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
              <Button
                variant={!this.props.disableFields ? "outlined" : "contained"}
                size="medium"
                color="primary"
                onClick={() => {
                  if (this.state.activeStep) this.setStep(0);
                  else toggleDialog();
                }}
              >
                {this.state.activeStep ? "Back" : "Cancel"}
              </Button>
              {!this.props.disableFields && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    this.state.activeStep &&
                    !this.state.name &&
                    !this.props.familyName
                  }
                  onClick={() =>
                    !planFamilyId
                      ? this.createPlanFamily({
                          name,
                          plans: this.state.aisensyPlan,
                        })
                      : this.updateFamily()
                  }
                >
                  {!planFamilyId ? "Create" : "Update"}
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
}

export default CreatePlanFamily;

const SAMPLE_PLAN = {
  partnerId: "627ea4876340e14cf7081778",
  tiers: {
    tier1: {
      minProjects: 0,
      plans: {
        BASIC_MONTHLY: {
          name: "Basic One Month",
          duration: 1,
          INR: 79900000,
          USD: 2000000,
        },
        PRO_MONTHLY: {
          name: "Pro One Month",
          duration: 1,
          INR: 229900000,
          USD: 4500000,
        },
        BASIC_YEARLY: {
          name: "Basic One Year",
          duration: 12,
          INR: 862920000,
          USD: 21600000,
        },
        PRO_YEARLY: {
          name: "Pro One Year",
          duration: 12,
          INR: 2482920000,
          USD: 48600000,
        },
      },
    },
  },
};
