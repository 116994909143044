import axios from "axios";

/**
 *
 * @param {string} url
 */
export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

/**
 *
 * @param {string} url
 */
export const isUrlLive = async (url) => {
  if (!isValidUrl(url)) return false;

  if (url.includes("localhost")) return true;

  // Mocking the response for localhost
  return true;
  // TODO: implement Logic to check URL is live or not
  const abortController = new AbortController();
  const timeout = setTimeout(() => {
    abortController.abort();
  }, 3000);

  try {
    const response = await axios.get(url, {
      signal: abortController.signal,
    });
    clearTimeout(timeout);
    return response.status === 200;
  } catch (error) {
    return false;
  }
};
