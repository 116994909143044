import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  Button,
  Snackbar,
  TextField,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import { Alert, Autocomplete, Skeleton } from "@material-ui/lab";
import { Facebook, SentimentDissatisfied } from "@material-ui/icons";
import { withRouter } from "react-router";
import WhatsAppLogo from "../../static/WhatsAppLogo.svg";
import AiSensyLogo from "../../static/full_logo.png";
import dialCodes, { countryMap, isoMap } from "../../config/dialCodes";
import axios from "axios";
import { timeZones } from "../../config/timezone";
import moment from "moment";
import { partnerURL, APP_ID } from "../../config/config";
import { checkFbConnectRateLimit } from "../../helpers/rateLimit";
import { connect } from "react-redux";
import { fbInit } from "../../helpers/metaHelper";

class MigrateWaba extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      step: 1,
      contact: "",
      otp: "",
      otpSent: false,
      otpSending: false,
      targetId: "",
      phoneNumber: "",
      countryCode: "+91",
      remainingTime: null,
      mode: "",
      validated: false,
      validationError: null,
      data: {},
    };
  }

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  decrementRemainingTime = () => {
    const { remainingTime } = this.state;
    if (remainingTime > 0) {
      this.setState({
        remainingTime: remainingTime - 1,
      });
    } else {
      clearInterval(this.intervalId);
    }
  };

  validateUser = async () => {
    try {
      const { id } = this.props.match.params;
      const { data: resData } = await axios.post(
        `${partnerURL}/api/verify-waba-migration-link/${id}`
      );
      this.setState({ validated: true, data: resData });
      const { appId } = resData || {};
      fbInit({ appId: appId || APP_ID });
    } catch (error) {
      if (error.response?.data?.message) {
        this.setState({
          alert: true,
          alertMsg: error.response.data.message,
          alertSeverity: "error",
          validationError: {
            message: error.response.data.message,
            code: error.response?.status,
          },
        });
      }
      console.log(error);
    }
  };

  componentDidMount() {
    this.validateUser();
  }

  mirgateToPartner = () => {
    const { targetId, countryCode, phoneNumber, data } = this.state;
    const { assistantId, partnerId } = data || {};
    this.setState({ otpSending: true });
    axios
      .post(
        partnerURL +
          "/api/submit-facebook-access-token-for-migration-to-partner",
        {
          assistantId,
          targetId,
          countryCode: countryCode?.split("+")[1],
          phoneNumber,
          partnerId,
        }
      )
      .then((data) => {
        if (data.data?.success) {
          this.setState({
            step: 2,
            otpSending: false,
          });
        } else {
          this.setState({
            alert: true,
            alertMsg: "WABA migration failed",
            alertSeverity: "info",
            otpSending: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alert: true,
          alertMsg: err?.response?.data?.message || "Something went wrong!",
          alertSeverity: "error",
          otpSending: false,
        });
      });
  };

  launchWhatsAppSignup = async () => {
    const { data } = this.state;
    const {
      assistantId,
      businessCompany,
      businessEmail,
      businessContact,
      businessTimezone,
      setup,
      solutionId,
    } = data || {};

    this.setState({ fbLoading: true });
    const { business, phone } = setup || {};

    const rateLimitBreached = await checkFbConnectRateLimit(
      assistantId,
      (data) =>
        this.setState({
          alert: data?.snackbar?.alert,
          alertMsg: data?.snackbar?.message,
          alertSeverity: data?.snackbar?.severity,
          fbLoading: false,
        })
    );
    if (rateLimitBreached) return;

    window.launchWhatsAppSignup && window.launchWhatsAppSignup();
    window.FB?.login &&
      window.FB.login(
        (response) => {
          this.setState({ fbLoading: false });
        },
        {
          scope:
            "business_management,whatsapp_business_management,whatsapp_business_messaging",
          extras: {
            feature: "whatsapp_embedded_signup",
            setup: {
              solutionID: solutionId || null,
              business: {
                name: business?.name || businessCompany || "",
                email: business?.email || businessEmail || "",
                phone: {
                  code: business?.phone?.code || 91,
                  number: business?.phone?.number || businessContact || "",
                },
                website: business?.website || "",
                address: {
                  streetAddress1: business?.address?.streetAddress1 || "",
                  city: business?.address?.city || "",
                  state: business?.address?.state || "",
                  zipPostal: business?.address?.zipPostal || "",
                  country: business?.address?.country || "",
                },
                timezone:
                  business?.timezone ||
                  `UTC${
                    timeZones.find((i) => i.label.includes(businessTimezone))
                      ?.value || "+05:30"
                  }`,
              },
              phone: {
                displayName: phone?.displayName || businessCompany || "",
                category: phone?.category || "",
                description: phone?.description || "",
              },
            },
          },
        }
      );
  };

  sendOTP = () => {
    const { data } = this.state;
    const { assistantId, partnerId } = data || {};
    this.setState({ otpSending: true });
    axios
      .post(partnerURL + "/api/request-otp-for-migration-to-partner", {
        assistantId,
        partnerId,
        mode: this.state.mode,
      })
      .then((data) => {
        this.setState(
          {
            otpSent: true,
            alertMsg: "OTP Sent Successfully!",
            alert: true,
            alertSeverity: "success",
            otpSending: false,
            otp: "",
            remainingTime: 120,
          },
          () => {
            this.intervalId = setInterval(this.decrementRemainingTime, 1000);
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertMsg: err?.response?.data?.message || "Something went wrong!",
          alert: true,
          alertSeverity: "error",
          otpSending: false,
        });
      });
  };

  validateOTP = () => {
    this.setState({ otpSending: true });
    const { otp, data } = this.state;
    const { assistantId, partnerId } = data || {};
    axios
      .post(partnerURL + "/api/validate-otp-for-migration-to-partner", {
        assistantId,
        partnerId,
        otp,
      })
      .then((data) => {
        this.setState({
          otpSent: true,
          step: 3,
          alertMsg: "Migration Successfully Completed!",
          alert: true,
          alertSeverity: "success",
          otpSending: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          alertMsg: err?.response?.data?.message || "Something went wrong!",
          alert: true,
          alertSeverity: "error",
          otpSending: false,
        });
      });
  };

  getflag = (langcode) => {
    var first = langcode.charCodeAt(0) + 127397;
    var second = langcode.charCodeAt(1) + 127397;
    var flag = `&#${first};&#${second};`;
    const x = document.createElement("p");
    x.innerHTML = flag;
    return x.innerText;
  };

  render() {
    const { classes } = this.props;
    const {
      step,
      otpSending,
      otpSent,
      otp,
      targetId,
      phoneNumber,
      countryCode,
      remainingTime,
      mode,
      validated,
      data,
      fbLoading,
      validationError,
    } = this.state;

    const { partnerLogo, partnerName } = data || {};

    const duration = moment.duration(remainingTime || 0, "seconds");
    let seconds = duration.seconds();
    let minutes = duration.minutes();
    if (seconds < 10) seconds = "0" + seconds;
    if (minutes < 10) minutes = "0" + minutes;

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box
              px={2}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid container alignItems="center" style={{ width: 150 }}>
                <Grid item>
                  <img
                    src={partnerLogo}
                    alt={partnerName}
                    style={{ maxHeight: 40 }}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                style={{
                  color: "white",
                  maxWidth: "45%",
                  textAlign: "center",
                  height: "40px",
                  overflow: "hidden",
                  fontWeight: 600,
                }}
              >
                {Object.keys(this.props.tenantDetails).length
                  ? this.props.tenantDetails.name
                  : "AiSensy"}{" "}
                is a verified WhatsApp Solution Provider and has been given the
                right by Facebook/WhatsApp to grant their clients access to the
                WhatsApp Business API.
              </Typography>
              <img src={WhatsAppLogo} alt="whatsapp" style={{ height: 40 }} />
            </Box>
          </Grid>
        </Grid>
        <Box my={8} />
        {/* Layout */}
        <Grid container justify="center">
          {validated ? (
            <Grid item xs={12} md={6}>
              <Box className={classes.container} textAlign="center">
                <Box my={2} />
                <img
                  src={partnerLogo}
                  alt="partner"
                  style={{ maxHeight: 60 }}
                />
                <Box my={2} />
                {step < 3 && (
                  <Typography variant="body1" color="textSecondary">
                    {`Step ${step} of 2`}
                  </Typography>
                )}
                {step === 1 && (
                  <>
                    <Box textAlign="left" px={2}>
                      <Box my={4} />
                      <Typography
                        variant="h4"
                        color="primary"
                        gutterBottom
                        style={{ textAlign: "center", marginBottom: 24 }}
                      >
                        WhatsApp Business API Account Migration
                      </Typography>
                      <Box maxWidth="500px" margin="auto">
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          gutterBottom
                        >
                          Click on Connect to Facebook and follow the steps
                          below,
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                          • Click on Get Started then on Continue to Connect
                          your account to {partnerName}
                          <br />
                          • Select your Meta Business which contains your
                          WhatsApp Business Account
                          <br />
                          • Create new WhatsApp Business account
                          <br />
                          • Go Back & copy the ID of newly created WABA
                          <br />• Paste WABA ID & enter phone number, to start
                          migration
                        </Typography>
                      </Box>
                      <Box my={2} />
                    </Box>
                    <Button
                      size="large"
                      startIcon={<Facebook />}
                      onClick={this.launchWhatsAppSignup}
                      style={{
                        margin: "12px 0",
                        fontSize: 18,
                        color: "white",
                        background: fbLoading ? "lightgrey" : "#1877F2",
                        fontWeight: 600,
                      }}
                      variant="contained"
                      color="primary"
                      disabled={fbLoading}
                      endIcon={fbLoading ? <CircularProgress size={18} /> : ""}
                    >
                      Connect to Facebook
                    </Button>
                    <Box mb={3} mt={1} maxWidth="380px" margin="auto">
                      <Divider />
                    </Box>
                    <Box maxWidth="350px" margin="auto">
                      <TextField
                        fullWidth
                        value={targetId}
                        style={{
                          padding: "8px 20px",
                          border: "2px solid grey",
                          background: "white",
                        }}
                        placeholder="WhatsApp Business Account ID"
                        onChange={(e) => {
                          this.setState({
                            targetId: e.target.value,
                          });
                        }}
                      />
                      <Box mt={1} />
                      <Box position="relative">
                        <Autocomplete
                          disableClearable
                          options={dialCodes.map((i) => i.dialCode)}
                          classes={{ popper: classes.textFieldPopper }}
                          getOptionLabel={(option) => {
                            return `${this.getflag(
                              isoMap[option]
                            )}   ${option}             ${
                              countryMap[option.substring(1)]
                            }`;
                          }}
                          onChange={(e, value, reason) => {
                            this.setState({
                              countryCode: value,
                            });
                          }}
                          value={countryCode}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              classes={{ root: classes.codeTextFieldRoot }}
                              InputProps={{ ...params.InputProps }}
                            />
                          )}
                        />

                        <TextField
                          id="contact"
                          placeholder="Personal WhatsApp Number"
                          type="text"
                          name="contact"
                          value={phoneNumber}
                          onChange={(e) => {
                            this.setState({
                              phoneNumber: e.target.value,
                            });
                          }}
                          className={classes.phoneTextFieldRoot}
                        />
                      </Box>
                    </Box>

                    <Button
                      size="large"
                      disabled={
                        otpSending || !phoneNumber || !countryCode || !targetId
                      }
                      onClick={this.mirgateToPartner}
                      style={{
                        margin: "12px 0 24px 0",
                      }}
                      variant="contained"
                      color="primary"
                      endIcon={otpSending ? <CircularProgress size={18} /> : ""}
                    >
                      Start Migration
                    </Button>

                    {/* <Box mt={3}>
                    <Button
                      variant="text"
                      onClick={() => {
                        this.setState({
                          step: 2,
                        });
                      }}
                    >
                      Next Step →
                    </Button>
                  </Box> */}
                  </>
                )}
                {step === 2 && (
                  <>
                    <Box textAlign="center" px={2}>
                      <Box my={4} />
                      <Typography variant="h4" color="primary" gutterBottom>
                        Verify Phone Number
                      </Typography>
                      <Typography variant="body1" color="textSecondary">
                        Please verify your WABA Phone number for migration
                      </Typography>

                      <Box my={4} />
                    </Box>

                    {otpSent ? (
                      <>
                        <Box className={classes.otpFieldOverlay} />
                        <Grid
                          container
                          style={{ maxWidth: 380, margin: "auto" }}
                          spacing={2}
                        >
                          {[1, 2, 3, 4, 5, 6].map((i) => (
                            <Grid item xs={2}>
                              <Box
                                style={{
                                  border: "2px solid lightgrey",
                                  borderRadius: 4,
                                  height: 50,
                                }}
                              ></Box>
                            </Grid>
                          ))}
                        </Grid>
                        <TextField
                          id="otp"
                          type="text"
                          placeholder="000000"
                          name="otp"
                          className={classes.otpInputField}
                          value={otp}
                          onChange={(e) => {
                            this.setState({
                              otp: e.target.value
                                .replace(/\D/g, "")
                                .substring(0, 6),
                            });
                          }}
                        />

                        <Typography
                          color="textSecondary"
                          variant="body1"
                          gutterBottom
                          className={classes.otpTimer}
                        >{`${minutes}:${seconds}`}</Typography>

                        <Typography color="textSecondary" variant="body2">
                          Didn't received code?{" "}
                          <u
                            className={
                              remainingTime > 0
                                ? classes.resendButtonDisabled
                                : classes.resendButton
                            }
                            onClick={() => {
                              !(remainingTime > 0) &&
                                this.setState({
                                  otpSent: false,
                                });
                            }}
                          >
                            Regenerate
                          </u>
                        </Typography>
                        <Box>
                          <Button
                            size="large"
                            disabled={otpSending || otp.length !== 6}
                            onClick={this.validateOTP}
                            style={{
                              margin: "24px 0",
                            }}
                            variant="contained"
                            color="primary"
                            endIcon={
                              otpSending ? <CircularProgress size={18} /> : ""
                            }
                          >
                            Submit OTP
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Box maxWidth="350px" margin="auto">
                        <Box mt={1} />
                        <Autocomplete
                          disableClearable
                          value={mode}
                          options={["SMS", "VOICE"]}
                          onChange={(e, value, reason) =>
                            this.setState({
                              mode: value,
                            })
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              style={{
                                padding: "8px 20px",
                                border: "2px solid grey",
                                background: "white",
                              }}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Verification Mode"
                              name="Verification Mode"
                            />
                          )}
                        />
                        <Box mt={1} />

                        <Button
                          size="large"
                          disabled={otpSending || !mode}
                          onClick={this.sendOTP}
                          style={{
                            margin: "24px 0",
                          }}
                          variant="contained"
                          color="primary"
                          endIcon={
                            otpSending ? <CircularProgress size={18} /> : ""
                          }
                        >
                          Send OTP
                        </Button>
                      </Box>
                    )}

                    {/* <Box mt={3}>
                    <Button
                      variant="text"
                      onClick={() => {
                        this.setState({
                          step: 1,
                        });
                      }}
                    >
                      ← Previous Step
                    </Button>
                  </Box> */}
                  </>
                )}
                {step === 3 && (
                  <>
                    <Box textAlign="center" px={2}>
                      <Box my={4} />
                      <Typography variant="h4" color="primary" gutterBottom>
                        Setup Complete
                      </Typography>
                      <Box my={4} />
                      <Typography variant="h3" color="primary" gutterBottom>
                        Thank You!
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          ) : validationError ? (
            <Grid container justify="center">
              <Box textAlign="center" className={classes.errorContainer}>
                <div className={classes.errorSmile}>
                  <SentimentDissatisfied style={{ fontSize: "8.1875em" }} />
                  <Typography variant="h2" style={{ fontWeight: "normal" }}>
                    {"ERROR " + validationError.code}
                  </Typography>
                </div>
                <div className={classes.errorMessage}>
                  <Typography variant="h2">
                    {validationError.message}
                  </Typography>
                </div>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} md={6}>
              <Box className={classes.container} textAlign="center">
                <div>
                  <Skeleton
                    variant="rounded"
                    width={60}
                    height={60}
                    className={classes.skeleton}
                  />
                  <Box textAlign="left" px={2} py={3}>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="60%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      <Skeleton variant="rounded" height={60} />
                    </div>
                    <div className={classes.containerSegment}>
                      <Skeleton
                        variant="rounded"
                        width="40%"
                        height={18}
                        style={{ marginBottom: "0.6em" }}
                      />
                      {[1, 2, 3, 4].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={16}
                          width="40%"
                          style={{ marginBottom: "0.3em", marginLeft: "2.5em" }}
                        />
                      ))}
                    </div>
                    <div>
                      {[1, 2].map((idx) => (
                        <Skeleton
                          key={idx}
                          variant="rounded"
                          height={18}
                          width="40%"
                          style={{ marginBottom: "0.4em" }}
                        />
                      ))}
                    </div>
                  </Box>
                  <Skeleton
                    variant="rounded"
                    width={230}
                    height={50}
                    className={classes.skeleton}
                  />
                </div>
              </Box>
            </Grid>
          )}
        </Grid>
        {!Object.keys(this.props.tenantDetails).length && (
          <Box position="fixed" bottom="5px" left="20px" textAlign="center">
            <Typography variant="body2" color="textSecondary">
              Powered⚡ by
            </Typography>
            <Grid container alignItems="center">
              <Grid item>
                <img alt="" src={AiSensyLogo} className={classes.logo} />
              </Grid>
            </Grid>
          </Box>
        )}

        <Box my={8} />
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "#e8ece1",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "fixed",
    left: 0,
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 70,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "#0a474c",
  },
  primaryBackground: {
    background: "#EBF5F3",
  },
  container: {
    marginTop: 16,
    background: "white",
    borderRadius: 8,
    padding: "24px 40px",
  },
  viewOldButton: {
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
  TextField: {
    marginTop: 12,
  },
  otpInputField: {
    background: "transparent",
    margin: "auto",
    display: "flex",
    bottom: "60px",
    left: "35px",
    maxWidth: "430px",
    "& input": {
      fontSize: "24px",
      letterSpacing: "50px",
    },
  },
  otpFieldOverlay: {
    height: "60px",
    width: "10px",
    background: "white",
    position: "absolute",
    right: "calc(50% - 220px)",
    zIndex: 1,
  },
  logo: {
    height: 24,
  },
  resendButton: {
    color: "#0a474c",
    cursor: "pointer",
    "&:hover": {
      color: "grey",
    },
  },
  resendButtonDisabled: {
    color: "lightgrey",
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    left: 0,
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },

  errorContainer: {
    height: "27em",
    border: "solid #db5046 0.5em",
    borderRadius: "0.5em",
    width: "20em",
  },
  errorSmile: {
    background: "#db5046",
    height: "11em",
    padding: "2em",
    color: "white",
  },
  errorMessage: {
    height: "12em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#db5046",
  },
  containerSegment: {
    marginBottom: "1.5em",
  },
  infoWrapper: {
    marginLeft: "2em",
  },
  info: {
    marginLeft: "0.5em",
  },
  logo: {
    height: 40,
    verticalAlign: "middle",
  },
  skeleton: {
    margin: "auto",
  },
});

const connectedMigrateWaba = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(MigrateWaba);

export default withStyles(styles)(withRouter(connectedMigrateWaba));
