import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Box,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  makeStyles,
  FormControl,
  FormHelperText,
  TextField,
  NativeSelect,
  Input,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { URL as clientURL } from "../../config/config";
import { createRandomString } from "../../helpers/hashing";
import { FileCopy } from "@material-ui/icons";
import { timeZones } from "../../config/timezone";
import dialCodes from "../../config/dialCodes";

const useStyles = makeStyles({
  title: {
    padding: "2px 24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
  },
  selectFieldInput: {
    borderRadius: "8px",
    backgroundColor: "rgb(240,240,240)",
    padding: "12px",
  },
  copyIcon: {
    fontSize: "16px",
    color: "#606060",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  },
});

const CreationDetails = (props) => {
  const { classes, partnerDetails, partner, onClose, copy, setCopy } = props;
  return (
    <Box width={700}>
      <DialogTitle className={classes.title}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3" component="p">
              Setup Details
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="primary">{copy && "Copied!"}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <form className={classes.form} style={{ paddingBottom: 16 }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">
              Affiliate Dashboard Credentials
            </Typography>

            <IconButton
              onClick={() => {
                setCopy(true);
                navigator.clipboard.writeText(
                  `Affiliate Dashboard Credentials\nURL: https://www.prime.aisensy.com/ \nLogin Username: ${partner.userName}\nLogin Password: ${partner.loginPassword}`
                );
                setTimeout(() => setCopy(false), 1500);
              }}
            >
              <FileCopy className={classes.copyIcon} />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Username
                </Typography>
                <TextField value={partner.userName} disabled />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Password
                </Typography>
                <TextField value={partner.loginPassword} disabled />
              </FormControl>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Box>
  );
};

function AddAffiliate({ onClose, onSuccess }) {
  const classes = useStyles();
  const [partnerDetails, setPartnerDetails] = useState();

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    name: "",
    brandName: "",
    companyName: "",
    userName: "",
    loginPassword: "",
    type: "AFFILIATE-PLUS",
    planType: "tier1",
    currency: "INR",
    email: "",
    timezone: "Asia/Calcutta GMT+05:30",
    partnershipFee: 0,
    contact: null,
    isoCode: "IN",
  });

  const [agent, setAgentState] = useState({
    display_name: "",
    email: "",
    password: "",
    company: "",
    contact: "",
    timezone: "Asia/Calcutta GMT+05:30",
    companySize: "10 - 20",
    currency: "INR",
  });
  const [assistant, setAssistant] = useState("");
  const [completed, setCompleted] = useState(false);
  const [copy, setCopy] = useState(false);

  const updateState = (key, value) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const updateAgentState = (key, value) =>
    setAgentState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const generatePassword = (type) => {
    const password = createRandomString();
    updateState("loginPassword", password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const normalKeys = [
        "name",
        "brandName",
        "userName",
        "loginPassword",
        "type",
        "planType",
        "currency",
        "email",
        "timezone",
        "contact",
        "isoCode",
        "companyName",
      ];

      const body = {};

      for (const key of normalKeys) {
        body[key] = state[key];
      }

      body["partnershipFee"] = state["partnershipFee"] * 100000;

      const { data } = await axios.post(
        `${clientURL}/affiliates/create-affiliate`,
        body
      );

      setPartnerDetails(data);
      setCompleted(true);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong";
      setError(message);
      setLoading(false);
    }
  };

  return completed ? (
    <CreationDetails
      agent={agent}
      classes={classes}
      partner={state}
      partnerDetails={partnerDetails}
      onClose={onClose}
      copy={copy}
      setCopy={setCopy}
    />
  ) : (
    <Box width={700}>
      <DialogTitle className={classes.title}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3" component="p">
              Add new Affiliate
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="error">{error}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Brand name
                  </Typography>
                  <TextField
                    value={state.brandName}
                    onChange={(e) => updateState("brandName", e.target.value)}
                  />
                  <FormHelperText>
                    Enter Brand name of the affiliate
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Company name
                  </Typography>
                  <TextField
                    value={state.companyName}
                    onChange={(e) => updateState("companyName", e.target.value)}
                  />
                  <FormHelperText>
                    Enter Company name of the affiliate
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Affiliate Name
                </Typography>
                <TextField
                  value={state.name}
                  onChange={(e) => updateState("name", e.target.value)}
                />
                <FormHelperText>Enter name of the affiliate</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Country
                </Typography>
                <NativeSelect
                  value={state.isoCode}
                  onChange={(e) => updateState("isoCode", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  {dialCodes.map((e) => {
                    return <option value={e.isoCode}>{e.name}</option>;
                  })}
                </NativeSelect>
                <FormHelperText>
                  Select the country of the affiliate
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Manager Number
                  </Typography>
                  <TextField
                    value={state.contact}
                    onChange={(e) => {
                      if (e.target.value.includes(" "))
                        setError("No space is allowed in number");
                      else if (e.target.value.includes("+"))
                        setError("Enter country code without +");
                      else updateState("contact", e.target.value);
                    }}
                  />
                  <FormHelperText>
                    Enter the manager number of the affiliate, , eg: 91xxx
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs>
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Email
                  </Typography>
                  <TextField
                    value={state.email}
                    onChange={(e) => updateState("email", e.target.value)}
                  />
                  <FormHelperText>Enter email of the affiliate</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Username
                </Typography>
                <TextField
                  value={state.userName}
                  onChange={(e) => updateState("userName", e.target.value)}
                />
                <FormHelperText>
                  Enter the username that will be used to login
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Password
                </Typography>
                <TextField
                  value={state.loginPassword}
                  onChange={(e) => updateState("loginPassword", e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Button
                        size="small"
                        color="primary"
                        onClick={generatePassword}
                      >
                        Generate
                      </Button>
                    ),
                  }}
                />
                <FormHelperText>
                  Enter the pasword that will be used to login
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Affiliate Type
                </Typography>
                <NativeSelect
                  value={state.type}
                  onChange={(e) => updateState("type", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="AFFILIATE-PLUS">Affiliate Plus</option>
                </NativeSelect>
                <FormHelperText>
                  Select the type of the affiliate (Affiliate Plus)
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Affiliate Plan Type
                </Typography>
                <NativeSelect
                  value={state.planType}
                  onChange={(e) => updateState("planType", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="tier0">Regular (20%)</option>
                  <option value="tier1">Growth (25%)</option>
                  <option value="tier2">Premium (30%)</option>
                </NativeSelect>
                <FormHelperText>Select the commision %</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Timezone
                </Typography>
                <NativeSelect
                  value={state.timezone}
                  onChange={(e) => updateState("timezone", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  {timeZones.map((e) => {
                    const arr = e.label.split("(");
                    const timezone =
                      arr[arr.length - 1].slice(0, -1) + ` GMT${e.value}`;
                    return <option value={timezone}>{e.label}</option>;
                  })}
                </NativeSelect>
                <FormHelperText>
                  Select the timezone of the affiliate
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Partnership Fee
                </Typography>
                <TextField
                  value={state.partnershipFee}
                  type="number"
                  onChange={(e) =>
                    updateState("partnershipFee", e.target.value)
                  }
                />
                <FormHelperText>Enter fee (if any amount taken)</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Balance Currency
                </Typography>
                <NativeSelect
                  value={state.currency}
                  onChange={(e) => updateState("currency", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </NativeSelect>
                <FormHelperText>
                  Select the currency of the affiliate
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Box />

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            {!loading ? "Add Affiliate" : "Adding Affiliate..."}
          </Button>
        </form>
      </DialogContent>
    </Box>
  );
}

const companySize = [
  "1 - 10 Employees",
  "10 - 20 Employees",
  "20 - 50 Employees",
  "50 - 200 Employees",
  "200 - 500 Employees",
  "500 +",
];

const connectedAddPartner = connect((state) => ({
  agent: state.login.user,
}))(AddAffiliate);

export default connectedAddPartner;
