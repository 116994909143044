import React, { useEffect } from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";

import ErrorPage from "./commons/ErrorPage/ErrorPage";
import ProjectsLayout from "./commons/ProjectsLayout/ProjectsLayout";
import SignInPage from "./pages/Signin/SignInPage3.js";
import RegisterWaba from "./pages/RegisterWaba/RegisterWaba";
import MigrateWaba from "./pages/MigrateWaba/MigrateWaba";
import ConnectCatalog from "./pages/ConnectCatalog/ConnectCatalog";
import ConnectMetaAd from "./pages/ConnectMetaAd/ConnectMetaAd";
import axios from "axios";
import { TENANT_ID, partnerURL } from "./config/config.js";
import { fetchTenantDetails } from "./store/tenantState";

const PrivateRoute = ({ component, isAuthenticated, ...rest }) => {
  return !isAuthenticated ? (
    <Redirect to={{ pathname: "/signin" }} />
  ) : (
    <Route {...rest} component={component} />
  );
};

const PublicRoute = ({ component, isAuthenticated, ...rest }) => {
  return isAuthenticated ? (
    <Redirect to={{ pathname: "/" }} />
  ) : (
    <Route {...rest} component={component} />
  );
};

const App = (props) => {
  useEffect(() => {
    const fetchData = async () => {
      if (TENANT_ID) {
        props.fetchTenantDetails();
        axios
          .post(partnerURL + `/api/get-tenant-profile`, {
            tenantId: TENANT_ID,
          })
          .then((res) => {
            const { faviconUrl, name } = res.data.data;
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
              link = document.createElement("link");
              link.rel = "icon";
              document.head.appendChild(link);
            }
            link.href = faviconUrl;
            document.title = `SuperAdmin-${name}`;
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        document.title = "SuperAdmin-AiSensy";
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.head.appendChild(link);
        }
        link.href =
          "https://whatsapp-media-library.s3.ap-south-1.amazonaws.com/IMAGE/6353da2e153a147b991dd812/4361142_favicon1.png";
      }
    };
    fetchData();
  }, []);
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute
          exact
          path="/signIn"
          component={SignInPage}
          isAuthenticated={props.isAuthenticated}
        />
        <Route exact path="/register-waba/:id" component={RegisterWaba} />
        <Route exact path="/migrate-waba/:id" component={MigrateWaba} />
        <Route exact path="/connect-catalog/:id" component={ConnectCatalog} />
        <Route exact path="/connect-ads/:id" component={ConnectMetaAd} />
        <PrivateRoute
          path="/"
          component={ProjectsLayout}
          isAuthenticated={props.isAuthenticated}
        />
        <Route component={ErrorPage} />
      </Switch>
    </BrowserRouter>
  );
};

export default connect(
  (state) => ({
    isAuthenticated: state.login.isAuthenticated,
  }),
  { fetchTenantDetails }
)(App);
