import React, { Component } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import WccUpdatedRow from "./WccUpdatedRow";
import { isoToName } from "./../../config/dialCodes";
import { update } from "lodash";

class JsonDiff extends Component {
  constructor(props) {
    super(props);
  }

  showJsonDiff = (json1, json2) => {
    function findChangedValues(oldData, newData) {
      const updatedCountries = {};

      const fields = ["marketing", "utility", "authentication", "service"];

      for (const country in newData.marketing) {
        if (newData.marketing.hasOwnProperty(country)) {
          let changedCurrency = null;

          for (const field of fields) {
            if (newData[field][country]) {
              const oldValue = oldData[field][country];
              const newValue = newData[field][country];

              for (const currency in newValue) {
                if (
                  newValue.hasOwnProperty(currency) &&
                  oldValue[currency] !== newValue[currency]
                ) {
                  changedCurrency = currency;
                  break;
                }
              }

              if (changedCurrency) {
                break;
              }
            }
          }
          if (changedCurrency) {
            updatedCountries[country] = {
              currencyType: changedCurrency,
            };

            for (const field of fields) {
              updatedCountries[country][field] = {
                old: oldData[field][country]
                  ? oldData[field][country][changedCurrency]
                  : null,
                new: newData[field][country]
                  ? newData[field][country][changedCurrency]
                  : null,
              };
            }
          }
        }
      }

      return updatedCountries;
    }
    const updatedValues = findChangedValues(json1, json2);
    return updatedValues;
  };

  render() {
    const {
      openDialog2,
      toggleDialog2,
      aisensyWccPlan,
      updatedWccPlan,
      updatePlanFamily,
      convos,
      classes,
      origin,
    } = this.props;
    const updatedValues = this.showJsonDiff(aisensyWccPlan, updatedWccPlan);
    return (
      <Dialog
        open={openDialog2}
        onClose={toggleDialog2}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              Review Wcc Plan
            </Typography>

            <IconButton color="primary" size="small" onClick={toggleDialog2}>
              <Clear />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent style={{ display: "flex", gap: "20px" }}>
          {Object.keys(updatedValues || {}).length === 0 ? (
            <p>No Changes</p>
          ) : (
            <Grid
              container
              alignItems="center"
              className={classes.content}
              spacing={0}
              style={{ marginTop: 24 }}
            >
              <Grid item xs={12} md={2}></Grid>
              <Grid
                item
                container
                alignItems="center"
                xs={12}
                md={10}
                style={{ marginBottom: "8px" }}
              >
                <Grid md={12} style={{ display: "flex", marginBottom: 10 }}>
                  {convos.map(({ name }) => {
                    return (
                      <Grid
                        item
                        md={3}
                        style={{
                          alignItems: "center",
                          borderBottom: "1px solid #c4c4c4",
                          background: "#461486",
                          marginRight: 8,
                          color: "white",
                        }}
                      >
                        <Typography variant="h4" align="center">
                          {name}
                        </Typography>
                      </Grid>
                    );
                  })}
                </Grid>
                <Box md={2}></Box>
              </Grid>
              <Grid
                container
                alignItems="center"
                style={{
                  maxHeight: "50vh",
                  overflow: "scroll",
                }}
              >
                <Grid item xs={12}>
                  <WccUpdatedRow
                    convos={convos}
                    classes={classes}
                    updatedValues={updatedValues}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </DialogContent>

        {!(origin === "planFamily") && (
          <DialogActions>
            <Button
              onClick={toggleDialog2}
              variant={"outlined"}
              size="medium"
              color="primary"
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={updatePlanFamily}
            >
              Update
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

export default JsonDiff;
