import React from "react";
import {
  withStyles,
  Box,
  Grid,
  IconButton,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { OpenInNew, Phone,Clear, Edit  } from "@material-ui/icons";
import { Link } from "react-router-dom";
import TemplatePreview from "./TemplatePreview";

const COLORS = {
  PENDING: "grey",
  APPROVED: "green",
  REJECTED: "red",
  PAUSED: "#e59400",
  PENDING_DELETION: "#ff7f00",
  DISABLED: "darkslategrey",
};

function TemplateLibraryDetails(props) {
  const { classes, templates, templateIndex, toggleDialog } = props;
  const template = templates[templateIndex];
  return (
    <Box px={2} className={classes.dialogContainer}>
      <Grid
        container
        spacing={1}
        alignItems="center"
        className={classes.dialogTopbar}
      >
        <Grid item>{template.name}</Grid>
        <Grid item>{`|  ${template.type}  `}</Grid>
        <Grid item style={{ marginLeft: "auto" }}>
          <IconButton
            component={Link}
            to={{
              pathname:
                "/library/create",
              state: { template },
            }}
          >
            <Edit />
          </IconButton>
          <IconButton onClick={toggleDialog}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
      <Box px={2} py={3}>
        <Grid container spacing={2} alignItems="center" justify="center">
          <TemplatePreview template={template} />
        </Grid>
      </Box>
    </Box>
  );
}

const styles = (theme) => ({
  dialogContainer: {
    width: "40vw",
    // height: "70vh",
    background: "#f5f5f5",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: "100",
  },
  previewContainer: {
    width: "100%",
    background: "rgb(251,251,251)",
    borderRadius: "15px",
    padding: "0px 0px",
    border: "1px solid lightgrey",
    wordBreak: "break-word",
    whiteSpace: "pre-line",
  },
  root: {
    position: "relative",
    height: "320px",
    marginLeft: "24px",
    marginBottom: "24px",
    overflow: "visible",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "8px",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "600",
    paddingBottom: "8px",
  },
  sample: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    fontSize: "14px",
    color: "#808080",
    height: "80px",
  },
  modal: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    background: "#fff",
    borderRadius: "5px",
    outline: 0,
    padding: "32px",
    minWidth: "250px",
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "24px",
  },
  close: {
    marginLeft: "auto",
    color: "#A9A9A9",
    "&:hover": {
      cursor: "pointer",
    },
  },
  ribbon: {
    position: "absolute",
    left: "-8px",
    top: 0,
    color: "#fff",
    fontSize: "12px",
    fontWeight: 600,
    padding: "5px 9px 4px",
    maxWidth: "50%",
    background: "#28c152",
    color: "rgb(255, 255, 255)",
    borderColor: "#023020 transparent",

    "&::before": {
      position: "absolute",
      bottom: "-9px",
      content: '""',
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "inherit",
      left: 0,
      borderWidth: "9px 0 0 9px",
    },
  },
  type: {
    display: "flex",
    alignItems: "center",
    background: "rgb(214, 237, 233, 0.9)",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    letterSpacing: "0.5px",
    color: "#0a474c",
  },
  mediaWrapper: {
    borderRadius: "50%",
    background: "#dcdcdc",
    height: "75px",
    width: "75px",
    marginRight: "8px",
  },
  actionBox: {
    position: "absolute",
    justifyContent: "space-between",
    width: "calc(100% - 32px)",
    bottom: 0,
    padding: "16px 16px",
  },
  actionBtn: {
    color: "#0a474c",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "5px",
    border: "1px solid #0a474c",
    padding: "2px 12px",

    "&:hover": {
      cursor: "pointer",
      background: "#0a474c",
      color: "white",
    },
  },
  actionBtn2: {
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "5px",
    border: "1px solid #0a474c",
    padding: "2px 12px",
    background: "#0a474c",
    color: "white",

    "&:hover": {
      cursor: "pointer",
      background: "#073135",
    },
  },
});

export default withStyles(styles)(TemplateLibraryDetails);
