import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Grid,
  TextField,
  Box,
  Typography,
  Button,
  makeStyles,
  FormControl,
  Switch,
  FormGroup,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { Autocomplete } from "@material-ui/lab";
import { formatCurrency } from "../../helpers/currency";
import EnableAddonDialog from "./Addons/EnableAddonDialog";
import { Edit } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import { TENANT_ID } from "../../config/config";

const PLANS = ["SILVER", "GOLD", "DIAMOND"];

const TYPES = ["WHITELABEL", "DIRECT"];

const ADD_ONS = [
  {
    name: "Single Sign On",
    key: "SSO",
  },
  {
    name: "Webhooks",
    key: "WEBHOOKS",
  },
  {
    name: "Shopify Integration",
    key: "SHOPIFY",
  },
  {
    name: "LeadSquared Integration",
    key: "LEADSQUARED",
  },
  {
    name: "CleverTap Integration",
    key: "CLEVERTAP",
  },
  {
    name: "MoEngage Integration",
    key: "MOENGAGE",
  },
  {
    name: "WebEngage Integration",
    key: "WEBENGAGE",
  },
  {
    name: "Woocommerce Integration",
    key: "WOOCOMMERCE",
  },
  {
    name: "Meta Ads",
    key: "META_ADS",
  },
  {
    name: "Flow Builder",
    key: "FLOW_BUILDER",
  },
  {
    name: "Pabbly Integration",
    key: "PABBLY",
  },
  {
    name: "Razorpay Integration",
    key: "RAZORPAY",
  },
  {
    name: "Whatsapp Pay",
    key: "WHATSAPP_PAY",
  },
];

const useStyles = makeStyles({
  root: {
    padding: "2rem 1rem",
    backgroundColor: "#EBF5F3",
    borderRadius: "12px",
  },
  textField: {
    backgroundColor: "white",
    border: "1px solid black",
  },

  selectFieldInput: {
    borderRadius: "8px",
    backgroundColor: "white",
    border: "1px solid black",
    padding: "12px",
  },
});

/**
 * @typedef {import("../../types").Partner} Partner
 */

/**
 * @typedef {Object} PartnerBalanceInputProps
 * @property {Partner} partner
 * @property {(action: 'ADD' | 'SUBTRACT', amount: number) => Promise<void>} updateCentralBalance
 * @property {(key: string, value: string | number) => Promise<void>} updateAttribute
 */

/**
 * @param {PartnerBalanceInputProps} props
 */

function PartnerBalanceInput({
  partner,
  updateCentralBalance,
  updateAttribute,
  activateAddon,
  updateAddon,
  disableAddon,
  agent,
  switchPlanType,
  changeTrialSettings,
  trialSetting,
}) {
  const classes = useStyles();

  const [selectedPlan, setSelectedPlan] = useState(partner.planType ?? "");
  // DIRECT or WHITELABEL
  const [selectedType, setSelectedType] = useState(partner.type ?? "");
  // ADD or SUBTRACT
  const [selectedAction, setSelectedAction] = useState("ADD");
  const selectedAddons = partner.addons || {};
  const [activeAddon, setActiveAddon] = useState();
  const [addonDialogVisibility, setAddonVisibility] = useState(false);
  const handleAddonChange = async (event) => {
    const addonKey = event.target.name;
    setActiveAddon(addonKey);
    if (event.target.checked) {
      if (selectedAddons[addonKey]) activateAddon(addonKey);
      else setAddonVisibility(true);
    } else {
      await disableAddon(addonKey);
    }
  };

  // Amount to add or subtract from partner central balance
  const [amount, setAmount] = useState(NaN);
  const [paymentRemark, setPaymentRemark] = useState();
  // Min Allowed Central Balance for PARTNER
  const [minAllowedBalance, setMinAllowedBalance] = useState(NaN);

  const handleAttriuteUpdate = async (key, value) => {
    await updateAttribute(key, value);
  };

  return (
    <Box className={classes.root}>
      {/* PARTNER CENTRAL BALANCE */}

     {(TENANT_ID && agent.power >=10) || agent.power > 11 ? (
        <>
          {" "}
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            Add or Remove Partner Central Balance
          </Typography>
          <Typography variant="body1" gutterBottom>
            Current Balance:{" "}
            {partner.centralBalance
              ? `${formatCurrency(
                  partner.currency,
                  (partner.centralBalance ?? 0) / 100000
                )}`
              : "Nill"}
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <TextField
                value={amount}
                disabled={agent.power <= 10}
                onChange={(e) => setAmount(e.target.valueAsNumber)}
                type="number"
                placeholder="Amount to be added / subtracted"
                className={classes.textField}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                value={paymentRemark}
                disabled={agent.power <= 10}
                onChange={(e) => setPaymentRemark(e.target.value)}
                placeholder="Remark - optional"
                className={classes.textField}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={2}>
              <NativeSelect
                fullWidth
                value={selectedAction}
                disabled={agent.power <= 10}
                onChange={(e) => setSelectedAction(e.target.value)}
                input={
                  <InputBase
                    classes={{
                      input: classes.selectFieldInput,
                    }}
                  />
                }
              >
                <option value="ADD">ADD</option>
                <option value="SUBTRACT">SUBTRACT</option>
              </NativeSelect>
            </Grid>

            <Grid item>
              {agent.power <= 10 ? (
                <></>
              ) : (
                <Tooltip
                  title={!paymentRemark ? "Please fill out all fields" : ""}
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        updateCentralBalance(
                          selectedAction,
                          amount,
                          paymentRemark
                        );
                        setAmount(NaN);
                        setPaymentRemark(undefined);
                      }}
                      disabled={!paymentRemark}
                    >
                      Modify Balance
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Grid>
          </Grid>{" "}
        </>
      ) : (
        <></>
      )}

      {!TENANT_ID ? <Box my={6}>{/* <Divider /> */}</Box> : <></>}
      {/* PARTNER MIN ALLOWED BALANCE */}
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Update Partner Min Allowed Balance
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Min Allowed Balance:{" "}
        {partner.centralBalance
          ? `${formatCurrency(
              partner.currency,
              (partner.minAllowedBalance ?? 0) / 100000
            )}`
          : "Nill"}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={minAllowedBalance}
            disabled={agent.power <= 10}
            onChange={(e) => setMinAllowedBalance(e.target.valueAsNumber)}
            type="number"
            placeholder="Enter amount to be set as partner min allowed central balance"
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                updateAttribute("minAllowedBalance", minAllowedBalance);
                setMinAllowedBalance(NaN);
              }}
            >
              Modify Min Allowed Balance
            </Button>
          )}
        </Grid>
      </Grid>
      <Box my={6}>{/* <Divider /> */}</Box>
      {/* PARTNER PLAN TYPE */}
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Change Partner Plan Type
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Plan Type - {partner.planType || "Nill"}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Autocomplete
            disableClearable
            disabled={agent.power <= 10}
            options={PLANS.map((option) => option)}
            onChange={(e, value, reason) => setSelectedPlan(value)}
            value={selectedPlan}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                InputProps={{ ...params.InputProps }}
                placeholder="Select plan type"
                style={{
                  border: "1px solid ",
                  background: "white",
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => switchPlanType(selectedPlan)}
            >
              Update Plan
            </Button>
          )}
        </Grid>
      </Grid>
      <Box my={6}>{/* <Divider /> */}</Box>
      {/* PARTNER TYPE */}
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Change Partner Type (WHITELABEL or DIRECT)
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Type - {partner.type || "Nill"}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Autocomplete
            disableClearable
            disabled={agent.power <= 10}
            options={TYPES.map((option) => option)}
            onChange={(e, value, reason) => {
              if (reason == "select-option") {
                setSelectedType(value);
              }
            }}
            value={selectedType}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                InputProps={{ ...params.InputProps }}
                placeholder="Select plan"
                style={{
                  border: "1px solid ",
                  background: "white",
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("type", selectedType)}
            >
              Change Type
            </Button>
          )}
        </Grid>
      </Grid>
      <Box my={6}></Box>
      <Grid item xs={12} md={12}>
        <Box>
          {/* <Box mt={3} /> */}
          <Grid container alignItems="center">
            <Grid md={10} xs={10}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Signup Management
              </Typography>
            </Grid>
            <Grid md={2} xs={2}>
              <Grid container>
                <Switch
                  checked={!!trialSetting?.enableSignUp}
                  name="enableSignUp"
                  disabled={!(agent.power >= 10)}
                  onChange={(e) => changeTrialSettings(e)}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box>
          {/* <Box mt={3} /> */}
          <Grid container alignItems="center">
            <Grid md={10} xs={10}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Trial Management
              </Typography>
            </Grid>
            <Grid md={2} xs={2}>
              <Grid container>
                <Switch
                  checked={!!trialSetting?.enableTrial}
                  name="enableTrial"
                  disabled={!(agent.power >= 10)}
                  onChange={(e) => changeTrialSettings(e)}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Box>
          {/* <Box mt={3} /> */}
          <Grid container alignItems="center">
            <Grid md={10} xs={10}>
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Free WCC credit Management
              </Typography>
            </Grid>
            <Grid md={2} xs={2}>
              <Grid container>
                <Switch
                  checked={!!trialSetting?.enableTrialAmount}
                  name="enableTrialAmount"
                  disabled={!(agent.power >= 10)}
                  onChange={(e) => changeTrialSettings(e)}
                  color="primary"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Add or Remove Addons
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Addons -{" "}
        {Object.keys(selectedAddons)
          .filter((key) => selectedAddons[key]?.status === "active")
          .map((key) => selectedAddons[key].name)
          .join(", ")}
      </Typography>
      <FormControl component="fieldset">
        <FormGroup row>
          {ADD_ONS.map((addon) => {
            return (
              <FormControlLabel
                control={
                  <Grid container alignItems="center">
                    <Grid
                      item
                      xs={
                        selectedAddons[addon.key]?.status === "active" ? 6 : 12
                      }
                      style={{
                        display: "flex",
                        justifyContent:
                          selectedAddons[addon.key]?.status === "active"
                            ? "flex-end"
                            : "center",
                      }}
                    >
                      <Switch
                        disabled={agent.power <= 10}
                        checked={selectedAddons[addon.key]?.status === "active"}
                        onChange={handleAddonChange}
                        name={addon.key}
                        color="primary"
                      />
                    </Grid>
                    {selectedAddons[addon.key]?.status === "active" && (
                      <Grid item xs={6}>
                        <IconButton
                          color="primary"
                          onClick={() => {
                            setActiveAddon(addon.key);
                            setAddonVisibility(true);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                }
                label={addon.name}
                labelPlacement="bottom"
              />
            );
          })}
        </FormGroup>
      </FormControl>
      {addonDialogVisibility && (
        <EnableAddonDialog
          open={addonDialogVisibility}
          onClose={() => {
            setAddonVisibility(false);
          }}
          addonKey={activeAddon}
          addon={selectedAddons[activeAddon]}
          activateAddon={activateAddon}
          modifyAddon={updateAddon}
          classes={classes}
        />
      )}
    </Box>
  );
}
const connectedPartnerBalanceInput = connect((state) => ({
  agent: state.login.user,
}))(PartnerBalanceInput);
export default connectedPartnerBalanceInput;
