import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import CreateTemplateLibraryForm from "./CreateTemplateLibraryForm";

class CreateTemplateLibraryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { classes } = this.props;
    const template =
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.template;

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item>
            <Box pl={{ xs: 2, md: 2 }}>
              <IconButton component={Link} to={"/library"}>
                <ArrowBack />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs>
            <Typography variant="h3">New Template Library Message</Typography>
          </Grid>
        </Grid>
        <Box my={3} />
        {/* Layout */}
        <Grid container justify="center">
          <Grid item xs={12} md={11} lg={10}>
            <CreateTemplateLibraryForm template={template} />
          </Grid>
        </Grid>
        <Box my={15} />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
});

export default withStyles(styles)(CreateTemplateLibraryPage);
