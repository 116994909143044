const currencyFormatter = {
  USD: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }),
  INR: new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }),
};

export const formatCurrency = (currency, value) => {
  const formatter = currencyFormatter[currency];

  if (!formatter) return value;

  return formatter.format(value);
};
