import React, { Component } from "react";
import axios from "axios";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  TextField,
  InputLabel,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Snackbar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { URL } from "../../config/config";
import { connect } from "react-redux";
import { TablePagination } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CreateAgent from "./CreateAgent";
import { Alert } from "@material-ui/lab";

const powerToRole = {
  9: "SALES",
  10: "SUPPORT",
  11: "MANAGER",
  12: "ADMIN",
  20: "OWNER",
};

class Manage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 0,
      rowsPerPage: 5,
      openDeleteDialog: false,
      openEditDialog: false,
      selectedAgent: {},
      powerChange: 0,
      isDropdownOpen: false,
      createAgentDialog: false,
      alert: "",
      alertMessage: "",
    };
  }

  componentDidMount() {
    this.fetchAllAgents();
  }

  fetchAllAgents = () => {
    axios
      .get(URL + "/superadmin/get-agents")
      .then((res) => {
        this.setState({ data: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleDelete = (id) => {
    axios
      .post(URL + "/superadmin/delete-agent", {
        agentId: id,
      })
      .then((res) => {
        this.fetchAllAgents();
        this.handleCloseDeleteDialog();
      })
      .catch((err) => {
        console.log(err);
        this.handleCloseDeleteDialog();
      });
  };

  handleEdit = (id) => {
    const { powerChange } = this.state;
    axios
      .post(URL + "/superadmin/edit-agent", {
        agentId: id,
        powerChange,
      })
      .then((res) => {
        this.fetchAllAgents();
        this.handleCloseEditDialog();
      })
      .catch((err) => {
        console.log(err);
        this.handleCloseEditDialog();
      });
  };

  handleOpenDeleteDialog = (agent) => {
    this.setState({
      openDeleteDialog: true,
      selectedAgent: agent,
    });
  };

  handleCloseDeleteDialog = () => {
    this.setState({
      openDeleteDialog: false,
      selectedAgent: {},
    });
  };

  handleOpenEditDialog = (agent) => {
    this.setState({
      openEditDialog: true,
      selectedAgent: agent,
    });
  };

  handleCloseEditDialog = () => {
    this.setState({
      openEditDialog: false,
      selectedAgent: {},
    });
  };

  handlePowerChange = (event) => {
    this.setState({
      powerChange: Number(event),
    });
  };

  setAlert = (alert, alertMessage) => {
    this.setState({ alert, alertMessage });
  };

  onSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({ alert: "", alertMessage: "" });
  };

  onClose = () => {
    this.setState({ createAgentDialog: false });
  };

  render() {
    const { classes, match, agent } = this.props;
    const { data, page, rowsPerPage, selectedAgent } = this.state;

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box pl={{ xs: 2, md: 3 }}>
              <Typography variant="h3">AiSensy Employee</Typography>
            </Box>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ createAgentDialog: true })}
              style={{ marginRight: "20px" }}
            >
              Create Agent
            </Button>
          </Grid>
        </Grid>
        <Box my={3} />
        {/* Layout */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "40px",
          }}
        >
          <TableContainer
            component={Paper}
            style={{
              width: "77%",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "20%" }}>
                    <strong>Name</strong>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <strong>Contact</strong>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell style={{ width: "20%" }}>
                    <strong>Power</strong>
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    <strong>Delete</strong>
                  </TableCell>
                  <TableCell style={{ width: "10%" }}>
                    <strong>Edit</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.length > 0 ? (
                  data.map((row) => (
                    <TableRow key={row.name}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.contact}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        {this.props.agent.id === row._id ? (
                          <strong>ME</strong>
                        ) : powerToRole[row.power] ? (
                          powerToRole[row.power]
                        ) : (
                          <strong style={{ color: "green" }}>
                            {" "}
                            REQUESTING
                          </strong>
                        )}
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() => this.handleOpenDeleteDialog(row)}
                          style={row.power !== 20 ? { color: "red" } : {}}
                          disabled={
                            row.power === 20 || this.props.agent.id === row._id
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() => this.handleOpenEditDialog(row)}
                          disabled={
                            row.power === 20 || this.props.agent.id === row._id
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <Box
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Nothing to show here!
                  </Box>
                )}
              </TableBody>
            </Table>

            {/* <div className={classes.fixedBottomContainer}>
              <TablePagination
                component="div"
                count={data.length}
                page={page}
                onChangePage={(event, newPage) =>
                  this.setState({ page: newPage })
                }
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[25]}
              />
            </div> */}
          </TableContainer>
        </div>

        {/* DELETE DIALOG */}

        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete{" "}
              <strong>{selectedAgent.name}</strong> ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.handleDelete(selectedAgent._id)}
              color="primary"
              autoFocus
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* EDIT DIALOG */}

        <Dialog
          open={this.state.openEditDialog}
          onClose={this.handleCloseEditDialog}
          aria-labelledby="edit-dialog-title"
          PaperComponent={Paper}
          PaperProps={{
            style: {
              width: "500px",
              height: "200px",
            },
          }}
        >
          <DialogTitle id="edit-dialog-title">{`Edit ${selectedAgent.name}`}</DialogTitle>
          <DialogContent>
            <Autocomplete
              id="power-autocomplete"
              options={Object.entries(powerToRole).filter(
                ([value, role]) => role !== "OWNER"
              )}
              getOptionLabel={([value, role]) => role}
              clearOnEscape={false}
              disableClearable
              autoHighlight
              onChange={(event, newValue) => {
                this.handlePowerChange(newValue ? newValue[0] : "");
              }}
              style={{ width: "100%", height: "50%" }}
              renderInput={(params) => (
                <TextField {...params} label="Set Power" variant="outlined" />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseEditDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => this.handleEdit(selectedAgent._id)}
              color="primary"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <CreateAgent
          dialogOpen={this.state.createAgentDialog}
          onClose={this.onClose}
          loadData={this.fetchAllAgents}
          setAlert={this.setAlert}
        />

        <Snackbar
          open={!!this.state.alert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
        >
          <Alert severity={this.state.alert} onClose={this.onSnackbarClose}>
            {this.state.alertMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
});
const connectedManagePage = connect((state) => ({
  agent: state.login.user,
}))(Manage);
export default withStyles(styles)(connectedManagePage);
