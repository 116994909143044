import React, { Component } from "react";
import "react-dates/lib/css/_datepicker.css";
import { Autocomplete } from "@material-ui/lab";
import {
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  withStyles,
  CircularProgress,
} from "@material-ui/core";

import DateFilter from "./DateFilter";
import "../../react-dates-custom.css";
import { PLANS, assitantFilter } from "./CRMConfig";
import LatestDateFilter from "./CRMDetails/KanbanComponent/LatestDateFilter";

class CRMFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        value: null,
        filter: null,
        operator: null,
      },
    };
  }

  handleAutoCompleteInput = (field, value, reason) => {
    const row = { ...this.state.filter };
    if (reason === "input" || reason === "select-option") {
      row[field] = value;
      if (field === "filter") {
        row["operator"] = null;
        row["value"] = null;
      }
      if (field === "operator") {
        row["value"] = null;
      }
      this.setState({ filter: row });
    }
  };

  render() {
    const { classes, isLoading, showAssistantFilter } = this.props;

    return (
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          {showAssistantFilter && (
            <Box mt={2} mb={2} className={classes.filterTypeContainer}>
              <Grid
                item
                container
                xs={9}
                spacing={1}
                alignItems="center"
                style={{ maxWidth: "700px" }}
              >
                <Typography variant="body1" style={{ minHeight: 30 }}>
                  Attributes
                </Typography>
                <Grid item container spacing={1} alignItems="center" xs={12}>
                  <Grid item xs>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      options={assitantFilter}
                      getOptionLabel={(option) => option.name}
                      onChange={(k, value, reason) =>
                        this.handleAutoCompleteInput("filter", value, reason)
                      }
                      value={this.state.filter?.filter?.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          classes={{ root: classes.textFieldRoot }}
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select Filter"
                          name="name"
                        />
                      )}
                      classes={{ inputRoot: classes.inputRoot }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Autocomplete
                      disableClearable
                      autoHighlight
                      options={(this.state.filter?.filter?.filters || []).map(
                        (option) => option
                      )}
                      getOptionLabel={(option) => {
                        return option || "";
                      }}
                      onChange={(k, value, reason) =>
                        this.handleAutoCompleteInput("operator", value, reason)
                      }
                      value={this.state.filter.operator}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          classes={{ root: classes.textFieldRoot }}
                          InputProps={{ ...params.InputProps }}
                          placeholder="Operator"
                          name="operator"
                        />
                      )}
                      classes={{ inputRoot: classes.inputRoot }}
                    />
                  </Grid>
                  <Grid item xs>
                    {this.state.filter?.filter?.name === "activePlan" ? (
                      <>
                        <Autocomplete
                          disableClearable
                          autoHighlight
                          options={PLANS.map((option) => option)}
                          onChange={(_, value) =>
                            this.handleAutoCompleteInput(
                              "value",
                              value,
                              "input"
                            )
                          }
                          value={this.state.filter.value}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              classes={{ root: classes.textFieldRoot }}
                              InputProps={{ ...params.InputProps }}
                              placeholder="Select plan"
                              name="Select plan"
                            />
                          )}
                        />
                      </>
                    ) : (
                      <TextField
                        variant="outlined"
                        classes={{ root: classes.textFieldRoot }}
                        onChange={(e) =>
                          this.handleAutoCompleteInput(
                            "value",
                            e.target.value,
                            "input"
                          )
                        }
                        value={this.state.filter.value}
                        placeholder="Attribute Value"
                        name="value"
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
        <Grid>
          <Grid container spacing={2} xs={12} style={{ marginBottom: "32px" }}>
            <Grid item>
              <DateFilter
                classes={classes}
                filterKey="APIProcuredOn"
                filterName="API Procured On"
                applied={this.props.applied}
                setDate={this.props.setDate}
                setFocus={this.props.setFocus}
                clearDate={this.props.clearDate}
                checkDateInput={this.props.checkDateInput}
              />
            </Grid>

            <Grid item>
              <LatestDateFilter
                filterKey="APIProcuredOn"
                setDate={this.props.setDate}
                setFocus={this.props.setFocus}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} xs={12}>
            <Grid item>
              <DateFilter
                classes={classes}
                filterKey="createdAt"
                filterName="Created At"
                applied={this.props.applied}
                setDate={this.props.setDate}
                setFocus={this.props.setFocus}
                clearDate={this.props.clearDate}
                checkDateInput={this.props.checkDateInput}
              />
            </Grid>

            <Grid item>
              <LatestDateFilter
                filterKey="createdAt"
                setDate={this.props.setDate}
                setFocus={this.props.setFocus}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          {isLoading === true ? (
            <Box m="0.7em 2em">
              <CircularProgress />
            </Box>
          ) : (
            <Grid style={{ display: "flex" }}>
              <Grid item>
                <Box mt={2} mb={2} mr={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      this.props.apply(this.state.filter);
                    }}
                  >
                    Apply
                  </Button>
                </Box>
              </Grid>
              <Grid item>
                <Box mt={2} mb={2}>
                  <Button
                    onClick={() => {
                      this.props.clearAll();
                      this.setState({
                        filter: { value: null, filter: null, operator: null },
                      });
                    }}
                  >
                    Clear All
                  </Button>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    );
  }
}

const styles = () => ({
  root: {
    width: "80vw",
    minHeight: "400px",
  },

  textFieldRoot: {
    padding: "0",
    width: "100%",
    border: "none",
    fontWeight: 500,
    outline: "none",
    fontSize: "15px",
    boxShadow: "none",
    borderRadius: "8px",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "rgb(240,240,240)",
  },
});

export default withStyles(styles)(CRMFilter);
