import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import { loginUser, loginWithToken } from "./loginState";

import {
  withStyles,
  Typography,
  Box,
  Button,
  Divider,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import axios from "axios";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_CLIENT_ID, URL } from "../../config/config";

import AuthPageWrapper from "../../commons/Auth/AuthPageWrapper";
import LOGO from "../../static/logo.jpg";

class SignInPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      status: "",
      statusMessage: "",
    };
  }

  onGoogleSignIn = (accessToken) => {
    axios
      .post(URL + "/auth/google-signin", { accessToken, isSuperAdmin: true })
      .then((response) => {
        const { token } = response.data;
        this.props.loginWithToken(token);
      })
      .catch((error) => {
        console.error(error);
        const errorResponse = error.response;
        const errorMessage = errorResponse
          ? errorResponse.data.errorMessage
          : "something went wrong! Check your network connection.";
        errorMessage &&
          this.setState({ status: "error", statusMessage: errorMessage });
      });
  };

  onSnackbarClose = (event, reason) => {
    if (reason === "clickaway") return;
    this.setState({ status: "", statusMessage: "" });
  };

  onGoogleAuthFailure = (response) => {
    const { error, details } = response;
    const alerts = {
      idpiframe_initialization_failed:
        "Third party cookies are disabled! Action not authorized.",
      popup_closed_by_user: "Popup closed by User. Google signUp failure",
      access_denied: "Access denied by user. Google signUp failure",
    };
    this.setState({ status: "error", statusMessage: alerts[error] });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password } = this.state;
    const { classes, isAuthenticated, loginUser, tenantDetails } = this.props;
    if (isAuthenticated) {
      return <Redirect to="/" />;
    } else {
      return (
        <AuthPageWrapper>
          <Box p={1} display="flex" alignItems="center" flexDirection="column">
            {/*<Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <img
              src={
                Object.keys(tenantDetails).length
                  ? tenantDetails.brandLogo
                  : LOGO
              }
              alt={
                Object.keys(tenantDetails).length
                  ? tenantDetails.name
                  : "AiSensy"
              }
              className={classes.logo}
            />
            <Typography
              variant="h4"
              component="div"
              align="center"
              gutterBottom
              style={{ paddingBottom: "30px" }}
            >
              Sign In
            </Typography>
            <input
              id="email"
              placeholder="Email or User Name"
              type="email"
              name="email"
              onChange={this.handleChange}
              className={classes.textFieldRoot}
            />
            <input
              id="password"
              placeholder="Password"
              type="password"
              name="password"
              onChange={this.handleChange}
              className={classes.textFieldRoot}
            />
            {/*
              <Typography
                variant="body2"
                gutterBottom
                style={{ padding: "10px 20px" }}
              >
                By continuing, I accept the AiTimey{" "}
                <Link to="/termsofservice" target="blank">
                  <Typography variant="body2" component="span" color="primary">
                    Terms of Services
                  </Typography>
                </Link>{" "}
                and acknowledge the{" "}
                <Link to="privacypolicy">
                  <Typography variant="body2" component="span" color="primary">
                    Privacy Policy
                  </Typography>
                </Link>
                .
              </Typography>
            */}
            <Button
              fullWidth
              variant="contained"
              size="large"
              margin={0}
              color="primary"
              disabled={email === "" || password === ""}
              className={classes.formButton}
              onClick={() => {
                loginUser(email, password);
              }}
            >
              Continue
            </Button>
            <Typography variant="body1" align="center">
              OR
            </Typography>
            <div>
              <GoogleLogin
                clientId={GOOGLE_CLIENT_ID}
                buttonText="Continue with Google"
                onSuccess={this.onGoogleSignIn}
                onFailure={this.onGoogleAuthFailure}
                cookiePolicy={"single_host_origin"}
                className={classes.googleButtonContainer}
              />
            </div>
            <Divider style={{ width: "100%" }} />
            <Box align="center" width="100%" paddingTop={2} paddingBottom={1}>
              {/*
                <Link to="/reset">
                  <Typography
                    variant="body1"
                    component="span"
                    color="primary"
                    style={{ paddingRight: "2px" }}
                  >
                    Can't sign in ?
                  </Typography>
                </Link>{" "}
                •{" "}
              */}
              <Link to="/signup">
                <Typography
                  variant="body1"
                  component="span"
                  color="primary"
                  style={{ paddingLeft: "2px" }}
                  align="center"
                >
                  Sign up for new account
                </Typography>
              </Link>
            </Box>
          </Box>
          <Snackbar
            open={!!this.state.status}
            autoHideDuration={20000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            onClose={this.onSnackbarClose}
          >
            <Alert
              severity={this.state.status || "error"}
              onClose={this.onSnackbarClose}
            >
              {this.state.statusMessage}
            </Alert>
          </Snackbar>
        </AuthPageWrapper>
      );
    }
  }
}

const styles = (theme) => ({
  // css rules
  root: {},
  avatar: {
    margin: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.primary.main,
  },
  logo: {
    height: 50,
    marginBottom: 20,
  },
  textFieldRoot: {
    backgroundColor: "#f1f1f1",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    width: "100%",
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: "14px 15px",
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "15px",
    fontWeight: 500,
  },
  formButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  googleButtonContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    height: "50px",
    backgroundColor: "lightgrey",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: "15px",
    justifyContent: "center",
  },
});

const connectedSignInPage = connect(
  (state) => ({
    isAuthenticated: state.login.isAuthenticated,
    isLoading: state.login.isLoading,
    error: state.login.error,
    errmsg: state.login.errmsg,
    tenantDetails: state.tenant.tenant,
  }),
  { loginUser, loginWithToken }
)(SignInPage);

export default withStyles(styles)(connectedSignInPage);
