import { Snackbar, withStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AssistantsPage from "../Assistants/AssistantsPage";
import { Alert } from "@material-ui/lab";

const KycSignalPage = (props) => {
  const [alertInfo, setAlertInfo] = useState({});

  const alertHandler = (alert, alertMsg, alertSeverity) => {
    setAlertInfo({
      alert,
      alertMsg,
      alertSeverity,
    });
  };

  const closeSnackbar = () => {
    setAlertInfo({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  return (
    <>
      <AssistantsPage bvbp={true} alertHandler={alertHandler} />
      <Snackbar
        open={alertInfo?.alert}
        autoHideDuration={4000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={closeSnackbar} severity={alertInfo?.alertSeverity}>
          {alertInfo?.alertMsg}
        </Alert>
      </Snackbar>
    </>
  );
};

export default KycSignalPage;
