import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import axios from "axios";
import { AISENSY_PARTNER_ID, URL } from "../../config/config";

class CreateWhatsappPaymentsPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aisensyPlan: {
        ...JSON.parse(
          JSON.stringify(
            this.props.aisensyPlan || {
              ...SAMPLE_PLAN,
              partnerId: this.props.partnerId || AISENSY_PARTNER_ID,
              assistantId: this.props.assistantId,
            }
          )
        ),
      },
      isLoading: false,
      isAisensyPlanLoading: false,
    };
  }

  getRoundedSlabs = (slabs) => {
    for (const slab of slabs) {
      const { INR } = slab;
      INR.fee = Math.ceil(INR.fee);
    }

    return slabs;
  };

  createPlanFamily = () => {
    const { slabs } = this.state.aisensyPlan;
    axios
      .post(URL + `/superadmin/aisensy-whatsapp-payments-plans`, {
        ...this.state.aisensyPlan,
        slabs: this.getRoundedSlabs(slabs),
      })
      .then((res) => {
        this.setState({ isAisensyPlanLoading: false });
        this.props.toggleDialog();
        this.props.fetchFamilies();
        // this.setState({});
      })
      .catch((error) => {
        console.log(error);
        this.props.showErrorMessage(
          "Error in create AiSensy WhatsApp Plan Family"
        );
        this.setState({ isAisensyPlanLoading: false });
      });
  };

  updateFamily = () => {
    const { slabs } = this.state.aisensyPlan;
    axios
      .patch(
        URL +
          `/superadmin/aisensy-whatsapp-payments-plans/${this.props.aisensyPlan._id}`,
        { ...this.state.aisensyPlan, slabs: this.getRoundedSlabs(slabs) }
      )
      .then((_) => {
        this.props.showSuccessMessage("Plan updated successfully!");
        this.setState({
          isAisensyPlanLoading: false,
        });
        this.props.toggleDialog();
        this.props.fetchFamilies();
      })
      .catch((error) => {
        const message =
          error?.response?.data?.error?.message || "Something went wrong!";
        this.setState({
          isAisensyPlanLoading: false,
        });
        this.props.showErrorMessage(message);
      });
  };

  render() {
    const { classes, convertPrice, openDialog, toggleDialog, planFamilyId } =
      this.props;
    const { isLoading, aisensyPlan } = this.state;
    const { slabs } = aisensyPlan;

    return isLoading ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog
          open={openDialog}
          closeDialog={toggleDialog}
          maxWidth={"sm"}
          fullWidth
        >
          <DialogTitle>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">
                {planFamilyId
                  ? "Update WhatsApp Payments Plan Family"
                  : "Create WhatsApp Payments Plan Family"}
              </Typography>
              <IconButton
                color="primary"
                size="small"
                variant="filled"
                onClick={toggleDialog}
              >
                <Clear
                  style={{
                    position: "fixed",
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          <Box
            style={{ padding: "8px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <DialogContent>
              {slabs?.map((slab, idx) => {
                const { startAmount, INR } = slab;
                return (
                  <Grid container>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <Typography
                        required
                        className={classes.typo}
                        variant="h5"
                        align="left"
                      >
                        Slab {idx + 1}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: 500,
                            letterSpacing: 0.5,
                          }}
                        >
                          ₹{startAmount / convertPrice} {" - "}
                          {slabs?.[idx + 1]?.startAmount
                            ? `₹${
                                slabs?.[idx + 1].startAmount / convertPrice - 1
                              }`
                            : "beyond"}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3} container alignItems="center">
                      <TextField
                        style={{ width: "100%", padding: "3px 0px" }}
                        required
                        type="Number"
                        variant="outlined"
                        placeholder="0"
                        disabled={this.props.disableFields}
                        className={classes.input}
                        error={this.state.displayNameError}
                        margin="dense"
                        value={slabs[idx].INR.fee / convertPrice}
                        onChange={(e) => {
                          const value = Number(e.target.value) * 100000 || 0;
                          slabs[idx].INR.fee = value;
                          this.setState({
                            aisensyPlan: { ...aisensyPlan, slabs },
                          });
                        }}
                        InputProps={{
                          startAdornment: "₹",
                        }}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </DialogContent>

            <DialogActions style={{ padding: "24px 16px 8px 0px" }}>
              <Button
                variant={!this.props.disableFields ? "outlined" : "contained"}
                size="medium"
                color="primary"
                onClick={toggleDialog}
              >
                Cancel
              </Button>
              {!this.props.disableFields && (
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  disabled={
                    this.state.activeStep &&
                    !this.state.name &&
                    !this.props.familyName
                  }
                  onClick={() =>
                    !planFamilyId
                      ? this.createPlanFamily()
                      : this.updateFamily()
                  }
                >
                  {!planFamilyId ? "Create" : "Update"}
                </Button>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
}

export default CreateWhatsappPaymentsPlan;

const SAMPLE_PLAN = {
  partnerId: AISENSY_PARTNER_ID,
  slabs: [
    {
      startAmount: 0,
      INR: { fee: 700000 },
    },
    {
      startAmount: 100100000,
      INR: { fee: 1200000 },
    },
    {
      startAmount: 500100000,
      INR: { fee: 1800000 },
    },
    {
      startAmount: 2000100000,
      INR: { fee: 3000000 },
    },
    {
      startAmount: 5000100000,
      INR: { fee: 5000000 },
    },
  ],
};
