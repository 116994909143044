import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Tooltip,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { isUrlLive } from "../../helpers/url";
import { useCopyToClipboard } from "react-use";

const WEBHOOKS_TOPICS = [
  "business.created",
  "business.deleted",
  "business.updated",
  "project.created",
  "project.deleted",
  "project.status.updated",
  "project.waba.updated",
  "project.plan.updated",
  "wa_template.status.updated",
  "project-wc-created",
  "project-payment-captured",
];

const useStyles = makeStyles({
  root: {
    padding: "2rem 1rem 1rem 1rem",
    backgroundColor: "#EBF5F3",
    borderRadius: "12px",
  },
  textField: {
    backgroundColor: "white",
    border: "1px solid black",
  },
  textFieldDisabled: {
    backgroundColor: "white",
  },
});

/**
 * @typedef {import("../../types").Partner} Partner
 */

/**
 * @typedef {Object} PartnerCredentialsInputProps
 * @property {Partner} partner
 * @property {() => Promise<string | undefined>} resetPassword
 * @property {() => Promise<any | undefined>} resetApiKey
 * @property {(webhookUrl: string) => Promise<any | undefined>} updateSharedSecret
 */

/**
 * @param {PartnerCredentialsInputProps} props
 */
function PartnerCredentialsInput({
  partner,
  resetPassword,
  resetApiKey,
  updateSharedSecret,
  handleWebhookTopic,
  showSuccessMessage,
  showErrorMessage,
  agent,
}) {
  const classes = useStyles();
  const [_, copyToClipboard] = useCopyToClipboard();

  const [newApiKey, setNewApiKey] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newWebhookUrl, setNewWebhookUrl] = useState(partner.webhookUrl);
  const [isWebhookVerified, setIsWebhookVerified] = useState(false);
  const [sharedSecret, setSharedSecret] = useState(partner.sharedSecret);
  const [webhooks, setWebhooks] = useState(partner.partnerWebhooks || []);

  async function handleVerifyWebhook() {
    const isLive = await isUrlLive(newWebhookUrl);
    if (isLive && webhooks?.length > 0) {
      setIsWebhookVerified(true);
      showSuccessMessage("Webhook Subscription Verified!");
    } else {
      setIsWebhookVerified(false);
      if (!isLive) {
        showErrorMessage("Webhook URL is not valid");
      } else {
        showErrorMessage("Please select at least one webhook topic");
      }
      console.log("Webhook URL is not valid");
    }
  }

  async function handleResetPassword() {
    const newPass = await resetPassword();
    newPass && setNewPassword(newPass);
  }

  async function handleResetApiKey() {
    const newKey = await resetApiKey();
    newKey && setNewApiKey(newKey.apiKey);
  }

  async function handleUpdateWebhookSecret() {
    const data = await updateSharedSecret();
    data && setSharedSecret(data.sharedSecret);
  }

  async function handleCheckboxChange(topic) {
    if (webhooks.includes(topic)) {
      setWebhooks(webhooks.filter((item) => item !== topic));
    } else {
      setWebhooks([...webhooks, topic]);
    }
  }

  async function handleUpdateWebhook() {
    const data = await handleWebhookTopic(newWebhookUrl, webhooks);
    if (data) {
      setNewWebhookUrl(data.webhookUrl);
      setWebhooks(data.partnerWebhooks);
    }
  }

  return (
    <Box className={classes.root}>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Login
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            className={classes.textFieldDisabled}
            disabled={true}
            type="text"
            fullWidth
            value={partner.loginUsername}
          />
        </Grid>
      </Grid>
      {/** Partner Password */}
      <Box my={6}></Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Login Password
      </Typography>
      <Typography variant="body2" gutterBottom>
        ** Password shown here will lost after page refresh **
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            className={classes.textFieldDisabled}
            disabled={true}
            type="text"
            placeholder="Click Reset to get a new password"
            fullWidth
            value={newPassword}
            InputProps={{
              endAdornment: (
                <span>
                  <Tooltip title="Click to copy Password">
                    <IconButton
                      size="small"
                      disabled={!newPassword}
                      onClick={() => copyToClipboard(newPassword)}
                    >
                      <FileCopyIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </span>
              ),
            }}
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetPassword}
            >
              Reset
            </Button>
          )}
        </Grid>
      </Grid>
      {/** Parter API Key */}
      <Box my={6}></Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner API Key (Generated key will be displayed here after clicking
        Regenerate)
      </Typography>
      <Typography variant="body2" gutterBottom>
        ** API Key shown here will lost after page refresh **
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            className={classes.textFieldDisabled}
            disabled={true}
            type="text"
            placeholder="Click Regenerate to get a new key"
            fullWidth
            value={newApiKey}
            InputProps={{
              endAdornment: (
                <Tooltip title="Click to copy API Key">
                  <IconButton
                    size="small"
                    disabled={!newApiKey}
                    onClick={() => copyToClipboard(newApiKey)}
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetApiKey}
            >
              Regenerate
            </Button>
          )}
        </Grid>
      </Grid>
      {/** Parter Webhook URL */}
      <Box my={6}></Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Webhooks
      </Typography>

      <Grid
        container
        spacing={2}
        alignItems="center"
        style={{ marginBottom: "10px" }}
      >
        <Grid item xs={6}>
          <TextField
            type="text"
            disabled={agent.power <= 10 || true}
            fullWidth
            value={sharedSecret}
            className={classes.textFieldDisabled}
            placeholder="Webhook Secret"
            InputProps={{
              endAdornment: (
                <Tooltip title="Click to copy Webhook secret">
                  <IconButton
                    size="small"
                    disabled={!sharedSecret}
                    onClick={() => {
                      navigator.clipboard.writeText(sharedSecret);
                    }}
                  >
                    <FileCopyIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={false}
              onClick={handleUpdateWebhookSecret}
            >
              Regenerate webhook secret
            </Button>
          )}
        </Grid>
      </Grid>

      <Box
        border={1}
        borderColor="grey.500"
        borderRadius="borderRadius"
        p={2}
        style={{ backgroundColor: "rgb(240, 240, 240)" }}
      >
        {/* <Typography
          variant="body2"
          gutterBottom
          style={{
            fontFamily: "monospace",
            marginBottom: "20px",
          }}
        >
          Events: {partner?.partnerWebhooks?.join(", ")}
        </Typography> */}
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginTop: "1px" }}
        >
          <Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <TextField
                  type="url"
                  value={newWebhookUrl}
                  onChange={(e) => setNewWebhookUrl(e.target.value)}
                  className={classes.textField}
                  disabled={isWebhookVerified || agent.power <= 10}
                  fullWidth
                  style={{ marginLeft: "1%", width: "99%" }}
                  InputProps={{
                    endAdornment: (
                      <Box mr={-2}>
                        {agent.power <= 10 ? (
                          <></>
                        ) : (
                          <Button
                            size="small"
                            color={isWebhookVerified ? "default" : "primary"}
                            onClick={
                              !isWebhookVerified
                                ? handleVerifyWebhook
                                : () => setIsWebhookVerified(false)
                            }
                          >
                            {!isWebhookVerified ? "Verify" : "Modify"}
                          </Button>
                        )}
                      </Box>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                {agent.power <= 10 ? (
                  <></>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!isWebhookVerified}
                    onClick={handleUpdateWebhook}
                  >
                    Update Webhook
                  </Button>
                )}
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                paddingLeft: "4%",
                paddingTop: "2%",
              }}
            >
              {WEBHOOKS_TOPICS.map((topic) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={webhooks?.includes(topic)}
                      onChange={() => handleCheckboxChange(topic)}
                      color="primary"
                    />
                  }
                  label={topic}
                  disabled={isWebhookVerified || agent.power <= 10}
                  style={{
                    width: "30%",
                    marginBottom: "5px",
                  }}
                />
              ))}
            </div>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}
const connectedPartnerCredentialsInput = connect((state) => ({
  agent: state.login.user,
}))(PartnerCredentialsInput);

export default connectedPartnerCredentialsInput;
