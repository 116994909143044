import React, { Component } from "react";
import {
  withStyles,
  WithStyles,
  Box,
  Dialog,
  CircularProgress,
  Typography,
  Avatar,
  Link,
} from "@material-ui/core";

import clsx from "clsx";
import PartnerDetails from "./PartnerDetails";
import { formatCurrency } from "../../helpers/currency";

const fields = [
  {
    name: "Partner Id",
    key: "_id",
  },
  {
    name: "Partner Name",
    key: "name",
  },
  {
    name: "Created At",
    key: "createdAt",
  },
  {
    name: "Brand Name",
    key: "brandName",
  },
  {
    name: "Brand Logo",
    key: "brandLogoUrl",
  },
  {
    name: "Favicon",
    key: "faviconUrl",
  },
  {
    name: "Loading Gif",
    key: "loadingGifUrl",
  },
  {
    name: "Login Username",
    key: "loginUsername",
  },
  {
    name: "Default WCC Plan",
    key: "defaultWccPlan",
  },
  {
    name: "Central Balance",
    key: "centralBalance",
  },
  {
    name: "Currency",
    key: "currency",
  },
  {
    name: "Webhook Url",
    key: "webhookUrl",
  },
  {
    name: "Plan Type",
    key: "planType",
  },
  {
    name: "Type",
    key: "type",
  },
  {
    name: "White Label",
    key: "whiteLabelUrl",
  },
  {
    name: "Onboarding Method",
    key: "onboardingMethod",
  },
  {
    name: "Agent Size",
    key: "agentSize",
  },
  {
    name: "Default Plan Family",
    key: "defaultPlanFamily",
  },
  {
    name: "Default Plan Name",
    key: "defaultPlanName",
  },
];

/**
 * @typedef {import("../../types").Partner} Partner
 */

/**
 * @typedef {Object} PartnersTableProps
 * @property {Partner[]} partners - Partnerd Array
 * @property {Boolean} isLoading - Loading state
 * @property {(partner) => void} setPartner - setPartner callback
 */

/**
 * @typedef {PartnersTableProps & WithStyles<typeof styles>} Props
 * @typedef {{openDialog: boolean, selectedPartner: Partner}} State
 */

/**
 * @extends Component<Props, State>
 */
class PartnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedPartnerIndex: null,
    };
  }

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  openPartner = (e) => {
    const partners = this.props.partners;
    const foundIndex = partners.findIndex((x) => x._id == e._id);
    this.setState({ openDialog: true, selectedPartnerIndex: foundIndex });
  };
  render() {
    const { classes, isLoading, partners } = this.props;
    return (
      <div className={classes.root}>
        <table>
          <thead>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className={clsx(classes.column, classes.dataColumn)}
                >
                  <Typography variant="h5" color="primary" align="center">
                    {field.name.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <th>
                  <Box my={4} display="flex" justifyContent="center">
                    <CircularProgress color="primary" />
                  </Box>
                </th>
              </tr>
            ) : (
              <React.Fragment>
                {partners.map((e, index) => (
                  <tr
                    key={e._id}
                    className={clsx(classes.row, classes.dataRow, {
                      [classes.alterRow]: index % 2 == 0,
                      [classes.stayToLeft]: e.key == "assistantName",
                    })}
                  >
                    {fields.map((field) => (
                      <th
                        key={field.key}
                        className={clsx(classes.column, classes.dataColumn)}
                        onClick={() => this.openPartner(e)}
                      >
                        {columnType(classes, e, field)}
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          fullScreen
        >
          <PartnerDetails
            partner={this.props.partners[this.state.selectedPartnerIndex]}
            onClose={this.closeDialog}
            setPartner={this.props.setPartner}
          />
        </Dialog>
      </div>
    );
  }
}

function columnType(classes, columnData, fieldName) {
  const key = fieldName.key;
  switch (key) {
    case "faviconUrl":
      return columnData[key] ? (
        <img className={classes.logo} alt="Favicon" src={columnData[key]} />
      ) : null;
    case "brandLogoUrl":
      return columnData[key] ? (
        <img className={classes.logo} alt="Brand Logo" src={columnData[key]} />
      ) : null;
    case "loadingGifUrl":
      return columnData[key] ? (
        <img className={classes.logo} alt="Loading GIF" src={columnData[key]} />
      ) : null;
    case "webhookUrl":
      return columnData[key] ? (
        <Link target="_blank" rel="noopener" href={columnData[key]}>
          Open Webhook
        </Link>
      ) : null;
    case "whiteLabelUrl":
      return columnData[key] ? (
        <Link target="_blank" rel="noopener" href={columnData[key]}>
          Open Whitelabel
        </Link>
      ) : null;
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
    case "centralBalance":
      const centralBalance = (columnData[key] ?? 0) / 100000;
      const currency = columnData["currency"];
      return (
        <Typography variant="body1" align="center">
          {formatCurrency(currency, centralBalance)}
        </Typography>
      );
    default:
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    borderRadius: "8px",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    [theme.breakpoints.down("sm")]: {},
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#EBF5F3",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "200px",
  },
  stayToLeft: {
    position: "sticky",
    left: 0,
    top: 0,
  },
  checkboxColumn: {
    width: "60px",
  },
  logo: {
    height: theme.spacing(3),
  },
});

export default withStyles(styles)(PartnersTable);
