import React from "react";
import {
  withStyles,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { OpenInNew, Phone } from "@material-ui/icons";

function TemplatePreview({ template, classes }) {
  const cta = new Set(template.callToAction.map((x) => x.type));
  return (
    <Card className={classes.root}>
      {/* Header: image, name and buttons */}
      <CardContent className={classes.header}>
        <Box className={classes.mediaWrapper}>
          {template.iconUrl && (
            <img
              style={{ borderRadius: "50%", height: "75px", width: "75px" }}
              src={template.iconUrl}
            />
          )}
        </Box>
        <Box className={classes.headerText}>
          <Typography className={classes.title}>
            {template.previewName}
          </Typography>
          <Box style={{ display: "flex" }}>
            <Typography className={classes.type}>{template.type}</Typography>
            {cta.has("URL") && (
              <Box
                className={classes.type}
                style={{ background: "#f5f5f5", marginLeft: "8px" }}
              >
                <OpenInNew style={{ color: "#a8a8a8", fontSize: "14px" }} />
              </Box>
            )}
            {cta.has("Phone Number") && (
              <Box
                className={classes.type}
                style={{ background: "#f5f5f5", marginLeft: "8px" }}
              >
                <Phone style={{ color: "#a8a8a8", fontSize: "14px" }} />
              </Box>
            )}
            {template.quickReplies.length > 0 && (
              <Box
                className={classes.type}
                style={{
                  background: "#f5f5f5",
                  color: "#808080",
                  marginLeft: "8px",
                }}
              >
                {template.quickReplies.length > 1
                  ? "Quick Replies"
                  : "Quick Reply"}
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
      {/* Tags */}
      {template.bannerText && (
        <Box className={classes.ribbon}>{template.bannerText}</Box>
      )}
      {/* Card body: sample message */}
      <CardContent style={{ padding: "16px", paddingTop: "8px" }}>
        <Typography className={classes.sample}>
          {template.sampleMessage}
        </Typography>
      </CardContent>
      {/* Action buttons */}
      <CardActions className={classes.actionBox}>
        <Typography size="small" className={classes.actionBtn}>
          Preview
        </Typography>
        <Typography size="small" className={classes.actionBtn2}>
          Submit
        </Typography>
      </CardActions>
    </Card>
  );
}

const styles = (theme) => ({
  root: {
    position: "relative",
    height: "320px",
    minWidth: "200px",
    overflow: "visible",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      margin: "8px",
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "16px",
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "8px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "600",
    paddingBottom: "8px",
  },
  sample: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 4,
    "-webkit-box-orient": "vertical",
    fontSize: "12px",
    color: "#808080",
    height: "70px",
  },
  modal: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    background: "#fff",
    borderRadius: "5px",
    outline: 0,
    padding: "32px",
    minWidth: "250px",
  },
  modalTitle: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "24px",
  },
  close: {
    marginLeft: "auto",
    color: "#A9A9A9",
    "&:hover": {
      cursor: "pointer",
    },
  },
  ribbon: {
    position: "absolute",
    left: "-8px",
    top: 0,
    color: "#fff",
    fontSize: "12px",
    fontWeight: 600,
    padding: "5px 9px 4px",
    maxWidth: "50%",
    background: "#28c152",
    color: "rgb(255, 255, 255)",
    borderColor: "#023020 transparent",

    "&::before": {
      position: "absolute",
      bottom: "-9px",
      content: '""',
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "inherit",
      left: 0,
      borderWidth: "9px 0 0 9px",
    },
  },
  type: {
    display: "flex",
    alignItems: "center",
    background: "rgb(214, 237, 233, 0.9)",
    padding: "4px 8px",
    borderRadius: "4px",
    fontSize: "12px",
    letterSpacing: "0.5px",
    color: "#0a474c",
  },
  mediaWrapper: {
    borderRadius: "50%",
    background: "#dcdcdc",
    height: "75px",
    width: "75px",
    marginRight: "8px",
    border: "none",
  },
  actionBox: {
    position: "absolute",
    justifyContent: "space-between",
    width: "calc(100% - 32px)",
    bottom: 0,
    padding: "16px 16px",
  },
  actionBtn: {
    color: "#0a474c",
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "5px",
    border: "1px solid #0a474c",
    padding: "2px 12px",

    "&:hover": {
      cursor: "pointer",
      background: "#0a474c",
      color: "white",
    },
  },
  actionBtn2: {
    fontSize: "14px",
    fontWeight: "500",
    borderRadius: "5px",
    border: "1px solid #0a474c",
    padding: "2px 12px",
    background: "#0a474c",
    color: "white",

    "&:hover": {
      cursor: "pointer",
      background: "#073135",
    },
  },
});

export default withStyles(styles)(TemplatePreview);
