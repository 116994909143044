import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  withStyles,
  IconButton,
  Typography,
  Box,
  Hidden,
} from "@material-ui/core";
import { connect } from "react-redux";
import { FilterList, Search, Menu } from "@material-ui/icons";
import clsx from "clsx";

import NavDrawerMobile from "./NavDrawerMobile";

class NavBarMobile extends Component {
  state = {
    drawerOpen: false,
  };

  onDrawerClose = (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ drawerOpen: false });
  };

  onDrawerOpen = () => {
    this.setState({ drawerOpen: true });
  };
  render() {
    const { drawerOpen } = this.state;
    const { classes, location, agent } = this.props;
    // const livePage = location.pathname.includes('live');
    const livePage = false
    // console.log(location)
    return (
      <Hidden mdUp>
        <div
          className={clsx(classes.root, {
            [classes.sticky]: !livePage,
          })}
        >
          <Box display="flex" alignItems="center" height="50px">
            <IconButton onClick={this.onDrawerOpen}>
              <Menu className={classes.icon} />
            </IconButton>
            <Typography variant="h3" style={{ flexGrow: 1 }}>
              AiSensy
            </Typography>
            {livePage ? (
              <React.Fragment>
                <IconButton>
                  <Search className={classes.icon} />
                </IconButton>
                {/*
                  <input
                    type="string"
                    id="searchQuery"
                    placeholder="Search"
                    name="searchQuery"
                    onChange={this.handleChange}
                    className={classes.textFieldRoot}
                  />
                */}
                <IconButton>
                  <FilterList className={classes.icon} />
                </IconButton>
              </React.Fragment>
            ) : (
              ""
            )}
          </Box>
          <NavDrawerMobile
            drawerOpen={drawerOpen}
            onDrawerClose={this.onDrawerClose}
            agent={agent}
          />
        </div>
      </Hidden>
    );
  }
}

const styles = (theme) => ({
  root: {
    // position: "sticky",
    // top: "0",
    width: "100%",
    height: "50px",
    overflow: "hidden",
    transition: "0.3s",
    zIndex: 1000,
  },
  sticky: {
    position: "sticky",
    top: 0,
  },
  icon: {
    fontSize: "1em",
    // color: "white"
  },
  hideNavBar: {
    height: 0,
  },
});

const connectedNavBarMobile = connect((state) => ({
  agent: state.login.user,
}))(NavBarMobile);

export default withStyles(styles)(withRouter(connectedNavBarMobile));
