import React, { Component } from "react";
import axios from "axios";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  TextField,
  InputLabel,
  Button,
  Card,
} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import { URL } from "../../config/config";
import { connect } from "react-redux";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      searchString: "",
    };
  }

  handleSearchName = (event) => {
    this.setState({ searchString: event.target.value });
  };

  isEmail(input) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  }

  otpFetcher = () => {
    let filt = {};
    filt = this.isEmail(this.state.searchString)
      ? {
          email: this.state.searchString,
          contact: "",
        }
      : { contact: this.state.searchString, email: "" };

    axios
      .post(URL + "/superadmin/get-signup-otps", filt)
      .then((res) => {
        const temp = res.data.result.documents;
        this.setState({ data: temp });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  render() {
    const { classes, match, agent } = this.props;
    const assistantId = match.params.projectId;
    const { data } = this.state;

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          justify="center"
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
        >
          <Grid item xs>
            <Box pl={{ xs: 2, md: 3 }}>
              <Typography variant="h3">Dashboard</Typography>
            </Box>
          </Grid>
        </Grid>
        {agent.power > 10 ? (
          <Grid container>
            <Card
              style={{
                width: "500px",
                maxHeight: "350px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                overflow: "auto",
                marginTop: "10px",
                marginLeft: "10px",
                borderRadius: "10px",
              }}
            >
              <Grid
                container
                justify="center"
                alignItems="center"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  paddingLeft: "3%",
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  width="80%%"
                  p={{ xs: 2, md: 3 }}
                  borderRadius="4px"
                  style={{ paddingLeft: "0", paddingRight: "0" }}
                >
                  <InputLabel
                    htmlFor="search-otp"
                    style={{ marginRight: "16px" }}
                  >
                    Search OTP
                  </InputLabel>
                  <TextField
                    id="search-number"
                    placeholder="Enter contact or email"
                    color="primary"
                    value={this.state.searchString}
                    style={{
                      backgroundColor: "#f0f0f0",
                      width: "200px",
                      marginRight: "10px",
                    }}
                    onChange={this.handleSearchName}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!this.state.searchString}
                    onClick={() => {
                      this.otpFetcher();
                    }}
                  >
                    Search
                  </Button>
                </Box>
              </Grid>
              <Grid style={{}}>
                <TableContainer component={Paper} className={classes.table}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold", fontSize: "1em" }}
                        >
                          Name
                        </TableCell>
                        {/* <TableCell
                        style={{ fontWeight: "bold", fontSize: "1em" }}
                      >
                        Contact
                      </TableCell> */}
                        <TableCell
                          style={{ fontWeight: "bold", fontSize: "1em" }}
                        >
                          OTP
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", fontSize: "1em" }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", fontSize: "1em" }}
                        >
                          Time
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data
                        ?.sort((a, b) => {
                          const dateComparison =
                            new Date(b.createdAt) - new Date(a.createdAt);
                          if (dateComparison !== 0) {
                            return dateComparison; // Sort by date in descending order
                          } else {
                            // If dates are equal, sort by time in descending order
                            return (
                              new Date(b.createdAt).getTime() -
                              new Date(b.createdAt).getTime()
                            );
                          }
                        })
                        .map((item) => (
                          <TableRow key={item._id}>
                            <TableCell style={{ fontSize: "1em" }}>
                              {item.fullName}
                            </TableCell>
                            {/* <TableCell style={{ fontSize: "1em" }}>
                            {item.contact}
                          </TableCell> */}
                            <TableCell style={{ fontSize: "1em" }}>
                              {item.otp}
                            </TableCell>
                            <TableCell style={{ fontSize: "1em" }}>
                              {new Date(item.createdAt).toLocaleDateString(
                                "en-GB"
                              )}
                            </TableCell>
                            <TableCell style={{ fontSize: "1em" }}>
                              {new Date(item.createdAt).toLocaleTimeString(
                                "en-GB"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Card>
          </Grid>
        ) : (
          ""
        )}
        <Box my={3} />
        {/* Layout */}
        <Grid container justify="center">
          <Grid item xs={12} md={10} lg={8}></Grid>
        </Grid>
        <Box my={8} />
      </div>
    );
  }
}
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(247,247,247)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 60,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  table: {
    minWidth: 450,
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  primaryBackground: {
    background: "#EBF5F3",
  },
});
const connectedDashboard = connect((state) => ({
  agent: state.login.user,
}))(Dashboard);
export default withStyles(styles)(connectedDashboard);
