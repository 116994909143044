import React, { useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import {
  Clear,
  BarChart,
  AccountCircle,
  PeopleOutline,
  FilterListSharp,
  MonetizationOnOutlined,
} from "@material-ui/icons";
import {
  Box,
  Grid,
  Menu,
  Button,
  Dialog,
  Divider,
  MenuItem,
  TextField,
  IconButton,
  Typography,
  withStyles,
  DialogTitle,
  InputAdornment,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";
import FacebookIcon from "@material-ui/icons/Facebook";
import CRMFilter from "../../CRMFilter";
import "../../../../react-dates-custom.css";
import {
  PRO_PLANS,
  BASIC_PLANS,
  COMPANY_SIZE,
  STATUS_FILTERS,
  ENTERPRISE_PLANS,
  INDUSTRY,
  META_ADS_TWO_TIER,
} from "../../CRMConfig";

const CRMHeader = (props) => {
  const {
    apply,
    filter,
    applied,
    classes,
    CRMSection,
    handleClear,
    handleEnter,
    handleSearch,
    ownerFilters,
    businessTitle,
    backgroundColor,
    searchPlaceholder,
    SALES_TEAM,
  } = props;
  const [anchorEl, setanchorEl] = useState(null);
  const [searchDone, setSearchDone] = useState(false);
  const [filters, setFilters] = useState({
    filter: false,
    planFilter: false,
    companySize: false,
    ownerFilter: false,
    statusFilter: false,
    industry: false,
    meta_ads: false,
  });

  const handleFilter = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  return (
    <Grid
      xs={12}
      alignItems="center"
      className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
      style={{ height: window.innerWidth < 768 ? 160 : 0, backgroundColor }}
    >
      <Grid item xs={12}>
        <Box
          py={2}
          display="flex"
          alignItems="center"
          px={{ xs: 2, md: 2 }}
          justifyContent="space-between"
        >
          <Typography variant="h3" style={{ flex: 1 }}>
            {businessTitle}
          </Typography>

          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {CRMSection && (
              <Grid>
                <Button
                  variant="text"
                  startIcon={<FacebookIcon />}
                  className={classes.IconButton}
                  onClick={(e) => {
                    setanchorEl(e.target);
                    handleFilter("meta_ads", true);
                  }}
                >
                  Meta Ads
                </Button>
              </Grid>
            )}

            {CRMSection && (
              <Grid>
                <Button
                  variant="text"
                  startIcon={<BusinessIcon />}
                  className={classes.IconButton}
                  onClick={(e) => {
                    setanchorEl(e.target);
                    handleFilter("industry", true);
                  }}
                >
                  Industry
                </Button>
              </Grid>
            )}

            {CRMSection && (
              <Grid>
                <Button
                  variant="text"
                  startIcon={<PeopleOutline />}
                  className={classes.IconButton}
                  onClick={(e) => {
                    setanchorEl(e.target);
                    handleFilter("companySize", true);
                  }}
                >
                  Company Size
                </Button>
              </Grid>
            )}

            {CRMSection && (
              <Grid>
                <Button
                  variant="text"
                  className={classes.IconButton}
                  startIcon={<MonetizationOnOutlined />}
                  onClick={(e) => {
                    setanchorEl(e.target);
                    handleFilter("planFilter", true);
                  }}
                >
                  Plan Filter
                </Button>
              </Grid>
            )}

            {CRMSection && (
              <Grid>
                <Button
                  variant="text"
                  startIcon={<AccountCircle />}
                  className={classes.IconButton}
                  onClick={(e) => {
                    setanchorEl(e.target);
                    handleFilter("ownerFilter", true);
                  }}
                >
                  Owner Filter
                </Button>
              </Grid>
            )}

            {CRMSection && (
              <Grid>
                <Button
                  variant="text"
                  startIcon={<BarChart />}
                  className={classes.IconButton}
                  onClick={(e) => {
                    setanchorEl(e.target);
                    handleFilter("statusFilter", true);
                  }}
                >
                  Status Filter
                </Button>
              </Grid>
            )}

            <Grid>
              <Button
                variant="text"
                className={classes.IconButton}
                startIcon={<FilterListSharp />}
                style={{ paddingLeft: "16px" }}
                onClick={() => handleFilter("filter", true)}
              >
                Filter
              </Button>
            </Grid>
          </Grid>

          <Grid>
            <TextField
              name="search"
              value={filter}
              onChange={handleSearch}
              key={searchDone ? "1" : "2"}
              className={classes.textField}
              placeholder={searchPlaceholder}
              onKeyPress={(e) => {
                handleEnter(e);
                setSearchDone(false);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        handleClear();
                        setSearchDone(true);
                      }}
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Box>

        <Box>
          <Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="owner-filter-menu"
              open={filters.ownerFilter}
              className={classes.importMenuRoot}
              onClose={() => handleFilter("ownerFilter", false)}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  Owner Filter
                </Typography>
              </DialogTitle>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>

              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  ownerFilters("Everyone");
                  handleFilter("ownerFilter", false);
                }}
              >
                Everyone
              </MenuItem>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
              {SALES_TEAM.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    ownerFilters(element.name);
                    handleFilter("ownerFilter", false);
                  }}
                >
                  {element.name}
                </MenuItem>
              ))}

              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  ownerFilters("UNASSIGNED");
                  handleFilter("ownerFilter", false);
                }}
              >
                UNASSIGNED
              </MenuItem>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
            </Menu>
          </Box>
        </Box>
        <Dialog
          maxWidth="md"
          fullWidth={true}
          open={filters.filter}
          onClose={() => handleFilter("filter", false)}
        >
          <Box p="2em 5%">
            <Grid container justifyContent="flex-end">
              <Button
                variant="outlined"
                onClick={() => handleFilter("filter", false)}
              >
                Close
              </Button>
            </Grid>
            <CRMFilter
              apply={apply}
              classes={classes}
              applied={applied}
              key="contactsFilter"
              setDate={props.setDate}
              clearAll={props.clearAll}
              setFocus={props.setFocus}
              clearDate={props.clearDate}
              showAssistantFilter={CRMSection}
              checkDateInput={props.checkDateInput}
              clearFilters={() => handleFilter("filter", false)}
            />
          </Box>
        </Dialog>

        <Box>
          <Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="status-filter-menu"
              open={filters.meta_ads}
              className={classes.importMenuRoot}
              onClose={() => handleFilter("meta_ads", false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  Meta Ads
                </Typography>
              </DialogTitle>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>

              {META_ADS_TWO_TIER[0].filter.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    handleFilter("meta_ads", false);
                    apply({
                      value: element,
                      operator: "is",
                      metaAds: { name: "Meta Ads two tier" },
                    });
                  }}
                >
                  {element}
                </MenuItem>
              ))}
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
            </Menu>
          </Box>
        </Box>

        <Box>
          <Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="status-filter-menu"
              open={filters.industry}
              className={classes.importMenuRoot}
              onClose={() => handleFilter("industry", false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  Industry
                </Typography>
              </DialogTitle>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>

              {INDUSTRY[0].filter.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    handleFilter("industry", false);
                    apply({
                      value: element,
                      operator: "is equals to",
                      industry: { name: "Industry" },
                    });
                  }}
                >
                  {element}
                </MenuItem>
              ))}
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
            </Menu>
          </Box>
        </Box>

        <Box>
          <Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="status-filter-menu"
              open={filters.companySize}
              className={classes.importMenuRoot}
              onClose={() => handleFilter("companySize", false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  Company Size
                </Typography>
              </DialogTitle>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>

              {COMPANY_SIZE[0].filter.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    handleFilter("companySize", false);
                    apply({
                      value: element,
                      operator: "is equals to",
                      companySize: { name: "Company Size" },
                    });
                  }}
                >
                  {element}
                </MenuItem>
              ))}
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
            </Menu>
          </Box>
        </Box>

        <Box>
          <Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="status-filter-menu"
              open={filters.statusFilter}
              className={classes.importMenuRoot}
              onClose={() => handleFilter("statusFilter", false)}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  Status Filter
                </Typography>
              </DialogTitle>
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>

              {STATUS_FILTERS[0].filter.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    props.statusapply(element);
                    handleFilter("statusFilter", false);
                  }}
                >
                  {element}
                </MenuItem>
              ))}
              <Box mb={3} mt={1} margin="auto">
                <Divider />
              </Box>
            </Menu>
          </Box>
        </Box>

        <Box>
          <Box>
            <Menu
              keepMounted
              anchorEl={anchorEl}
              id="plan-filter-menu"
              open={filters.planFilter}
              className={classes.importMenuRoot}
              onClose={() => handleFilter("planFilter", false)}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "bottom", horizontal: "left" }}
            >
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  TRIAL PLANS
                </Typography>
              </DialogTitle>

              <MenuItem
                className={classes.menuItem}
                onClick={() => {
                  handleFilter("planFilter", false);
                  apply({
                    value: "BASIC_TRIAL",
                    operator: "is equals to",
                    filter: { name: "activePlan" },
                  });
                }}
              >
                BASIC_TRIAL
              </MenuItem>
              <Box margin="auto">
                <Divider />
              </Box>
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  BASIC PLANS
                </Typography>
              </DialogTitle>
              <Box margin="auto">
                <Divider />
              </Box>
              {BASIC_PLANS.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    handleFilter("planFilter", false);
                    apply({
                      value: element,
                      operator: "is equals to",
                      filter: { name: "activePlan" },
                    });
                  }}
                >
                  {element}
                </MenuItem>
              ))}

              <Box margin="auto">
                <Divider />
              </Box>
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  PRO PLANS
                </Typography>
              </DialogTitle>
              <Box margin="auto">
                <Divider />
              </Box>
              {PRO_PLANS.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    handleFilter("planFilter", false);
                    apply({
                      value: element,
                      operator: "is equals to",
                      filter: { name: "activePlan" },
                    });
                  }}
                >
                  {element}
                </MenuItem>
              ))}
              <Box margin="auto">
                <Divider />
              </Box>
              <DialogTitle>
                <Typography variant="h5" className={classes.menuItemTitle}>
                  ENTERPRISE PLANS
                </Typography>
              </DialogTitle>

              {ENTERPRISE_PLANS.map((element, index) => (
                <MenuItem
                  key={index}
                  className={classes.menuItem}
                  onClick={() => {
                    handleFilter("planFilter", false);
                    apply({
                      value: element,
                      operator: "is equals to",
                      filter: { name: "activePlan" },
                    });
                  }}
                >
                  {element}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const styles = (theme) => ({
  pageTitleContainer: {
    top: 0,
    height: 80,
    zIndex: 100,
    position: "sticky",
    boxSizing: "border-box",
  },

  fullWidth: {
    width: "100%",
    background: "white",
  },

  menuItemTitle: {
    color: "black",
    fontWeight: "bold",
  },

  importMenuRoot: {
    top: "60px !important",
    "& .MuiPaper-root": {
      backgroundColor: "#ffffff",
      borderRadius: theme.spacing(1),
      width: "280px", // Increase or decrease the width as needed
    },
  },

  textField: {
    width: 280,
    height: 42,
    "& .MuiIconButton-root": {
      padding: 6,
      background: "rgb(70 20 134 / 15%)",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 15,
    },
  },

  menuItem: {
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
  },

  IconButton: {
    color: "#504444",
    width: "17.5 rem",
    height: "42px !important",
    "& .MuiButton-label": {
      display: "flex",
      justifyContent: "flex-start",
    },
  },
});

export default withStyles(styles)(CRMHeader);
