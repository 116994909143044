import React, { Component } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
  Switch,
} from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import axios from "axios";
import { URL } from "../../config/config";

class Clients extends Component {
  constructor(props) {
    super(props);
    const { agents, agentIndex } = this.props;
    this.state = {
      alert: false,
      alertMsg: "",
      alertSeverity: "",
      email: agents[agentIndex].email,
    };
  }

  componentDidMount = () => {
    console.log(this.props.agents);
  };
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };

  updateAgentDetails = async (data) => {
    try {
      const agent = this.props.agents[this.props.agentIndex];
      await axios.patch(`${URL}/superadmin/update-agent-details`, {
        previousEmail: agent.email,
        agentId: agent._id,
        ...data,
      });
      this.setState({
        alert: true,
        alertMsg: "Updated successfully!",
        alertSeverity: "success",
      });
      this.props.setAgent({ ...agent, ...data });
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong. Cannot migrate this Partner project to AiSensy!";
      this.setState({
        alert: true,
        alertMsg: message,
        alertSeverity: "error",
      });
    }
  };

  render() {
    const { classes, toggleDialog, agentIndex, agents } = this.props;
    const agent = agents[agentIndex];

    return (
      <Box px={2} className={classes.dialogContainer}>
        <Grid container alignItems="center" className={classes.dialogTopbar}>
          <Grid item>
            <IconButton onClick={toggleDialog}>
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item>{agent.displayName}</Grid>
        </Grid>
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <Box py={2} px={2} my={2}>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                ></Grid>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Update Email
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Email in use - {agent.email}
                </Typography>
                <Box my={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        disabled={this.props.agent.power <= 10}
                        style={{
                          width: "100%",
                          border: "1px solid ",
                          background: "white",
                          marginRight: 10,
                        }}
                        name="email"
                        onChange={this.handleInput}
                        value={this.state.email}
                      />
                    </Grid>
                    <Grid item>
                      {this.props.agent.power <= 10 ? (
                        <></>
                      ) : (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            this.updateAgentDetails({ email: this.state.email })
                          }
                        >
                          Update Email
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "#EBF5F3",
    borderRadius: "15px",
  },
  disabledContainer: {
    background: "#dcdcdc",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});

const connectedClients = connect((state) => ({
  agent: state.login.user,
}))(Clients);

export default withStyles(styles)(connectedClients);
