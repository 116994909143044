import clsx from "clsx";
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  withStyles,
  CircularProgress,
} from "@material-ui/core";

import { URL } from "../../config/config";
import CRMHeader from "../CRM/CRMDetails/KanbanComponent/CRMHeader";

const RowName = [
  "OWNER NAME",
  "Total",
  "Api Total",
  "Project Created",
  "API Procurement",
  "FB Verification",
  "Display Verification",
  "Tier 1",
  "Broadcast",
  "Tier 2",
  "Drop Off",
];

const CustomTable = (props) => {
  const { classes } = props;
  const [data, setdata] = useState([]);
  const [salesTeam, setSalesTeam] = useState([]);
  const [applies, setApplies] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [applied, setapplied] = useState({
    createdAt: { endDate: null, startDate: null, focusedInput: null },
    APIProcuredOn: { endDate: null, startDate: null, focusedInput: null },
  });

  const clearAll = () => {
    setapplied({
      createdAt: { endDate: null, startDate: null, focusedInput: null },
      APIProcuredOn: { endDate: null, startDate: null, focusedInput: null },
    });
    setApplies(!applies);
  };

  const apply = () => {
    setApplies(!applies);
  };

  const setDate = (filterKey, startDate, endDate) => {
    const applieds = { ...applied };
    applieds[filterKey].startDate = startDate;
    applieds[filterKey].endDate = endDate;
    setapplied(applieds);
  };

  const setFocus = (filterKey, focusedInput) => {
    const applieds = { ...applied };
    applieds[filterKey].focusedInput = focusedInput;
    setapplied(applieds);
  };

  const clearDate = (filterKey) => {
    const applieds = { ...applied };
    applieds[filterKey].startDate = null;
    applieds[filterKey].endDate = null;
    setapplied(applieds);
    setApplies(!applies);
  };

  const checkDateInput = (filterKey) => {
    const s = applied[filterKey];
    if (s.startDate && s.endDate) return;
    clearDate(filterKey);
  };

  const getSalesOwnerReport = useCallback(async () => {
    try {
      const url = `${URL}/crm/get-sales-owner-report`;
      const body = { applied };
      const response = await axios.post(url, body);
      const { data } = response;
      const arr = Object.keys(data).map((salesPerson) => ({
        ...data[salesPerson],
        salesPerson,
      }));
      setdata([...arr]);
      setisLoading(false);
    } catch (error) {
      console.error({ error });
      setisLoading(false);
    }
  }, [applied]);

  const fetchAllAgents = () => {
    axios
      .get(URL + "/superadmin/get-crm-agents")
      .then((res) => {
        const filteredAgents = res?.data
          .filter((agent) => agent.power >= 9 && agent.power <= 12)
          .sort((a, b) => a.power - b.power)
          .map((agent) => ({ name: agent.displayName }));
        setSalesTeam(filteredAgents);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setisLoading(true);
    getSalesOwnerReport();
    fetchAllAgents();
  }, [getSalesOwnerReport]);

  return (
    <Box className={classes.root}>
      <CRMHeader
        businessTitle={"Reporting"}
        applied={applied}
        setDate={setDate}
        setFocus={setFocus}
        checkDateInput={checkDateInput}
        clearDate={clearDate}
        apply={apply}
        clearAll={clearAll}
        CRMSection={false}
        SALES_TEAM={salesTeam}
      />
      <table>
        <tbody>
          <tr className={`${classes.row} ${classes.topbar}`}>
            {RowName.map((field, index) => (
              <th
                key={index}
                className={clsx(classes.column, classes.dataColumn)}
              >
                <Typography variant="h5" color="primary" align="center">
                  {field.toUpperCase()}
                </Typography>
              </th>
            ))}
          </tr>
          {isLoading ? (
            <Box my={4} display="flex" justifyContent="center">
              <CircularProgress color="primary" />
            </Box>
          ) : (
            <React.Fragment>
              {data.map((item, index) => (
                <tr
                  key={index}
                  className={clsx(classes.row, classes.dataRow, {
                    [classes.alterRow]: index % 2 === 0,
                  })}
                >
                  {RowName.map((field, index) => (
                    <th
                      key={index}
                      className={clsx(classes.column, classes.dataColumn)}
                    >
                      <Typography variant="body1" align="center">
                        {columnType(item, field)}
                      </Typography>
                    </th>
                  ))}
                </tr>
              ))}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </Box>
  );
};

const columnType = (columnData, field) => {
  switch (field) {
    case "Total":
      return (
        columnData["Project Created"] +
        columnData["API Procurement"] +
        columnData["Tier 1"] +
        columnData["Tier 2"] +
        columnData["Display Verification"] +
        columnData["FB Verification"] -
        columnData["Drop Off"]
      );
    case "Api Total":
      return (
        columnData["API Procurement"] +
        columnData["Tier 1"] +
        columnData["Tier 2"] +
        columnData["Display Verification"] +
        columnData["FB Verification"]
      );
    case "Project Created":
    case "API Procurement":
    case "FB Verification":
    case "Display Verification":
    case "Tier 1":
    case "Tier 2":
    case "Broadcast":
    case "Drop Off":
      return columnData[field];
    default:
      return columnData["salesPerson"];
  }
};

const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },

  row: {
    background: "white",
    display: "flex",
  },

  alterRow: {
    background: "rgb(246,246,246)",
  },

  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#EBF5F3",
    },
  },

  topbar: {
    top: "0",
    zIndex: "100",
    position: "sticky",
    background: "rgb(252,252,252)",
  },

  column: {
    width: "100%",
    height: "65px",
    fontWeight: "normal",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },

  dataColumn: {
    width: "175px",
  },
});

export default withStyles(styles)(CustomTable);
