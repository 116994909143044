import React, { useState } from "react";
import {
  Grid,
  TextField,
  Box,
  Typography,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  Switch,
} from "@material-ui/core";
import ImageUpload from "../../commons/Image/ImageUpload";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { validLimits } from "../../config/rateLimits";
import axios from "axios";
import {
  ADMIN_KEY,
  PARTNER_APP_URL,
  TENANT_ID,
  URL,
} from "../../config/config";
import PlanFamily from "../../commons/PlanFamily/PlanFamily";
/**
 * @typedef {import("../../types").Partner} Partner
 */

/**
 * @typedef {Object} PartnerNameInputProps
 * @property {Partner} partner
 * @property {(key: string, value: string) => Promise<void>} updateAttribute
 */

/**
 * @param {PartnerNameInputProps & WithStyles<typeof styles>} props
 */
function PartnerNameInput({
  classes,
  partner,
  updateAttribute,
  agent,
  handleImageChange,
  showSuccessMessage,
  showErrorMessage,
  // skipBalanceCheck,
}) {
  const [{ openDialog, selectedName }, setDialog] = useState({
    openDialog: false,
    selectedName: "",
  });

  const closeDialog = () => setDialog({ selectedName: "", openDialog: false });

  const openImageDialog = (name) => {
    setDialog({
      openDialog: true,
      selectedName: name,
    });
  };

  const [{ name, brandName, website, partnerApiLimit }, setState] = useState({
    name: partner?.name || "",
    brandName: partner?.brandName || "",
    website: partner?.website || "",
    partnerApiLimit: partner?.apiLimit || 100,
  });

  const setAttribute = (key, value) => {
    setState((state) => ({ ...state, [key]: value }));
  };

  const handleAttriuteUpdate = async (key, value) => {
    await updateAttribute(key, value);
  };

  const loginAsOwner = async () => {
   
    const agentId = partner.agentId;
    try {
      const {
        data: { token },
      } = await axios.post(
        `${URL}/superadmin/generate-any-token`,
        {
          _id: agentId,
          type: "partner",
        },
        {
          headers: {
            "x-aisensy-admin-token-key": ADMIN_KEY,
          },
        }
      );
      window.open(`${PARTNER_APP_URL}/login?token=${token}`, "_blank");
    } catch (error) {}
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Name - Enter the name to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner Name - {partner.name}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={name}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("name", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("name", name)}
            >
              Update Name
            </Button>
          )}
        </Grid>
      </Grid>
      <Box my={6}>{/* <Divider /> */}</Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Brand Name - Enter the name to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner Brand Name - {partner.brandName}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={brandName}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("brandName", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("brandName", brandName)}
            >
              Update Brand Name
            </Button>
          )}
        </Grid>
      </Grid>
      <Box my={6}></Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner Website - Enter the name to update
      </Typography>
      <Typography variant="body1" gutterBottom>
        Current Partner Website - {partner.website}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <TextField
            value={website}
            disabled={agent.power <= 10}
            onChange={(e) => setAttribute("website", e.target.value)}
            className={classes.textField}
            fullWidth
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("website", website)}
            >
              Update Website
            </Button>
          )}
        </Grid>
      </Grid>

      <Box my={6}></Box>
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Partner API's Rate Limit
      </Typography>
      <Typography variant="body1" gutterBottom>
        {`Partner Rate Limit - ${partner.apiLimit || 100}/min`}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Autocomplete
            disableClearable
            disabled={agent.power <= 10}
            options={validLimits}
            getOptionLabel={(option) => option.toString()}
            onChange={(e, value, reason) =>
              setAttribute("partnerApiLimit", value)
            }
            value={partnerApiLimit}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.textField}
                InputProps={{ ...params.InputProps }}
                placeholder="Select API Limit"
                style={{
                  border: "1px solid ",
                  background: "white",
                }}
              />
            )}
          />
        </Grid>
        <Grid item>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAttriuteUpdate("apiLimit", partnerApiLimit)}
            >
              Update API Limit
            </Button>
          )}
        </Grid>
      </Grid>
      <PlanFamily
        agent={agent}
        partner={partner}
        showSuccessMessage={showSuccessMessage}
        showErrorMessage={showErrorMessage}
      />

      {/* <Box py={2} my={2} className={classes.container}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
          Login as owner
        </Typography>
        <Box my={2} style={{ display: "flex", alignItems: "center" }}>
          {agent.power <= 11 ? (
            <></>
          ) : (
            <Button color="primary" variant="contained" onClick={loginAsOwner}>
              Login
            </Button>
          )}
        </Box>
      </Box> */}

      {/* <Box py={2} my={2} className={classes.container}>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
          Skip balance check before sendign campaign
        </Typography>
        <Box my={2} style={{ display: "flex", alignItems: "center" }}>
          {agent.power <= 10 ? (
            <></>
          ) : (
            <Switch
              name="skipBalanceCheck"
              checked={partner?.skipBalanceCheck}
              onChange={(e) => skipBalanceCheck(e)}
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          )}
        </Box>
      </Box> */}

      <Box my={2}>
        <Divider />
      </Box>
      <Grid container justifyContent="space-evenly">
        <Grid item>
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid
              item
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Partner Brand Logo
              </Typography>
              <img
                className={classes.logo}
                alt="Brand Logo"
                src={partner.brandLogo}
              />
            </Grid>
            <Grid item>
              {agent.power <= 10 ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => openImageDialog("brandLogo")}
                >
                  Change Logo
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid
              item
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Partner Brand Full Logo
              </Typography>
              <img
                className={classes.logo}
                alt="Brand Full Logo"
                src={partner.brandFullLogo}
              />
            </Grid>
            <Grid item>
              {agent.power <= 10 ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => openImageDialog("brandFullLogo")}
                >
                  Change Logo
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid
              item
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Partner Loading GIF
              </Typography>
              <img
                className={classes.logo}
                alt="Loading Gif"
                src={partner.loadingGifUrl}
              />
            </Grid>
            <Grid item>
              {agent.power <= 10 ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => openImageDialog("loadingGifUrl")}
                >
                  Change GIF
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1} alignItems="center">
            <Grid
              item
              style={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                style={{ fontWeight: "bold" }}
              >
                Partner Favicon
              </Typography>
              <img
                className={classes.logo}
                alt="Favicon"
                src={partner.faviconUrl}
              />
            </Grid>
            <Grid item>
              {agent.power <= 10 ? (
                <></>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => openImageDialog("faviconUrl")}
                >
                  Change Icon
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={closeDialog}>
        <ImageUpload
          handleImageChange={handleImageChange}
          closeDialog={closeDialog}
          assetKey={selectedName}
        />
      </Dialog>
    </Box>
  );
}

const styles = (theme) =>
  createStyles({
    root: {
      padding: "2rem 1rem",
      backgroundColor: "#EBF5F3",
      borderRadius: "12px",
    },
    textField: {
      backgroundColor: "white",
      border: "1px solid black",
    },
    logo: {
      height: theme.spacing(6),
    },
  });
const connectedPartnerNameInput = connect((state) => ({
  agent: state.login.user,
}))(PartnerNameInput);
export default withStyles(styles)(connectedPartnerNameInput);
