import axios from "axios";

import { TENANT_ID, partnerURL } from "../config/config";

const initialState = {
  tenant: {},
  isLoading: false,
  error: null,
};

const LOAD_START = "TENANT/LOAD_START";
const LOAD_SUCCESS = "TENANT/LOAD_SUCCESS";
const LOAD_FAILURE = "TENANT/LOAD_FAILURE";

const loadStart = () => ({
  type: LOAD_START,
});

const loadSuccess = (tenant) => ({
  type: LOAD_SUCCESS,
  payload: tenant,
});

const loadFailure = (error) => ({
  type: LOAD_FAILURE,
  payload: error,
});

export const fetchTenantDetails = () => (dispatch, getState) => {
  dispatch(loadStart());

  axios
    .post(partnerURL + "/api/get-tenant-profile", {
      tenantId: TENANT_ID,
    })
    .then((response) => {
      const tenantData = response.data.data;
      dispatch(loadSuccess({ tenantData }));
    })
    .catch((error) => {
      console.error(error);
      dispatch(loadFailure(error));
    });
};

export default function tenantReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOAD_START:
      return { ...state, isLoading: true, error: null };

    case LOAD_SUCCESS:
      return {
        ...state,
        tenant: { ...payload.tenantData },
        isLoading: false,
        error: null,
      };

    case LOAD_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
