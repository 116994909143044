import React from "react";
import { Typography, withStyles, Grid, Box, Link } from "@material-ui/core";
import ErrorLOGO from "../../static/404error.jpg";
import LOGO from "../../static/Timey_White_Blue.png";
import { connect } from "react-redux";

function ErrorPage(props) {
  const { classes, tenantDetails } = props;
  return (
    <Grid container justify="center" className={classes.root}>
      <Box display="flex" justifyContent="center" alignItems="center">
        <img
          src={
            Object.keys(tenantDetails).length ? tenantDetails.brandLogo : LOGO
          }
          alt={
            Object.keys(tenantDetails).length ? tenantDetails.name : "AiSensy"
          }
          className={classes.logo}
        />
      </Box>
      <Box display="flex" maxHeight="40%" justifyContent="center">
        <img src={ErrorLOGO} alt="404" className={classes.image} />
      </Box>
      <Grid item xs={12} container justify="center">
        <Typography variant="h2" color="error">
          {" "}
          YOU SEEM LOST!
        </Typography>
        <Grid container justify="center">
          <Link href="/" color="primary">
            Home
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = (theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
  },
  image: {
    maxHeight: "90%",
    maxWidth: "100%",
    // marginRight: ,
  },
  logo: {
    maxHeight: "100px",
    maxWidth: "250px",
  },
});

const connectErrorPage = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(ErrorPage);

export default withStyles(styles)(connectErrorPage);
