import React, { Component } from "react";
import { 
	withStyles, 
	Grid,
	Button,
	Box,
	Typography,
	IconButton,
	TextField,
	Snackbar 
} from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Autocomplete, Alert } from "@material-ui/lab";
import axios from "axios";

import { URL } from "../../config/config";


const PLANS = [
	"FREE",
	"LITE",
	"PRO",
	"PLUS",
	"PREMIUM"
]

function getStatusColor() {
	const templateStatus = "PENDING"
	switch(templateStatus) {
		case "PENDING":
			return "grey";
		case "APPROVED":
			return "#08CF65";
		case "REJECTED": 
			return "#ff0000";
		default:
			return "pink"
	}
}

class Assistant extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: "PENDING",
			alert: false,
			alertMsg: "",
			alertSeverity: "",
			remainingCredit: 0,
			activePlan: null
		}
	}
	handleInput = (e) => {
		this.setState({ [e.target.name]: parseInt(e.target.value) })
	}
	updateCredit = () => {
    let template = this.props.templates[this.props.templateIndex]
    const amount = template.remainingCredit + this.state.remainingCredit*100


    // console.log(template.remainingCredit, this.state.remainingCredit)
		if(this.state.activePlan) {
	    template.activePlan =  this.state.activePlan;
	    template.planActivatedOn = new Date();
		}

    template.remainingCredit = amount;

		this.setTemplateInDb({ 
				remainingCredit: amount, 
				activePlan: template.activePlan,
				planActivatedOn: template.planActivatedOn 
			})
			.then( response => {
				this.props.setTemplate(template);
        this.setState({ 
        	alert: true,
        	alertMsg: "Assistant status updated!",
        	alertSeverity: "success"
        });										
			})
			.catch(error => {
				this.setState({ 
        	alert: true,
        	alertMsg: "Unable to update template status",
        	alertSeverity: "error"
        });
			})	
	}
	handleAutoComplete = (fieldName, value, reason) => {
		if(reason == "select-option") {
			this.setState({ [fieldName]: value })
		}
	}
	handleMark = (mark, temp) => {
		const template = {...temp}
		if(mark) {
			// set process to new Date()
			this.setTemplateInDb({ processed: new Date() })
				.then( response => {
					template.processed = new Date();
					this.props.setTemplate(template);
          this.setState({ 
          	alert: true,
          	alertMsg: "Template marked processed!",
          	alertSeverity: "success"
          });										
				})
				.catch(error => {
  				this.setState({ 
          	alert: true,
          	alertMsg: "Unable to mark, Check your internet connection.",
          	alertSeverity: "error"
          });
				})
		} else {
				this.setTemplateInDb({ processed: null })
				.then( response => {
					template.processed = null;
					this.props.setTemplate(template);
          this.setState({ 
          	alert: true,
          	alertMsg: "Template marked un-processed!",
          	alertSeverity: "success"
          });										
				})
				.catch(error => {
  				this.setState({ 
          	alert: true,
          	alertMsg: "Unable to fulfill request, Check your internet connection.",
          	alertSeverity: "error"
          });
				})
		}
	}
	setTemplateInDb = (fields) => {
    // try {
      const t = this.props.templates[this.props.templateIndex]
      const queryObj = {
        assistantId: t._id,
        fields
      }
      return axios
        .post(URL + "/superadmin/set-assistant-field", { ...queryObj })
	}
	closeSnackbar = () => {
		this.setState({alert: false, alertMsg: "", alertSeverity: ""})
	}
	render() {
		const { classes, toggleDialog, templateIndex, templates, setTemplate } = this.props
		const template = templates[templateIndex]
		return(
			<Box px={2} className={classes.dialogContainer}>
				<Grid container alignItems="center" className={classes.dialogTopbar}>
					<Grid item>
						<IconButton onClick={toggleDialog}>
							<ArrowBackOutlined />
						</IconButton>
					</Grid>
					<Grid item>
						{template.assistantName}
					</Grid>
				</Grid>
				<div className={classes.root}>
					<Grid container justify="center">
						<Grid item xs={12} md={8}>
							<Box py={2} px={2} my={2}>
								<Grid container justify="space-between" alignItems='center'>
								</Grid>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Grid container>
									<Grid item xs={6}>
										<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
											Remaining Credit
										</Typography>
										<Typography variant="h5" paragraph noWrap={false}>
											{template.remainingCredit/100}
										</Typography>
									</Grid>
									<Grid item xs={6} container spacing={2}>
										<Grid item xs={6}>
											<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
												Add Credit
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<TextField
												name="remainingCredit"
												value={this.state.remainingCredit}
												onChange={this.handleInput}
												type="number"
											/>
										</Grid>
										<Grid item xs={6}>
											<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
												Change Plan
											</Typography>
										</Grid>
										<Grid item xs={6}>
											<Autocomplete
							          disableClearable
							          options={PLANS.map((option) => option)}
							          onChange={(e, value, reason) => this.handleAutoComplete("activePlan", value, reason)}
							          value={template.activePlan}
							          renderInput={(params) => (
							            <TextField
							              {...params}
							              className={classes.textField}
							              InputProps={{ ...params.InputProps }}
							              placeholder="Select plan"
							            />
							          )}
							        />
										</Grid>
										<Grid item xs={12}>
											<Button
												onClick={this.updateCredit}
												variant="contained"
												color="primary"
											>
												Update
											</Button>
										</Grid>
									</Grid>
								</Grid>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
									Template Label
								</Typography>
								<Typography variant="h5" paragraph noWrap={false}>
									{template.label}
								</Typography>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
									Template Category
								</Typography>
								<Typography variant="h5" paragraph noWrap={false}>
									{template.category}
								</Typography>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
									Template Language
								</Typography>
								<Typography variant="h5" paragraph noWrap={false}>
									{template.language}
								</Typography>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
									Template Type
								</Typography>
								<Typography variant="h5" paragraph noWrap={false}>
									{template.type}
								</Typography>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
									Template Format
								</Typography>
								<pre>
									<Typography variant="h5" paragraph noWrap={false}>
											{template.format}
									</Typography>					
								</pre>
							</Box>
							<Box py={2} px={2} my={2} className={classes.container}>
								<Typography variant="body1" gutterBottom style={{fontWeight:"bold"}}>
									Template Sample
								</Typography>
								<pre>
									<Typography variant="h5" paragraph noWrap={false}>
											{template.sampleMessage}
									</Typography>					
								</pre>
							</Box>
							<Box my={8}>
							</Box>
						</Grid>
					</Grid>
				</div>	
				<Snackbar 
					open={this.state.alert} 
					autoHideDuration={4000} 
					onClose={this.closeSnackbar}
					anchorOrigin={{vertical: "top", horizontal: "center"}}
				>
				  <Alert 
				  	onClose={this.closeSnackbar} 
				  	severity={this.state.alertSeverity}
			  	>
				    {this.state.alertMsg}
				  </Alert>
				</Snackbar>
			</Box>	
		)
	}
}

function getFormattedDate (date) {
	const d = new Date(date)
	const options = { year: 'numeric', month: 'long', day: 'numeric' };
	return d.toLocaleDateString("en-US", options)
}
const styles = (theme) => ({
	root: {
		// width: "100%",
		paddingTop: 20,
		overflowX: "hidden"
	},
  dialogContainer: {
  	overflowX: "hidden",
  	height: "100%",
  	position: "relative",
  	color: "black"
  },
  dialogTopbar: {
  	position: "sticky",
  	top: 0,
  	left: 0,
  	background: "white",
  	zIndex: 100,
  },
	container: {
		background: "#EBF5F3",
		borderRadius: "15px"
	},
	textField: {
		width: 200
	}
})

export default withStyles(styles)(Assistant);
