import React from "react";
import { useHistory, useLocation, useParams, Link } from "react-router-dom";
import { Box, Typography, withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";

import LOGO from "../../../static/logo.jpg";
import { topRoutes, bottomRoutes, tenantRoutes } from "../Routes/ProjectRoutes";
import { TENANT_ID } from "../../../config/config";

function NavBarDesktop(props) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { classes, agent, tenantDetails } = props;
  const routes = TENANT_ID ? tenantRoutes : topRoutes;
  return (
    <div className={classes.root}>
      <Box textAlign="center">
        {TENANT_ID ? (
          <Link to="/">
            <img
              src={
                Object.keys(tenantDetails).length ? tenantDetails.brandLogo : ""
              }
              alt=""
              className={classes.logo}
            />
          </Link>
        ) : (
          <Link to="/">
            <img src={LOGO} alt="AI Timey" className={classes.logo} />
          </Link>
        )}
        {routes.map((route, index) => {
          if (agent.power < route.power) return null;

          let className = "inactive";
          if (location.pathname == route.to) {
            className = "active";
          }
          return (
            <div
              key={index}
              className={classes[className]}
              onClick={() => {
                history.push(route.to);
              }}
            >
              <Typography
                variant="body1"
                align="center"
                component="div"
                className={classes["link_" + className]}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  className={classes["icon_" + className]}
                >
                  {route.icon}
                </Box>
              </Typography>
              <Typography
                variant="body2"
                align="center"
                style={{ fontSize: "10px" }}
                className={classes["name_" + className]}
              >
                {route.name}
              </Typography>
            </div>
          );
        })}
      </Box>
      <Box mb="20px">
        {bottomRoutes.map((route, index) => {
          let className = "inactive";
          if (location.pathname.includes(route.to)) {
            className = "active";
          }
          return (
            <div
              key={index}
              className={classes.inactive}
              onClick={() => {
                history.push(route.to);
              }}
            >
              <Typography
                variant="body1"
                align="center"
                component="div"
                className={classes["link_" + className]}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  fontSize={10}
                  className={classes["icon_" + className]}
                >
                  {route.icon}
                </Box>
              </Typography>
            </div>
          );
        })}
      </Box>
    </div>
  );
}

const styles = (theme) => ({
  root: {
    width: "70px",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    background: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    color: "gray",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    overflow: "auto",
  },
  logo: {
    height: 50,
    marginTop: 15,
  },
  link_inactive: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    cursor: "pointer",
    transition: "0.5s",
  },
  link_active: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "35px",
    width: "35px",
    margin: "auto",
    borderRadius: "50%",
    backgroundColor: "white",
    color: "white",
    cursor: "pointer",
    transition: "0.5s",
  },
  // profile_active: {

  // },
  // profile_inactive: {

  // }
  icon_active: {
    color: theme.palette.primary.main,
  },
  icon_inactive: {
    color: "#fbfbfb",
  },
  name_active: {
    color: "white",
  },
  name_inactive: {
    color: "#fbfbfb",
  },
  active: {
    margin: "0.9em 0em",
    borderLeft: "2px solid",
    borderColor: "white",
  },
  inactive: {
    margin: "0.9em 0em",
    borderLeft: "2px solid",
    borderColor: "transparent",
  },
});

const connectedNavBarDesktop = connect((state) => ({
  agent: state.login.user,
  tenantDetails: state.tenant.tenant,
}))(NavBarDesktop);

export default withStyles(styles)(connectedNavBarDesktop);
