const pending = "#e65100";
const resolved = "#388e3c";
const primary = "#0a474c";
const secondary = "#fec000";
const warning = "#FFC260";
const success = "#4caf50";
const error = "#f44336";
const info = "#9013FE";
const backdrop = "#f6f7ff";
const lightenRate = 7.5;
const darkenRate = 15;
const queryText = "#e0e0e0";

export default {
  palette: {
    pending: {
      main: pending,
    },
    resolved: {
      main: resolved,
    },

    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    warning: {
      main: warning,
    },
    success: {
      main: success,
    },
    error: {
      main: error,
    },
    info: {
      main: info,
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
    backdrop: {
      default: "#f6f7ff",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 767,
      md: 959, // 1348
      lg: 1599, //1902
      xl: 1920,
    },
  },
  // shadows: ["none"],
  shape: {
    borderRadius: 6,
  },
  typography: {
    h2: {
      fontFamily: "Poppins",
      fontSize: 25,
      fontWeight: 600,
      letterSpacing: 2,
      wordSpacing: 5,
      "@media (max-width:600px)": {
        fontSize: 20,
        fontWeight: 700,
        wordSpacing: 5,
        letterSpacing: 1
      },
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: 20,
      "@media (max-width:600px)": {
        fontSize: 18,
      },
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: 16,
    },
    h5: {
      fontFamily: "Poppins",
      fontSize: 14,
      fontWeight: 400,
    },
    h6: {
      // fontFamily: "Poppins",
      fontSize: 12,
      fontWeight: 400,
    },
    body1: {
      fontFamily: "Roboto",
      fontSize: 14,
    },
    body2: {
      fontFamily: "Roboto",
      fontSize: 12,
    },
  },
  overrides: {
    MuiTextField: {
      root:{
        backgroundColor: "rgb(240,240,240)",
        borderRadius: "8px",
        boxSizing: "border-box",
        fontSize: "15px",
        fontWeight: 500,
        padding: "6px 15px"
      },
      "&:focus": {
        border: "1px solid red"
      }
    },
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          border: "none"
        },
        "&:hover $notchedOutline": {
          border: "none"
        },
        "&$focused $notchedOutline": {
          border: "none"
        },
        "&&& $input": {
          padding: "12px"
        },
        "&&& $input:first-child": {
          padding: "12px"
        }
      },
      multiline: {
        padding: 0
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"].MuiAutocomplete-input': {
          padding: 12,
        },
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: 0,
        },
      }
    },
    MuiButton: {
      "root": {
        textTransform: "none"
      },
      "contained": {
        boxShadow: "none"
      },
      "containedPrimary": {
        "&:hover": {
          boxShadow: "none"
        }
      }
    },
    MuiGrid: {
      container: {
      }
    },
  },
  props: {
    MuiInput: {
      disableUnderline: true
    }
  }
};
