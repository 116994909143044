const countryNameMap = {
  Afghanistan: { name: "Afghanistan", dialCode: "+93", isoCode: "AF" },
  "Aland Islands": { name: "Aland Islands", dialCode: "+358", isoCode: "AX" },
  Albania: { name: "Albania", dialCode: "+355", isoCode: "AL" },
  Algeria: { name: "Algeria", dialCode: "+213", isoCode: "DZ" },
  AmericanSamoa: { name: "AmericanSamoa", dialCode: "+1684", isoCode: "AS" },
  Andorra: { name: "Andorra", dialCode: "+376", isoCode: "AD" },
  Angola: { name: "Angola", dialCode: "+244", isoCode: "AO" },
  Anguilla: { name: "Anguilla", dialCode: "+1264", isoCode: "AI" },
  Antarctica: { name: "Antarctica", dialCode: "+672", isoCode: "AQ" },
  "Antigua and Barbuda": {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    isoCode: "AG",
  },
  Argentina: { name: "Argentina", dialCode: "+54", isoCode: "AR" },
  Armenia: { name: "Armenia", dialCode: "+374", isoCode: "AM" },
  Aruba: { name: "Aruba", dialCode: "+297", isoCode: "AW" },
  "Ascension Island": {
    name: "Ascension Island",
    dialCode: "+247",
    isoCode: "AC",
  },
  Australia: { name: "Australia", dialCode: "+61", isoCode: "AU" },
  Austria: { name: "Austria", dialCode: "+43", isoCode: "AT" },
  Azerbaijan: { name: "Azerbaijan", dialCode: "+994", isoCode: "AZ" },
  Bahamas: { name: "Bahamas", dialCode: "+1242", isoCode: "BS" },
  Bahrain: { name: "Bahrain", dialCode: "+973", isoCode: "BH" },
  Bangladesh: { name: "Bangladesh", dialCode: "+880", isoCode: "BD" },
  Barbados: { name: "Barbados", dialCode: "+1246", isoCode: "BB" },
  Belarus: { name: "Belarus", dialCode: "+375", isoCode: "BY" },
  Belgium: { name: "Belgium", dialCode: "+32", isoCode: "BE" },
  Belize: { name: "Belize", dialCode: "+501", isoCode: "BZ" },
  Benin: { name: "Benin", dialCode: "+229", isoCode: "BJ" },
  Bermuda: { name: "Bermuda", dialCode: "+1441", isoCode: "BM" },
  Bhutan: { name: "Bhutan", dialCode: "+975", isoCode: "BT" },
  Bolivia: { name: "Bolivia", dialCode: "+591", isoCode: "BO" },
  "Bosnia and Herzegovina": {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    isoCode: "BA",
  },
  Botswana: { name: "Botswana", dialCode: "+267", isoCode: "BW" },
  Brazil: { name: "Brazil", dialCode: "+55", isoCode: "BR" },
  "British Indian Ocean Territory": {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    isoCode: "IO",
  },
  "Brunei Darussalam": {
    name: "Brunei Darussalam",
    dialCode: "+673",
    isoCode: "BN",
  },
  Bulgaria: { name: "Bulgaria", dialCode: "+359", isoCode: "BG" },
  "Burkina Faso": { name: "Burkina Faso", dialCode: "+226", isoCode: "BF" },
  Burundi: { name: "Burundi", dialCode: "+257", isoCode: "BI" },
  Cambodia: { name: "Cambodia", dialCode: "+855", isoCode: "KH" },
  Cameroon: { name: "Cameroon", dialCode: "+237", isoCode: "CM" },
  Canada: { name: "Canada", dialCode: "+1", isoCode: "CA" },
  "Cape Verde": { name: "Cape Verde", dialCode: "+238", isoCode: "CV" },
  "Cayman Islands": {
    name: "Cayman Islands",
    dialCode: "+1345",
    isoCode: "KY",
  },
  "Central African Republic": {
    name: "Central African Republic",
    dialCode: "+236",
    isoCode: "CF",
  },
  Chad: { name: "Chad", dialCode: "+235", isoCode: "TD" },
  Chile: { name: "Chile", dialCode: "+56", isoCode: "CL" },
  China: { name: "China", dialCode: "+86", isoCode: "CN" },
  "Christmas Island": {
    name: "Christmas Island",
    dialCode: "+61",
    isoCode: "CX",
  },
  "Cocos (Keeling) Islands": {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    isoCode: "CC",
  },
  Colombia: { name: "Colombia", dialCode: "+57", isoCode: "CO" },
  Comoros: { name: "Comoros", dialCode: "+269", isoCode: "KM" },
  "Republic of the Congo (Brazzaville)": { name: "Republic of the Congo (Brazzaville)", dialCode: "+242", isoCode: "CG" },
  "Cook Islands": { name: "Cook Islands", dialCode: "+682", isoCode: "CK" },
  "Costa Rica": { name: "Costa Rica", dialCode: "+506", isoCode: "CR" },
  Croatia: { name: "Croatia", dialCode: "+385", isoCode: "HR" },
  Cuba: { name: "Cuba", dialCode: "+53", isoCode: "CU" },
  Cyprus: { name: "Cyprus", dialCode: "+357", isoCode: "CY" },
  "Czech Republic": { name: "Czech Republic", dialCode: "+420", isoCode: "CZ" },
  "Democratic Republic of the Congo": {
    name: "Democratic Republic of the Congo",
    dialCode: "+243",
    isoCode: "CD",
  },
  Denmark: { name: "Denmark", dialCode: "+45", isoCode: "DK" },
  Djibouti: { name: "Djibouti", dialCode: "+253", isoCode: "DJ" },
  Dominica: { name: "Dominica", dialCode: "+1767", isoCode: "DM" },
  "Dominican Republic": {
    name: "Dominican Republic",
    dialCode: "+1849",
    isoCode: "DO",
  },
  Ecuador: { name: "Ecuador", dialCode: "+593", isoCode: "EC" },
  Egypt: { name: "Egypt", dialCode: "+20", isoCode: "EG" },
  "El Salvador": { name: "El Salvador", dialCode: "+503", isoCode: "SV" },
  "Equatorial Guinea": {
    name: "Equatorial Guinea",
    dialCode: "+240",
    isoCode: "GQ",
  },
  Eritrea: { name: "Eritrea", dialCode: "+291", isoCode: "ER" },
  Estonia: { name: "Estonia", dialCode: "+372", isoCode: "EE" },
  Eswatini: { name: "Eswatini", dialCode: "+268", isoCode: "SZ" },
  Ethiopia: { name: "Ethiopia", dialCode: "+251", isoCode: "ET" },
  "Falkland Islands (Malvinas)": {
    name: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    isoCode: "FK",
  },
  "Faroe Islands": { name: "Faroe Islands", dialCode: "+298", isoCode: "FO" },
  Fiji: { name: "Fiji", dialCode: "+679", isoCode: "FJ" },
  Finland: { name: "Finland", dialCode: "+358", isoCode: "FI" },
  France: { name: "France", dialCode: "+33", isoCode: "FR" },
  "French Guiana": { name: "French Guiana", dialCode: "+594", isoCode: "GF" },
  "French Polynesia": {
    name: "French Polynesia",
    dialCode: "+689",
    isoCode: "PF",
  },
  Gabon: { name: "Gabon", dialCode: "+241", isoCode: "GA" },
  Gambia: { name: "Gambia", dialCode: "+220", isoCode: "GM" },
  Georgia: { name: "Georgia", dialCode: "+995", isoCode: "GE" },
  Germany: { name: "Germany", dialCode: "+49", isoCode: "DE" },
  Ghana: { name: "Ghana", dialCode: "+233", isoCode: "GH" },
  Gibraltar: { name: "Gibraltar", dialCode: "+350", isoCode: "GI" },
  Greece: { name: "Greece", dialCode: "+30", isoCode: "GR" },
  Greenland: { name: "Greenland", dialCode: "+299", isoCode: "GL" },
  Grenada: { name: "Grenada", dialCode: "+1473", isoCode: "GD" },
  Guadeloupe: { name: "Guadeloupe", dialCode: "+590", isoCode: "GP" },
  Guam: { name: "Guam", dialCode: "+1671", isoCode: "GU" },
  Guatemala: { name: "Guatemala", dialCode: "+502", isoCode: "GT" },
  Guernsey: { name: "Guernsey", dialCode: "+44", isoCode: "GG" },
  Guinea: { name: "Guinea", dialCode: "+224", isoCode: "GN" },
  "Guinea-Bissau": { name: "Guinea-Bissau", dialCode: "+245", isoCode: "GW" },
  Guyana: { name: "Guyana", dialCode: "+592", isoCode: "GY" },
  Haiti: { name: "Haiti", dialCode: "+509", isoCode: "HT" },
  "Holy See (Vatican City State)": {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    isoCode: "VA",
  },
  Honduras: { name: "Honduras", dialCode: "+504", isoCode: "HN" },
  "Hong Kong": { name: "Hong Kong", dialCode: "+852", isoCode: "HK" },
  Hungary: { name: "Hungary", dialCode: "+36", isoCode: "HU" },
  Iceland: { name: "Iceland", dialCode: "+354", isoCode: "IS" },
  India: { name: "India", dialCode: "+91", isoCode: "IN" },
  Indonesia: { name: "Indonesia", dialCode: "+62", isoCode: "ID" },
  Iran: { name: "Iran", dialCode: "+98", isoCode: "IR" },
  Iraq: { name: "Iraq", dialCode: "+964", isoCode: "IQ" },
  Ireland: { name: "Ireland", dialCode: "+353", isoCode: "IE" },
  "Isle of Man": { name: "Isle of Man", dialCode: "+44", isoCode: "IM" },
  Israel: { name: "Israel", dialCode: "+972", isoCode: "IL" },
  Italy: { name: "Italy", dialCode: "+39", isoCode: "IT" },
  "Ivory Coast": {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: "+225",
    isoCode: "CI",
  },
  Jamaica: { name: "Jamaica", dialCode: "+1876", isoCode: "JM" },
  Japan: { name: "Japan", dialCode: "+81", isoCode: "JP" },
  Jersey: { name: "Jersey", dialCode: "+44", isoCode: "JE" },
  Jordan: { name: "Jordan", dialCode: "+962", isoCode: "JO" },
  Kazakhstan: { name: "Kazakhstan", dialCode: "+77", isoCode: "KZ" },
  Kenya: { name: "Kenya", dialCode: "+254", isoCode: "KE" },
  Kiribati: { name: "Kiribati", dialCode: "+686", isoCode: "KI" },
  "Korea, Democratic People's Republic of Korea": {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    isoCode: "KP",
  },
  "Korea, Republic of South Korea": {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    isoCode: "KR",
  },
  Kosovo: { name: "Kosovo", dialCode: "+383", isoCode: "XK" },
  Kuwait: { name: "Kuwait", dialCode: "+965", isoCode: "KW" },
  Kyrgyzstan: { name: "Kyrgyzstan", dialCode: "+996", isoCode: "KG" },
  Laos: { name: "Laos", dialCode: "+856", isoCode: "LA" },
  Latvia: { name: "Latvia", dialCode: "+371", isoCode: "LV" },
  Lebanon: { name: "Lebanon", dialCode: "+961", isoCode: "LB" },
  Lesotho: { name: "Lesotho", dialCode: "+266", isoCode: "LS" },
  Liberia: { name: "Liberia", dialCode: "+231", isoCode: "LR" },
  Libya: { name: "Libya", dialCode: "+218", isoCode: "LY" },
  Liechtenstein: { name: "Liechtenstein", dialCode: "+423", isoCode: "LI" },
  Lithuania: { name: "Lithuania", dialCode: "+370", isoCode: "LT" },
  Luxembourg: { name: "Luxembourg", dialCode: "+352", isoCode: "LU" },
  Macau: { name: "Macau", dialCode: "+853", isoCode: "MO" },
  Madagascar: { name: "Madagascar", dialCode: "+261", isoCode: "MG" },
  Malawi: { name: "Malawi", dialCode: "+265", isoCode: "MW" },
  Malaysia: { name: "Malaysia", dialCode: "+60", isoCode: "MY" },
  Maldives: { name: "Maldives", dialCode: "+960", isoCode: "MV" },
  Mali: { name: "Mali", dialCode: "+223", isoCode: "ML" },
  Malta: { name: "Malta", dialCode: "+356", isoCode: "MT" },
  "Marshall Islands": {
    name: "Marshall Islands",
    dialCode: "+692",
    isoCode: "MH",
  },
  Martinique: { name: "Martinique", dialCode: "+596", isoCode: "MQ" },
  Mauritania: { name: "Mauritania", dialCode: "+222", isoCode: "MR" },
  Mauritius: { name: "Mauritius", dialCode: "+230", isoCode: "MU" },
  Mayotte: { name: "Mayotte", dialCode: "+262", isoCode: "YT" },
  Mexico: { name: "Mexico", dialCode: "+52", isoCode: "MX" },
  "Micronesia, Federated States of Micronesia": {
    name: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    isoCode: "FM",
  },
  Moldova: { name: "Moldova", dialCode: "+373", isoCode: "MD" },
  Monaco: { name: "Monaco", dialCode: "+377", isoCode: "MC" },
  Mongolia: { name: "Mongolia", dialCode: "+976", isoCode: "MN" },
  Montenegro: { name: "Montenegro", dialCode: "+382", isoCode: "ME" },
  Montserrat: { name: "Montserrat", dialCode: "+1664", isoCode: "MS" },
  Morocco: { name: "Morocco", dialCode: "+212", isoCode: "MA" },
  Mozambique: { name: "Mozambique", dialCode: "+258", isoCode: "MZ" },
  Myanmar: { name: "Myanmar", dialCode: "+95", isoCode: "MM" },
  Namibia: { name: "Namibia", dialCode: "+264", isoCode: "NA" },
  Nauru: { name: "Nauru", dialCode: "+674", isoCode: "NR" },
  Nepal: { name: "Nepal", dialCode: "+977", isoCode: "NP" },
  Netherlands: { name: "Netherlands", dialCode: "+31", isoCode: "NL" },
  "Netherlands Antilles": {
    name: "Netherlands Antilles",
    dialCode: "+599",
    isoCode: "AN",
  },
  "New Caledonia": { name: "New Caledonia", dialCode: "+687", isoCode: "NC" },
  "New Zealand": { name: "New Zealand", dialCode: "+64", isoCode: "NZ" },
  Nicaragua: { name: "Nicaragua", dialCode: "+505", isoCode: "NI" },
  Niger: { name: "Niger", dialCode: "+227", isoCode: "NE" },
  Nigeria: { name: "Nigeria", dialCode: "+234", isoCode: "NG" },
  Niue: { name: "Niue", dialCode: "+683", isoCode: "NU" },
  "Norfolk Island": { name: "Norfolk Island", dialCode: "+672", isoCode: "NF" },
  "North Macedonia": {
    name: "North Macedonia",
    dialCode: "+389",
    isoCode: "MK",
  },
  "Northern Mariana Islands": {
    name: "Northern Mariana Islands",
    dialCode: "+1670",
    isoCode: "MP",
  },
  Norway: { name: "Norway", dialCode: "+47", isoCode: "NO" },
  Oman: { name: "Oman", dialCode: "+968", isoCode: "OM" },
  Pakistan: { name: "Pakistan", dialCode: "+92", isoCode: "PK" },
  Palau: { name: "Palau", dialCode: "+680", isoCode: "PW" },
  Palestine: { name: "Palestine", dialCode: "+970", isoCode: "PS" },
  Panama: { name: "Panama", dialCode: "+507", isoCode: "PA" },
  "Papua New Guinea": {
    name: "Papua New Guinea",
    dialCode: "+675",
    isoCode: "PG",
  },
  Paraguay: { name: "Paraguay", dialCode: "+595", isoCode: "PY" },
  Peru: { name: "Peru", dialCode: "+51", isoCode: "PE" },
  Philippines: { name: "Philippines", dialCode: "+63", isoCode: "PH" },
  Pitcairn: { name: "Pitcairn", dialCode: "+872", isoCode: "PN" },
  Poland: { name: "Poland", dialCode: "+48", isoCode: "PL" },
  Portugal: { name: "Portugal", dialCode: "+351", isoCode: "PT" },
  "Puerto Rico": { name: "Puerto Rico", dialCode: "+1939", isoCode: "PR" },
  Qatar: { name: "Qatar", dialCode: "+974", isoCode: "QA" },
  Reunion: { name: "Reunion", dialCode: "+262", isoCode: "RE" },
  Romania: { name: "Romania", dialCode: "+40", isoCode: "RO" },
  Russia: { name: "Russia", dialCode: "+7", isoCode: "RU" },
  Rwanda: { name: "Rwanda", dialCode: "+250", isoCode: "RW" },
  "Saint Barthelemy": {
    name: "Saint Barthelemy",
    dialCode: "+590",
    isoCode: "BL",
  },
  "Saint Helena, Ascension and Tristan Da Cunha": {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    isoCode: "SH",
  },
  "Saint Kitts and Nevis": {
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    isoCode: "KN",
  },
  "Saint Lucia": { name: "Saint Lucia", dialCode: "+1758", isoCode: "LC" },
  "Saint Martin": { name: "Saint Martin", dialCode: "+590", isoCode: "MF" },
  "Saint Pierre and Miquelon": {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    isoCode: "PM",
  },
  "Saint Vincent and the Grenadines": {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    isoCode: "VC",
  },
  Samoa: { name: "Samoa", dialCode: "+685", isoCode: "WS" },
  "San Marino": { name: "San Marino", dialCode: "+378", isoCode: "SM" },
  "Sao Tome and Principe": {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    isoCode: "ST",
  },
  "Saudi Arabia": { name: "Saudi Arabia", dialCode: "+966", isoCode: "SA" },
  Senegal: { name: "Senegal", dialCode: "+221", isoCode: "SN" },
  Serbia: { name: "Serbia", dialCode: "+381", isoCode: "RS" },
  Seychelles: { name: "Seychelles", dialCode: "+248", isoCode: "SC" },
  "Sierra Leone": { name: "Sierra Leone", dialCode: "+232", isoCode: "SL" },
  Singapore: { name: "Singapore", dialCode: "+65", isoCode: "SG" },
  "Sint Maarten": { name: "Sint Maarten", dialCode: "+1721", isoCode: "SX" },
  Slovakia: { name: "Slovakia", dialCode: "+421", isoCode: "SK" },
  Slovenia: { name: "Slovenia", dialCode: "+386", isoCode: "SI" },
  "Solomon Islands": {
    name: "Solomon Islands",
    dialCode: "+677",
    isoCode: "SB",
  },
  Somalia: { name: "Somalia", dialCode: "+252", isoCode: "SO" },
  "South Africa": { name: "South Africa", dialCode: "+27", isoCode: "ZA" },
  "South Georgia and the South Sandwich Islands": {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    isoCode: "GS",
  },
  "South Sudan": { name: "South Sudan", dialCode: "+211", isoCode: "SS" },
  Spain: { name: "Spain", dialCode: "+34", isoCode: "ES" },
  "Sri Lanka": { name: "Sri Lanka", dialCode: "+94", isoCode: "LK" },
  Sudan: { name: "Sudan", dialCode: "+249", isoCode: "SD" },
  Suriname: { name: "Suriname", dialCode: "+597", isoCode: "SR" },
  "Svalbard and Jan Mayen": {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    isoCode: "SJ",
  },
  Sweden: { name: "Sweden", dialCode: "+46", isoCode: "SE" },
  Swaziland: {name: "Eswatini", dialCode:"+268", isoCode: "SZ"},
  Switzerland: { name: "Switzerland", dialCode: "+41", isoCode: "CH" },
  "Syrian Arab Republic": {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    isoCode: "SY",
  },
  Taiwan: { name: "Taiwan", dialCode: "+886", isoCode: "TW" },
  Tajikistan: { name: "Tajikistan", dialCode: "+992", isoCode: "TJ" },
  "Tanzania": {
    name: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    isoCode: "TZ",
  },
  Thailand: { name: "Thailand", dialCode: "+66", isoCode: "TH" },
  "Timor-Leste": { name: "Timor-Leste", dialCode: "+670", isoCode: "TL" },
  Togo: { name: "Togo", dialCode: "+228", isoCode: "TG" },
  Tokelau: { name: "Tokelau", dialCode: "+690", isoCode: "TK" },
  Tonga: { name: "Tonga", dialCode: "+676", isoCode: "TO" },
  "Trinidad and Tobago": {
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    isoCode: "TT",
  },
  Tunisia: { name: "Tunisia", dialCode: "+216", isoCode: "TN" },
  Turkey: { name: "Turkey", dialCode: "+90", isoCode: "TR" },
  Turkmenistan: { name: "Turkmenistan", dialCode: "+993", isoCode: "TM" },
  "Turks and Caicos Islands": {
    name: "Turks and Caicos Islands",
    dialCode: "+1649",
    isoCode: "TC",
  },
  Tuvalu: { name: "Tuvalu", dialCode: "+688", isoCode: "TV" },
  Uganda: { name: "Uganda", dialCode: "+256", isoCode: "UG" },
  Ukraine: { name: "Ukraine", dialCode: "+380", isoCode: "UA" },
  "United Arab Emirates": {
    name: "United Arab Emirates",
    dialCode: "+971",
    isoCode: "AE",
  },
  "United Kingdom": { name: "United Kingdom", dialCode: "+44", isoCode: "GB" },
  "United States": { name: "United States", dialCode: "+1", isoCode: "US" },
  Uruguay: { name: "Uruguay", dialCode: "+598", isoCode: "UY" },
  Uzbekistan: { name: "Uzbekistan", dialCode: "+998", isoCode: "UZ" },
  Vanuatu: { name: "Vanuatu", dialCode: "+678", isoCode: "VU" },
  "Venezuela": {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    isoCode: "VE",
  },
  Vietnam: { name: "Vietnam", dialCode: "+84", isoCode: "VN" },
  "Virgin Islands, British": {
    name: "Virgin Islands, British",
    dialCode: "+1284",
    isoCode: "VG",
  },
  "Virgin Islands, U.S.": {
    name: "Virgin Islands, U.S.",
    dialCode: "+1340",
    isoCode: "VI",
  },
  "Wallis and Futuna": {
    name: "Wallis and Futuna",
    dialCode: "+681",
    isoCode: "WF",
  },
  Yemen: { name: "Yemen", dialCode: "+967", isoCode: "YE" },
  Zambia: { name: "Zambia", dialCode: "+260", isoCode: "ZM" },
  Zimbabwe: { name: "Zimbabwe", dialCode: "+263", isoCode: "ZW" },
  Other: { name: "Other", isoCode: "OTHER" },
};

const northAmerica = ["Canada", "United States"];
const restOfAfrica = [
  "Algeria",
  "Angola",
  "Benin",
  "Botswana",
  "Burkina Faso",
  "Burundi",
  "Cameroon",
  "Chad",
  "Republic of the Congo (Brazzaville)",
  "Eritrea",
  "Ethiopia",
  "Gabon",
  "Gambia",
  "Ghana",
  "Guinea-Bissau",
  "Ivory Coast",
  "Kenya",
  "Lesotho",
  "Liberia",
  "Libya",
  "Madagascar",
  "Malawi",
  "Mali",
  "Mauritania",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Niger",
  "Rwanda",
  "Senegal",
  "Sierra Leone",
  "Somalia",
  "South Sudan",
  "Sudan",
  "Swaziland",
  "Tanzania",
  "Togo",
  "Tunisia",
  "Uganda",
  "Zambia",
];
const restOfAsiaPacific = [
  "Afghanistan",
  "Australia",
  "Bangladesh",
  "Cambodia",
  "China",
  "Hong Kong",
  "Japan",
  "Laos",
  "Mongolia",
  "Nepal",
  "New Zealand",
  "Papua New Guinea",
  "Philippines",
  "Singapore",
  "Sri Lanka",
  "Taiwan",
  "Tajikistan",
  "Thailand",
  "Turkmenistan",
  "Uzbekistan",
  "Vietnam",
];
const restOfCentralAndEasternEurope = [
  "Albania",
  "Armenia",
  "Azerbaijan",
  "Belarus",
  "Bulgaria",
  "Croatia",
  "Czech Republic",
  "Georgia",
  "Greece",
  "Hungary",
  "Latvia",
  "Lithuania",
  "Moldova",
  "North Macedonia",
  "Poland",
  "Romania",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Ukraine",
];
const restOfWesternEurope = [
  "Austria",
  "Belgium",
  "Denmark",
  "Finland",
  "Ireland",
  "Norway",
  "Portugal",
  "Sweden",
  "Switzerland",
];
const restOfLatinAmerica = [
  "Bolivia",
  "Costa Rica",
  "Dominican Republic",
  "Ecuador",
  "El Salvador",
  "Guatemala",
  "Haiti",
  "Honduras",
  "Jamaica",
  "Nicaragua",
  "Panama",
  "Paraguay",
  "Puerto Rico",
  "Uruguay",
  "Venezuela",
];
const restOfMiddleEast = [
  "Bahrain",
  "Iraq",
  "Jordan",
  "Kuwait",
  "Lebanon",
  "Oman",
  "Qatar",
  "Yemen",
];

module.exports = {
  countryNameMap,
  northAmerica,
  restOfAfrica,
  restOfAsiaPacific,
  restOfCentralAndEasternEurope,
  restOfWesternEurope,
  restOfLatinAmerica,
  restOfMiddleEast,
};
