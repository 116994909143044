import React, { Component } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import ViewTemplateLibraryPage from "./ViewTemplateLibraryPage";
import CreateTemplateLibraryPage from "./CreateTemplateLibraryPage";

class TemplateMsgPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Switch>
        <Route exact path={"/library"} component={ViewTemplateLibraryPage} />
        <Route
          path={"/library/create"}
          component={CreateTemplateLibraryPage}
        />
      </Switch>
    );
  }
}

const styles = (theme) => ({});

export default withStyles(styles)(TemplateMsgPage);
