import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Button,
  Box,
  Typography,
  IconButton,
  TextField,
  Snackbar,
} from "@material-ui/core";
import { ArrowBackOutlined } from "@material-ui/icons";
import { Autocomplete, Alert } from "@material-ui/lab";
import axios from "axios";

import { URL } from "../../config/config";

const STATUS = ["PENDING", "APPROVED", "REJECTED"];

function getStatusColor() {
  const templateStatus = "PENDING";
  switch (templateStatus) {
    case "PENDING":
      return "grey";
    case "APPROVED":
      return "#08CF65";
    case "REJECTED":
      return "#ff0000";
    default:
      return "pink";
  }
}

class TemplateDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "PENDING",
      alert: false,
      alertMsg: "",
      alertSeverity: "",
    };
  }
  handleAutoComplete = (fieldName, value, reason) => {
    if (reason == "select-option") {
      const templates = [...this.props.templates];
      let template = templates[this.props.templateIndex];
      template.status = value;
      this.setTemplateInDb({ status: value })
        .then((response) => {
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template status updated!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg: "Unable to update template status",
            alertSeverity: "error",
          });
        });
    }
  };
  handleMark = (mark, temp) => {
    const template = { ...temp };
    if (mark) {
      // set process to new Date()
      this.submitTemplateMessage()
        .then((response) => {
          template.processed = new Date();
          this.props.setTemplate(template);
          this.setState({
            alert: true,
            alertMsg: "Template marked processed!",
            alertSeverity: "success",
          });
        })
        .catch((error) => {
          this.setState({
            alert: true,
            alertMsg: "Unable to mark, Check your internet connection.",
            alertSeverity: "error",
          });
        });
    } else {
      // this.setTemplateInDb({ processed: null })
      //   .then((response) => {
      //     template.processed = null;
      //     this.props.setTemplate(template);
      this.setState({
        alert: true,
        alertMsg: "Do nothing!",
        alertSeverity: "success",
      });
      //   })
      //   .catch((error) => {
      //     this.setState({
      //       alert: true,
      //       alertMsg:
      //         "Unable to fulfill request, Check your internet connection.",
      //       alertSeverity: "error",
      //     });
      //   });
    }
  };
  setTemplateInDb = (fields) => {
    // try {
    const t = this.props.templates[this.props.templateIndex];
    const queryObj = {
      assistantId: t.assistantId,
      templateId: t._id,
      fields,
    };
    return axios.post(URL + "/superadmin/set-template-field", { ...queryObj });
  };
  submitTemplateMessage = () => {
    // try {
    const t = this.props.templates[this.props.templateIndex];
    const queryObj = {
      assistantId: t.assistantId,
      template: t,
    };
    return axios.post(URL + "/superadmin/submit-template-message", {
      ...queryObj,
    });
  };
  closeSnackbar = () => {
    this.setState({ alert: false, alertMsg: "", alertSeverity: "" });
  };
  render() {
    const { classes, toggleDialog, templateIndex, templates, setTemplate } =
      this.props;
    const template = templates[templateIndex];
    return (
      <Box px={2} className={classes.dialogContainer}>
        <Grid container alignItems="center" className={classes.dialogTopbar}>
          <Grid item>
            <IconButton onClick={toggleDialog}>
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item>
            {template.assistantName} : {template.name}
          </Grid>
        </Grid>
        <div className={classes.root}>
          <Grid container justify="center">
            <Grid item xs={12} md={8}>
              <Box py={2} px={2} my={2}>
                <Grid container justify="space-between" alignItems="center">
                  <Grid item xs={12} md={"auto"}>
                    <Autocomplete
                      disableClearable
                      options={STATUS.map((option) => option)}
                      onChange={(e, value, reason) =>
                        this.handleAutoComplete("status", value, reason)
                      }
                      value={template.status}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={classes.textField}
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select message type"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={"auto"}>
                    {!template.processed ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.handleMark(true, template)}
                      >
                        Submit
                      </Button>
                    ) : (
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          component="span"
                          style={{ marginRight: 10 }}
                        >
                          Processed at {getFormattedDate(template.processed)}
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => this.handleMark(false, template)}
                        >
                          Unmark
                        </Button>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Box>
              {template.rejectedReason ? (
                <Box py={2} px={2} my={2} className={classes.container}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Failure Reason
                  </Typography>
                  <Typography
                    variant="h5"
                    paragraph
                    noWrap={false}
                    color="error"
                  >
                    {template.rejectedReason}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Name
                </Typography>
                <Typography variant="h5" paragraph noWrap={false}>
                  {template.name}
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Label
                </Typography>
                <Typography variant="h5" paragraph noWrap={false}>
                  {template.label}
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Category
                </Typography>
                <Typography variant="h5" paragraph noWrap={false}>
                  {template.category}
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Language
                </Typography>
                <Typography variant="h5" paragraph noWrap={false}>
                  {template.language || template.templateLanguage}
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Type
                </Typography>
                <Typography variant="h5" paragraph noWrap={false}>
                  {template.type}
                </Typography>
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Format
                </Typography>
                {/* <pre> */}
                <Typography
                  variant="h5"
                  paragraph
                  noWrap={false}
                  component="pre"
                >
                  {template.format}
                </Typography>
                {/* </pre> */}
              </Box>
              <Box py={2} px={2} my={2} className={classes.container}>
                <Typography
                  variant="body1"
                  gutterBottom
                  style={{ fontWeight: "bold" }}
                >
                  Template Sample
                </Typography>
                <pre>
                  <Typography variant="h5" paragraph>
                    {template.sampleMessage}
                  </Typography>
                </pre>
              </Box>
              {template.sampleCTAUrl ? (
                <Box py={2} px={2} my={2} className={classes.container}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Sample CTA URL
                  </Typography>
                  <pre>
                    <Typography variant="h5" paragraph>
                      {template.sampleCTAUrl}
                    </Typography>
                  </pre>
                </Box>
              ) : (
                ""
              )}
              {template.sampleMediaUrl ? (
                <Box py={2} px={2} my={2} className={classes.container}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    style={{ fontWeight: "bold" }}
                  >
                    Sample Media URL
                  </Typography>
                  <pre>
                    <Typography variant="h5" paragraph>
                      {template.sampleMediaUrl}
                    </Typography>
                  </pre>
                </Box>
              ) : (
                ""
              )}
              <Box my={8}></Box>
            </Grid>
          </Grid>
        </div>
        <Snackbar
          open={this.state.alert}
          autoHideDuration={4000}
          onClose={this.closeSnackbar}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={this.closeSnackbar}
            severity={this.state.alertSeverity}
          >
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    // width: "100%",
    paddingTop: 20,
    overflowX: "hidden",
    "& pre": {
      whiteSpace: "pre-wrap",
      // whiteSpace: "-moz-pre-wrap",
      // whiteSpace: "-pre-wrap",
      // whiteSpace: "-o-pre-wrap",
      wordWrap: "break-word",
      wordBreak: "keep-all",
    },
  },
  dialogContainer: {
    overflowX: "hidden",
    height: "100%",
    position: "relative",
    color: "black",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  container: {
    background: "#EBF5F3",
    borderRadius: "15px",
  },
  textField: {
    width: 200,
  },
});

export default withStyles(styles)(TemplateDetails);
