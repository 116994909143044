import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Check, ExpandMore } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React, { useState } from "react";

function BulkUpdate(props) {
  const { classes, applyBulkUpdate } = props;
  const [expanded, setExpanded] = useState(false);
  const [sign, setSign] = useState("+");
  const [mc, setMc] = useState();
  const [mcApplied, setMcApplied] = useState(false);
  const [sc, setSc] = useState();
  const [scApplied, setScApplied] = useState(false);
  const [ac, setAc] = useState();
  const [acApplied, setAcApplied] = useState(false);
  const [uc, setUc] = useState();
  const [ucApplied, setUcApplied] = useState(false);
  const [region, setRegion] = useState("all");
  const [updateAgainstMeta, setUpdateAgainstMeta] = useState(false);

  const SignToggle = () => {
    return (
      <ToggleButtonGroup
        value={sign}
        exclusive
        onChange={(e, value) => {
          console.log(value);
          setSign(value);
          setMcApplied(false);
          setScApplied(false);
          setAcApplied(false);
          setUcApplied(false);
          setUpdateAgainstMeta(false);
          e.stopPropagation();
        }}
        aria-label="Sign toggle"
        style={{ padding: 0 }}
      >
        <ToggleButton
          value="%"
          aria-label="centered"
          size="small"
          style={{
            padding: "4px 12px",
            background: sign === "%" ? "rgb(70 20 134)" : "#A9A9A9",
            borderColor: sign === "%" ? "rgb(70 20 134)" : "#A9A9A9",
            color: "white",
            fontWeight: 600,
          }}
        >
          Percentage
        </ToggleButton>
        <ToggleButton
          value="+"
          aria-label="centered"
          size="small"
          style={{
            padding: "4px 12px",
            background: sign === "+" ? "rgb(70 20 134)" : "#A9A9A9",
            borderColor: sign === "+" ? "rgb(70 20 134)" : "#A9A9A9",
            color: "white",
            fontWeight: 600,
          }}
        >
          Increment
        </ToggleButton>
        <ToggleButton
          value="="
          aria-label="centered"
          size="small"
          style={{
            padding: "4px 12px",
            background: sign === "=" ? "rgb(70 20 134)" : "#A9A9A9",
            borderColor: sign === "=" ? "rgb(70 20 134)" : "#A9A9A9",
            color: "white",
            fontWeight: 600,
          }}
        >
          Custom Value
        </ToggleButton>
      </ToggleButtonGroup>
    );
  };

  return (
    <Accordion
      elevation={0}
      style={{ background: "transparent" }}
      className={`${classes.cells} ${classes.accordian}`}
      onChange={(e, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={classes.accordianSummary}
      >
        <Grid
          container
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 0,
          }}
        >
          <Grid item xs={12} md={6} className={classes.cellText}>
            Bulk update margin
          </Grid>
          {expanded && (
            <Grid
              item
              xs={12}
              md={6}
              style={{ justifyContent: "flex-end", display: "flex" }}
            >
              <SignToggle />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
          paddingTop: 16,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              required
              // className={classes.}
              variant="h5"
              // align="center"
            >
              Target Region
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioGroup
              aria-label="region"
              name="regions-1"
              value={region}
              onChange={(e) => {
                setRegion(e.target.value);
                setMcApplied(false);
                setScApplied(false);
                setAcApplied(false);
                setUcApplied(false);
                e.stopPropagation();
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "16px 0px",
                background: "rgb(192,192,192, 0.15)",
                padding: "8px 16px",
                borderRadius: 5,
              }}
            >
              <FormControlLabel
                value="all"
                control={<Radio color="primary" />}
                label="All"
              />
              <FormControlLabel
                value="northAmerica"
                control={<Radio color="primary" />}
                label="North America"
              />
              <FormControlLabel
                value="restOfAfrica"
                control={<Radio color="primary" />}
                label="Rest of Africa"
              />
              <FormControlLabel
                value="restOfAsiaPacific"
                control={<Radio color="primary" />}
                label="Rest of Asia Pacific"
              />
              <FormControlLabel
                value="restOfCentralAndEasternEurope"
                control={<Radio color="primary" />}
                label="Rest of Central & Eastern Europe"
              />
              <FormControlLabel
                value="restOfWesternEurope"
                control={<Radio color="primary" />}
                label="Rest of Western Europe"
              />
              <FormControlLabel
                value="restOfLatinAmerica"
                control={<Radio color="primary" />}
                label="Rest of Latin America"
              />
              <FormControlLabel
                value="restOfMiddleEast"
                control={<Radio color="primary" />}
                label="Rest of Middle East"
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </AccordionDetails>
      {sign !== "=" && (
        <AccordionDetails
          style={{
            display: "flex",
            alignItems: "center",
            padding: 0,
            paddingTop: 16,
          }}
        >
          <Grid container>
            <Grid item xs={12} md={8}>
              <Typography
                required
                // className={classes.}
                variant="h5"
                // align="center"
              >
                Perform this operation against Meta's pricing
              </Typography>
            </Grid>

            {/* <-- bic wcc handling --> */}
            <Grid
              item
              xs={12}
              md={4}
              style={{
                justifyContent: "flex-end",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Switch
                checked={updateAgainstMeta}
                onChange={(e) => setUpdateAgainstMeta(e.target.checked)}
                name="metaPricingCheck"
                color="primary"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      )}
      <AccordionDetails
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
          paddingTop: 16,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              required
              // className={classes.}
              variant="h5"
              // align="center"
            >
              Marketing Initiated Margin
            </Typography>
          </Grid>

          {/* <-- bic wcc handling --> */}
          <Grid
            item
            xs={12}
            md={6}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <TextField
              id="margin"
              placeholder="Specify margin here"
              name="margin"
              value={mc}
              type="Number"
              onChange={(e) => {
                setMc(e.target.value);
                setMcApplied(false);
              }}
              className={classes.phoneTextFieldRoot}
              InputProps={{
                endAdornment: mc && (
                  <InputAdornment position="start">
                    <Typography>({sign})</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              onClick={() => {
                applyBulkUpdate(
                  "marketing",
                  sign,
                  Number(mc),
                  region,
                  updateAgainstMeta
                );
                setMcApplied(true);
              }}
              color="primary"
              variant="contained"
              disabled={!mc}
              startIcon={mcApplied && <Check color="white" />}
              style={{
                marginLeft: 8,
                padding: mcApplied ? "8px 20px" : "8px 38px",
                marginTop: "4px",
                marginBottom: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                background: mcApplied && "#50C878",
                color: mcApplied && "#fff",
              }}
            >
              {mcApplied ? "Applied" : "Apply"}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionDetails
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              required
              // className={classes.}
              variant="h5"
              // align="center"
            >
              Service Initiated Margin
            </Typography>
          </Grid>

          {/* <-- bic wcc handling --> */}
          <Grid
            item
            xs={12}
            md={6}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <TextField
              id="margin"
              placeholder="Specify margin here"
              type="Number"
              name="margin"
              value={sc}
              onChange={(e) => {
                setSc(e.target.value);
                setScApplied(false);
              }}
              className={classes.phoneTextFieldRoot}
              InputProps={{
                endAdornment: sc && (
                  <InputAdornment position="start">
                    <Typography>({sign})</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              onClick={() => {
                applyBulkUpdate(
                  "service",
                  sign,
                  Number(sc),
                  region,
                  updateAgainstMeta
                );
                setScApplied(true);
              }}
              color="primary"
              variant="contained"
              disabled={!sc}
              startIcon={scApplied && <Check color="white" />}
              style={{
                marginLeft: 8,
                padding: scApplied ? "8px 20px" : "8px 38px",
                marginTop: "4px",
                marginBottom: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                background: scApplied && "#50C878",
                color: scApplied && "#fff",
              }}
            >
              {scApplied ? "Applied" : "Apply"}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionDetails
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              required
              // className={classes.}
              variant="h5"
              // align="center"
            >
              Utility Initiated Margin
            </Typography>
          </Grid>

          {/* <-- bic wcc handling --> */}
          <Grid
            item
            xs={12}
            md={6}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <TextField
              id="margin"
              placeholder="Specify margin here"
              type="Number"
              name="margin"
              value={uc}
              onChange={(e) => {
                setUc(e.target.value);
                setUcApplied(false);
              }}
              className={classes.phoneTextFieldRoot}
              InputProps={{
                endAdornment: uc && (
                  <InputAdornment position="start">
                    <Typography>({sign})</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              onClick={() => {
                applyBulkUpdate(
                  "utility",
                  sign,
                  Number(uc),
                  region,
                  updateAgainstMeta
                );
                setUcApplied(true);
              }}
              color="primary"
              variant="contained"
              disabled={!uc}
              startIcon={ucApplied && <Check color="white" />}
              style={{
                marginLeft: 8,
                padding: ucApplied ? "8px 20px" : "8px 38px",
                marginTop: "4px",
                marginBottom: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                background: ucApplied && "#50C878",
                color: ucApplied && "#fff",
              }}
            >
              {ucApplied ? "Applied" : "Apply"}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionDetails
        style={{
          display: "flex",
          alignItems: "center",
          padding: 0,
        }}
      >
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography
              required
              // className={classes.}
              variant="h5"
              // align="center"
            >
              Authentication Initiated Margin
            </Typography>
          </Grid>

          {/* <-- bic wcc handling --> */}
          <Grid
            item
            xs={12}
            md={6}
            style={{ justifyContent: "flex-end", display: "flex" }}
          >
            <TextField
              id="margin"
              placeholder="Specify margin here"
              type="Number"
              name="margin"
              value={ac}
              onChange={(e) => {
                setAc(e.target.value);
                setAcApplied(false);
              }}
              className={classes.phoneTextFieldRoot}
              InputProps={{
                endAdornment: ac && (
                  <InputAdornment position="start">
                    <Typography>({sign})</Typography>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              onClick={() => {
                applyBulkUpdate(
                  "authentication",
                  sign,
                  Number(ac),
                  region,
                  updateAgainstMeta
                );
                setAcApplied(true);
              }}
              color="primary"
              variant="contained"
              disabled={!ac}
              startIcon={acApplied && <Check color="white" />}
              style={{
                marginLeft: 8,
                padding: acApplied ? "8px 20px" : "8px 38px",
                marginTop: "4px",
                marginBottom: "20px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                background: acApplied && "#50C878",
                color: acApplied && "#fff",
              }}
            >
              {acApplied ? "Applied" : "Apply"}
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

const styles = (theme) => ({
  projectdialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        height: "100%",
        maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        maxHeight: "unset",
        minHeight: "100px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    overflowX: "hidden",
    paddingTop: "15px",
    paddingLeft: "15px",
    paddingRight: "15px",
    paddingBottom: "15px",
    background: "rgb(248,248,248)",
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "20px"
    },
  },
  skeltonContainer: {
    width: "100%",
    borderRadius: "8px",
    height: "270px",
    // background: "rgb(70 20 134 / 15%)",
    background: "white",
  },
  skelton: {
    height: "100%",
    width: "100%",
    margin: "0px",
    transform: "none",
    borderRadius: "8px",
  },
  formCloseButton: {
    padding: 0,
    float: "right",
  },
  dialogTopbar: {
    position: "sticky",
    top: 0,
    left: 0,
    background: "white",
    zIndex: 100,
  },
  roundedBox: {
    minWidth: "100px",
    height: "60px",
    borderRadius: "12px",
    backgroundColor: "white",
    border: "1px black",
    margin: "1em 0em",
    padding: "2em",
  },
  roundedBox3: {
    // height: "45px",
    borderRadius: "12px",
    backgroundColor: "rgb(237, 226, 252)",
    border: "1px black",
    margin: "1em 0em",
    padding: "1em 2em",
    flexWrap: "nowrap",
    lineBreak: "anywhere",
  },
  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 4px",
    marginTop: "4px",
    marginBottom: "10px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  phoneTextFieldRoot: {
    width: "160px",
    padding: "4px 0px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 8px)", marginLeft: "8px" },
    background: "white",
    border: "2px solid grey",
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  roundedBox2: {
    // width: "100%",
    [theme.breakpoints.down("sm")]: {
      // height: "auto",
      borderRadius: "12px",
      marginRight: "0px",
    },
    height: "100%",
    backgroundColor: "white",
    border: "1px black",
    borderRadius: "8px",
    margin: "0px 8px",
    padding: "1.5em 1.5em 1.5em 1.5em",
    marginBottom: "2em",
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 0px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  codeTextFieldRoot: {
    width: "100%",
    padding: "0 0 0 18px",
    position: "absolute",
    zIndex: 1,
    margin: "6px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "8px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  cellText: {
    fontSize: 16,
    fontWeight: 500,
  },
  accordianDetail: {
    padding: "0",
    display: "block",
  },
  accordianSummary: {
    paddingLeft: "0px",
    paddingRight: 0,
    paddingTop: 0,
    "&.Mui-expanded": {
      minHeight: "0px",
    },
  },
  accordian: {
    "&:before": {
      display: "none",
      paddingRight: 0,
    },
    "&.Mui-expanded": {
      minHeight: "0px",
      marginTop: "0px",
    },
  },
});

export default withStyles(styles)(BulkUpdate);
