import axios from "axios";
import { URL } from "../config/config";

/**
 * @param {string} assistantId
 * @param {method} setState
 */
export const checkFbConnectRateLimit = async (assistantId, setState) => {
  try {
    await axios.post(`${URL}/api/rate-limit-connect-to-fb`, { assistantId });
  } catch (error) {
    setState({
      fbLoading: false,
      snackbar: {
        alert: true,
        message:
          error?.response?.data?.message ||
          "Something went wrong. Please try again later!",
        severity: "error",
        code: error.response?.status || 500,
      },
    });

    return true;
  }
};
