import moment from "moment";
import { Box, Button } from "@material-ui/core";

const LatestDateFilter = (props) => {
  const { setDate, filterKey, setFocus } = props;

  const applyDate = (day) => {
    setFocus(filterKey);
    if (day === "yesterday") {
      const now = moment();
      const startOfYesterday = moment().subtract(1, "days").startOf("day");
      const startOfToday = now.startOf("day");
      setDate(filterKey, startOfYesterday, startOfToday);
    } else if (day === "today") {
      const now = moment();
      const startOfToday = now.startOf("day");
      const endOfToday = moment().add(1, "days").startOf("day");
      setDate(filterKey, startOfToday, endOfToday);
    }
  };

  return (
    <Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={() => applyDate("yesterday")}
      >
        Yesterday
      </Button>
      <Button
        color="primary"
        variant="outlined"
        style={{ marginLeft: "1rem" }}
        onClick={() => applyDate("today")}
      >
        Today
      </Button>
    </Box>
  );
};

export default LatestDateFilter;
