import React from "react";
import { Box, Grid, TextField, Tooltip, Typography } from "@material-ui/core";
import { isoToName } from "../../config/dialCodes";

const CONVERT_PRICE = 100000;

function WccUpdatedRow(props) {
  const { countryCode, updatedValues } = props;
  const structuredData = {};
  for (const countryCode in updatedValues) {
    structuredData[countryCode] = [];
    const countryData = updatedValues[countryCode];
    for (const type in countryData) {
      if (type === "currencyType") continue;
      const typeData = countryData[type];
      structuredData[countryCode].push({
        currencyType: countryData.currencyType,
        type: type,
        oldData: typeData.old,
        newData: typeData.new,
      });
    }
  }

  return (
    <React.Fragment key={countryCode}>
      {Object.keys(structuredData).map((countryCode) => (
        <Grid container alignItems="center" xs={12} md={12} key={countryCode}>
          <Grid item xs={12} md={2} container alignItems="center">
            <Typography required variant="h5">
              {isoToName[countryCode]}
            </Typography>
          </Grid>
          <Grid
            xs={12}
            md={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            {structuredData[countryCode].map((obj, index) => {
              return (
                <Grid item md={3} style={{ display: "flex" }}>
                  <Grid item xs={12} md={12} style={{ marginRight: 4 }}>
                    <TextField
                      style={{ width: "100%", padding: "3px 0px" }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled={true}
                      margin="dense"
                      value={
                        obj.oldData === obj.newData
                          ? obj.oldData / CONVERT_PRICE
                          : obj.oldData / CONVERT_PRICE
                      }
                      name={obj.type.toUpperCase()}
                      InputProps={{
                        startAdornment: obj.currencyType === "USD" ? "$" : "₹",
                        style: {
                          color:
                            obj.oldData === obj.newData ? "inherit" : "red",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} md={2} container alignItems="center"></Grid>
          <Grid
            xs={12}
            md={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            {structuredData[countryCode].map((obj, index) => {
              return (
                <Grid item md={3} style={{ display: "flex" }}>
                  <Grid item xs={12} md={12} style={{ marginRight: 4 }}>
                    <TextField
                      style={{ width: "100%", padding: "3px 0px" }}
                      required
                      type="Number"
                      variant="outlined"
                      placeholder="0"
                      disabled={true}
                      margin="dense"
                      value={
                        obj.oldData === obj.newData
                          ? obj.oldData / CONVERT_PRICE
                          : obj.newData / CONVERT_PRICE
                      }
                      name={obj.type.toUpperCase()}
                      InputProps={{
                        startAdornment: obj.currencyType === "USD" ? "$" : "₹",
                        style: {
                          color:
                            obj.oldData === obj.newData ? "inherit" : "green",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      ))}
    </React.Fragment>
  );
}

export default WccUpdatedRow;
