import React, { Component } from "react";
import {
  Grid,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import { isoToName } from "../../config/dialCodes";
import { Clear } from "@material-ui/icons";
import WccRow from "./WccRow";
import axios from "axios";
import { URL } from "../../config/config";
import BulkUpdate from "./BulkUpdate";
import * as regions from "../../config/billingMetadata";
import { CBP } from "../../config/CBP";
import JsonDiff from "../JsonDifference/JsonDiff";

const CONVOS = [
  // {
  //   name: "Business Initiated",
  //   key: "business_initiated",
  // },
  // {
  //   name: "User Initiated",
  //   key: "user_initiated",
  // },
  {
    name: "Marketing",
    key: "marketing",
  },
  {
    name: "Utility",
    key: "utility",
  },
  {
    name: "Authentication",
    key: "authentication",
  },
  {
    name: "Service",
    key: "service",
  },
];

class CreateWccPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aisensyWccPlan: this.props.aisensyWccPlan,
      oldValue: {},
      keyValue: 0,
    };
  }

  updateConvo = (value, iso, category) => {
    const { currency } = this.props;
    const obj = {
      ...this.state.aisensyWccPlan[category],
    };
    obj[iso][currency] = value;

    this.setState({
      aisensyWccPlan: {
        ...this.state.aisensyWccPlan,
        [category]: obj,
      },
    });
  };

  getRoundedConvoPricing = (aisensyWccPlan) => {
    const convoPricing = {};
    for (const { key } of CONVOS) {
      convoPricing[key] = aisensyWccPlan[key];
      for (const iso in convoPricing[key]) {
        convoPricing[key][iso].INR = Math.ceil(convoPricing[key][iso].INR);
        convoPricing[key][iso].USD = Math.ceil(convoPricing[key][iso].USD);
      }
    }

    return convoPricing;
  };

  createWccPlan = () => {
    const { assistantId } = this.props;
    const { aisensyWccPlan } = this.state;

    const convoPricing = this.getRoundedConvoPricing(aisensyWccPlan);
    const updateObj = {
      assistantId,
      partnerId: aisensyWccPlan.partnerId,
      ...convoPricing,
    };

    axios
      .post(URL + "/superadmin/aisensy-wcc-plan", updateObj)
      .then((_) => {
        this.props.showSuccessMessage("Successfully created!");
        this.props.fetchFamilies();
        this.props.toggleDialog();
      })
      .catch((error) => {
        const message = error?.response?.data?.error?.message;
        this.props.showErrorMessage(
          message || "Error in creating Wcc plan family"
        );
      });
  };

  applyBulkUpdate = (convoType, sign, value, region, useMetaPricing) => {
    const { currency } = this.props;
    const { aisensyWccPlan } = this.state;

    const countries = regions[region];
    const countrySet = new Set();
    if (countries) {
      const map = regions.countryNameMap;
      for (const country of countries) {
        const iso = map[country].isoCode;
        countrySet.add(iso);
      }
    }

    if (sign === "%") {
      value /= 100;
      for (const key in aisensyWccPlan[convoType]) {
        if (!countries || countrySet.has(key)) {
          const prevBIC = useMetaPricing
            ? CBP[key][convoType]["metaPrice"][currency]
            : aisensyWccPlan[convoType][key][currency];
          const newMargin = prevBIC * value;
          aisensyWccPlan[convoType][key][currency] = prevBIC + newMargin;
        }
      }
    }

    if (sign === "+") {
      value *= 100000;
      for (const key in aisensyWccPlan[convoType]) {
        if (!countries || countrySet.has(key)) {
          const prev = useMetaPricing
            ? CBP[key][convoType]["metaPrice"][currency]
            : aisensyWccPlan[convoType][key][currency];
          aisensyWccPlan[convoType][key][currency] = prev + value;
        }
      }
    }

    if (sign === "=") {
      value *= 100000;
      for (const key in aisensyWccPlan[convoType]) {
        if (!countries || countrySet.has(key)) {
          aisensyWccPlan[convoType][key][currency] = value;
        }
      }
    }

    this.setState({ aisensyWccPlan });
  };

  updatePlanFamily = async () => {
    const { aisensyWccPlan } = this.state;
    const convoPricing = this.getRoundedConvoPricing(aisensyWccPlan);
    const updateObj = {
      partnerId: aisensyWccPlan.partnerId,
      ...convoPricing,
    };

    if (aisensyWccPlan.assistantId) {
      updateObj.assistantId = aisensyWccPlan.assistantId;
    }

    try {
      await axios.patch(
        URL + "/superadmin/aisensy-wcc-plan/" + aisensyWccPlan._id,
        updateObj
      );
      this.props.showSuccessMessage("Successfully updated!");
      this.props.toggleDialog();
      this.props.fetchFamilies();
    } catch (error) {
      const message = error?.response?.data?.error?.message;
      this.props.showErrorMessage(
        message || "Error in creating Wcc plan family"
      );
    }
  };

  toggleDialog2 = () => {
    this.setState({ openDialog2: !this.state.openDialog2 });
  };

  handleClick = async () => {
    const { aisensyWccPlan, assistantId } = this.props;
    if (aisensyWccPlan?._id && !!aisensyWccPlan.assistantId === !!assistantId) {
      try {
        this.toggleDialog2();
      } catch (error) {
        console.error("Error occurred during get And Update key:", error);
      }
    } else {
      this.createWccPlan();
    }
  };

  componentDidMount = () => {
    if (this.props.initialData != undefined) {
      const originalDataCopy = JSON.parse(
        JSON.stringify(this.props.initialData)
      );
      this.setState({ oldValue: originalDataCopy });
    }
  };

  render() {
    const { classes, openDialog, toggleDialog, currency, assistantId } =
      this.props;
    const { aisensyWccPlan, openDialog2 } = this.state;

    return Object.keys(aisensyWccPlan?.marketing || {}).length <= 0 ? (
      <CircularProgress />
    ) : (
      <>
        <Dialog
          open={openDialog}
          closeDialog={toggleDialog}
          maxWidth={"md"}
          fullWidth
        >
          <DialogTitle>
            <Box
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">
                {aisensyWccPlan?._id &&
                !!aisensyWccPlan.assistantId === !!assistantId
                  ? "Update Wcc Plan"
                  : "Create Wcc Plan"}
              </Typography>
              <IconButton
                color="primary"
                size="small"
                variant="filled"
                onClick={toggleDialog}
              >
                <Clear
                  style={{
                    position: "fixed",
                  }}
                />
              </IconButton>
            </Box>
          </DialogTitle>

          {/* <Tabs
              value={currency}
              onChange={this.handleCurrencyChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                className: classes.tabIndicator,
              }}
            >
              <Tab label="₹INR" value="INR" className={classes.tabHeader} />
              <Tab label="$USD" value="USD" className={classes.tabHeader} />
            </Tabs> */}

          <Grid style={{ padding: "24px 24px 16px 46px" }}>
            <BulkUpdate applyBulkUpdate={this.applyBulkUpdate} />
          </Grid>

          <Box
            style={{
              padding: "24px 16px 16px 24px",
              width: this.state.activeStep ? "auto" : 1000,
            }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <DialogContent>
              <Grid
                container
                alignItems="center"
                className={classes.content}
                spacing={0}
                style={{ marginTop: 24 }}
              >
                <Grid item xs={12} md={2}></Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  xs={12}
                  md={10}
                  style={{ marginBottom: "8px" }}
                >
                  <Grid md={12} style={{ display: "flex", marginBottom: 10 }}>
                    {CONVOS.map(({ name }) => {
                      return (
                        <Grid
                          item
                          md={3}
                          style={{
                            alignItems: "center",
                            borderBottom: "1px solid #c4c4c4",
                            background: "#461486",
                            marginRight: 8,
                            color: "white",
                          }}
                        >
                          <Typography variant="h4" align="center">
                            {name}
                          </Typography>
                        </Grid>
                      );
                    })}
                  </Grid>
                  <Box md={2}></Box>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  style={{
                    maxHeight: "50vh",
                    overflow: "scroll",
                  }}
                >
                  {/* Render countries list */}
                  {aisensyWccPlan?.marketing &&
                    Object.keys(aisensyWccPlan?.marketing).map((key) => {
                      return (
                        <Grid container key={key} xs={12} md={12}>
                          <WccRow
                            countryCode={key}
                            country={isoToName[key]}
                            aisensyWccPlan={aisensyWccPlan}
                            updateConvo={this.updateConvo}
                            currency={currency}
                            keyValue={this.state.keyValue}
                            disabledFields={this.props.disableFields}
                            convos={CONVOS}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions
              style={{
                padding: "24px 16px 8px 0px",
                justifyContent: "flex-start",
              }}
            >
              <Button
                variant={"outlined"}
                size="medium"
                color="primary"
                onClick={() => {
                  toggleDialog();
                }}
              >
                Cancel
              </Button>
              {!this.props.disableFields && (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={this.handleClick}
                  >
                    {aisensyWccPlan?._id &&
                    !!aisensyWccPlan.assistantId === !!assistantId
                      ? "Review"
                      : "Create"}
                  </Button>
                  {openDialog2 ? (
                    <JsonDiff
                      openDialog2={openDialog2}
                      toggleDialog2={this.toggleDialog2}
                      aisensyWccPlan={this.state.oldValue}
                      updatedWccPlan={this.state.aisensyWccPlan}
                      updatePlanFamily={this.updatePlanFamily}
                      keyValue={this.state.keyValue}
                      disabledFields={this.props.disableFields}
                      convos={CONVOS}
                      classes={this.props.classes}
                    />
                  ) : (
                    ""
                  )}
                </>
              )}
            </DialogActions>
          </Box>
        </Dialog>
      </>
    );
  }
}

export default CreateWccPlan;
