/*
	Wrap signin & signup page with background & AiTimey Logo
*/
import React from 'react';
import { Route } from "react-router-dom";
import { 
	withStyles, 
	Grid,
	Box,
	Typography
} from "@material-ui/core";

import LOGO from "../../static/Timey_White_Blue.png"

function AuthPage(props) {
	
	const {classes} = props

	return(
		<div className={classes.root}>
			<div className={classes.backgroundImageCont}>
			</div>
			<div className={classes.scrollContainer}>
				<Grid 
					container
					justify="center"
					alignItems="center"
					className={classes.main}
				>
					<Grid item xs={12}>
						<Box
							pt={{xs: 4, md: 8}}
						>
						</Box>
					</Grid>
					<Grid item xs={12} className={classes.routeWrapper}>
						{ props.children }
					</Grid>

				</Grid>
        <Box width="100%" pt={5} pb={5}>
					<Typography
						align="center"
						variant="body1"
					>	
	          © 2020-2021 Triny. All rights reserved.
					</Typography>
        </Box>
			</div>
		</div>
	)
}

const backimageUrl = "url('https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.159/static/media/default_left.89be8e17.svg'), url('https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/5.0.159/static/media/default_right.6c847da3.svg')"


const styles = (theme) => ({
	root: {
		height: "100vh",
		overflow: "hidden",
		width: "100vw",
		position: "relative",
		backgroundColor: "#f5f2eb"
	},
	backgroundImageCont: {
		position: "absolute",
		height: "100%",
		width: "100%",
		zIndex: "1",
		backgroundImage: backimageUrl,
		backgroundSize: "368px, 368px",
		backgroundRepeat: "no-repeat, no-repeat",
		backgroundAttachment: "fixed, fixed",
		backgroundPosition: "left bottom, right bottom",
    [theme.breakpoints.down('sm')]: {
      display: "none",
    },
	},
	scrollContainer: {
		width: "100%",
		height: "100%",
		overflowX: "hidden",
		overflowY: "auto",
		position: "relative",
		zIndex:"2",
	},
	main: {
		width: "440px",
		margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      width: "95%",
    },
	},
	routeWrapper: {
		padding: theme.spacing(2),
		minHeight: '200px',
		// border: "1px solid lightgrey",
		borderRadius: "8px",
		background: "white",
	},
	logo: {
		height: 80
	}
})
export default withStyles(styles)(AuthPage)