// const format = "This is {{1}} and the name of company is {{2}}."
// const sample = "This is [Ajay] and the name of company is [Triny]."

function ifFloating(format) {
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  const validFloatingPattern = /[a-z|A-Z|0-9]/g;
  let ifFloating = false;
  // Check if each line as non-floating paramters
  format.split("/n").forEach((line) => {
    const replacedText = line.replace(validBracePattern, (match) => "");
    const valid = validFloatingPattern.test(replacedText);
    if (!valid) {
      ifFloating = true;
    }
    console.log({
      replacedText,
      valid,
    });
  });
  console.log({ ifFloating });
  return ifFloating;
}
function validateFormat(format) {
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  const invalidBracePattern = /(\{|\})/g;
  const invalidBracketPattern = /(\[|\])/g;
  // no more than 1,024 characters
  if (format.length > 1024) {
    return { valid: false, msg: "Message length over 1024 characters." };
  }
  // check for floating parameters
  // if (ifFloating(format)) {
  //   return { valid: false, msg: "Floating paramters aren't allowed." };
  // }
  // replace valid {{}} with param value only
  const replacedText = format.replace(validBracePattern, (match) =>
    match.substring(2, match.length - 2)
  );
  // if { or } exists anywhere return false else true
  const ifCurlyValid = !invalidBracePattern.test(replacedText);
  if (!ifCurlyValid) {
    return { valid: false, msg: "Extra curly braces found." };
  }
  // if [ or ] exists anywhere 	return false
  const ifBracketValid = !invalidBracketPattern.test(replacedText);
  if (!ifBracketValid) {
    return { valid: false, msg: "'[' or ']' aren't allowed." };
  }

  return { valid: true, msg: "Format seems legit." };
}

function extractParameters(format) {
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  let parameters = [];

  function getParameter(match) {
    parameters.push(match.substring(2, match.length - 2));
  }

  const replacedText = format.replace(validBracePattern, getParameter);
  // console.log({parameters});
  return parameters;
}

function compareFormatAndSample(format, sample) {
  let ifSame = false;
  const validBracePattern = /\{\{[0-9]+\}\}/g;
  const validBracketPattern = /\[[a-zA-Z0-9._-]+\]/g;
  const braceFreeText = format.replace(validBracePattern, (match) => "");
  const bracketFreeText = sample.replace(validBracketPattern, (match) => "");

  console.log({ bracketFreeText, braceFreeText });

  if (braceFreeText === bracketFreeText) {
    return true;
  } else {
    return false;
  }
}

function validateUrl(value) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    value
  );
}

export default {
  compareFormatAndSample,
  extractParameters,
  validateFormat,
  validateUrl,
};
