import moment from "moment";

export const FIELDS = [
  { name: "Assistant Name", key: "assistantName" },
  { name: "Trial Left", key: "trialLeft" },
  { name: "Signed Up On", key: "createdAt" },
];

export const DEFAULT_CHECKLIST = [
  { name: "Website", exists: false, notExists: false, markedBy: "" },
  { name: "WABA Number", exists: false, notExists: false, markedBy: "" },
  { name: "Website Footer", exists: false, notExists: false, markedBy: "" },
  {
    name: "Header Display Name",
    exists: false,
    notExists: false,
    markedBy: "",
  },
  { name: "FBM Account Access", exists: false, notExists: false, markedBy: "" },
  { name: "Contacts", exists: false, notExists: false, markedBy: "" },
  { name: "GST Document", exists: false, notExists: false, markedBy: "" },
  { name: "Business Email", exists: false, notExists: false, markedBy: "" },
  { name: "Training", exists: false, notExists: false, markedBy: "" },
  { name: "API Disabled", exists: false, notExists: false, markedBy: "" },
];

// export const SUCCESS_TEAM = [
//   { name: "Kamalakshi Talwar" },
//   { name: "Preeti Adhikari" },
//   { name: "Utkarsh Pandey" },
//   { name: "Shreya Yadav" },
//   { name: "Preeti Gupta" },
//   { name: "Anshika Chaudhary" },
//   { name: "Himadri Saha" },
// ];

// Define the stages of the sales process
export const SALES_STAGES = [
  { name: "Project Created", completed: true, date: null },
  { name: "Whatsapp Drop", completed: false, date: null },
  { name: "No Website", completed: false, date: null },
  { name: "Call Done", completed: false, date: null },
  { name: "Demo Call Scheduled", completed: false, date: null },
  { name: "Demo Call Attended", completed: false, date: null },
  { name: "API Call Scheduled", completed: false, date: null },
  { name: "API Procured", completed: false, date: null },
  { name: "Training Scheduled", completed: false, date: null },
  { name: "Training Done", completed: false, date: null },
  { name: "Deal Finalising", completed: false, date: null },
  { name: "Plan Purchased", completed: false, date: null },
  { name: "Plan Updated", completed: false, date: null },
  { name: "Plan Upgraded", completed: false, date: null },
  { name: "Drop Off", completed: false, date: null },
];

// Define the members of the sales team
// export const SALES_TEAM = [
//   { name: "Mitul Malavia" },
//   { name: "Surendra Srivastava" },
//   { name: "Vaibhav Gianchandani" },
//   { name: "Yatin Kapoor" },
//   { name: "Aman Chaurasia" },
//   { name: "Mayank Dixit" },
//   { name: "Sonal Kumari" },
//   { name: "Samarth Srivastava" },
//   { name: "Akanksha" },
//   { name: "Archit" },
//   // { name: "Manvendra Thanua" },
//   // { name: "Ridhi Grover" },
//   // { name: "Shobhit Singh" },
//   // { name: "Pankaj Ogra" },
//   // Interns
//   // { name: "Harsha Singh" },
//   // { name: "Mohammad Ali" },
//   // { name: "Pritam Debnath" },
//   // { name: "Sundari Tomar" },
//   // { name: "Sunidhi Singh" },
//   // { name: "Tanisha Gupta" },
// ];

export const SALES_TEAM_COLOR = [
  {
    "Mitul Malavia": { color: "#F19C79" },
    "Surendra Srivastava": { color: "#E58C8A" },
    "Vaibhav Gianchandani": { color: "#EEC0C6" },
    "Yatin Kapoor": { color: "#1E1E24" },
    "Aman Chaurasia": { color: "#7EE8FA" },
    "Mayank Dixit": { color: "#2E5EAA" },
    "Sonal Kumari": { color: "#60435F" },
    "Samarth Srivastava": { color: "#D67AB1" },
    Akanksha: { color: "#FFCF99" },
    Archit: { color: "#2E5EAA" },
    // "Manvendra Thanua": { color: "#C4EBC8" },
    // "Ridhi Grover": { color: "#7EE8FA" },
    // "Pankaj Ogra": { color: "#7EE8FA" },
    // "Shobhit Singh": { color: "#EEC0C6" },
    // Interns
    // "Harsha Singh": { color: "#92140C" },
    // "Mohammad Ali": { color: "#D36135" },
    // "Pritam Debnath": { color: "#FFCF99" },
    // "Sundari Tomar": { color: "#2E5EAA" },
    // "Sunidhi Singh": { color: "#60435F" },
    // "Tanisha Gupta": { color: "#D67AB1" },
  },
];

// Onboarding Stages
export const ONBOARDING_STAGES = [
  { name: "Project Created", completed: false, date: null },
  { name: "API Procurement", completed: false, date: null },
  { name: "FB Manager Verification", completed: false, date: null },
  { name: "Display Name Verification", completed: false, date: null },
  { name: "Tier Limit", completed: false, date: null, dailyTemplateLimit: "0" },
  { name: "Broadcast", completed: false, date: null },
];

export const TEXT_QUALIFICATIONS = [
  { sentiment: "" },
  { websiteName: "" },
  { database: "" },
  { requirement: "" },
  { pastApiHistory: "" },
  { expectedClosureDate: "" },
];

export const DROPDOWN_QUALIFICATIONS = [
  { duration: "" },
  { Priority: "" },
  { integrations: "" },
  { estimatedPlan: "" },
  { estimatedAddOn: "" },
];

export const INITIAL_CARD = [
  {
    id: 0,
    status: "Project Created",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
  {
    id: 1,
    status: "API Procurement",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
  {
    id: 2,
    status: "FB Verification",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
  {
    id: 3,
    status: "Display Verification",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
  {
    id: 4,
    status: "Tier 1",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
  {
    id: 5,
    status: "Broadcast",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
  {
    id: 6,
    status: "Tier 2",
    projects: [],
    totalCount: 0,
    skip: 0,
    hasMore: true,
  },
];

export const INITIAL_CARD_SALES = [
  { id: 1, status: "Project Created", projects: [] },
  { id: 2, status: "whatsapp drop", projects: [] },
  { id: 3, status: "no website", projects: [] },
  { id: 4, status: "call done", projects: [] },
  { id: 5, status: "demo call scheduled", projects: [] },
  { id: 6, status: "demo call attended", projects: [] },
  { id: 7, status: "API call scheduled", projects: [] },
  { id: 8, status: "api procured", projects: [] },
  { id: 9, status: "traning scheduled", projects: [] },
  { id: 10, status: "training done", projects: [] },
  { id: 11, status: "deal finallizing", projects: [] },
  { id: 12, status: "plan purchased", projects: [] },
  { id: 13, status: "plan updated", projects: [] },
  { id: 14, status: "plan upgraded", projects: [] },
  { id: 15, status: "drop off", projects: [] },
];

export const assitantFilter = [
  {
    name: "assistantName",
    type: String,
    filters: [
      "contains",
      "is equals to",
      "is not equals to",
      "is not contains",
    ],
  },
  {
    type: String,
    name: "whatsappNumber",
    filters: [
      "contains",
      "is equals to",
      "is not equals to",
      "is not contains",
    ],
  },
  {
    name: "activePlan",
    type: String,
    filters: [
      "contains",
      "is equals to",
      "is not equals to",
      "is not contains",
    ],
  },
  {
    name: "OwnerName",
    type: String,
    filters: [
      "contains",
      "is equals to",
      "is not equals to",
      "is not contains",
    ],
  },
];

export const STATUS_FILTERS = [
  {
    name: "status",
    type: "String",
    filter: [
      "active",
      "terminated",
      "pending",
      "suspended",
      "stopped",
      "archived",
      "FREE TRIAL",
    ],
  },
];

export const BASIC_PLANS = ["BASIC_MONTHLY", "BASIC_QUARTERLY", "BASIC_YEARLY"];

export const PRO_PLANS = ["PRO_MONTHLY", "PRO_QUARTERLY", "PRO_YEARLY"];

export const ENTERPRISE_PLANS = [
  "ENTERPRISE_MONTHLY",
  "ENTERPRISE_QUARTERLY",
  "ENTERPRISE_YEARLY",
];

export const COMPANY_SIZE = [
  {
    name: "company size",
    type: "String",
    filter: [
      "1 - 10 Employees",
      "10 - 20 Employees",
      "20 - 50 Employees",
      "50 - 200 Employees",
      "200 - 500 Employees",
      "500 +",
    ],
  },
];

export const INDUSTRY = [
  {
    name: "industry",
    type: "String",
    filter: [
      "Ecommerce",
      "Education",
      "Automotive",
      "IT Services",
      "Real Estate",
      "SAAS/Apps",
      "Gaming",
      "Entertainment",
      "Finance and Banking",
      "Medical and Health",
      "Hotel and Lodging",
      "Beauty, Spa and Salon",
      "Clothing and Apparel",
      "Event Planning and Service",
      "Food and Grocery",
      "Professional Services",
      "Public Service",
      "Non-profit",
      "Shopping and Retail",
      "Travel and Transportation",
      "Restaurant",
      "Other",
    ],
  },
];

export const META_ADS_TWO_TIER = [
  {
    name: "Meta Ads",
    type: "String",
    filter: ["Connected"],
  },
];

export const PLANS = [
  "BASIC_TRIAL",
  "BASIC_MONTHLY",
  "BASIC_DIWALI_MONTHLY",
  "PRO_MONTHLY",
  "ENTERPRISE_MONTHLY",
  "BASIC_YEARLY",
  "BASIC_DIWALI_YEARLY",
  "PRO_YEARLY",
  "ENTERPRISE_YEARLY",
  "FREE",
  "LITE",
  "PRO",
  "PLUS",
  "PREMIUM",
  "BASIC_MONTHLY_TIER_1",
  "BASIC_MONTHLY_TIER_2",
  "BASIC_MONTHLY_TIER_3",
  "BASIC_MONTHLY_TIER_4",
  "BASIC_MONTHLY_TIER_5",
  "BASIC_MONTHLY_TIER_6",
  "BASIC_MONTHLY_TIER_7",
  "BASIC_MONTHLY_TIER_8",
  "BASIC_YEARLY_TIER_1",
  "BASIC_YEARLY_TIER_2",
];

export const formatDate = (createdAt) => {
  let formattedDate;
  const parsedDate = moment(createdAt);
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "day").startOf("day");

  if (parsedDate.isSame(today, "d")) {
    formattedDate = parsedDate.format("[Today at] h:mm A");
  } else if (parsedDate.isSame(yesterday, "d")) {
    formattedDate = parsedDate.format("[Yesterday at] h:mm A");
  } else {
    formattedDate = parsedDate.format("D MMM YYYY");
  }

  return formattedDate;
};

export const calculateTrial = (planDuration, createdAt) => {
  if (!planDuration || planDuration > 14) return null;

  const date1 = moment(Date.now());
  const date2 = moment(createdAt);
  const duration = moment.duration(date1.diff(date2));
  const trialLeft = 14 - Math.floor(duration.asDays());

  return trialLeft;
};

export const calculatePlan = (trialLeft, activePlan) => {
  return trialLeft
    ? trialLeft < 0
      ? "Trial Ended"
      : "Free Trial"
    : activePlan;
};

export const calculateProvider = (providerType) => {
  return providerType === "v3"
    ? "AiSensy"
    : providerType === "v2"
    ? "D360"
    : "Gupshup";
};
