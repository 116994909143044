import { APP_ID } from "../config/config";
/**
 * Init FB Javascript SDK with dynamic APP ID
 *
 * @param {string} appId - The count of parameters.
 * @return {void}
 */
export const fbInit = ({ appId = APP_ID }) => {
  // Re-init FB JS SDK if session is already established
  if (window.FB) {
    const fbRoot = document.getElementById("fb-root");
    delete window.FB;
    if (fbRoot) {
      fbRoot.remove();
    }

    const fbScript = document.getElementById("facebook-jssdk");
    if (fbScript) {
      fbScript.remove();
    }

    return fbInit({ appId });
  }

  window.fbAsyncInit = function () {
    window.FB.init({
      appId,
      cookie: true,
      xfbml: true,
      version: "v14.0",
    });
  };

  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");

  window.waitForFbq = function (callback) {
    if (typeof window.fbq !== "undefined") {
      callback();
    } else {
      setTimeout(function () {
        window.waitForFbq(callback);
      }, 100);
    }
  };

  window.launchWhatsAppSignup = function () {
    window.waitForFbq(function () {
      window.fbq("trackCustom", "WhatsAppOnboardingStart", {
        appId,
        feature: "whatsapp_embedded_signup",
      });
    });
  };
};
