import React, { Component } from "react";
import {
  withStyles,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import axios from "axios";
import { URL } from "../../config/config";
import { Edit, Delete, Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";
import TemplateLibraryDetails from "./TemplateLibraryDetails";

let fields = [
  {
    name: "ID",
    key: "name",
  },
  {
    name: "Name",
    key: "previewName",
  },
  {
    name: "Type",
    key: "type",
  },
  {
    name: "Tags",
    key: "tags",
  },
  {
    name: "Created At",
    key: "createdAt",
  },
  {
    name: "Action",
    key: "action",
  },
];

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

const quickFilters = [
  "Last active - 24 hr window",
  "Created - This week",
  "Created - This month",
];

class TemplateLibraryTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      openDeleteDialog: false,
      openVisibilityDialog: false,
      selectedTemplate: null,
    };
  }

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  openTemplate = (index) => {
    this.setState({ openDialog: true, selectedTemplate: index });
  };

  deleteTemplate = () => {
    const { removeTemplate, templates } = this.props;
    axios
      .post(URL + "/superadmin/delete-library-template", {
        templateId: templates[this.state.selectedTemplate]._id,
      })
      .then(() => {
        removeTemplate(this.state.selectedTemplate);
        this.handleDeleteClose();
      })
      .catch((error) => {
        this.handleDeleteClose();
        console.log(error);
      });
  };

  handleDeleteOpen = (templateIndex) =>
    this.setState({ selectedTemplate: templateIndex, openDeleteDialog: true });
  handleDeleteClose = () =>
    this.setState({ selectedTemplate: null, openDeleteDialog: false });

  updateCurrentTemplate = (templateObject) => {
    //console.log(templateObject);
    const { updateTemplate, templates } = this.props;
    axios
      .post(URL + "/superadmin/update-library-template", {
        _id: templates[this.state.selectedTemplate]._id,
        ...templateObject,
      })
      .then(() => {
        updateTemplate(this.state.selectedTemplate, templateObject);
        this.handleUpdateClose();
      })
      .catch((error) => {
        this.handleUpdateClose();
        console.log(error);
      });
  };

  handleUpdateOpen = (templateIndex) =>
    this.setState({
      selectedTemplate: templateIndex,
      openVisibilityDialog: true,
    });

  handleUpdateClose = () =>
    this.setState({ selectedTemplate: null, openVisibilityDialog: false });

  render() {
    const {
      classes,
      templates,
      isLoading,
      starredCannedMessages,
      activeApiCampaignTemplateList,
    } = this.props;
    const fullScreen = window.innerWidth < 767 ? true : false;
    return (
      <div className={classes.root}>
        <table>
          <tbody>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {fields.map((field) => (
                <th
                  className={clsx(classes.column, classes.dataColumn)}
                  style={{
                    justifyContent: field.name === "Action" ? "center" : "left",
                  }}
                >
                  <Typography variant="h5" color="primary">
                    {field.name}
                  </Typography>
                </th>
              ))}
            </tr>
            {!isLoading ? (
              <React.Fragment>
                {templates.map((e, index) => (
                  <tr
                    className={clsx(classes.row, classes.clickable, {
                      [classes.alterRow]: index % 2 == 0,
                    })}
                    onClick={() => this.openTemplate(index)}
                  >
                    {fields.map((field) => (
                      <th className={clsx(classes.column, classes.dataColumn)}>
                        {columnType(
                          classes,
                          e,
                          field,
                          starredCannedMessages,
                          this.unstarMessage,
                          this.starMessage,
                          this.confirmDelete,
                          activeApiCampaignTemplateList,
                          index,
                          this.handleDeleteOpen,
                          this.handleUpdateOpen
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {[1, 2, 3, 4].map((e, index) => (
                  <tr className={clsx(classes.row)}>
                    {fields.map((field) => (
                      <th className={clsx(classes.column, classes.dataColumn)}>
                        <Skeleton style={{ width: "100%" }} height={40} />
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        {/* Preview dialog */}
        <Dialog
          open={this.state.openDialog}
          onClose={this.toggleDialog}
          aria-labelledby="form-dialog-title"
          maxWidth="md"
          fullScreen={fullScreen}
        >
          {/* Template Dteails */}
          <TemplateLibraryDetails
            templateIndex={this.state.selectedTemplate}
            templates={this.props.templates}
            toggleDialog={this.toggleDialog}
            setTemplate={this.props.setTemplate}
          />
        </Dialog>
        {/* Delete dialog */}
        <Dialog
          open={this.state.openDeleteDialog}
          onClose={this.handleDeleteClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Delete template</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this template from template
              library?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteClose}>Cancel</Button>
            <Button onClick={this.deleteTemplate} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        {/* visibility dialog */}
        {this.state.selectedTemplate != null && (
          <Dialog
            open={this.state.openVisibilityDialog}
            onClose={this.handleUpdateClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Toggle Visibility</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {templates[this.state.selectedTemplate].isVisible
                  ? "Are you sure that you want to disable the visibility of this template? The template will not be visible to customers"
                  : "Are you sure that you want to enable the visibility of this template? The template will be visible to customers"}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleUpdateClose}>Cancel</Button>
              <Button
                onClick={() => {
                  const visibility =
                    templates[this.state.selectedTemplate].isVisible;
                  this.updateCurrentTemplate({
                    isVisible: !visibility,
                  });
                }}
                autoFocus
              >
                {templates[this.state.selectedTemplate].isVisible
                  ? "Disable"
                  : "Enable"}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {/* <ConfirmationDialog
          open={!!this.state.confirmTask}
          taskMessage={this.state.confirmTask}
          onClose={this.onConfirmationDialogClose}
          onConfirm={() => this.onDelete()}
        /> */}
      </div>
    );
  }
}

const COLORS = {
  PENDING: "grey",
  APPROVED: "green",
  REJECTED: "red",
  PAUSED: "rgb(229, 148, 0)",
  PENDING_DELETION: "rgb(255, 127, 0)",
  DISABLED: "darkslategrey",
};

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date ? d.toLocaleDateString("en-US", options) : "";
}

function columnType(
  classes,
  columnData,
  field,
  starredCannedMessages,
  unstarMessage,
  starMessage,
  confirmDelete,
  activeApiCampaignTemplateList,
  templateIndex,
  handleDeleteOpen,
  handleUpdateOpen
) {
  const key = field.key;
  switch (key) {
    case "action":
      return (
        <Box
          width="100%"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <IconButton
            component={Link}
            to={{
              pathname: "/library/create",
              state: { template: columnData },
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleDeleteOpen(templateIndex);
              e.stopPropagation();
            }}
          >
            <Delete />
          </IconButton>
          <IconButton
            onClick={(e) => {
              handleUpdateOpen(templateIndex);
              e.stopPropagation();
            }}
          >
            {!columnData["isVisible"] ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </Box>
      );
    case "tags":
      return (
        <Typography variant="body1" display="block" noWrap>
          {columnData[key].join(", ")}
        </Typography>
      );
    case "createdAt":
      return (
        <Typography variant="body1" display="block" noWrap>
          {getFormattedDate(columnData[field.key])}
        </Typography>
      );
      break;
    default:
      return (
        <Typography variant="body1" display="block" noWrap>
          {columnData[field.key]}
        </Typography>
      );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    overflowX: "hidden",
    // overflowY: "auto",
    // background: theme.palette.secondary.main,
    // borderRadius: "8px",
    position: "relative",
    // border: "1px solid lightgrey",
    [theme.breakpoints.down("sm")]: {
      // height: "calc(100vh - 60px)",
    },
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      // background: "pink"
    },
  },
  header: {},
  row: {
    background: "white",
    display: "flex",
    margin: "15px 0px",
    borderRadius: "8px",
    padding: "10px 10px",
    boxSizing: "border-box",
  },
  alterRow: {
    // background: "rgb(245,245,245)"
  },
  topbar: {
    background: "rgb(255,255,255)",
    padding: "0px 10px",
    cursor: "auto",
    // position: "sticky",
    // top: "0",
    // zIndex: "100"
  },
  column: {
    // width: "4400px",
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "left",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "20%",
    overflow: "hidden",
    // minHeight: "80px",
    // display: "flex",
    // background: "red"
    // flexWrap: "wrap"
    [theme.breakpoints.down("sm")]: {
      width: "180px",
      // height: "calc(100vh - 60px)",
    },
  },
  checkboxColumn: {
    width: "60px",
    // background: "red"
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      background: "rgb(252,252,252)",
    },
  },
});

export default withStyles(styles)(TemplateLibraryTable);
