import {
  Box,
  Grid,
  Typography,
  createStyles,
  withStyles,
  Button,
  IconButton,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import CreateWccPlan from "./CreateWccPlanFamily";
import CreatePlanFamily from "./CreatePlanFamily";
import axios from "axios";
import { Skeleton } from "@material-ui/lab";
import { Edit, RateReview, Visibility } from "@material-ui/icons";
import { AISENSY_PARTNER_ID, URL } from "../../config/config";
import { AISENSY_WCC_PLAN } from "../../config/partnerWCC";
import CreateWhatsappPaymentsPlan from "./CreateWhatsappPaymentPlan";
import { connect } from "react-redux";
import JsonDiff from "../JsonDifference/JsonDiff";

function PlanFamily(props) {
  const {
    agent,
    partner,
    classes,
    assistant,
    showSuccessMessage,
    showErrorMessage,
    tenantDetails,
  } = props;
  let isTenant = !!Object.keys(tenantDetails).length;
  const [isLoading, setLoading] = useState(true);
  const [aisensyPlan, setAisensyPlan] = useState({});
  const [aisensyWccPlan, setAisensyWccPlan] = useState({});
  const [initialData, setInitialData] = useState();
  const [aisensyWhatsappPaymentsPlan, setAisensyWhatsappPaymentsPlan] =
    useState({});
  const [familyDialog, setFamilyDialog] = useState(false);
  const [editPlanFamily, setEditPlanFamily] = useState(false);

  const fetchFamilies = async () => {
    const { _id: projectId } = assistant || {};
    const partnerId =
      partner?._id || assistant?.partnerId || "627ea4876340e14cf7081778";
    const planFamilyBaseURL =
      URL + `/superadmin/aisensy-plan-family?partnerId=${partnerId}`;
    const wccFamilyBaseURL =
      URL + `/superadmin/aisensy-wcc-plan?partnerId=${partnerId}`;
    const whatsappPaymentsBaseURL =
      URL +
      `/superadmin/aisensy-whatsapp-payments-plans?partnerId=${partnerId}`;

    let plan, wccPlan, whatsappPaymentsPlan;
    // Get Project affiliated wcc plan
    if (projectId) {
      const promises = [
        axios.get(planFamilyBaseURL + `&projectId=${projectId}`),
        axios.get(wccFamilyBaseURL + `&projectId=${projectId}`),
        axios.get(whatsappPaymentsBaseURL + `&projectId=${projectId}`),
      ];

      const [planFamilyData, wccFamilyData, whatsappPaymentsFamilyData] =
        await Promise.allSettled(promises);

      plan = planFamilyData?.value?.data?.aisensyPlanFamily;
      wccPlan = wccFamilyData?.value?.data?.aisensyWccPlan;
      whatsappPaymentsPlan =
        whatsappPaymentsFamilyData?.value?.data?.aisensyWhatsappPaymentsPlan;
      setAisensyPlan(plan);
      setAisensyWccPlan(wccPlan);
      setAisensyWhatsappPaymentsPlan(whatsappPaymentsPlan);
      setInitialData(wccPlan);
    }

    // Get partner global config object
    const promises = [];
    if (!plan) {
      promises.push(axios.get(planFamilyBaseURL));
    } else {
      promises.push(Promise.reject(new Error("fail")));
    }

    if (!wccPlan) {
      promises.push(axios.get(wccFamilyBaseURL));
    } else {
      promises.push(Promise.reject(new Error("fail")));
    }

    if (!whatsappPaymentsPlan) {
      promises.push(axios.get(whatsappPaymentsBaseURL));
    }

    const [planFamilyData, wccFamilyData, whatsappPaymentsFamilyData] =
      await Promise.allSettled(promises);

    if (!plan) {
      setAisensyPlan(planFamilyData?.value?.data?.aisensyPlanFamily);
    }

    if (!wccPlan) {
      setInitialData(wccFamilyData?.value?.data?.aisensyWccPlan);
      setAisensyWccPlan(wccFamilyData?.value?.data?.aisensyWccPlan);
    }

    if (!whatsappPaymentsPlan) {
      setAisensyWhatsappPaymentsPlan(
        whatsappPaymentsFamilyData?.value?.data?.aisensyWhatsappPaymentsPlan
      );
    }

    setLoading(false);
  };

  const toggleFamilyDialog = (key, mode) => {
    if (key === familyDialog) {
      key = undefined;
    }

    setFamilyDialog(key);
    setEditPlanFamily(mode);
  };

  useEffect(() => {
    fetchFamilies();
  }, []);

  const isAisensy =
    !assistant?.partnerId || assistant.partnerId === AISENSY_PARTNER_ID;

  return (
    <Box>
      {/* Subscription Plan Family Management */}
      {(!isAisensy || partner) && (
        <>
          <Box my={6}></Box>
          <Typography
            variant="body1"
            gutterBottom
            style={{ fontWeight: "bold" }}
          >
            {isTenant ? tenantDetails.name : "AiSensy"} Plan Family
          </Typography>
          <Typography variant="body1" gutterBottom>
            This family consists of pricing charged by{" "}
            {isTenant ? tenantDetails.name : "AiSensy"} for this project across
            different type of available plans
          </Typography>
          <Grid container justify="center">
            <Grid item xs={12} md={12} lg={10}>
              {/* First section */}
              {/* Scrollable (x & y) table container */}
              <div className={classes.tableContainer}>
                <Grid container>
                  <Grid container item xs={12} md={12} lg={12}>
                    <Grid container>
                      <Box
                        className={classes.tableWrapper}
                        mb={{ xs: 2, md: 4 }}
                        mx={{ xs: 2, md: 0 }}
                      >
                        <Grid
                          item
                          container
                          className={classes.billTableContainer}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            className={classes.tableHeader}
                          >
                            {fields.map((e) => (
                              <Grid
                                item
                                xs={e.size}
                                key={e.key}
                                style={{
                                  width: "20%",
                                }}
                              >
                                <Box py={2} pl={2}>
                                  <Typography variant="h5" color="primary">
                                    {e.name}
                                  </Typography>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            container
                            // onScroll={}
                            className={classes.tableItem}
                          >
                            {fields.map((field) => {
                              return (
                                <Grid
                                  item
                                  xs={field.size}
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  <Cell
                                    classes={classes}
                                    item={aisensyPlan}
                                    field={isLoading ? "Loading" : field.key}
                                    partnerPlans={aisensyPlan}
                                    activeId={aisensyPlan?._id}
                                    aisensyPlans={aisensyPlan}
                                    editPlan={(mode) =>
                                      toggleFamilyDialog(
                                        "SubscriptionPlanFamily",
                                        mode
                                      )
                                    }
                                    assistant={assistant}
                                    // openPlanDialog={this.openPlanDialog}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        </Grid>

                        {assistant?.partnerId && (
                          <Grid item>
                            {agent.power <= 10 ||
                            aisensyPlan?.assistantId == assistant?._id ? (
                              <></>
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  toggleFamilyDialog(
                                    "SubscriptionPlanFamily",
                                    "CREATE"
                                  )
                                }
                              >
                                Create Project Plan Family
                              </Button>
                            )}
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      )}

      {/* WCC Plan Family Management */}
      <>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
          {isTenant ? tenantDetails.name : "AiSensy"} WCC Family
        </Typography>
        <Typography variant="body1" gutterBottom>
          This family consists of WCC pricing charged by{" "}
          {isTenant ? tenantDetails.name : "AiSensy"} for this project across
          different countries / regions
        </Typography>
        <Grid container justify="center">
          <Grid item xs={12} md={12} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <Grid container>
                <Grid container item xs={12} md={12} lg={12}>
                  <Grid container>
                    <Box
                      className={classes.tableWrapper}
                      mb={{ xs: 2, md: 4 }}
                      mx={{ xs: 2, md: 0 }}
                      style={{ marginBottom: 0 }}
                    >
                      <Grid
                        item
                        container
                        className={classes.billTableContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          className={classes.tableHeader}
                        >
                          {fields.map((e) => (
                            <Grid
                              item
                              xs={e.size}
                              key={e.key}
                              style={{
                                width: "20%",
                              }}
                            >
                              <Box py={2} pl={2}>
                                <Typography variant="h5" color="primary">
                                  {e.name}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        {aisensyWccPlan && (
                          <Grid
                            item
                            xs={12}
                            container
                            // onScroll={}
                            className={classes.tableItem}
                          >
                            {fields.map((field) => {
                              return (
                                <Grid
                                  item
                                  xs={field.size}
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  <Cell
                                    classes={classes}
                                    item={aisensyWccPlan}
                                    type="WccPlanFamily"
                                    field={isLoading ? "Loading" : field.key}
                                    activeId={aisensyWccPlan._id}
                                    aisensyPlans={aisensyWccPlan}
                                    editPlan={(mode) =>
                                      toggleFamilyDialog("WccPlanFamily", mode)
                                    }
                                    assistant={assistant}
                                    // openPlanDialog={setWccFamilyDialog}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            {agent.power <= 10 ||
            aisensyWccPlan?.assistantId == assistant?._id ? (
              <></>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => toggleFamilyDialog("WccPlanFamily", "CREATE")}
              >
                Create Project WCC Plan Family
              </Button>
            )}
          </Grid>
        </Grid>
      </>

      <Box my={3}></Box>
      {/* WhatsApp Payments Plan Family Management */}
      <>
        <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
          {isTenant ? tenantDetails.name : "AiSensy"} WhatsApp Payments Plan
          Family
        </Typography>
        <Typography variant="body1" gutterBottom>
          This family consists of WhatsApp Payments pricing charged by{" "}
          {isTenant ? tenantDetails.name : "AiSensy"}
          for this project across different slabs / transaction price ranges
        </Typography>
        <Grid container justify="center">
          <Grid item xs={12} md={12} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <Grid container>
                <Grid container item xs={12} md={12} lg={12}>
                  <Grid container>
                    <Box
                      className={classes.tableWrapper}
                      mb={{ xs: 2, md: 4 }}
                      mx={{ xs: 2, md: 0 }}
                      style={{ marginBottom: 0 }}
                    >
                      <Grid
                        item
                        container
                        className={classes.billTableContainer}
                      >
                        <Grid
                          item
                          xs={12}
                          container
                          className={classes.tableHeader}
                        >
                          {fields.map((e) => (
                            <Grid
                              item
                              xs={e.size}
                              key={e.key}
                              style={{
                                width: "20%",
                              }}
                            >
                              <Box py={2} pl={2}>
                                <Typography variant="h5" color="primary">
                                  {e.name}
                                </Typography>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                        {aisensyWhatsappPaymentsPlan && (
                          <Grid
                            item
                            xs={12}
                            container
                            // onScroll={}
                            className={classes.tableItem}
                          >
                            {fields.map((field) => {
                              return (
                                <Grid
                                  item
                                  xs={field.size}
                                  style={{
                                    width: "20%",
                                  }}
                                >
                                  <Cell
                                    classes={classes}
                                    item={aisensyWhatsappPaymentsPlan}
                                    field={isLoading ? "Loading" : field.key}
                                    activeId={aisensyWhatsappPaymentsPlan._id}
                                    aisensyPlans={aisensyWhatsappPaymentsPlan}
                                    editPlan={(mode) =>
                                      toggleFamilyDialog(
                                        "WhatsappPaymentsPlanFamily",
                                        mode
                                      )
                                    }
                                    assistant={assistant}
                                    // openPlanDialog={setWccFamilyDialog}
                                  />
                                </Grid>
                              );
                            })}
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            {agent.power <= 10 ||
            (partner &&
              aisensyWhatsappPaymentsPlan?.partnerId == partner?._id) ||
            (assistant &&
              assistant._id == aisensyWhatsappPaymentsPlan?.assistantId) ? (
              <></>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  toggleFamilyDialog("WhatsappPaymentsPlanFamily", "CREATE")
                }
              >
                Create Project WhatsApp Payments Plan Family
              </Button>
            )}
          </Grid>
        </Grid>
      </>
      {/* Dialogs */}
      {familyDialog === "SubscriptionPlanFamily" && (
        <CreatePlanFamily
          classes={classes}
          aisensyPlan={aisensyPlan}
          currency={partner?.currency || assistant?.currency || "INR"}
          partnerId={partner?._id || assistant?.partnerId}
          openDialog={true}
          convertPrice={100000}
          toggleDialog={() =>
            toggleFamilyDialog("SubscriptionPlanFamily", editPlanFamily)
          }
          disableFields={[undefined, "VIEW"].includes(editPlanFamily)}
          planFamilyId={editPlanFamily !== "CREATE" && aisensyPlan?._id}
          familyName={aisensyPlan?.name}
          fetchFamilies={fetchFamilies}
          assistantId={assistant?._id}
          showSuccessMessage={showSuccessMessage}
          showErrorMessage={showErrorMessage}
        />
      )}
      {familyDialog === "WccPlanFamily" && (
        <CreateWccPlan
          initialData={initialData}
          currency={partner?.currency || assistant?.currency || "INR"}
          openDialog={true}
          toggleDialog={() => toggleFamilyDialog("WccPlanFamily", "CREATE")}
          aisensyWccPlan={aisensyWccPlan}
          classes={classes}
          partnerId={partner?._id}
          assistantId={assistant?._id}
          showSuccessMessage={showSuccessMessage}
          showErrorMessage={showErrorMessage}
          fetchFamilies={fetchFamilies}
          disableFields={[undefined, "VIEW"].includes(editPlanFamily)}
        />
      )}
      {familyDialog === "WhatsappPaymentsPlanFamily" && (
        <CreateWhatsappPaymentsPlan
          classes={classes}
          aisensyPlan={aisensyWhatsappPaymentsPlan}
          currency={partner?.currency || assistant?.currency || "INR"}
          partnerId={partner?._id || assistant?.partnerId}
          openDialog={true}
          convertPrice={100000}
          toggleDialog={() =>
            toggleFamilyDialog("WhatsappPaymentsPlanFamily", editPlanFamily)
          }
          disableFields={[undefined, "VIEW"].includes(editPlanFamily)}
          planFamilyId={
            editPlanFamily !== "CREATE" && aisensyWhatsappPaymentsPlan?._id
          }
          familyName={aisensyWhatsappPaymentsPlan?.name}
          fetchFamilies={fetchFamilies}
          assistantId={assistant?._id}
          showSuccessMessage={showSuccessMessage}
          showErrorMessage={showErrorMessage}
        />
      )}
    </Box>
  );
}

function Cell({
  classes,
  item,
  type,
  field,
  allBusiness,
  openCampaign,
  editPlan,
  openPlanDialog,
  aisensyPlans,
  assistant,
  ...props
}) {
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const defaultWcc = AISENSY_WCC_PLAN;

  const toggleDialog2 = () => {
    setOpenDialog2(true);
  };
  const handleReviewButtonClick = () => {
    setOpenReviewDialog(!openReviewDialog);
  };
  const CONVOS = [
    {
      name: "Marketing",
      key: "marketing",
    },
    {
      name: "Utility",
      key: "utility",
    },
    {
      name: "Authentication",
      key: "authentication",
    },
    {
      name: "Service",
      key: "service",
    },
  ];

  switch (field) {
    case "remainingCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );

    case "templateCredit":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {item[field] ? item[field] / 100000 : 0}
            </Typography>
          </Box>
        </Grid>
      );
    case "createdAt":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {getFormattedDate(item?.[field])}
            </Typography>
          </Box>
        </Grid>
      );

    case "clientId":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] && allBusiness[item[field]]?.name}
            {/* {columnData[key]} */}
            {/* {agents.find((i) => i._id === columnData[key])?.displayName || "-"} */}
          </Typography>
        </Box>
      );

    case "Loading":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            {Array.from(Array(20).keys()).map((i) => {
              return (
                <div key={i} className={classes.tableItem1}>
                  <Skeleton className={classes.skeleton} />
                </div>
              );
            })}
          </Box>
        </Grid>
      );

    case "whatsappNumber":
      return (
        <Box py={2} pl={2}>
          <Typography variant="body1" noWrap>
            {item[field] ? "+" + item[field] : "-"}
          </Typography>
        </Box>
      );

    case "excessUsage":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100000}</Typography>
          </Box>
        </Grid>
      );

    case " company":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field] / 100}</Typography>
          </Box>
        </Grid>
      );

    case "actions":
      const isEditable = assistant ? !!item?.assistantId : true;
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <IconButton
              style={{ padding: 0, margin: 0 }}
              onClick={() => {
                return editPlan("VIEW");
              }}
            >
              <Visibility color="primary" />
            </IconButton>
            <IconButton
              style={{ padding: 0, margin: 0 }}
              disabled={!isEditable}
              onClick={() => editPlan("EDIT")}
            >
              <Edit
                color={isEditable ? "primary" : "disabled"}
                style={{ marginLeft: 16 }}
              />
            </IconButton>
            {type === "WccPlanFamily" ? (
              <>
                <IconButton
                  onClick={handleReviewButtonClick}
                  style={{ color: "#0a474c" }}
                >
                  <RateReview />
                </IconButton>
                {openReviewDialog && (
                  <JsonDiff
                    openDialog2={openReviewDialog}
                    toggleDialog2={handleReviewButtonClick}
                    aisensyWccPlan={defaultWcc}
                    updatedWccPlan={aisensyPlans}
                    convos={CONVOS}
                    classes={classes}
                    origin="planFamily"
                  />
                )}
              </>
            ) : (
              ""
            )}
          </Box>
        </Grid>
      );
    case "configType":
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">
              {!item?.assistantId
                ? "Global"
                : item?.partnerId !== "627ea4876340e14cf7081778"
                ? "Partner Project Custom Config"
                : "AiSensy Project Custom Config"}
            </Typography>
          </Box>
        </Grid>
      );
    default:
      return (
        <Grid item xs>
          <Box py={2} pl={2}>
            <Typography variant="h5">{item[field]}</Typography>
          </Box>
        </Grid>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}

const fields = [
  {
    name: "Config Type",
    size: 4,
    key: "configType",
  },
  {
    name: "Created At",
    size: 4,
    key: "createdAt",
  },
  {
    name: "Actions",
    size: 4,
    key: "actions",
  },
];

const styles = (theme) =>
  createStyles({
    root: {
      padding: "2rem 1rem",
      backgroundColor: "#EBF5F3",
      borderRadius: "12px",
    },
    textField: {
      backgroundColor: "white",
      border: "1px solid black",
    },
    tableWrapper: {
      overflowX: "auto",
      width: "100%",
    },
    billTableContainer: {
      marginTop: "1.5em",
      width: "100%",
    },
    tableHeader: {
      background: "white",
      padding: 4,
      borderRadius: 8,
      marginBottom: "1em",
      flexWrap: "nowrap",
    },
    tableContainer: {
      top: "60px",
      height: "100%",
      width: "100%",
      overflow: "hidden",
      // background: "red",
      boxSizing: "border-box",
      position: "sticky",
      [theme.breakpoints.down("sm")]: {
        // position top + bottombar height + bottom padding + navbar
        // height: "calc(100vh - 70px - 50px - 10px - 50px)",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxSizing: "border-box",
      },
    },
    tableItem: {
      background: "white",
      marginBottom: "1em",
      padding: 4,
      flexWrap: "nowrap",
      borderRadius: 8,
      cursor: "pointer",
      "&:hover": {
        background: "#efefef",
      },
    },
    logo: {
      height: theme.spacing(6),
    },
  });

const connectedPlanFamily = connect((state) => ({
  tenantDetails: state.tenant.tenant,
}))(PlanFamily);
export default withStyles(styles)(connectedPlanFamily);
