import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import axios from "axios";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  ButtonGroup,
  TextField,
} from "@material-ui/core";
import {
  AddOutlined,
  NavigateNext,
  NavigateBefore,
  DateRange,
  Clear,
  CheckBoxOutlineBlank,
  CheckBox as CheckBoxIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";
import ViewTemplateMsg from "../TemplateMsg/ViewTemplateMsg";
import { TablePagination } from "@material-ui/core";
import TemplateLibraryForm from "./CreateTemplateLibraryForm";
import { URL } from "../../config/config";
import TemplateLibraryTable from "./TemplateLibraryTable";

class ViewTemplateLibraryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowsPerPage: 15,
      totalCount: null,
      skip: 0,
      timeStamp: new Date(),
      search: "",
      templates: [],
      err: null,
      isLoading: false,
      page: 0,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
    };
  }
  componentDidMount() {
    this.fetchTemplates();
  }

  fetchTemplates = async () => {
    try {
      this.setState({ isLoading: true, err: null });
      const t = this.state;
      let filter = {};
      if (t.search) {
        filter["$text"] = { $search: t.search };
      }
      if (t.applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: t.applied.createdAt.startDate,
          $lte: t.applied.createdAt.endDate,
        };
      }

      const queryObj = {
        skip: t.skip,
        timeStamp: t.timeStamp,
        rowsPerPage: t.rowsPerPage,
        search: "",
        allFilters: { ...filter },
      };

      axios
        .post(URL + "/superadmin/get-library-templates", { ...queryObj })
        .then((response) => {
          const { totalCount, newSkip, templates } = response.data;
          this.setState({
            skip: newSkip,
            templates: [...this.state.templates, ...templates],
            totalCount,
            isLoading: false,
          });
        })
        .catch((err) => {
          console.error(err);
          this.setState({ err, isLoading: false });
        });
    } catch (error) {
      console.log(error);
    }
  };
  getPageRows = () => {
    const { templates, page, rowsPerPage } = this.state;
    return templates.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  };
  handleChangePage = (event, newPage) => {
    const { templates, page, rowsPerPage } = this.state;
    const step = newPage - page;
    if (step === 1) {
      const pageRows = templates.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );
      if (!pageRows.length) {
        this.fetchTemplates();
      }
    }
    this.setState({ page: newPage });
  };
  setTemplate = (template) => {
    const templates = [...this.state.templates];
    const foundIndex = templates.findIndex((x) => x._id == template._id);
    // console.log({foundIndex})
    // if(foundIndex > -1) {
    templates[foundIndex] = template;
    this.setState({ templates }, () => {
      console.log(this.state.templates);
    });
    // } else {
    // }
    // templates.forEach
  };
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key == "Enter") {
      this.setState(
        {
          timeStamp: new Date(),
          skip: 0,
          page: 0,
          totalCount: null,
          templates: [],
          isLoading: true,
          err: null,
        },
        () => {
          this.fetchTemplates();
        }
      );
    }
  };
  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({
      applied,
      timeStamp: new Date(),
      skip: 0,
      page: 0,
      totalCount: null,
      templates: [],
      isLoading: true,
      err: null,
    }, this.fetchTemplates);
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        templates: [],
        isLoading: true,
        err: null,
      },
      () => {
        this.fetchTemplates();
      }
    );
  };
  removeTemplate = (index) => {
    this.setState((prev) => {
      prev.templates.splice(index, 1);
      return {
        ...prev,
        templates: [...prev.templates],
      };
    });
  };
  updateTemplate = (index, obj) => {
    this.setState((prev) => {
      const newtemps = [...prev.templates];
      newtemps[index] = { ...newtemps[index], ...obj };
      return {
        ...prev,
        templates: newtemps,
      };
    });
  };
  createSidebar = () => {
    const nav_items = [
      {
        name: "Trending",
        tag: "trending",
        prefixIcon: "WhatshotOutlined",
        selectColor: "#fec89a",
        textColor: "#e85d04",
        categories: [],
      },
      {
        name: "General",
        tag: "general",
        prefixIcon: "CloudQueueOutlined",
        selectColor: "#caf0f8",
        textColor: "#023e8a",
        categories: [],
      },
      {
        name: "Top Rated",
        tag: "top_rated",
        prefixIcon: "StarBorderOutlined",
        selectColor: "#f7cad0",
        textColor: "#c9184a",
        categories: [],
      },
      {
        name: "Industry",
        tag: "industry",
        prefixIcon: "ApartmentOutlined",
        selectColor: "#dcdcdc",
        textColor: "#000000",
        categories: [
          {
            name: "Ecommerce",
            tag: "ecommerce",
            prefixIcon: "ShoppingCartOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Education",
            tag: "education",
            prefixIcon: "SchoolOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Banking and Finance",
            tag: "banking_and_finance",
            prefixIcon: "AccountBalanceOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Webinar and Events",
            tag: "webinar_and_events",
            prefixIcon: "EventOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Healthcare",
            tag: "healthcare",
            prefixIcon: "HealingOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Automobile",
            tag: "automobile",
            prefixIcon: "AirportShuttleOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Real Estate",
            tag: "real_estate",
            prefixIcon: "HouseOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Services",
            tag: "services",
            prefixIcon: "SettingsInputAntennaOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
          {
            name: "Non profit and NGO",
            tag: "non_profit_and_ngo",
            prefixIcon: "FaceOutlined",
            selectColor: "#dcdcdc",
            textColor: "#000000",
            categories: [],
          },
        ],
      },
    ];
    axios
      .post(URL + "/superadmin/update-explore-sidebar", nav_items)
      .then((response) => {
        console.log(response)
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        <Grid
          container
          alignItems="center"
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
          spacing={2}
        >
          <Grid item>
            <Box pl={{ xs: 2, md: 3 }}>
              <Typography variant="h3">Template Library</Typography>
            </Box>
          </Grid>
          <Grid item>
            <TextField
              name="search"
              placeholder="Search templates"
              className={classes.textField}
              onChange={this.handleInput}
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <DateFilter
              filterKey="createdAt"
              filterName="Created At "
              classes={classes}
              applied={this.state.applied}
              setDate={this.setDate}
              setFocus={this.setFocus}
              checkDateInput={this.checkDateInput}
              clearDate={this.clearDate}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={this.applyFilter}
            >
              Apply
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={"/library/create"}
            >
              Create
            </Button>
          </Grid>
        </Grid>
        <Box my={3} />
        {/* Layout */}
        <Grid container justify="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <TemplateLibraryTable
                templates={this.getPageRows()}
                isLoading={this.state.isLoading}
                setTemplate={this.setTemplate}
                removeTemplate={this.removeTemplate}
                updateTemplate={this.updateTemplate}
              />
            </div>
          </Grid>
        </Grid>
        {/* Table footer fixed at bottom */}
        <div className={classes.fixedBottomContainer}>
          <TablePagination
            component="div"
            count={this.state.totalCount || 0}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={[25]}
          />
        </div>
      </div>
    );
  }
}

// Navigattion icons used in date fliters
function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={2} mb={2} className={classes.filterTypeContainer}>
      <Grid container justify="space-between" alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Typography variant="body1">{filterName}</Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 80,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 70px - 60px - 40px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textField: {
    width: 300,
  },
});

export default withStyles(styles)(ViewTemplateLibraryPage);
