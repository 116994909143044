import React from "react";
import { Grid, TextField, Typography } from "@material-ui/core";

const CONVERT_PRICE = 100000;

function WccRow(props) {
  const {
    countryCode,
    country,
    currency,
    keyValue,
    aisensyWccPlan,
    updateConvo,
    disabledFields,
    convos,
  } = props;
  return (
    <React.Fragment key={keyValue}>
      <Grid item xs={12} md={2} container alignItems="center">
        <Typography
          required
          // className={classes.}
          variant="h5"
          // align="center"
        >
          {country}
        </Typography>
      </Grid>

      <Grid xs={12} md={10} style={{ display: "flex", alignItems: "center" }}>
        {convos.map(({ name, key }) => {
          return (
            <Grid item md={3} style={{ display: "flex" }}>
              <Grid item xs={12} md={12} style={{ marginRight: 4 }}>
                <TextField
                  style={{ width: "100%", padding: "3px 0px" }}
                  required
                  type="Number"
                  variant="outlined"
                  placeholder="0"
                  disabled={disabledFields}
                  // className={classes.input}
                  margin="dense"
                  onChange={(e) => {
                    const value = Number(e.target.value) * 100000 ?? 0;
                    const newPrice = value;
                    updateConvo(newPrice, countryCode, key);
                  }}
                  value={
                    aisensyWccPlan[key][countryCode][currency] / CONVERT_PRICE
                  }
                  name={name}
                  InputProps={{
                    startAdornment: currency === "USD" ? "$" : "₹",
                  }}
                />
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}

export default WccRow;
