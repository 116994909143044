import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@material-ui/core";
import ImageIcon from "@material-ui/icons/Image";
import PublishIcon from "@material-ui/icons/Publish";

function ImageUpload({ handleImageChange, assetKey, closeDialog }) {
  const [selected, setSelected] = useState();

  return (
    <Box p={2} width="500px">
      <Typography variant="body1" gutterBottom style={{ fontWeight: "bold" }}>
        Select the Image
      </Typography>
      <Box my={2} />
      <Grid container justifyContent="center">
        <label
          style={{
            display: "flex",
            background: "rgb(240,240,240)",
            padding: "40px",
            borderRadius: 8,
          }}
          for="MyInput"
        >
          {selected ? (
            <img
              alt="preview"
              width="auto"
              height="24px"
              src={URL.createObjectURL(selected)}
            />
          ) : (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ImageIcon fontSize="large" />
              <Typography variant="subheading">Tap to select</Typography>
            </Box>
          )}
          <input
            type="file"
            accept="image/*"
            id="MyInput"
            hidden
            style={{ width: "100%", height: "100%" }}
            onChange={(e) => setSelected(e.target.files[0])}
          />
        </label>
      </Grid>
      <Box my={4} />
      <Box my={4} />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        disabled={!selected}
        startIcon={<PublishIcon />}
        onClick={() => {
          handleImageChange(selected, assetKey);
          closeDialog();
        }}
      >
        Upload
      </Button>
    </Box>
  );
}

export default ImageUpload;
