import axios from "axios";
import moment from "moment";
import PartnersTable from "./PartnersTable";
import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import {
  Theme,
  createStyles,
  withStyles,
  WithStyles,
  Button,
  Box,
  Typography,
  Grid,
  TextField,
  IconButton,
  TablePagination,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Tab,
  Tabs,
} from "@material-ui/core";
import { NavigateNext, NavigateBefore, Clear } from "@material-ui/icons";
import { URL } from "../../config/config";
import AddIcon from "@material-ui/icons/AddOutlined";
import AddPartner from "./AddPartner";
import DownloadIcon from "@material-ui/icons/GetApp";
import CloseIcon from "@material-ui/icons/Close";

/**
 * @typedef {import("../../types").Partner} Partner
 * @typedef {import("../../types").PartnersResponse} PartnersResponse
 * @typedef {import("axios").AxiosResponse<PartnersResponse>} AxiosPartnersResponse
 */

const createInitialState = () => ({
  rowsPerPage: 15,
  totalCount: null,
  skip: 0,
  timeStamp: new Date(),
  search: "",
  /** @type {Types.Partner[]} */
  partners: [],
  err: null,
  isLoading: false,
  page: 0,
  applied: {
    createdAt: {
      startDate: null,
      endDate: null,
      focus: null,
    },
    dateRange: {
      startDate: null,
      endDate: null,
      focus: null,
    },
  },
  openAddPartnerDialog: false,
  openPartnerReportDialog: false,
  successPartnersData: true,
  percentageFetchedData: 0,
  tabValue: 0,
  whitelabelsCount: 0,
  directCount: 0,
  whitelabels: [],
  direct: [],
});

/**
 * @typedef {WithStyles<typeof styles>} Props
 * @typedef {ReturnType<typeof createInitialState>} State
 */

/**
 * @extends Component<Props, State>
 */
class PartnersPage extends Component {
  constructor(props) {
    super(props);
    this.state = createInitialState();
    this.intervalId = null;
  }

  componentDidMount() {
    this.fetchPartners();
  }

  closeAddPartnerDialog = () => this.setState({ openAddPartnerDialog: false });

  closePartnerReportDialog = () =>
    this.setState({ openPartnerReportDialog: false });

  onAddedPartnerDialog = () => {
    this.closeAddPartnerDialog();
    this.setState(createInitialState(), () => {
      this.fetchPartners();
    });
  };

  openAddPartnerDialog = () => this.setState({ openAddPartnerDialog: true });

  openPartnerReportDialog = () =>
    this.setState({ openPartnerReportDialog: true });

  calculateEstimatedTime = () => {
    const { totalCount, successPartnersData } = this.state;
    let secondsPassed = 0;

    this.intervalId = setInterval(() => {
      secondsPassed++;
      this.setState({
        percentageFetchedData: (secondsPassed / totalCount) * 100,
      });
      if (secondsPassed >= totalCount || !successPartnersData) {
        clearInterval(this.intervalId);
      }
    }, 1000);
  };

  formatDate = (date) => {
    const months = [
      "JANUARY",
      "FEBRUARY",
      "MARCH",
      "APRIL",
      "MAY",
      "JUNE",
      "JULY",
      "AUGUST",
      "SEPTEMBER",
      "OCTOBER",
      "NOVEMBER",
      "DECEMBER",
    ];

    const day = String(date._d.getDate()).padStart(2, "0");
    const month = months[date._d.getMonth()];
    const year = date._d.getFullYear();

    return `${day}_${month}_${year}`;
  };

  getPartnersAggregatedReport = () => {
    const { dateRange } = this.state.applied;
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    this.setState({ successPartnersData: false });
    this.calculateEstimatedTime();
    let sd, ed;
    if (startDate) sd = this.formatDate(startDate);
    if (endDate) ed = this.formatDate(endDate);
    axios
      .post(
        `${URL}/superadmin/partners-aggregated-report`,
        { startDate, endDate },
        { responseType: "stream" }
      )
      .then((res) => {
        const cbLogs = res.data?.data;
        if (!cbLogs || !cbLogs.length) {
          console.error("No data available to generate CSV.");
          return "failure";
        }
        // Function to flatten JSON
        const flattenObject = (obj, parent = "", res = {}) => {
          for (let key in obj) {
            let propName = parent ? `${parent}/${key}` : key;
            if (typeof obj[key] === "object" && obj[key] !== null) {
              flattenObject(obj[key], propName, res);
            } else {
              res[propName] = obj[key];
            }
          }
          return res;
        };
        // Flatten all rows
        const flattenedData = cbLogs.map((row) => flattenObject(row));
        // Get all headers from the flattened data
        const headers = Array.from(
          flattenedData.reduce((acc, row) => {
            Object.keys(row).forEach((key) => acc.add(key));
            return acc;
          }, new Set())
        );
        // Create CSV content
        const replacer = (key, value) => (value === null ? "" : value);
        const csv = [
          headers.join(","), // CSV header row
          ...flattenedData.map((row) =>
            headers
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(",")
          ),
        ].join("\r\n");
        // Download the file as CSV
        var downloadLink = document.createElement("a");
        var blob = new Blob(["\ufeff", csv]);
        var url = window.URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download =
          sd && ed
            ? "PARTNERS_STATS_" + `${sd}-${ed}` + ".csv"
            : "PARTNERS_STATS_ALL" + ".csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.setState({ successPartnersData: true });
      })
      .catch((error) => {
        console.error(error);
        this.setState({
          status: "error",
          statusMessage: "Unable to fetch Data",
          loading: false,
        });
      });
  };

  fetchPartners = async () => {
    this.setState({ isLoading: true, err: null });
    try {
      const t = this.state;
      let filter = {};
      if (t.search) {
        filter["search"] = t.search;
      }
      if (t.applied.createdAt.startDate) {
        filter["createdAt"] = {
          $gte: t.applied.createdAt.startDate,
          $lte: t.applied.createdAt.endDate,
        };
      }
      const partnerType = this.state.tabValue === 0 ? "WHITELABEL" : "DIRECT";
      if (t.search) {
        filter.type = { $in: ["WHITELABEL", "DIRECT"] };
      } else filter.type = partnerType;
      const queryObj = {
        skip: t.skip,
        timeStamp: t.timeStamp,
        rowsPerPage: t.rowsPerPage,
        search: "",
        allFilters: { ...filter },
      };

      /** @type {AxiosPartnersResponse} */
      const response = await axios.get(`${URL}/superadmin/get-partners`, {
        params: queryObj,
      });
      const { totalCount, newSkip, partners } = response.data;
      this.setState({
        skip: newSkip,
        partners: [...this.state.partners, ...partners],
        totalCount,
        isLoading: false,
      });
      const whitelabels = this.state.partners.filter(
        (partner) => partner.type === "WHITELABEL"
      );
      const direct = this.state.partners.filter(
        (partner) => partner.type === "DIRECT"
      );

      if (whitelabels?.length === 0 && direct?.length != 0) {
        this.setState({ tabValue: 1 });
      } else if (direct?.length === 0 && whitelabels?.length != 0) {
        this.setState({ tabValue: 0 });
      }

      this.setState({
        directCount: totalCount,
        direct: direct,
        whitelabelsCount: totalCount,
        whitelabels: whitelabels,
      });
    } catch (err) {
      this.setState({ err, isLoading: false });
    }
  };

  /** @param {Partner} partner */
  setPartner = (partner) => {
    const partners = [...this.state.partners];
    const foundIndex = partners.findIndex((x) => x._id == partner._id);
    partners[foundIndex] = partner;
    const whitelabels = partners.filter(
      (partner) => partner.type === "WHITELABEL"
    );
    const direct = partners.filter((partner) => partner.type === "DIRECT");
    this.setState({
      directCount: direct.length,
      direct: direct,
      whitelabelsCount: whitelabels.length,
      whitelabels: whitelabels,
      partners: partners,
    });
  };

  getPageRows = () => {
    const { partners, page, rowsPerPage, tabValue, whitelabels, direct } =
      this.state;
    if (tabValue === 0) {
      return whitelabels.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      );
    } else {
      return direct.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }
  };

  handleChangePage = (event, newPage) => {
    const { partners, page, rowsPerPage, whitelabels, direct, tabValue } =
      this.state;
    const currentPartners = tabValue === 0 ? whitelabels : direct;
    const step = newPage - page;
    if (step === 1) {
      const pageRows = currentPartners.slice(
        newPage * rowsPerPage,
        newPage * rowsPerPage + rowsPerPage
      );
      if (!pageRows.length) {
        this.fetchPartners();
      }
    }
    this.setState({ page: newPage });
  };

  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleEnter = (e) => {
    if (e.key == "Enter") {
      this.setState(
        {
          timeStamp: new Date(),
          skip: 0,
          page: 0,
          totalCount: null,
          partners: [],
          isLoading: true,
          err: null,
        },
        () => {
          this.fetchPartners();
        }
      );
    }
  };
  // methods for date filter
  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        partners: [],
        isLoading: true,
        err: null,
      },
      () => {
        this.fetchPartners();
      }
    );
  };

  handleTabs = (event, newValue) => {
    const t = this.state;
    this.setState(
      {
        timeStamp: new Date(),
        skip: 0,
        page: 0,
        totalCount: null,
        templates: [],
        isLoading: true,
        err: null,
        value: newValue,
        partners: [],
      },
      () => {
        this.fetchPartners();
      }
    );
    this.setState({ tabValue: newValue, page: 0 });
  };

  render() {
    const { classes, agent } = this.props;
    const { tabValue } = this.state;
    return (
      <div className={classes.root}>
        <Grid
          container
          className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
          spacing={2}
        >
          <Grid item>
            <Typography variant="h3">Partners</Typography>
          </Grid>
          <Grid item>
            <TextField
              name="search"
              placeholder="Search Partners"
              className={classes.textField}
              onChange={this.handleInput}
              onKeyPress={this.handleEnter}
            />
          </Grid>
          <Grid item>
            <DateFilter
              filterKey="createdAt"
              filterName="Created At"
              classes={classes}
              applied={this.state.applied}
              setDate={this.setDate}
              setFocus={this.setFocus}
              checkDateInput={this.checkDateInput}
              clearDate={this.clearDate}
            />
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={this.applyFilter}
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            {agent.power <= 11 ? (
              <></>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={this.openPartnerReportDialog}
              >
                Report
              </Button>
            )}
          </Grid>
          <Grid item>
            {agent.power <= 10 ? (
              <></>
            ) : (
              <Button
                color="primary"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={this.openAddPartnerDialog}
              >
                Add Partner
              </Button>
            )}
          </Grid>
        </Grid>
        <Box my={3} />
        <Grid container justify="center">
          <Grid item xs={12} md={11} lg={10}>
            {/* First section */}
            {/* Scrollable (x & y) table container */}
            <div className={classes.tableContainer}>
              <Tabs
                value={tabValue}
                onChange={this.handleTabs}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
              >
                <Tab label="WHITELABELS " />
                <Tab label="DIRECT" />
              </Tabs>
              {tabValue === 0 && (
                <PartnersTable
                  partners={this.getPageRows()}
                  isLoading={this.state.isLoading}
                  setPartner={this.setPartner}
                />
              )}
              {tabValue === 1 && (
                <PartnersTable
                  partners={this.getPageRows()}
                  isLoading={this.state.isLoading}
                  setPartner={this.setPartner}
                />
              )}
            </div>
          </Grid>
        </Grid>

        {/* <Grid container justifyContent="center">
          <Grid item xs={12} md={11} lg={10}> */}
        {/* First section */}
        {/* Scrollable (x & y) table container */}
        {/* <div className={classes.tableContainer}>
              <PartnersTable
                partners={this.getPageRows()}
                isLoading={this.state.isLoading}
                setPartner={this.setPartner}
              />
            </div>
          </Grid>
        </Grid> */}
        {/* Table footer fixed at bottom */}
        <div className={classes.fixedBottomContainer}>
          {tabValue === 0 ? (
            <TablePagination
              component="div"
              count={this.state.whitelabelsCount || 0}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              rowsPerPage={this.state.rowsPerPage}
              rowsPerPageOptions={[25]}
            />
          ) : (
            tabValue === 1 && (
              <TablePagination
                component="div"
                count={this.state.directCount || 0}
                page={this.state.page}
                onPageChange={this.handleChangePage}
                rowsPerPage={this.state.rowsPerPage}
                rowsPerPageOptions={[25]}
              />
            )
          )}
        </div>
        <Dialog open={this.state.openAddPartnerDialog}>
          <AddPartner
            onClose={this.closeAddPartnerDialog}
            onSuccess={this.onAddedPartnerDialog}
          />
        </Dialog>
        <Dialog
          open={this.state.openPartnerReportDialog}
          onClose={this.closePartnerReportDialog}
        >
          <DialogTitle className={classes.title}>
            <Grid
              container
              alignItems="center"
              spacing={2}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item>
                <Typography variant="h3" component="p">
                  Partners Report
                </Typography>
              </Grid>
              <Grid item>
                <IconButton onClick={this.closePartnerReportDialog}>
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Typography variant="h5">
              Select the date range to download partners report
            </Typography>
            <br></br>
            <Grid
              container
              direction="column"
              className={classes.content}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <DateFilter
                filterKey="dateRange"
                filterName="Date Range"
                classes={classes}
                applied={this.state.applied}
                setDate={this.setDate}
                setFocus={this.setFocus}
                checkDateInput={this.checkDateInput}
                clearDate={this.clearDate}
              />
              <Button
                color="primary"
                variant="contained"
                endIcon={
                  this.state.successPartnersData ? (
                    <DownloadIcon />
                  ) : (
                    <CircularProgress
                      variant="determinate"
                      size={20}
                      value={this.state.percentageFetchedData}
                    />
                  )
                }
                disabled={!this.state.successPartnersData}
                onClick={this.getPartnersAggregatedReport}
                style={{
                  width: "180px",
                  height: "33px",
                }}
              >
                Download Report
              </Button>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <div className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </div>
  );
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box className={classes.filterTypeContainer}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        {A.startDate && A.endDate ? (
          <Grid item>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item>
          <Typography variant="body1">{filterName}</Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
            minimumNights={0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

/** @param {Theme} theme */
const styles = (theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      background: "rgb(249,249,249)",
      boxSizing: "border-box",
      overflowX: "hidden",
      position: "relative",
    },
    pageTitleContainer: {
      position: "sticky",
      zIndex: 100,
      top: 0,
      height: 60,
      boxSizing: "border-box",
      alignItems: "center",
      padding: "0 60px",
      [theme.breakpoints.down("md")]: {},
      [theme.breakpoints.down("sm")]: {},
    },
    fullWidth: {
      background: "white",
    },
    container: {
      background: "white",
      borderRadius: "8px",
    },
    fixedBottomContainer: {
      position: "fixed",
      height: "60px",
      borderTop: "1px solid lightgrey",
      background: "white",
      bottom: "0",
      left: "71px",
      right: "0",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        left: "0px",
        height: "50px",
      },
    },
    tableContainer: {
      top: "60px",
      height: "calc(100vh - 70px - 60px - 40px)",
      width: "100%",
      overflow: "hidden",
      boxSizing: "border-box",
      position: "sticky",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 70px - 50px - 10px - 50px)",
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        boxSizing: "border-box",
      },
    },
    textField: {
      width: 300,
    },
    content: {
      width: "70vw",
      minHeight: "400px",
      maxWidth: "600px",
      display: "flex",
      flexDirection: "row",
    },
  });

const connectedPartnersPage = connect((state) => ({
  agent: state.login.user,
}))(PartnersPage);

export default withStyles(styles)(connectedPartnersPage);
