import React from "react";
import moment from "moment";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";
import { NavigateBefore, Clear, NavigateNext } from "@material-ui/icons";

import "../../react-dates-custom.css";

function NavigationWrapper(props) {
  const { classes } = props;
  return (
    <Box className={classes.navigationWrapper}>
      <IconButton size="small">{props.children}</IconButton>
    </Box>
  );
}

function DateFilter({
  applied,
  setDate,
  setFocus,
  clearDate,
  filterKey,
  checkDateInput,
  ...props
}) {
  const A = applied[filterKey];
  const { classes, filterName } = props;

  return (
    <Box>
      <Grid>
        {A.startDate && A.endDate && (
          <Grid item>
            <IconButton
              size="small"
              color="primary"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        )}
        <Grid item>
          <Typography style={{ marginRight: "8px" }} variant="body1">
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs>
          <DateRangePicker
            small
            readOnly
            placement="right"
            numberOfMonths={2}
            endDate={A.endDate}
            endDateId="endDate"
            customArrowIcon={null}
            startDate={A.startDate}
            startDateId="startDate"
            hideKeyboardShortcutsPanel
            endDatePlaceholderText="To"
            navPosition="navPositionTop"
            focusedInput={A.focusedInput}
            screenReaderInputMessage={" "}
            startDatePlaceholderText="From"
            onClose={() => checkDateInput(filterKey)}
            isOutsideRange={(day) => moment().diff(day) < 0}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            navPrev={
              <NavigationWrapper classes={classes}>
                <NavigateBefore />
              </NavigationWrapper>
            }
            navNext={
              <NavigationWrapper classes={classes}>
                <NavigateNext />
              </NavigationWrapper>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default DateFilter;
