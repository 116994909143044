const AISENSY_WCC_PLAN = {
  business_initiated: {
    AF: {
      INR: 602000,
      USD: 9080,
    },
    AL: {
      INR: 673000,
      USD: 10120,
    },
    DZ: {
      INR: 967000,
      USD: 14440,
    },
    AO: {
      INR: 967000,
      USD: 14440,
    },
    AR: {
      INR: 430000,
      USD: 6540,
    },
    AM: {
      INR: 673000,
      USD: 10120,
    },
    AU: {
      INR: 602000,
      USD: 9080,
    },
    AT: {
      INR: 1058000,
      USD: 15790,
    },
    AZ: {
      INR: 673000,
      USD: 10120,
    },
    BH: {
      INR: 588000,
      USD: 8870,
    },
    BD: {
      INR: 602000,
      USD: 9080,
    },
    BY: {
      INR: 673000,
      USD: 10120,
    },
    BE: {
      INR: 1058000,
      USD: 15790,
    },
    BJ: {
      INR: 967000,
      USD: 14440,
    },
    BO: {
      INR: 572000,
      USD: 8630,
    },
    BW: {
      INR: 967000,
      USD: 14440,
    },
    BR: {
      INR: 409000,
      USD: 6240,
    },
    BG: {
      INR: 673000,
      USD: 10120,
    },
    BF: {
      INR: 967000,
      USD: 14440,
    },
    BI: {
      INR: 967000,
      USD: 14440,
    },
    KH: {
      INR: 602000,
      USD: 9080,
    },
    CA: {
      INR: 572000,
      USD: 8630,
    },
    CM: {
      INR: 967000,
      USD: 14440,
    },
    TD: {
      INR: 967000,
      USD: 14440,
    },
    CL: {
      INR: 612000,
      USD: 9220,
    },
    CN: {
      INR: 602000,
      USD: 9080,
    },
    CO: {
      INR: 95000,
      USD: 1610,
    },
    CG: {
      INR: 396000,
      USD: 6050,
    },
    CR: {
      INR: 572000,
      USD: 8630,
    },
    HR: {
      INR: 673000,
      USD: 10120,
    },
    CZ: {
      INR: 673000,
      USD: 10120,
    },
    DK: {
      INR: 1058000,
      USD: 15790,
    },
    VE: {
      INR: 572000,
      USD: 8630,
    },
    EC: {
      INR: 572000,
      USD: 8630,
    },
    EG: {
      INR: 861000,
      USD: 12880,
    },
    SV: {
      INR: 572000,
      USD: 8630,
    },
    ER: {
      INR: 967000,
      USD: 14440,
    },
    ET: {
      INR: 967000,
      USD: 14440,
    },
    FI: {
      INR: 1058000,
      USD: 15790,
    },
    FR: {
      INR: 1143000,
      USD: 17040,
    },
    GA: {
      INR: 967000,
      USD: 14440,
    },
    GM: {
      INR: 967000,
      USD: 14440,
    },
    GE: {
      INR: 673000,
      USD: 10120,
    },
    DE: {
      INR: 1091000,
      USD: 16260,
    },
    GH: {
      INR: 967000,
      USD: 14440,
    },
    GR: {
      INR: 673000,
      USD: 10120,
    },
    GT: {
      INR: 572000,
      USD: 8630,
    },
    GW: {
      INR: 967000,
      USD: 14440,
    },
    HT: {
      INR: 572000,
      USD: 8630,
    },
    HN: {
      INR: 572000,
      USD: 8630,
    },
    HK: {
      INR: 602000,
      USD: 9080,
    },
    HU: {
      INR: 673000,
      USD: 10120,
    },
    IN: {
      INR: 55000,
      USD: 800,
    },
    ID: {
      INR: 265000,
      USD: 4110,
    },
    IQ: {
      INR: 588000,
      USD: 8870,
    },
    IE: {
      INR: 1058000,
      USD: 15790,
    },
    IL: {
      INR: 252000,
      USD: 3930,
    },
    IT: {
      INR: 522000,
      USD: 7900,
    },
    JP: {
      INR: 602000,
      USD: 9080,
    },
    JO: {
      INR: 588000,
      USD: 8870,
    },
    KE: {
      INR: 967000,
      USD: 14440,
    },
    KW: {
      INR: 588000,
      USD: 8870,
    },
    LA: {
      INR: 602000,
      USD: 9080,
    },
    LV: {
      INR: 673000,
      USD: 10120,
    },
    LB: {
      INR: 588000,
      USD: 8870,
    },
    LS: {
      INR: 967000,
      USD: 14440,
    },
    LR: {
      INR: 967000,
      USD: 14440,
    },
    LY: {
      INR: 967000,
      USD: 14440,
    },
    LT: {
      INR: 673000,
      USD: 10120,
    },
    MG: {
      INR: 967000,
      USD: 14440,
    },
    MW: {
      INR: 967000,
      USD: 14440,
    },
    MY: {
      INR: 592000,
      USD: 8930,
    },
    ML: {
      INR: 967000,
      USD: 14440,
    },
    MR: {
      INR: 967000,
      USD: 14440,
    },
    MX: {
      INR: 291000,
      USD: 4490,
    },
    MD: {
      INR: 673000,
      USD: 10120,
    },
    MN: {
      INR: 602000,
      USD: 9080,
    },
    MA: {
      INR: 967000,
      USD: 14440,
    },
    MZ: {
      INR: 967000,
      USD: 14440,
    },
    NA: {
      INR: 967000,
      USD: 14440,
    },
    NP: {
      INR: 602000,
      USD: 9080,
    },
    NL: {
      INR: 1185000,
      USD: 17650,
    },
    NZ: {
      INR: 602000,
      USD: 9080,
    },
    NI: {
      INR: 572000,
      USD: 8630,
    },
    NE: {
      INR: 967000,
      USD: 14440,
    },
    NG: {
      INR: 422000,
      USD: 6430,
    },
    NO: {
      INR: 1058000,
      USD: 15790,
    },
    OM: {
      INR: 588000,
      USD: 8870,
    },
    PK: {
      INR: 388000,
      USD: 5930,
    },
    PA: {
      INR: 572000,
      USD: 8630,
    },
    PG: {
      INR: 602000,
      USD: 9080,
    },
    PY: {
      INR: 572000,
      USD: 8630,
    },
    PE: {
      INR: 487000,
      USD: 7380,
    },
    PH: {
      INR: 602000,
      USD: 9080,
    },
    PL: {
      INR: 673000,
      USD: 10120,
    },
    PT: {
      INR: 1058000,
      USD: 15790,
    },
    PR: {
      INR: 572000,
      USD: 8630,
    },
    QA: {
      INR: 588000,
      USD: 8870,
    },
    RO: {
      INR: 673000,
      USD: 10120,
    },
    RU: {
      INR: 538000,
      USD: 8130,
    },
    RW: {
      INR: 967000,
      USD: 14440,
    },
    SA: {
      INR: 272000,
      USD: 4220,
    },
    SN: {
      INR: 967000,
      USD: 14440,
    },
    RS: {
      INR: 673000,
      USD: 10120,
    },
    SL: {
      INR: 967000,
      USD: 14440,
    },
    SG: {
      INR: 602000,
      USD: 9080,
    },
    SK: {
      INR: 673000,
      USD: 10120,
    },
    SI: {
      INR: 673000,
      USD: 10120,
    },
    SO: {
      INR: 967000,
      USD: 14440,
    },
    ZA: {
      INR: 236000,
      USD: 3690,
    },
    SS: {
      INR: 967000,
      USD: 14440,
    },
    ES: {
      INR: 500000,
      USD: 7570,
    },
    LK: {
      INR: 602000,
      USD: 9080,
    },
    SD: {
      INR: 967000,
      USD: 14440,
    },
    SE: {
      INR: 1058000,
      USD: 15790,
    },
    CH: {
      INR: 1058000,
      USD: 15790,
    },
    TW: {
      INR: 602000,
      USD: 9080,
    },
    TJ: {
      INR: 602000,
      USD: 9080,
    },
    TH: {
      INR: 602000,
      USD: 9080,
    },
    TG: {
      INR: 967000,
      USD: 14440,
    },
    TN: {
      INR: 967000,
      USD: 14440,
    },
    TR: {
      INR: 95000,
      USD: 1610,
    },
    TM: {
      INR: 602000,
      USD: 9080,
    },
    UG: {
      INR: 967000,
      USD: 14440,
    },
    UA: {
      INR: 673000,
      USD: 10120,
    },
    AE: {
      INR: 265000,
      USD: 4110,
    },
    GB: {
      INR: 525000,
      USD: 7950,
    },
    US: {
      INR: 572000,
      USD: 8630,
    },
    UY: {
      INR: 572000,
      USD: 8630,
    },
    UZ: {
      INR: 602000,
      USD: 9080,
    },
    VN: {
      INR: 602000,
      USD: 9080,
    },
    YE: {
      INR: 588000,
      USD: 8870,
    },
    ZM: {
      INR: 967000,
      USD: 14440,
    },
    TZ: {
      INR: 967000,
      USD: 14440,
    },
    SZ: {
      INR: 967000,
      USD: 14440,
    },
    MK: {
      INR: 673000,
      USD: 10120,
    },
    DO: {
      INR: 572000,
      USD: 8630,
    },
    JM: {
      INR: 572000,
      USD: 8630,
    },
    CI: {
      INR: 967000,
      USD: 14440,
    },
    OTHER: {
      INR: 396000,
      USD: 6050,
    },
  },
  user_initiated: {
    AF: {
      INR: 192000,
      USD: 2830,
    },
    AL: {
      INR: 213000,
      USD: 3130,
    },
    DZ: {
      INR: 302000,
      USD: 4440,
    },
    AO: {
      INR: 302000,
      USD: 4440,
    },
    AR: {
      INR: 265000,
      USD: 3890,
    },
    AM: {
      INR: 213000,
      USD: 3130,
    },
    AU: {
      INR: 192000,
      USD: 2830,
    },
    AT: {
      INR: 328000,
      USD: 4830,
    },
    AZ: {
      INR: 213000,
      USD: 3130,
    },
    BH: {
      INR: 187000,
      USD: 2760,
    },
    BD: {
      INR: 192000,
      USD: 2830,
    },
    BY: {
      INR: 213000,
      USD: 3130,
    },
    BE: {
      INR: 328000,
      USD: 4830,
    },
    BJ: {
      INR: 302000,
      USD: 4440,
    },
    BO: {
      INR: 349000,
      USD: 5130,
    },
    BW: {
      INR: 302000,
      USD: 4440,
    },
    BR: {
      INR: 252000,
      USD: 3710,
    },
    BG: {
      INR: 213000,
      USD: 3130,
    },
    BF: {
      INR: 302000,
      USD: 4440,
    },
    BI: {
      INR: 302000,
      USD: 4440,
    },
    KH: {
      INR: 192000,
      USD: 2830,
    },
    CA: {
      INR: 349000,
      USD: 5130,
    },
    CM: {
      INR: 302000,
      USD: 4440,
    },
    TD: {
      INR: 302000,
      USD: 4440,
    },
    CL: {
      INR: 373000,
      USD: 5490,
    },
    CN: {
      INR: 192000,
      USD: 2830,
    },
    CO: {
      INR: 63000,
      USD: 930,
    },
    CG: {
      INR: 130000,
      USD: 1910,
    },
    CR: {
      INR: 349000,
      USD: 5130,
    },
    HR: {
      INR: 213000,
      USD: 3130,
    },
    CZ: {
      INR: 213000,
      USD: 3130,
    },
    DK: {
      INR: 328000,
      USD: 4830,
    },
    VE: {
      INR: 349000,
      USD: 5130,
    },
    EC: {
      INR: 349000,
      USD: 5130,
    },
    EG: {
      INR: 523000,
      USD: 7690,
    },
    SV: {
      INR: 349000,
      USD: 5130,
    },
    ER: {
      INR: 302000,
      USD: 4440,
    },
    ET: {
      INR: 302000,
      USD: 4440,
    },
    FI: {
      INR: 328000,
      USD: 4830,
    },
    FR: {
      INR: 692000,
      USD: 10180,
    },
    GA: {
      INR: 302000,
      USD: 4440,
    },
    GM: {
      INR: 302000,
      USD: 4440,
    },
    GE: {
      INR: 213000,
      USD: 3130,
    },
    DE: {
      INR: 661000,
      USD: 9720,
    },
    GH: {
      INR: 302000,
      USD: 4440,
    },
    GR: {
      INR: 213000,
      USD: 3130,
    },
    GT: {
      INR: 349000,
      USD: 5130,
    },
    GW: {
      INR: 302000,
      USD: 4440,
    },
    HT: {
      INR: 349000,
      USD: 5130,
    },
    HN: {
      INR: 349000,
      USD: 5130,
    },
    HK: {
      INR: 192000,
      USD: 2830,
    },
    HU: {
      INR: 213000,
      USD: 3130,
    },
    IN: {
      INR: 35000,
      USD: 510,
    },
    ID: {
      INR: 165000,
      USD: 2430,
    },
    IQ: {
      INR: 187000,
      USD: 2760,
    },
    IE: {
      INR: 328000,
      USD: 4830,
    },
    IL: {
      INR: 157000,
      USD: 2320,
    },
    IT: {
      INR: 320000,
      USD: 4700,
    },
    JP: {
      INR: 192000,
      USD: 2830,
    },
    JO: {
      INR: 187000,
      USD: 2760,
    },
    KE: {
      INR: 302000,
      USD: 4440,
    },
    KW: {
      INR: 187000,
      USD: 2760,
    },
    LA: {
      INR: 192000,
      USD: 2830,
    },
    LV: {
      INR: 213000,
      USD: 3130,
    },
    LB: {
      INR: 187000,
      USD: 2760,
    },
    LS: {
      INR: 302000,
      USD: 4440,
    },
    LR: {
      INR: 302000,
      USD: 4440,
    },
    LY: {
      INR: 302000,
      USD: 4440,
    },
    LT: {
      INR: 213000,
      USD: 3130,
    },
    MG: {
      INR: 302000,
      USD: 4440,
    },
    MW: {
      INR: 302000,
      USD: 4440,
    },
    MY: {
      INR: 189000,
      USD: 2780,
    },
    ML: {
      INR: 302000,
      USD: 4440,
    },
    MR: {
      INR: 302000,
      USD: 4440,
    },
    MX: {
      INR: 98000,
      USD: 1450,
    },
    MD: {
      INR: 213000,
      USD: 3130,
    },
    MN: {
      INR: 192000,
      USD: 2830,
    },
    MA: {
      INR: 302000,
      USD: 4440,
    },
    MZ: {
      INR: 302000,
      USD: 4440,
    },
    NA: {
      INR: 302000,
      USD: 4440,
    },
    NP: {
      INR: 192000,
      USD: 2830,
    },
    NL: {
      INR: 717000,
      USD: 10550,
    },
    NZ: {
      INR: 192000,
      USD: 2830,
    },
    NI: {
      INR: 349000,
      USD: 5130,
    },
    NE: {
      INR: 302000,
      USD: 4440,
    },
    NG: {
      INR: 260000,
      USD: 3820,
    },
    NO: {
      INR: 328000,
      USD: 4830,
    },
    OM: {
      INR: 187000,
      USD: 2760,
    },
    PK: {
      INR: 128000,
      USD: 1880,
    },
    PA: {
      INR: 349000,
      USD: 5130,
    },
    PG: {
      INR: 192000,
      USD: 2830,
    },
    PY: {
      INR: 349000,
      USD: 5130,
    },
    PE: {
      INR: 157000,
      USD: 2300,
    },
    PH: {
      INR: 192000,
      USD: 2830,
    },
    PL: {
      INR: 213000,
      USD: 3130,
    },
    PT: {
      INR: 328000,
      USD: 4830,
    },
    PR: {
      INR: 349000,
      USD: 5130,
    },
    QA: {
      INR: 187000,
      USD: 2760,
    },
    RO: {
      INR: 213000,
      USD: 3130,
    },
    RU: {
      INR: 329000,
      USD: 4840,
    },
    RW: {
      INR: 302000,
      USD: 4440,
    },
    SA: {
      INR: 169000,
      USD: 2490,
    },
    SN: {
      INR: 302000,
      USD: 4440,
    },
    RS: {
      INR: 213000,
      USD: 3130,
    },
    SL: {
      INR: 302000,
      USD: 4440,
    },
    SG: {
      INR: 192000,
      USD: 2830,
    },
    SK: {
      INR: 213000,
      USD: 3130,
    },
    SI: {
      INR: 213000,
      USD: 3130,
    },
    SO: {
      INR: 302000,
      USD: 4440,
    },
    ZA: {
      INR: 148000,
      USD: 2180,
    },
    SS: {
      INR: 302000,
      USD: 4440,
    },
    ES: {
      INR: 306000,
      USD: 4500,
    },
    LK: {
      INR: 192000,
      USD: 2830,
    },
    SD: {
      INR: 302000,
      USD: 4440,
    },
    SE: {
      INR: 328000,
      USD: 4830,
    },
    CH: {
      INR: 328000,
      USD: 4830,
    },
    TW: {
      INR: 192000,
      USD: 2830,
    },
    TJ: {
      INR: 192000,
      USD: 2830,
    },
    TH: {
      INR: 192000,
      USD: 2830,
    },
    TG: {
      INR: 302000,
      USD: 4440,
    },
    TN: {
      INR: 302000,
      USD: 4440,
    },
    TR: {
      INR: 39000,
      USD: 580,
    },
    TM: {
      INR: 192000,
      USD: 2830,
    },
    UG: {
      INR: 302000,
      USD: 4440,
    },
    UA: {
      INR: 213000,
      USD: 3130,
    },
    AE: {
      INR: 165000,
      USD: 2430,
    },
    GB: {
      INR: 321000,
      USD: 4730,
    },
    US: {
      INR: 349000,
      USD: 5130,
    },
    UY: {
      INR: 349000,
      USD: 5130,
    },
    UZ: {
      INR: 192000,
      USD: 2830,
    },
    VN: {
      INR: 192000,
      USD: 2830,
    },
    YE: {
      INR: 187000,
      USD: 2760,
    },
    ZM: {
      INR: 302000,
      USD: 4440,
    },
    TZ: {
      INR: 302000,
      USD: 4440,
    },
    SZ: {
      INR: 302000,
      USD: 4440,
    },
    MK: {
      INR: 213000,
      USD: 3130,
    },
    DO: {
      INR: 349000,
      USD: 5130,
    },
    JM: {
      INR: 349000,
      USD: 5130,
    },
    CI: {
      INR: 302000,
      USD: 4440,
    },
    OTHER: {
      INR: 130000,
      USD: 1910,
    },
  },
  service: {
    AF: {
      INR: 226620,
      USD: 3333,
    },
    AL: {
      INR: 250200,
      USD: 3680,
    },
    DZ: {
      INR: 348864,
      USD: 5131,
    },
    AO: {
      INR: 348864,
      USD: 5131,
    },
    AR: {
      INR: 307560,
      USD: 4523,
    },
    AM: {
      INR: 250200,
      USD: 3680,
    },
    AU: {
      INR: 226620,
      USD: 3333,
    },
    AT: {
      INR: 379248,
      USD: 5578,
    },
    AZ: {
      INR: 250200,
      USD: 3680,
    },
    BH: {
      INR: 221820,
      USD: 3263,
    },
    BD: {
      INR: 226620,
      USD: 3333,
    },
    BY: {
      INR: 250200,
      USD: 3680,
    },
    BE: {
      INR: 379248,
      USD: 5578,
    },
    BJ: {
      INR: 348864,
      USD: 5131,
    },
    BO: {
      INR: 402360,
      USD: 5918,
    },
    BW: {
      INR: 348864,
      USD: 5131,
    },
    BR: {
      INR: 293844,
      USD: 4322,
    },
    BG: {
      INR: 250200,
      USD: 3680,
    },
    BF: {
      INR: 348864,
      USD: 5131,
    },
    BI: {
      INR: 348864,
      USD: 5131,
    },
    KH: {
      INR: 226620,
      USD: 3333,
    },
    CA: {
      INR: 107532,
      USD: 1582,
    },
    CM: {
      INR: 348864,
      USD: 5131,
    },
    TD: {
      INR: 348864,
      USD: 5131,
    },
    CL: {
      INR: 429348,
      USD: 6314,
    },
    CN: {
      INR: 226620,
      USD: 3333,
    },
    CO: {
      INR: 82764,
      USD: 1218,
    },
    CG: {
      INR: 348864,
      USD: 5131,
    },
    CR: {
      INR: 402360,
      USD: 5918,
    },
    HR: {
      INR: 250200,
      USD: 3680,
    },
    CZ: {
      INR: 250200,
      USD: 3680,
    },
    DK: {
      INR: 379248,
      USD: 5578,
    },
    VE: {
      INR: 402360,
      USD: 5918,
    },
    EC: {
      INR: 402360,
      USD: 5918,
    },
    EG: {
      INR: 596292,
      USD: 8769,
    },
    SV: {
      INR: 402360,
      USD: 5918,
    },
    ER: {
      INR: 348864,
      USD: 5131,
    },
    ET: {
      INR: 348864,
      USD: 5131,
    },
    FI: {
      INR: 379248,
      USD: 5578,
    },
    FR: {
      INR: 785880,
      USD: 11558,
    },
    GA: {
      INR: 348864,
      USD: 5131,
    },
    GM: {
      INR: 348864,
      USD: 5131,
    },
    GE: {
      INR: 250200,
      USD: 3680,
    },
    DE: {
      INR: 750528,
      USD: 11038,
    },
    GH: {
      INR: 348864,
      USD: 5131,
    },
    GR: {
      INR: 250200,
      USD: 3680,
    },
    GT: {
      INR: 402360,
      USD: 5918,
    },
    GW: {
      INR: 348864,
      USD: 5131,
    },
    HT: {
      INR: 402360,
      USD: 5918,
    },
    HN: {
      INR: 402360,
      USD: 5918,
    },
    HK: {
      INR: 226620,
      USD: 3333,
    },
    HU: {
      INR: 250200,
      USD: 3680,
    },
    IN: {
      INR: 35000,
      USD: 515,
    },
    ID: {
      INR: 196680,
      USD: 2893,
    },
    IQ: {
      INR: 221820,
      USD: 3263,
    },
    IE: {
      INR: 379248,
      USD: 5578,
    },
    IL: {
      INR: 188304,
      USD: 2770,
    },
    IT: {
      INR: 369108,
      USD: 5429,
    },
    JP: {
      INR: 226620,
      USD: 3333,
    },
    JO: {
      INR: 221820,
      USD: 3263,
    },
    KE: {
      INR: 348864,
      USD: 5131,
    },
    KW: {
      INR: 221820,
      USD: 3263,
    },
    LA: {
      INR: 226620,
      USD: 3333,
    },
    LV: {
      INR: 250200,
      USD: 3680,
    },
    LB: {
      INR: 221820,
      USD: 3263,
    },
    LS: {
      INR: 348864,
      USD: 5131,
    },
    LR: {
      INR: 348864,
      USD: 5131,
    },
    LY: {
      INR: 348864,
      USD: 5131,
    },
    LT: {
      INR: 250200,
      USD: 3680,
    },
    MG: {
      INR: 348864,
      USD: 5131,
    },
    MW: {
      INR: 348864,
      USD: 5131,
    },
    MY: {
      INR: 223153,
      USD: 3282,
    },
    ML: {
      INR: 348864,
      USD: 5131,
    },
    MR: {
      INR: 348864,
      USD: 5131,
    },
    MX: {
      INR: 122028,
      USD: 1795,
    },
    MD: {
      INR: 250200,
      USD: 3680,
    },
    MN: {
      INR: 226620,
      USD: 3333,
    },
    MA: {
      INR: 348864,
      USD: 5131,
    },
    MZ: {
      INR: 348864,
      USD: 5131,
    },
    NA: {
      INR: 348864,
      USD: 5131,
    },
    NP: {
      INR: 226620,
      USD: 3333,
    },
    NL: {
      INR: 813841,
      USD: 11969,
    },
    NZ: {
      INR: 226620,
      USD: 3333,
    },
    NI: {
      INR: 402360,
      USD: 5918,
    },
    NE: {
      INR: 348864,
      USD: 5131,
    },
    NG: {
      INR: 302520,
      USD: 4449,
    },
    NO: {
      INR: 379248,
      USD: 5578,
    },
    OM: {
      INR: 221820,
      USD: 3263,
    },
    PK: {
      INR: 154860,
      USD: 2278,
    },
    PA: {
      INR: 402360,
      USD: 5918,
    },
    PG: {
      INR: 226620,
      USD: 3333,
    },
    PY: {
      INR: 402360,
      USD: 5918,
    },
    PE: {
      INR: 187824,
      USD: 2763,
    },
    PH: {
      INR: 226620,
      USD: 3333,
    },
    PL: {
      INR: 250200,
      USD: 3680,
    },
    PT: {
      INR: 379248,
      USD: 5578,
    },
    PR: {
      INR: 402360,
      USD: 5918,
    },
    QA: {
      INR: 221820,
      USD: 3263,
    },
    RO: {
      INR: 250200,
      USD: 3680,
    },
    RU: {
      INR: 379788,
      USD: 5586,
    },
    RW: {
      INR: 348864,
      USD: 5131,
    },
    SA: {
      INR: 201324,
      USD: 2961,
    },
    SN: {
      INR: 348864,
      USD: 5131,
    },
    RS: {
      INR: 250200,
      USD: 3680,
    },
    SL: {
      INR: 348864,
      USD: 5131,
    },
    SG: {
      INR: 226620,
      USD: 3333,
    },
    SK: {
      INR: 250200,
      USD: 3680,
    },
    SI: {
      INR: 250200,
      USD: 3680,
    },
    SO: {
      INR: 348864,
      USD: 5131,
    },
    ZA: {
      INR: 177984,
      USD: 2618,
    },
    SS: {
      INR: 348864,
      USD: 5131,
    },
    ES: {
      INR: 354312,
      USD: 5211,
    },
    LK: {
      INR: 226620,
      USD: 3333,
    },
    SD: {
      INR: 348864,
      USD: 5131,
    },
    SE: {
      INR: 379248,
      USD: 5578,
    },
    CH: {
      INR: 379248,
      USD: 5578,
    },
    TW: {
      INR: 226620,
      USD: 3333,
    },
    TJ: {
      INR: 226620,
      USD: 3333,
    },
    TH: {
      INR: 226620,
      USD: 3333,
    },
    TG: {
      INR: 348864,
      USD: 5131,
    },
    TN: {
      INR: 348864,
      USD: 5131,
    },
    TR: {
      INR: 56389,
      USD: 830,
    },
    TM: {
      INR: 226620,
      USD: 3333,
    },
    UG: {
      INR: 348864,
      USD: 5131,
    },
    UA: {
      INR: 250200,
      USD: 3680,
    },
    AE: {
      INR: 196788,
      USD: 2894,
    },
    GB: {
      INR: 371376,
      USD: 5462,
    },
    US: {
      INR: 107532,
      USD: 1582,
    },
    UY: {
      INR: 402360,
      USD: 5918,
    },
    UZ: {
      INR: 226620,
      USD: 3333,
    },
    VN: {
      INR: 226620,
      USD: 3333,
    },
    YE: {
      INR: 221820,
      USD: 3263,
    },
    ZM: {
      INR: 348864,
      USD: 5131,
    },
    TZ: {
      INR: 348864,
      USD: 5131,
    },
    SZ: {
      INR: 348864,
      USD: 5131,
    },
    MK: {
      INR: 250200,
      USD: 3680,
    },
    DO: {
      INR: 402360,
      USD: 5918,
    },
    JM: {
      INR: 402360,
      USD: 5918,
    },
    CI: {
      INR: 348864,
      USD: 5131,
    },
    OTHER: {
      INR: 157380,
      USD: 2315,
    },
  },
  marketing: {
    AF: {
      INR: 673968,
      USD: 9912,
    },
    AL: {
      INR: 785964,
      USD: 11559,
    },
    DZ: {
      INR: 227964,
      USD: 3353,
    },
    AO: {
      INR: 227964,
      USD: 3353,
    },
    AR: {
      INR: 573516,
      USD: 8435,
    },
    AM: {
      INR: 785964,
      USD: 11559,
    },
    AU: {
      INR: 673968,
      USD: 9912,
    },
    AT: {
      INR: 550536,
      USD: 8097,
    },
    AZ: {
      INR: 785964,
      USD: 11559,
    },
    BH: {
      INR: 329916,
      USD: 4852,
    },
    BD: {
      INR: 673968,
      USD: 9912,
    },
    BY: {
      INR: 785964,
      USD: 11559,
    },
    BE: {
      INR: 550536,
      USD: 8097,
    },
    BJ: {
      INR: 227964,
      USD: 3353,
    },
    BO: {
      INR: 680484,
      USD: 10008,
    },
    BW: {
      INR: 227964,
      USD: 3353,
    },
    BR: {
      INR: 579696,
      USD: 8525,
    },
    BG: {
      INR: 785964,
      USD: 11559,
    },
    BF: {
      INR: 227964,
      USD: 3353,
    },
    BI: {
      INR: 227964,
      USD: 3353,
    },
    KH: {
      INR: 673968,
      USD: 9912,
    },
    CA: {
      INR: 249756,
      USD: 3673,
    },
    CM: {
      INR: 227964,
      USD: 3353,
    },
    TD: {
      INR: 227964,
      USD: 3353,
    },
    CL: {
      INR: 811620,
      USD: 11936,
    },
    CN: {
      INR: 673968,
      USD: 9912,
    },
    CO: {
      INR: 139932,
      USD: 2058,
    },
    CG: {
      INR: 227964,
      USD: 3353,
    },
    CR: {
      INR: 680484,
      USD: 10008,
    },
    HR: {
      INR: 785964,
      USD: 11559,
    },
    CZ: {
      INR: 785964,
      USD: 11559,
    },
    DK: {
      INR: 550536,
      USD: 8097,
    },
    VE: {
      INR: 680484,
      USD: 10008,
    },
    EC: {
      INR: 680484,
      USD: 10008,
    },
    EG: {
      INR: 973812,
      USD: 14321,
    },
    SV: {
      INR: 680484,
      USD: 10008,
    },
    ER: {
      INR: 227964,
      USD: 3353,
    },
    ET: {
      INR: 227964,
      USD: 3353,
    },
    FI: {
      INR: 550536,
      USD: 8097,
    },
    FR: {
      INR: 1289808,
      USD: 18968,
    },
    GA: {
      INR: 227964,
      USD: 3353,
    },
    GM: {
      INR: 227964,
      USD: 3353,
    },
    GE: {
      INR: 785964,
      USD: 11559,
    },
    DE: {
      INR: 1230876,
      USD: 18102,
    },
    GH: {
      INR: 227964,
      USD: 3353,
    },
    GR: {
      INR: 785964,
      USD: 11559,
    },
    GT: {
      INR: 680484,
      USD: 10008,
    },
    GW: {
      INR: 227964,
      USD: 3353,
    },
    HT: {
      INR: 680484,
      USD: 10008,
    },
    HN: {
      INR: 680484,
      USD: 10008,
    },
    HK: {
      INR: 673968,
      USD: 9912,
    },
    HU: {
      INR: 785964,
      USD: 11559,
    },
    IN: {
      INR: 81000,
      USD: 1192,
    },
    ID: {
      INR: 391332,
      USD: 5755,
    },
    IQ: {
      INR: 329916,
      USD: 4852,
    },
    IE: {
      INR: 550536,
      USD: 8097,
    },
    IL: {
      INR: 340452,
      USD: 5007,
    },
    IT: {
      INR: 637368,
      USD: 9374,
    },
    JP: {
      INR: 673968,
      USD: 9912,
    },
    JO: {
      INR: 329916,
      USD: 4852,
    },
    KE: {
      INR: 227964,
      USD: 3353,
    },
    KW: {
      INR: 329916,
      USD: 4852,
    },
    LA: {
      INR: 673968,
      USD: 9912,
    },
    LV: {
      INR: 785964,
      USD: 11559,
    },
    LB: {
      INR: 329916,
      USD: 4852,
    },
    LS: {
      INR: 227964,
      USD: 3353,
    },
    LR: {
      INR: 227964,
      USD: 3353,
    },
    LY: {
      INR: 227964,
      USD: 3353,
    },
    LT: {
      INR: 785964,
      USD: 11559,
    },
    MG: {
      INR: 227964,
      USD: 3353,
    },
    MW: {
      INR: 227964,
      USD: 3353,
    },
    MY: {
      INR: 786444,
      USD: 11566,
    },
    ML: {
      INR: 227964,
      USD: 3353,
    },
    MR: {
      INR: 227964,
      USD: 3353,
    },
    MX: {
      INR: 413256,
      USD: 6078,
    },
    MD: {
      INR: 785964,
      USD: 11559,
    },
    MN: {
      INR: 673968,
      USD: 9912,
    },
    MA: {
      INR: 227964,
      USD: 3353,
    },
    MZ: {
      INR: 227964,
      USD: 3353,
    },
    NA: {
      INR: 227964,
      USD: 3353,
    },
    NP: {
      INR: 673968,
      USD: 9912,
    },
    NL: {
      INR: 1434936,
      USD: 21102,
    },
    NZ: {
      INR: 673968,
      USD: 9912,
    },
    NI: {
      INR: 680484,
      USD: 10008,
    },
    NE: {
      INR: 227964,
      USD: 3353,
    },
    NG: {
      INR: 484213,
      USD: 7121,
    },
    NO: {
      INR: 550536,
      USD: 8097,
    },
    OM: {
      INR: 329916,
      USD: 4852,
    },
    PK: {
      INR: 446197,
      USD: 6562,
    },
    PA: {
      INR: 680484,
      USD: 10008,
    },
    PG: {
      INR: 673968,
      USD: 9912,
    },
    PY: {
      INR: 680484,
      USD: 10008,
    },
    PE: {
      INR: 648432,
      USD: 9536,
    },
    PH: {
      INR: 673968,
      USD: 9912,
    },
    PL: {
      INR: 785964,
      USD: 11559,
    },
    PT: {
      INR: 550536,
      USD: 8097,
    },
    PR: {
      INR: 680484,
      USD: 10008,
    },
    QA: {
      INR: 329916,
      USD: 4852,
    },
    RO: {
      INR: 785964,
      USD: 11559,
    },
    RU: {
      INR: 735204,
      USD: 10812,
    },
    RW: {
      INR: 227964,
      USD: 3353,
    },
    SA: {
      INR: 386700,
      USD: 5687,
    },
    SN: {
      INR: 227964,
      USD: 3353,
    },
    RS: {
      INR: 785964,
      USD: 11559,
    },
    SL: {
      INR: 227964,
      USD: 3353,
    },
    SG: {
      INR: 673968,
      USD: 9912,
    },
    SK: {
      INR: 785964,
      USD: 11559,
    },
    SI: {
      INR: 785964,
      USD: 11559,
    },
    SO: {
      INR: 227964,
      USD: 3353,
    },
    ZA: {
      INR: 363852,
      USD: 5351,
    },
    SS: {
      INR: 227964,
      USD: 3353,
    },
    ES: {
      INR: 570528,
      USD: 8391,
    },
    LK: {
      INR: 673968,
      USD: 9912,
    },
    SD: {
      INR: 227964,
      USD: 3353,
    },
    SE: {
      INR: 550536,
      USD: 8097,
    },
    CH: {
      INR: 550536,
      USD: 8097,
    },
    TW: {
      INR: 673968,
      USD: 9912,
    },
    TJ: {
      INR: 673968,
      USD: 9912,
    },
    TH: {
      INR: 673968,
      USD: 9912,
    },
    TG: {
      INR: 227964,
      USD: 3353,
    },
    TN: {
      INR: 227964,
      USD: 3353,
    },
    TR: {
      INR: 125868,
      USD: 1851,
    },
    TM: {
      INR: 673968,
      USD: 9912,
    },
    UG: {
      INR: 227964,
      USD: 3353,
    },
    UA: {
      INR: 785964,
      USD: 11559,
    },
    AE: {
      INR: 329088,
      USD: 4840,
    },
    GB: {
      INR: 649956,
      USD: 9559,
    },
    US: {
      INR: 249756,
      USD: 3673,
    },
    UY: {
      INR: 680484,
      USD: 10008,
    },
    UZ: {
      INR: 673968,
      USD: 9912,
    },
    VN: {
      INR: 673968,
      USD: 9912,
    },
    YE: {
      INR: 329916,
      USD: 4852,
    },
    ZM: {
      INR: 227964,
      USD: 3353,
    },
    TZ: {
      INR: 227964,
      USD: 3353,
    },
    SZ: {
      INR: 227964,
      USD: 3353,
    },
    MK: {
      INR: 785964,
      USD: 11559,
    },
    DO: {
      INR: 680484,
      USD: 10008,
    },
    JM: {
      INR: 680484,
      USD: 10008,
    },
    CI: {
      INR: 227964,
      USD: 3353,
    },
    OTHER: {
      INR: 560976,
      USD: 8250,
    },
  },
  authentication: {
    AF: {
      INR: 403488,
      USD: 5934,
    },
    AL: {
      INR: 519708,
      USD: 7643,
    },
    DZ: {
      INR: 156696,
      USD: 2305,
    },
    AO: {
      INR: 156696,
      USD: 2305,
    },
    AR: {
      INR: 352656,
      USD: 5187,
    },
    AM: {
      INR: 519708,
      USD: 7643,
    },
    AU: {
      INR: 403488,
      USD: 5934,
    },
    AT: {
      INR: 362364,
      USD: 5329,
    },
    AZ: {
      INR: 519708,
      USD: 7643,
    },
    BH: {
      INR: 186360,
      USD: 2741,
    },
    BD: {
      INR: 403488,
      USD: 5934,
    },
    BY: {
      INR: 519708,
      USD: 7643,
    },
    BE: {
      INR: 362364,
      USD: 5329,
    },
    BJ: {
      INR: 156696,
      USD: 2305,
    },
    BO: {
      INR: 420972,
      USD: 6191,
    },
    BW: {
      INR: 156696,
      USD: 2305,
    },
    BR: {
      INR: 307044,
      USD: 4516,
    },
    BG: {
      INR: 519708,
      USD: 7643,
    },
    BF: {
      INR: 156696,
      USD: 2305,
    },
    BI: {
      INR: 156696,
      USD: 2305,
    },
    KH: {
      INR: 403488,
      USD: 5934,
    },
    CA: {
      INR: 148668,
      USD: 2187,
    },
    CM: {
      INR: 156696,
      USD: 2305,
    },
    TD: {
      INR: 156696,
      USD: 2305,
    },
    CL: {
      INR: 493704,
      USD: 7261,
    },
    CN: {
      INR: 403488,
      USD: 5934,
    },
    CO: {
      INR: 97284,
      USD: 1431,
    },
    CG: {
      INR: 156696,
      USD: 2305,
    },
    CR: {
      INR: 420972,
      USD: 6191,
    },
    HR: {
      INR: 519708,
      USD: 7643,
    },
    CZ: {
      INR: 519708,
      USD: 7643,
    },
    DK: {
      INR: 362364,
      USD: 5329,
    },
    VE: {
      INR: 420972,
      USD: 6191,
    },
    EC: {
      INR: 420972,
      USD: 6191,
    },
    EG: {
      INR: 573852,
      USD: 8439,
    },
    SV: {
      INR: 420972,
      USD: 6191,
    },
    ER: {
      INR: 156696,
      USD: 2305,
    },
    ET: {
      INR: 156696,
      USD: 2305,
    },
    FI: {
      INR: 362364,
      USD: 5329,
    },
    FR: {
      INR: 638088,
      USD: 9384,
    },
    GA: {
      INR: 156696,
      USD: 2305,
    },
    GM: {
      INR: 156696,
      USD: 2305,
    },
    GE: {
      INR: 519708,
      USD: 7643,
    },
    DE: {
      INR: 705696,
      USD: 10378,
    },
    GH: {
      INR: 156696,
      USD: 2305,
    },
    GR: {
      INR: 519708,
      USD: 7643,
    },
    GT: {
      INR: 420972,
      USD: 6191,
    },
    GW: {
      INR: 156696,
      USD: 2305,
    },
    HT: {
      INR: 420972,
      USD: 6191,
    },
    HN: {
      INR: 420972,
      USD: 6191,
    },
    HK: {
      INR: 403488,
      USD: 5934,
    },
    HU: {
      INR: 519708,
      USD: 7643,
    },
    IN: {
      INR: 35000,
      USD: 515,
    },
    ID: {
      INR: 293748,
      USD: 4320,
    },
    IQ: {
      INR: 186360,
      USD: 2741,
    },
    IE: {
      INR: 362364,
      USD: 5329,
    },
    IL: {
      INR: 178416,
      USD: 2624,
    },
    IT: {
      INR: 362244,
      USD: 5328,
    },
    JP: {
      INR: 403488,
      USD: 5934,
    },
    JO: {
      INR: 186360,
      USD: 2741,
    },
    KE: {
      INR: 156696,
      USD: 2305,
    },
    KW: {
      INR: 186360,
      USD: 2741,
    },
    LA: {
      INR: 403488,
      USD: 5934,
    },
    LV: {
      INR: 519708,
      USD: 7643,
    },
    LB: {
      INR: 186360,
      USD: 2741,
    },
    LS: {
      INR: 156696,
      USD: 2305,
    },
    LR: {
      INR: 156696,
      USD: 2305,
    },
    LY: {
      INR: 156696,
      USD: 2305,
    },
    LT: {
      INR: 519708,
      USD: 7643,
    },
    MG: {
      INR: 156696,
      USD: 2305,
    },
    MW: {
      INR: 156696,
      USD: 2305,
    },
    MY: {
      INR: 188328,
      USD: 2770,
    },
    ML: {
      INR: 156696,
      USD: 2305,
    },
    MR: {
      INR: 156696,
      USD: 2305,
    },
    MX: {
      INR: 240444,
      USD: 3536,
    },
    MD: {
      INR: 519708,
      USD: 7643,
    },
    MN: {
      INR: 403488,
      USD: 5934,
    },
    MA: {
      INR: 156696,
      USD: 2305,
    },
    MZ: {
      INR: 156696,
      USD: 2305,
    },
    NA: {
      INR: 156696,
      USD: 2305,
    },
    NP: {
      INR: 403488,
      USD: 5934,
    },
    NL: {
      INR: 663408,
      USD: 9756,
    },
    NZ: {
      INR: 403488,
      USD: 5934,
    },
    NI: {
      INR: 420972,
      USD: 6191,
    },
    NE: {
      INR: 156696,
      USD: 2305,
    },
    NG: {
      INR: 282720,
      USD: 4158,
    },
    NO: {
      INR: 362364,
      USD: 5329,
    },
    OM: {
      INR: 186360,
      USD: 2741,
    },
    PK: {
      INR: 230352,
      USD: 3388,
    },
    PA: {
      INR: 420972,
      USD: 6191,
    },
    PG: {
      INR: 403488,
      USD: 5934,
    },
    PY: {
      INR: 420972,
      USD: 6191,
    },
    PE: {
      INR: 361512,
      USD: 5317,
    },
    PH: {
      INR: 403488,
      USD: 5934,
    },
    PL: {
      INR: 519708,
      USD: 7643,
    },
    PT: {
      INR: 362364,
      USD: 5329,
    },
    PR: {
      INR: 420972,
      USD: 6191,
    },
    QA: {
      INR: 186360,
      USD: 2741,
    },
    RO: {
      INR: 519708,
      USD: 7643,
    },
    RU: {
      INR: 407484,
      USD: 5993,
    },
    RW: {
      INR: 156696,
      USD: 2305,
    },
    SA: {
      INR: 228960,
      USD: 3368,
    },
    SN: {
      INR: 156696,
      USD: 2305,
    },
    RS: {
      INR: 519708,
      USD: 7643,
    },
    SL: {
      INR: 156696,
      USD: 2305,
    },
    SG: {
      INR: 403488,
      USD: 5934,
    },
    SK: {
      INR: 519708,
      USD: 7643,
    },
    SI: {
      INR: 519708,
      USD: 7643,
    },
    SO: {
      INR: 156696,
      USD: 2305,
    },
    ZA: {
      INR: 188556,
      USD: 2773,
    },
    SS: {
      INR: 156696,
      USD: 2305,
    },
    ES: {
      INR: 330588,
      USD: 4862,
    },
    LK: {
      INR: 403488,
      USD: 5934,
    },
    SD: {
      INR: 156696,
      USD: 2305,
    },
    SE: {
      INR: 362364,
      USD: 5329,
    },
    CH: {
      INR: 362364,
      USD: 5329,
    },
    TW: {
      INR: 403488,
      USD: 5934,
    },
    TJ: {
      INR: 403488,
      USD: 5934,
    },
    TH: {
      INR: 403488,
      USD: 5934,
    },
    TG: {
      INR: 156696,
      USD: 2305,
    },
    TN: {
      INR: 156696,
      USD: 2305,
    },
    TR: {
      INR: 103212,
      USD: 1518,
    },
    TM: {
      INR: 403488,
      USD: 5934,
    },
    UG: {
      INR: 156696,
      USD: 2305,
    },
    UA: {
      INR: 519708,
      USD: 7643,
    },
    AE: {
      INR: 186396,
      USD: 2742,
    },
    GB: {
      INR: 344988,
      USD: 5074,
    },
    US: {
      INR: 148668,
      USD: 2187,
    },
    UY: {
      INR: 420972,
      USD: 6191,
    },
    UZ: {
      INR: 403488,
      USD: 5934,
    },
    VN: {
      INR: 403488,
      USD: 5934,
    },
    YE: {
      INR: 186360,
      USD: 2741,
    },
    ZM: {
      INR: 156696,
      USD: 2305,
    },
    TZ: {
      INR: 156696,
      USD: 2305,
    },
    SZ: {
      INR: 156696,
      USD: 2305,
    },
    MK: {
      INR: 519708,
      USD: 7643,
    },
    DO: {
      INR: 420972,
      USD: 6191,
    },
    JM: {
      INR: 420972,
      USD: 6191,
    },
    CI: {
      INR: 156696,
      USD: 2305,
    },
    OTHER: {
      INR: 297504,
      USD: 4376,
    },
  },
  utility: {
    AF: {
      INR: 445236,
      USD: 6548,
    },
    AL: {
      INR: 574116,
      USD: 8443,
    },
    DZ: {
      INR: 170772,
      USD: 2512,
    },
    AO: {
      INR: 170772,
      USD: 2512,
    },
    AR: {
      INR: 388824,
      USD: 5718,
    },
    AM: {
      INR: 574116,
      USD: 8443,
    },
    AU: {
      INR: 445236,
      USD: 6548,
    },
    AT: {
      INR: 399300,
      USD: 5873,
    },
    AZ: {
      INR: 574116,
      USD: 8443,
    },
    BH: {
      INR: 204144,
      USD: 3003,
    },
    BD: {
      INR: 445236,
      USD: 6548,
    },
    BY: {
      INR: 574116,
      USD: 8443,
    },
    BE: {
      INR: 399300,
      USD: 5873,
    },
    BJ: {
      INR: 170772,
      USD: 2512,
    },
    BO: {
      INR: 464244,
      USD: 6828,
    },
    BW: {
      INR: 170772,
      USD: 2512,
    },
    BR: {
      INR: 337824,
      USD: 4968,
    },
    BG: {
      INR: 574116,
      USD: 8443,
    },
    BF: {
      INR: 170772,
      USD: 2512,
    },
    BI: {
      INR: 170772,
      USD: 2512,
    },
    KH: {
      INR: 445236,
      USD: 6548,
    },
    CA: {
      INR: 161856,
      USD: 2381,
    },
    CM: {
      INR: 170772,
      USD: 2512,
    },
    TD: {
      INR: 170772,
      USD: 2512,
    },
    CL: {
      INR: 545220,
      USD: 8018,
    },
    CN: {
      INR: 445236,
      USD: 6548,
    },
    CO: {
      INR: 104761,
      USD: 1541,
    },
    CG: {
      INR: 170772,
      USD: 2512,
    },
    CR: {
      INR: 464244,
      USD: 6828,
    },
    HR: {
      INR: 574116,
      USD: 8443,
    },
    CZ: {
      INR: 574116,
      USD: 8443,
    },
    DK: {
      INR: 399300,
      USD: 5873,
    },
    VE: {
      INR: 464244,
      USD: 6828,
    },
    EC: {
      INR: 464244,
      USD: 6828,
    },
    EG: {
      INR: 634284,
      USD: 9328,
    },
    SV: {
      INR: 464244,
      USD: 6828,
    },
    ER: {
      INR: 170772,
      USD: 2512,
    },
    ET: {
      INR: 170772,
      USD: 2512,
    },
    FI: {
      INR: 399300,
      USD: 5873,
    },
    FR: {
      INR: 705648,
      USD: 10378,
    },
    GA: {
      INR: 170772,
      USD: 2512,
    },
    GM: {
      INR: 170772,
      USD: 2512,
    },
    GE: {
      INR: 574116,
      USD: 8443,
    },
    DE: {
      INR: 780432,
      USD: 11477,
    },
    GH: {
      INR: 170772,
      USD: 2512,
    },
    GR: {
      INR: 574116,
      USD: 8443,
    },
    GT: {
      INR: 464244,
      USD: 6828,
    },
    GW: {
      INR: 170772,
      USD: 2512,
    },
    HT: {
      INR: 464244,
      USD: 6828,
    },
    HN: {
      INR: 464244,
      USD: 6828,
    },
    HK: {
      INR: 445236,
      USD: 6548,
    },
    HU: {
      INR: 574116,
      USD: 8443,
    },
    IN: {
      INR: 35000,
      USD: 515,
    },
    ID: {
      INR: 205836,
      USD: 3027,
    },
    IQ: {
      INR: 204144,
      USD: 3003,
    },
    IE: {
      INR: 399300,
      USD: 5873,
    },
    IL: {
      INR: 195348,
      USD: 2873,
    },
    IT: {
      INR: 399168,
      USD: 5871,
    },
    JP: {
      INR: 445236,
      USD: 6548,
    },
    JO: {
      INR: 204144,
      USD: 3003,
    },
    KE: {
      INR: 170772,
      USD: 2512,
    },
    KW: {
      INR: 204144,
      USD: 3003,
    },
    LA: {
      INR: 445236,
      USD: 6548,
    },
    LV: {
      INR: 574116,
      USD: 8443,
    },
    LB: {
      INR: 204144,
      USD: 3003,
    },
    LS: {
      INR: 170772,
      USD: 2512,
    },
    LR: {
      INR: 170772,
      USD: 2512,
    },
    LY: {
      INR: 170772,
      USD: 2512,
    },
    LT: {
      INR: 574116,
      USD: 8443,
    },
    MG: {
      INR: 170772,
      USD: 2512,
    },
    MW: {
      INR: 170772,
      USD: 2512,
    },
    MY: {
      INR: 205920,
      USD: 3029,
    },
    ML: {
      INR: 170772,
      USD: 2512,
    },
    MR: {
      INR: 170772,
      USD: 2512,
    },
    MX: {
      INR: 263820,
      USD: 3880,
    },
    MD: {
      INR: 574116,
      USD: 8443,
    },
    MN: {
      INR: 445236,
      USD: 6548,
    },
    MA: {
      INR: 170772,
      USD: 2512,
    },
    MZ: {
      INR: 170772,
      USD: 2512,
    },
    NA: {
      INR: 170772,
      USD: 2512,
    },
    NP: {
      INR: 445236,
      USD: 6548,
    },
    NL: {
      INR: 733788,
      USD: 10791,
    },
    NZ: {
      INR: 445236,
      USD: 6548,
    },
    NI: {
      INR: 464244,
      USD: 6828,
    },
    NE: {
      INR: 170772,
      USD: 2512,
    },
    NG: {
      INR: 310800,
      USD: 4571,
    },
    NO: {
      INR: 399300,
      USD: 5873,
    },
    OM: {
      INR: 204144,
      USD: 3003,
    },
    PK: {
      INR: 252612,
      USD: 3715,
    },
    PA: {
      INR: 464244,
      USD: 6828,
    },
    PG: {
      INR: 445236,
      USD: 6548,
    },
    PY: {
      INR: 464244,
      USD: 6828,
    },
    PE: {
      INR: 398604,
      USD: 5862,
    },
    PH: {
      INR: 445236,
      USD: 6548,
    },
    PL: {
      INR: 574116,
      USD: 8443,
    },
    PT: {
      INR: 399300,
      USD: 5873,
    },
    PR: {
      INR: 464244,
      USD: 6828,
    },
    QA: {
      INR: 204144,
      USD: 3003,
    },
    RO: {
      INR: 574116,
      USD: 8443,
    },
    RU: {
      INR: 449437,
      USD: 6610,
    },
    RW: {
      INR: 170772,
      USD: 2512,
    },
    SA: {
      INR: 251400,
      USD: 3698,
    },
    SN: {
      INR: 170772,
      USD: 2512,
    },
    RS: {
      INR: 574116,
      USD: 8443,
    },
    SL: {
      INR: 170772,
      USD: 2512,
    },
    SG: {
      INR: 445236,
      USD: 6548,
    },
    SK: {
      INR: 574116,
      USD: 8443,
    },
    SI: {
      INR: 574116,
      USD: 8443,
    },
    SO: {
      INR: 170772,
      USD: 2512,
    },
    ZA: {
      INR: 206173,
      USD: 3032,
    },
    SS: {
      INR: 170772,
      USD: 2512,
    },
    ES: {
      INR: 363984,
      USD: 5353,
    },
    LK: {
      INR: 445236,
      USD: 6548,
    },
    SD: {
      INR: 170772,
      USD: 2512,
    },
    SE: {
      INR: 399300,
      USD: 5873,
    },
    CH: {
      INR: 399300,
      USD: 5873,
    },
    TW: {
      INR: 445236,
      USD: 6548,
    },
    TJ: {
      INR: 445236,
      USD: 6548,
    },
    TH: {
      INR: 445236,
      USD: 6548,
    },
    TG: {
      INR: 170772,
      USD: 2512,
    },
    TN: {
      INR: 170772,
      USD: 2512,
    },
    TR: {
      INR: 111792,
      USD: 1644,
    },
    TM: {
      INR: 445236,
      USD: 6548,
    },
    UG: {
      INR: 170772,
      USD: 2512,
    },
    UA: {
      INR: 574116,
      USD: 8443,
    },
    AE: {
      INR: 204180,
      USD: 3003,
    },
    GB: {
      INR: 379992,
      USD: 5589,
    },
    US: {
      INR: 161856,
      USD: 2381,
    },
    UY: {
      INR: 464244,
      USD: 6828,
    },
    UZ: {
      INR: 445236,
      USD: 6548,
    },
    VN: {
      INR: 445236,
      USD: 6548,
    },
    YE: {
      INR: 204144,
      USD: 3003,
    },
    ZM: {
      INR: 170772,
      USD: 2512,
    },
    TZ: {
      INR: 170772,
      USD: 2512,
    },
    SZ: {
      INR: 170772,
      USD: 2512,
    },
    MK: {
      INR: 574116,
      USD: 8443,
    },
    DO: {
      INR: 464244,
      USD: 6828,
    },
    JM: {
      INR: 464244,
      USD: 6828,
    },
    CI: {
      INR: 170772,
      USD: 2512,
    },
    OTHER: {
      INR: 327132,
      USD: 4811,
    },
  },
};

module.exports = {
  AISENSY_WCC_PLAN,
};
