import React, { Component } from "react";
import axios from "axios";
import {
  Grid,
  Typography,
  withStyles,
  Box,
  TextField,
  Avatar,
  Button,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { connect } from "react-redux";
import { modifyUser, signOut } from "../Signin/loginState";
import { URL, GOOGLE_CLIENT_ID } from "../../config/config";
import { GoogleLogout } from "react-google-login";

class AgentProfilePage extends Component {
  state = {
    displayName: "",
    password: "",
    confirmPassword: "",
    changeName: false,
    changePassword: false,
    emailHelper: "",
    showPassword: false,
    status: "",
    isLoading: false,
  };
  timeout = null;

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setEmailMessage = () => {
    if (this.state.changeName) {
      this.setState({ emailHelper: "You cannot change your email address!" });
    }
  };

  changePassword = () => {
    if (this.state.changeName) {
      this.setState({ changePassword: true });
    }
  };

  onEdit = () => {
    this.setState({ changeName: true });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  onCancel = () => {
    this.setState({
      changeName: false,
      changePassword: false,
      displayName: "",
      password: "",
      confirmPassword: "",
      emailHelper: "",
    });
  };

  onSave = async () => {
    const { displayName, password, confirmPassword } = this.state;
    this.setState({ isLoading: true });
    axios
      .post(URL + "/auth/modify-agent", {
        displayName: displayName ? displayName : this.props.agent.displayName,
        password,
        confirmPassword,
        _id: this.props.agent.id,
      })
      .then((response) => {
        const { token } = response.data;
        localStorage.setItem("jwtToken", token);
        this.props.modifyUser(token);

        this.setState(
          {
            statusMessage: "Details Succesfully updated!",
            success: true,
            isLoading: false,
          },
          () => {
            this.onCancel();
            this.timeout = setTimeout(() => {
              this.setState({ statusMessage: "" });
            }, 10000);
          }
        );
      })
      .catch((error) => {
        console.error(error);
        this.setState(
          {
            statusMessage:
              "Your request was not successful. some error Occured!",
            success: false,
            isLoading: false,
          },
          () => {
            this.onCancel();
            this.timeout = setTimeout(() => {
              this.setState({ statusMessage: "" });
            }, 10000);
          }
        );
      });
  };

  onGoogleLogoutSuccess = (props) => {
    const { agent, signOut } = this.props;
    signOut(agent.id);
  };

  onGoogleLogoutFailure = (props) => {
    console.log({ props });
  };

  render() {
    const { password, confirmPassword, displayName, showPassword } = this.state;
    const { changeName, changePassword, emailHelper } = this.state;
    const { success, statusMessage, isLoading } = this.state;
    const { classes, agent } = this.props;
    return (
      <div className={classes.root}>
        <Grid container justify="center">
          <Grid item xs={12} md={10} lg={8}>
            <Box pb={{ xs: 2, md: 4 }} pl={{ xs: 1, md: 0 }}>
              <Typography variant="h2" gutterBottom>
                Profile
              </Typography>
              <Typography variant="body1" gutterBottom color="textSecondary">
                View and manage your personal details
              </Typography>
            </Box>
            <Box p={{ xs: 4, md: 3 }}>
              <Grid
                item
                xs={12}
                md={10}
                container
                direction="row-reverse"
                justify="flex-start"
              >
                <Grid item md={6} container direction="column" justify="center">
                  <Box
                    pb={{ xs: 4, md: 2 }}
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Avatar className={classes.avatar}>
                      {agent.displayName.substring(0, 1).toUpperCase()}
                    </Avatar>
                  </Box>
                  {!changeName && (
                    <Button color="primary" onClick={this.onEdit}>
                      Edit
                    </Button>
                  )}
                  <Grid container justify="center">
                    {!isLoading ? (
                      changeName ? (
                        <Grid container justify="center">
                          <Box mt="30px">
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={this.onCancel}
                            >
                              Cancel
                            </Button>
                          </Box>
                          <Box ml="30px" mt="30px">
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={this.onSave}
                            >
                              Save
                            </Button>
                          </Box>
                        </Grid>
                      ) : !agent.googleId ? (
                        <Button
                          variant="outlined"
                          color="inherit"
                          onClick={() => this.props.signOut(agent.id)}
                        >
                          Sign Out
                        </Button>
                      ) : (
                        <GoogleLogout
                          clientId={GOOGLE_CLIENT_ID}
                          buttonText="Logout"
                          onLogoutSuccess={this.onGoogleLogoutSuccess}
                          onFailure={this.onGoogleLogoutFailure}
                          className={classes.googleButtonContainer}
                        ></GoogleLogout>
                      )
                    ) : (
                      <CircularProgress />
                    )}
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <Box m="20px"></Box>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container direction="column" alignItems="flex-start">
                    <Typography variant="h4" align="center">
                      Display Name
                    </Typography>
                    <Box width="100%" boxSizing="border-box" mt="0px" mb="10px">
                      <TextField
                        variant="outlined"
                        margin="dense"
                        type="string"
                        value={changeName ? displayName : agent.displayName}
                        placeholder={agent.displayName}
                        disabled={!changeName}
                        name="displayName"
                        fullWidth
                        onChange={this.onChange}
                      />
                    </Box>
                  </Grid>
                  {agent.name && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        Name
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          type="string"
                          value={agent.name}
                          placeholder={agent.name}
                          disabled={true}
                          name="name"
                          fullWidth
                        />
                      </Box>
                    </Grid>
                  )}
                  {agent.email && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        Email
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          name="email"
                          disabled={true}
                          value={agent.email}
                          onClick={this.setEmailMessage}
                          type="email"
                          fullWidth
                          helperText={emailHelper}
                        />
                      </Box>
                    </Grid>
                  )}
                  <Grid container direction="column" alignItems="flex-start">
                    <Typography variant="h4" align="center">
                      Password
                    </Typography>
                    <Box width="100%" boxSizing="border-box" mt="0px" mb="10px">
                      <TextField
                        variant="outlined"
                        autoComplete="off"
                        margin="dense"
                        name="password"
                        disabled={!changeName}
                        onChange={this.onChange}
                        onFocus={this.changePassword}
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        value={changePassword ? password : "***********"}
                        placeholder="***********"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword}
                                disabled={!changeName}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        inputProps={{ autocomplete: "off" }}
                      />
                    </Box>
                  </Grid>
                  {changePassword && (
                    <Grid container direction="column" alignItems="flex-start">
                      <Typography variant="h4" align="center">
                        Confirm password
                      </Typography>
                      <Box
                        width="100%"
                        boxSizing="border-box"
                        mt="0px"
                        mb="10px"
                      >
                        <TextField
                          variant="outlined"
                          margin="dense"
                          name="confirmPassword"
                          disabled={!changePassword}
                          onChange={this.onChange}
                          fullWidth
                          placeholder="***********"
                          type={showPassword ? "text" : "password"}
                          value={confirmPassword}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid container direction="column" alignItems="flex-end">
                    {statusMessage && (
                      <Typography
                        style={{
                          width: "100%",
                          color: success ? "green" : "red",
                        }}
                        align="left"
                        variant="body1"
                      >
                        {statusMessage}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    background: "whitesmoke",
    boxSizing: "border-box",
    overflowY: "auto",
    paddingTop: "60px",
    [theme.breakpoints.down("md")]: {
      height: "100%",
      paddingTop: "70px",
      paddingLeft: theme.spacing(1) * 1.5,
      paddingRight: theme.spacing(1) * 1.5,
    },
  },
  progress: {
    position: "fixed",
    top: "8em",
    right: "44.5vw",
  },
  avatar: {
    height: "2em",
    width: "2em",
    fontSize: "4em",
  },
  googleButtonContainer: {
    width: "100%",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
    height: "50px",
    backgroundColor: "lightgrey",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    paddingLeft: "15px",
    justifyContent: "center",
  },
});

const connectedAgentProfilePage = connect(
  (state) => ({
    agent: state.login.user,
  }),
  { modifyUser, signOut }
)(AgentProfilePage);

export default withStyles(styles)(connectedAgentProfilePage);
