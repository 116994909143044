import React, { useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Box,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  IconButton,
  makeStyles,
  FormControl,
  FormHelperText,
  TextField,
  NativeSelect,
  Input,
  Button,
  InputAdornment,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import { ADMIN_KEY, URL as clientURL } from "../../config/config";
import { createRandomString } from "../../helpers/hashing";
import { FileCopy, Publish } from "@material-ui/icons";
import { timeZones } from "../../config/timezone";
import dialCodes from "../../config/dialCodes";
import { TENANT_ID } from "../../config/config";

const useStyles = makeStyles({
  title: {
    padding: "2px 24px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "1rem",
  },
  selectFieldInput: {
    borderRadius: "8px",
    backgroundColor: "rgb(240,240,240)",
    padding: "12px",
  },
  copyIcon: {
    fontSize: "16px",
    color: "#606060",
    cursor: "pointer",
    "&:hover": {
      color: "#000",
    },
  },
});

const CreationDetails = (props) => {
  const { classes, partnerDetails, partner, agent, onClose, copy, setCopy } =
    props;
  return (
    <Box width={700}>
      <DialogTitle className={classes.title}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3" component="p">
              Setup Details
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="primary">{copy && "Copied!"}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <form className={classes.form} style={{ paddingBottom: 16 }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Partner API details</Typography>
            <IconButton
              onClick={() => {
                setCopy(true);
                navigator.clipboard.writeText(
                  `Partner API details\n${JSON.stringify(
                    partnerDetails,
                    null,
                    2
                  )}`
                );
                setTimeout(() => setCopy(false), 1500);
              }}
            >
              <FileCopy className={classes.copyIcon} />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs>
              <pre
                style={{
                  background: "rgb(240, 240, 240)",
                  padding: 16,
                  borderRadius: 8,
                  margin: 0,
                }}
              >
                {JSON.stringify(partnerDetails, null, 2)}
              </pre>
            </Grid>
          </Grid>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h4">Partner Dashboard Credentials</Typography>

            <IconButton
              onClick={() => {
                setCopy(true);
                navigator.clipboard.writeText(
                  `Partner Dashboard Credentials\nWebsite: ${
                    TENANT_ID
                      ? "https://www.partner.whatsapp.redingtongroup.com"
                      : "https://www.partner.aisensy.com"
                  } \nLogin Username: ${
                    partner.loginUsername
                  }\nLogin Password: ${partner.loginPassword}`
                );
                setTimeout(() => setCopy(false), 1500);
              }}
            >
              <FileCopy className={classes.copyIcon} />
            </IconButton>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Username
                </Typography>
                <TextField value={partner.loginUsername} disabled />
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Password
                </Typography>
                <TextField value={partner.loginPassword} disabled />
              </FormControl>
            </Grid>
          </Grid>
          {partner.type === "WHITELABEL" && (
            <>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h4">
                  Whitelabel Dashboard Credentials
                </Typography>

                <IconButton
                  onClick={() => {
                    setCopy(true);
                    navigator.clipboard.writeText(
                      `Whitelabel Dashboard Credentials\nLogin Username: ${agent.email}\nLogin Password: ${agent.password}`
                    );
                    setTimeout(() => setCopy(false), 1500);
                  }}
                >
                  <FileCopy className={classes.copyIcon} />
                </IconButton>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs>
                  <FormControl fullWidth>
                    <Typography variant="body1" gutterBottom>
                      Login Username
                    </Typography>
                    <TextField value={agent.email} disabled />
                  </FormControl>
                </Grid>
                <Grid item xs>
                  <FormControl fullWidth>
                    <Typography variant="body1" gutterBottom>
                      Login Password
                    </Typography>
                    <TextField value={agent.password} disabled />
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </form>
      </DialogContent>
    </Box>
  );
};

const AddBusinessForm = (props) => {
  const {
    agent,
    updateAgentState,
    classes,
    assistant,
    setAssistant,
    error,
    setError,
    onClose,
    loading,
    createBusiness,
    generatePassword,
  } = props;
  return (
    <Box width={700}>
      <DialogTitle className={classes.title}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3" component="p">
              Create a new business & project
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="error">{error}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={createBusiness} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Display Name
                </Typography>
                <TextField
                  value={agent.display_name}
                  onChange={(e) =>
                    updateAgentState("display_name", e.target.value)
                  }
                />
                <FormHelperText>
                  Enter Display Name of the business
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Company
                </Typography>
                <TextField
                  value={agent.company}
                  onChange={(e) => updateAgentState("company", e.target.value)}
                />
                <FormHelperText>Enter name of the company</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Email
                </Typography>
                <TextField
                  value={agent.email}
                  onChange={(e) => updateAgentState("email", e.target.value)}
                />
                <FormHelperText>
                  Enter the email that will be used to login
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Password
                </Typography>
                <TextField
                  value={agent.password}
                  onChange={(e) => updateAgentState("password", e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Button
                        size="small"
                        color="primary"
                        onClick={() => generatePassword("agent")}
                      >
                        Generate
                      </Button>
                    ),
                  }}
                />
                <FormHelperText>
                  Enter the pasword that will be used to login
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Contact Number
                </Typography>
                <TextField
                  value={agent.contact}
                  onChange={(e) => {
                    if (e.target.value.includes(" "))
                      setError("No space is allowed in number");
                    else if (e.target.value.includes("+"))
                      setError("Enter country code without +");
                    else updateAgentState("contact", e.target.value);
                  }}
                />
                <FormHelperText>
                  Enter the mobile number of the business, eg: 91xxx
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Timezone
                </Typography>
                <NativeSelect
                  value={agent.timezone}
                  onChange={(e) => updateAgentState("timezone", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  {/* { label: "International Date Line West (Etc/GMT+12)", value: "-12:00" }, */}
                  {timeZones.map((e) => {
                    const arr = e.label.split("(");
                    const timezone =
                      arr[arr.length - 1].slice(0, -1) + ` GMT${e.value}`;
                    return <option value={timezone}>{e.label}</option>;
                  })}
                </NativeSelect>
                <FormHelperText>
                  Select the timezone of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Company size
                </Typography>
                <NativeSelect
                  value={agent.companySize}
                  onChange={(e) =>
                    updateAgentState("companySize", e.target.value)
                  }
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  {companySize.map((e) => {
                    return <option value={e}>💻 {e}</option>;
                  })}
                </NativeSelect>
                <FormHelperText>Select the company size</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Balance Currency
                </Typography>
                <NativeSelect
                  value={agent.currency}
                  onChange={(e) => updateAgentState("currency", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </NativeSelect>
                <FormHelperText>
                  Select the business balance currency
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Project Name
                </Typography>
                <TextField
                  value={assistant}
                  onChange={(e) => setAssistant(e.target.value)}
                />
                <FormHelperText>Enter the name of the project</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            onClick={createBusiness}
          >
            {!loading ? "Create Business & Project" : "Creating..."}
          </Button>
        </form>
      </DialogContent>
    </Box>
  );
};

function AddPartner({ onClose, onSuccess }) {
  const classes = useStyles();
  const [partnerDetails, setPartnerDetails] = useState();

  const [error, setError] = useState("");

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    name: "",
    brandName: "",
    loginUsername: "",
    loginPassword: "",
    type: "WHITELABEL",
    planType: "GOLD",
    centralBalance: 0,
    currency: "INR",
    whiteLabelUrl: "",
    webhookUrl: "",
    brandLogo: null,
    brandFullLogo: null,
    faviconUrl: null,
    loadingGifUrl: null,
    email: "",
    timezone: "Asia/Calcutta GMT+05:30",
    partnershipFee: 0,
    minAllowedBalance: 0,
    managerNumber: null,
    isoCode: "IN",
    appId: "",
    appSecret: "",
    solutionId: "",
    businessManagerId: "",
    systemUserAccessToken: "",
  });

  const [agent, setAgentState] = useState({
    display_name: "",
    email: "",
    password: "",
    company: "",
    contact: "",
    timezone: "Asia/Calcutta GMT+05:30",
    companySize: "10 - 20",
    currency: "INR",
  });
  const [assistant, setAssistant] = useState("");
  const [completed, setCompleted] = useState(false);
  const [copy, setCopy] = useState(false);

  /**
   *
   * @param {string} key
   * @param {string | number} value
   */
  const updateState = (key, value) =>
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const updateAgentState = (key, value) =>
    setAgentState((prevState) => ({
      ...prevState,
      [key]: value,
    }));

  const generatePassword = (type) => {
    const password = createRandomString();
    if (type === "agent") updateAgentState("password", password);
    else updateState("loginPassword", password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const formData = new FormData();
      const normalKeys = [
        "name",
        "brandName",
        "loginUsername",
        "loginPassword",
        "type",
        "planType",
        "currency",
        "webhookUrl",
        "email",
        "timezone",
        "managerNumber",
        "isoCode",
        "appId",
        "appSecret",
        "solutionId",
        "businessManagerId",
        "systemUserAccessToken",
      ];
      for (const key of normalKeys) {
        formData.append(key, state[key]);
      }
      formData.append("whiteLabelUrl", "https://www." + state.whiteLabelUrl);
      const imgKeys = [
        "brandLogo",
        "faviconUrl",
        "brandFullLogo",
        "loadingGifUrl",
      ];
      for (const key of imgKeys) {
        formData.append("files", state[key]);
      }
      const balanceKeys = [
        "centralBalance",
        "minAllowedBalance",
        "partnershipFee",
      ];
      for (const key of balanceKeys) {
        formData.append(key, state[key] * 100000);
      }
      const { data } = await axios.post(
        `${clientURL}/superadmin/create-new-partner`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setPartnerDetails(data);
      if (state.type === "DIRECT") {
        setCompleted(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong";
      setError(message);
      setLoading(false);
    }
  };

  const createBusiness = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    const { partnerId, apiKey } = partnerDetails || {};
    try {
      const { data } = await axios.post(
        clientURL + `/partner-apis/v1/partner/${partnerId}/business/`,
        agent,
        {
          headers: TENANT_ID
            ? {
                "X-Partner-API-Key": apiKey,
                "x-admin-token-key": ADMIN_KEY,
                Authorization: "",
              }
            : {
                "X-AiSensy-Partner-API-Key": apiKey,
                "x-aisensy-admin-token-key": ADMIN_KEY,
                Authorization: "",
              },
        }
      );
      const { id: businessId } = data;
      await axios.post(
        clientURL +
          `/partner-apis/v1/partner/${partnerId}/business/${businessId}/project`,
        {
          name: assistant,
        },
        {
          headers: TENANT_ID
            ? {
                "X-Partner-API-Key": apiKey,
                Authorization: "",
              }
            : {
                "X-AiSensy-Partner-API-Key": apiKey,
                Authorization: "",
              },
        }
      );
      setCompleted(true);
      setLoading(false);
    } catch (error) {
      const message =
        error.response?.data?.message ||
        error.messsage ||
        "Something went wrong";
      setError(message);
      setLoading(false);
    }
  };

  const handleFileUpload = (e, field) => {
    if (e.target.files) {
      if (e.target.files[0].size >= 2000000) {
        setError("Invalid Operation. File size > 2MB");
        return;
      }
      updateState(field, e.target.files[0]);
    }
  };

  const UploadFile = ({ field }) => {
    return (
      <>
        <Button
          component="label"
          variant="outlined"
          startIcon={state[field] ? null : <Publish />}
          sx={{ marginRight: "1rem" }}
          disabled={state.type === "DIRECT" && field !== "brandLogo"}
        >
          {state[field] ? (
            <img
              alt="preview image"
              width="auto"
              height="24px"
              src={URL.createObjectURL(state[field])}
            />
          ) : (
            "Upload"
          )}
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => handleFileUpload(e, field)}
          />
        </Button>
      </>
    );
  };

  return completed ? (
    <CreationDetails
      agent={agent}
      classes={classes}
      partner={state}
      partnerDetails={partnerDetails}
      onClose={onClose}
      copy={copy}
      setCopy={setCopy}
    />
  ) : partnerDetails ? (
    <AddBusinessForm
      agent={agent}
      updateAgentState={updateAgentState}
      classes={classes}
      assistant={assistant}
      setAssistant={setAssistant}
      error={error}
      setError={setError}
      onClose={onClose}
      loading={loading}
      createBusiness={createBusiness}
      generatePassword={generatePassword}
    />
  ) : (
    <Box width={700}>
      <DialogTitle className={classes.title}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h3" component="p">
              Add new Partner
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography color="error">{error}</Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Name
                </Typography>
                <TextField
                  value={state.name}
                  onChange={(e) => updateState("name", e.target.value)}
                />
                <FormHelperText>Enter name of the partner</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Brand name
                </Typography>
                <TextField
                  value={state.brandName}
                  onChange={(e) => updateState("brandName", e.target.value)}
                />
                <FormHelperText>Enter Brand name of the partner</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Username
                </Typography>
                <TextField
                  value={state.loginUsername}
                  onChange={(e) => updateState("loginUsername", e.target.value)}
                />
                <FormHelperText>
                  Enter the username that will be used to login
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Login Password
                </Typography>
                <TextField
                  value={state.loginPassword}
                  onChange={(e) => updateState("loginPassword", e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <Button
                        size="small"
                        color="primary"
                        onClick={generatePassword}
                      >
                        Generate
                      </Button>
                    ),
                  }}
                />
                <FormHelperText>
                  Enter the pasword that will be used to login
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Central Balance
                </Typography>
                <TextField
                  value={state.centralBalance}
                  type="number"
                  onChange={(e) =>
                    updateState("centralBalance", e.target.valueAsNumber)
                  }
                />
                <FormHelperText>
                  Enter the central balance of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Balance Currency
                </Typography>
                <NativeSelect
                  value={state.currency}
                  onChange={(e) => updateState("currency", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </NativeSelect>
                <FormHelperText>
                  Select the currency of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Partner Type
                </Typography>
                <NativeSelect
                  value={state.type}
                  onChange={(e) => updateState("type", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="DIRECT">💻 Direct</option>
                  <option value="WHITELABEL">🌐 Whitelabel</option>
                </NativeSelect>
                <FormHelperText>
                  Select the type of the partner (Whitelabel or Direct)
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Partner Plan Type
                </Typography>
                <NativeSelect
                  value={state.planType}
                  disabled={state.type === "DIRECT"}
                  onChange={(e) => updateState("planType", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  <option value="SILVER">🪙 Silver</option>
                  <option value="GOLD">🏆 Gold</option>
                  <option value="DIAMOND">💎 Diamond</option>
                </NativeSelect>
                <FormHelperText>
                  Select the plan type (Silver, Gold, Diamond)
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Min Allowed Central Balance
                </Typography>
                <TextField
                  value={state.minAllowedBalance}
                  type="number"
                  onChange={(e) =>
                    updateState("minAllowedBalance", e.target.valueAsNumber)
                  }
                />
                <FormHelperText>
                  Enter the min allowed central balance of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Timezone
                </Typography>
                <NativeSelect
                  value={state.timezone}
                  onChange={(e) => updateState("timezone", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  {/* { label: "International Date Line West (Etc/GMT+12)", value: "-12:00" }, */}
                  {timeZones.map((e) => {
                    const arr = e.label.split("(");
                    const timezone =
                      arr[arr.length - 1].slice(0, -1) + ` GMT${e.value}`;
                    return <option value={timezone}>{e.label}</option>;
                  })}
                </NativeSelect>
                <FormHelperText>
                  Select the timezone of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              {state.type === "WHITELABEL" && (
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Whitelabel URL
                  </Typography>
                  <TextField
                    value={state.whiteLabelUrl}
                    onChange={(e) =>
                      updateState("whiteLabelUrl", e.target.value)
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          https://www.
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FormHelperText>
                    Enter the url of the whitelabel
                  </FormHelperText>
                </FormControl>
              )}
              {state.type === "DIRECT" && (
                <FormControl fullWidth>
                  <Typography variant="body1" gutterBottom>
                    Webhook URL
                  </Typography>
                  <TextField
                    value={state.webhookUrl}
                    onChange={(e) => updateState("webhookUrl", e.target.value)}
                  />
                  <FormHelperText>
                    Enter the webhook url to send the events to Partner
                  </FormHelperText>
                </FormControl>
              )}
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Manager Number
                </Typography>
                <TextField
                  value={state.managerNumber}
                  onChange={(e) => {
                    if (e.target.value.includes(" "))
                      setError("No space is allowed in number");
                    else if (e.target.value.includes("+"))
                      setError("Enter country code without +");
                    else updateState("managerNumber", e.target.value);
                  }}
                />
                <FormHelperText>
                  Enter the manager number of the partner, , eg: 91xxx
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Brand Logo
                </Typography>
                <UploadFile field={"brandLogo"} />
                <FormHelperText>
                  Select the logo of the brand(50 X 50)
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Brand Full Logo
                </Typography>
                <UploadFile field={"brandFullLogo"} />
                <FormHelperText>
                  Select the wide brand logo with name(100 X 70)
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Favicon
                </Typography>
                <UploadFile field={"faviconUrl"} />
                <FormHelperText>
                  Select the favicon for the whitelabel(32 X 32)
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Loader
                </Typography>
                <UploadFile field={"loadingGifUrl"} />
                <FormHelperText>
                  Select the loader for the whitelabel(70 X 70)
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Email
                </Typography>
                <TextField
                  value={state.email}
                  onChange={(e) => updateState("email", e.target.value)}
                />
                <FormHelperText>Enter email of the partner</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Partnership Fee
                </Typography>
                <TextField
                  value={state.partnershipFee}
                  type="number"
                  onChange={(e) =>
                    updateState("partnershipFee", e.target.value)
                  }
                />
                <FormHelperText>
                  Enter annual maintenance fee of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  App ID
                </Typography>
                <TextField
                  value={state.appId}
                  onChange={(e) => updateState("appId", e.target.value)}
                />
                <FormHelperText>
                  Enter Application ID of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  App Secret
                </Typography>
                <TextField
                  value={state.appSecret}
                  onChange={(e) => updateState("appSecret", e.target.value)}
                />
                <FormHelperText>
                  Enter the App Secret of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Solution ID
                </Typography>
                <TextField
                  value={state.solutionId}
                  onChange={(e) => updateState("solutionId", e.target.value)}
                />
                <FormHelperText>
                  Enter ID of the solution agreement with the partner
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  FB Business Manager ID
                </Typography>
                <TextField
                  value={state.businessManagerId}
                  onChange={(e) =>
                    updateState("businessManagerId", e.target.value)
                  }
                />
                <FormHelperText>
                  Enter FB Business Manager ID of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  System User Token
                </Typography>
                <TextField
                  value={state.systemUserAccessToken}
                  onChange={(e) =>
                    updateState("systemUserAccessToken", e.target.value)
                  }
                />
                <FormHelperText>
                  Enter the System User Token of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs>
              <FormControl fullWidth>
                <Typography variant="body1" gutterBottom>
                  Country
                </Typography>
                <NativeSelect
                  value={state.isoCode}
                  onChange={(e) => updateState("isoCode", e.target.value)}
                  input={
                    <Input
                      classes={{
                        input: classes.selectFieldInput,
                      }}
                    />
                  }
                >
                  {dialCodes.map((e) => {
                    return <option value={e.isoCode}>{e.name}</option>;
                  })}
                </NativeSelect>
                <FormHelperText>
                  Select the country of the partner
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
          >
            {!loading ? "Add Partner" : "Adding Partner..."}
          </Button>
        </form>
      </DialogContent>
    </Box>
  );
}

const companySize = [
  "1 - 10 Employees",
  "10 - 20 Employees",
  "20 - 50 Employees",
  "50 - 200 Employees",
  "200 - 500 Employees",
  "500 +",
];

const connectedAddPartner = connect((state) => ({
  agent: state.login.user,
}))(AddPartner);

export default connectedAddPartner;
