import React, { Component, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

const CRMDetails = lazy(() => import("./CRMDetails/CRMDetails"));
const Kanban = lazy(() => import("./CRMDetails/KanbanComponent/Kanban"));

class CRMPage extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/crm" component={Kanban} />
          <Route path="/crm/:assistantId" component={CRMDetails} />
          <Route component={() => <Redirect to="/error" />} />
        </Switch>
      </Suspense>
    );
  }
}

export default CRMPage;
