import React, { Component } from "react";
import {
  withStyles,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { connect } from "react-redux";
import { Autocomplete } from "@material-ui/lab";
import { AddOutlined, Clear } from "@material-ui/icons";
import axios from "axios";
// import Banner from "../../static/createpage.png";
import LANGUAGES from "../../config/languages";
import templateMsgHelper from "../../helpers/templateMsgHelper";
// import ImagePreview from "../../commons/ChatBox/MessageTypes/ImageMessage";
import { URL } from "../../config/config";
import TemplatePreview from "./TemplatePreview";
import dialCodes from "../../config/dialCodes";

const TEMPLATE_CATEGORIES = ["UTILITY", "MARKETING", "AUTHENTICATION"];

const MSG_TYPES = [
  {
    type: "TEXT",
    name: "Text",
  },
  {
    type: "IMAGE",
    name: "Image",
  },
  {
    type: "VIDEO",
    name: "Video",
  },
  {
    type: "FILE",
    name: "Document",
  },
];

class CreateTemplateLibraryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actionType: "None",
      callToAction: [],
      quickReplies: [],
      templateLabel: "",
      templateCategory: "",
      templateType: "",
      templateLanguage: "",
      templateName: "",
      templateFormat: "",
      templateSample: "",
      templateMediaURL: "",
      templateCTAUrl: "",
      templateHeader: "",
      templateFooter: "",
      templateIconUrl: "",
      templateBanner: "",
      templateTags: [],
      templatePreviewName: "",
      isSubmitting: false,
      err: "",
      success: false,
      ctaUrlParam: 0,
      parameters: [],
    };
  }

  componentDidMount = () => {
    const { inDialog, template } = this.props;

    if (template) {
      const format = template.format.split(" | ")[0];
      for (let i = 0; i < template.callToAction.length; i++) {
        if (
          template.callToAction[i].type === "Phone Number" &&
          template.callToAction[i].extension
        ) {
          const ext = template.callToAction[i].extension.length;
          const len = template.callToAction[i].buttonValue.length;
          template.callToAction[i].buttonValue = template.callToAction[
            i
          ].buttonValue.substring(ext, len);
        }
      }

      this.setState({
        templateLabel: template.label,
        templateCategory: template.category,
        templateType: template.type,
        templateLanguage: template.language || template.templateLanguage,
        templateName: template.name,
        templateFormat: format,
        templateSample: template.sampleMessage,
        actionType: template.actionType,
        callToAction: template.callToAction,
        quickReplies: template.quickReplies,
        templateMediaURL: template.templateMediaURL,
        templateCTAUrl: template.templateCTAUrl,
        parameters: template.templateParams,
        templateHeader: template.headerText ?? "",
        templateFooter: template.footerText ?? "",
        templateIconUrl: template.iconUrl,
        templateBanner: template.bannerText,
        templateTags: template.tags.join(", "),
        templatePreviewName: template.previewName ?? "",
      });

      const action = template.callToAction.find((i) => i.type === "URL");
      if (action) {
        this.setState({
          ctaUrlParam: (action.buttonValue.match(/\{\{[0-9]+\}\}/g) || [])
            .length,
        });
      }
    }
  };

  handleInput = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  extractParams = (e) => {
    const params = [];
    const regx = /{{([^}]+)}}/g;
    let curMatch;

    while ((curMatch = regx.exec(e.target.value))) {
      params.push("");
    }

    this.setState({
      templateFormat: e.target.value,
      parameters: params,
    });
  };

  handleParamsInput = (index, elem) => {
    const params = this.state.parameters;
    params[index] = elem.target.value;

    this.setState({
      parameters: params,
    });
  };

  handleAutoComplete = (fieldName, value, reason) => {
    if (reason === "select-option") {
      this.setState({ [fieldName]: value });
    }
  };

  handleRadioInput = (e) => {
    this.setState({
      actionType: e.target.value,
      callToAction:
        e.target.value === "CAT"
          ? [{ type: "", buttonValue: "", buttonTitle: "" }]
          : [],
      quickReplies: e.target.value !== "CAT" ? [""] : [],
      ctaUrlParam: 0,
      templateCTAUrl: "",
    });
  };

  catAutoComplete = (index, value, reason, isExtension) => {
    if (reason === "select-option") {
      const callToAction = [...this.state.callToAction];
      if (isExtension) callToAction[index]["extension"] = value;
      else callToAction[index]["type"] = value;
      if (value === "URL") {
        this.setState({
          ctaUrlParam: (
            callToAction[index]["buttonValue"].match(/\{\{[0-9]+\}\}/g) || []
          ).length,
        });
      } else {
        if (index === 0) {
          this.setState({
            ctaUrlParam: 0,
            templateCTAUrl: "",
          });
        }
      }
      this.setState({ callToAction });
    }
  };

  catInput = (index, elem) => {
    const callToAction = [...this.state.callToAction];
    let value = elem.target.value;

    if (callToAction[index].type === "Phone number") {
      value = this.state.phoneExtension + value;
    }

    callToAction[index][elem.target.name] = value;
    if (
      elem.target.name === "buttonValue" &&
      callToAction[index].type === "URL"
    ) {
      this.setState({
        ctaUrlParam: (value.match(/\{\{[0-9]+\}\}/g) || []).length,
      });
    }
    this.setState({ callToAction });
  };

  quickReplyInput = (index, elem) => {
    const quickReplies = [...this.state.quickReplies];
    quickReplies[index] = elem.target.value;

    this.setState({ quickReplies });
  };

  addQuickReply = (index, elem) => {
    const quickReplies = [...this.state.quickReplies, ""];
    this.setState({ quickReplies });
  };

  removeQuickReply = (index, elem) => {
    let quickReplies = [...this.state.quickReplies];
    quickReplies = quickReplies.filter((e, i) => i !== index);
    this.setState({ quickReplies });
  };

  addCAT = () => {
    const newAction = {
      type: "",
      buttonValue: "",
      buttonTitle: "",
    };
    const callToAction = this.state.callToAction;
    this.setState({ callToAction: [...callToAction, newAction] });
  };

  removeCAT = (index) => {
    let callToAction = [...this.state.callToAction];
    callToAction = callToAction.filter((e, i) => i !== index);
    this.setState({ callToAction });
  };

  validateTemplate = () => {
    const t = this.state;
    const validBracePattern = /\{\{[0-9]+\}\}/g;
    const validTextPattern = /^[a-zA-Z0-9].*$/; // string starting with alphabet & non-empty
    const buttonTextPattern = /^(?!\s*$).{0,20}$/; // string with atleast non-space character, maxlength 20
    const alphanumericPattern = /^[a-z0-9_][a-z0-9_]*$/; // string starting with alphanumeric & non-empty
    const validNumberPattern = /^\d+$/;

    if (!TEMPLATE_CATEGORIES.some((e) => e === t.templateCategory)) {
      return { valid: false, msg: "Select template category" };
    }
    if (!MSG_TYPES.some((e) => e.type === t.templateType)) {
      return { valid: false, msg: "Select template type" };
    }
    if (!LANGUAGES.some((e) => e === t.templateLanguage)) {
      return { valid: false, msg: "Select template language" };
    }
    if (!alphanumericPattern.test(t.templateName)) {
      return {
        valid: false,
        msg: "Template name is required field, only alphanumeric characters & underscores without spaces are allowed.",
      };
    }
    if (t.templateHeader) {
      // check header for length
      if (t.templateHeader.length > 60) {
        return {
          valid: false,
          msg: "Template header length over 60 characters.",
        };
      }
      //check if header contains params
      if (t.templateHeader.match(validBracePattern)) {
        return {
          valid: false,
          msg: "Template header cannot contain paramters",
        };
      }
    }
    if (t.templateFooter) {
      //check footer doesn't exceed char limit
      if (t.templateFooter.length > 60) {
        return {
          valid: false,
          msg: "Template footer length over 60 characters.",
        };
      }
      //check if footer contains params
      if (t.templateFooter.match(validBracePattern)) {
        return {
          valid: false,
          msg: "Template footer cannot contain paramters",
        };
      }
    }

    if (!t.templatePreviewName) {
      return {
        valid: false,
        msg: "Template preview name cannot be empty",
      };
    }

    if (!t.templateFormat) {
      return { valid: false, msg: "Template format is required field" };
    }
    const result = templateMsgHelper.validateFormat(t.templateFormat, "format");
    if (!result.valid) {
      return result;
    }

    for (const params of t.parameters) {
      if (!params)
        return {
          valid: false,
          msg: "Please provide sample values for all the paramters",
        };
    }

    if (t.templateType !== "TEXT") {
      if (!t.templateMediaURL) {
        return {
          valid: false,
          msg: `Sample media URL is required for template type ${t.templateType}`,
        };
      }
      if (!templateMsgHelper.validateUrl(t.templateMediaURL)) {
        return {
          valid: false,
          msg: `Invalid Sample media URL`,
        };
      }
    }

    if (!templateMsgHelper.validateUrl(t.templateIconUrl)) {
      return {
        valid: false,
        msg: `Invalid icon media URL`,
      };
    }

    if (t.templateTags.length <= 0)
      return {
        valid: false,
        msg: `Please enter atleast one tag`,
      };

    // validate interactive actions
    if (t.templateName === "shopify_cod_order_confirmation") {
      if (t.quickReplies.length !== 2) {
        return { valid: false, msg: "Need two quick reply" };
      }
    }
    if (t.actionType === "QuickReplies") {
      if (t.quickReplies.length === 0) {
        return { valid: false, msg: "Need atleast one quick reply" };
      }
      const isNotValid = t.quickReplies.some((e) => !buttonTextPattern.test(e)); // check for empty quick reply
      if (isNotValid) {
        return {
          valid: false,
          msg: "Quick replies must be non-empty & max 20 characters with spaces are allowed.",
        };
      }
      const uniqueSet = new Set(t.quickReplies.map((i) => i.trim()));
      if (t.quickReplies.length !== uniqueSet.size)
        return {
          valid: false,
          msg: "Each Quick Reply button must have unique text.",
        };
    }
    if (t.actionType === "CAT") {
      const uniqueSet = new Set(
        t.callToAction.map((i) => i.buttonTitle.trim())
      );
      if (t.callToAction.length !== uniqueSet.size)
        return {
          valid: false,
          msg: "Each Call-To-Action button must have unique text.",
        };
      if (this.state.ctaUrlParam > 1) {
        return { valid: false, msg: "Only one param allowed in CTA URL" };
      }
      if (t.callToAction.length === 0) {
        return { valid: false, msg: "Need atleast one call to action button" };
      }
      const isNotValid = t.callToAction.some((e) => {
        console.log(e);
        if (e.type === "Phone Number") {
          return (
            !validTextPattern.test(e.type) ||
            !buttonTextPattern.test(e.buttonTitle) ||
            !validNumberPattern.test(e.extension ?? "" + e.buttonValue)
          );
        }
        if (e.type === "URL") {
          return (
            !validTextPattern.test(e.type) ||
            !buttonTextPattern.test(e.buttonTitle) ||
            !templateMsgHelper.validateUrl(e.buttonValue)
          );
        }
        return true;
      });
      if (isNotValid) {
        return { valid: false, msg: "Call to action field invalid" };
      }
      const interativeURLAction = t.callToAction.find((e) => e.type === "URL");
      if (interativeURLAction && this.state.ctaUrlParam > 0) {
        if (!t.templateCTAUrl) {
          return {
            valid: false,
            msg: "Sample CTA URL is necessery for URL type actions.",
          };
        }
        if (!templateMsgHelper.validateUrl(t.templateCTAUrl)) {
          return {
            valid: false,
            msg: "Invalid Sample CTA URL.",
          };
        }
        const compare = templateMsgHelper.compareFormatAndSample(
          interativeURLAction.buttonValue,
          t.templateCTAUrl
        );
        if (!compare.valid) {
          return {
            valid: false,
            msg: "Sample action URL doesn't match with template URL format",
          };
        }
        const bodyParamCount = (
          this.state.templateFormat.match(/\{\{[0-9]+\}\}/g) || []
        ).length;
        const ctaDynamicParam =
          interativeURLAction.buttonValue.match(/\{\{[0-9]+\}\}/g) || [];
        if (ctaDynamicParam.length) {
          if (!ctaDynamicParam[0].includes(bodyParamCount + 1)) {
            return {
              valid: false,
              msg: "Invalid parameter in dynamic CTA URL",
            };
          }
        }
      }
    }
    // return { valid: true, msg: "All fields are validated" };
    return { valid: true, msg: "All fields are validated" };
  };

  autoFillAndSubmit = () => {
    //  Fill sample CTA and Media URLS
    const { templateType, callToAction, ctaUrlParam } = this.state;
    const interativeURLAction = callToAction.find((e) => e.type === "URL");

    const sampleMediaUrl =
      templateType === "IMAGE"
        ? "https://aisensy-demo-media.s3.ap-south-1.amazonaws.com/demo-image.png"
        : templateType === "VIDEO"
        ? "https://aisensy-demo-media.s3.ap-south-1.amazonaws.com/demo-video.mp4"
        : templateType === "FILE"
        ? "https://aisensy-demo-media.s3.ap-south-1.amazonaws.com/demo-doc.pdf"
        : "";

    const sampleCTAUrl =
      interativeURLAction && ctaUrlParam > 0
        ? interativeURLAction.buttonValue.replace(
            /\{\{[0-9]+\}\}/g,
            (match) => "[events]"
          )
        : "";

    this.setState(
      {
        templateMediaURL: sampleMediaUrl,
        templateCTAUrl: sampleCTAUrl,
      },
      this.onSubmit
    );
  };

  generateTemplate = () => {
    let formattedString = this.state.templateFormat;
    const formattedSampleMsg = ((string, replacementArray) => {
      return string.replace(/{{(\d+)}}/g, function (match, g1) {
        return `[${replacementArray[g1 - 1]}]`;
      });
    })(this.state.templateFormat, this.state.parameters);

    for (const reply of this.state.quickReplies) {
      formattedString += ` | [${reply}]`;
    }

    for (const action of this.state.callToAction) {
      formattedString += ` | [${action.buttonTitle},${action.buttonValue}]`;
    }

    return {
      label: this.state.templateName,
      category: this.state.templateCategory,
      type: this.state.templateType,
      language: this.state.templateLanguage,
      name: this.state.templateName,
      format: formattedString,
      sampleMessage: formattedSampleMsg,
      actionType: this.state.actionType,
      callToAction: this.state.callToAction,
      quickReplies: this.state.quickReplies,
      sampleMediaUrl: this.state.templateMediaURL,
      sampleCTAUrl: this.state.templateCTAUrl,
      headerText: this.state.templateHeader,
      footerText: this.state.templateFooter,
      iconUrl: this.state.templateIconUrl,
      tags:
        this.state.templateTags.length > 0
          ? this.state.templateTags.split(",").map((item) => item.trim())
          : this.state.templateTags,
      bannerText: this.state.templateBanner,
      previewName: this.state.templatePreviewName,
      templateParams: this.state.parameters,
    };
  };

  onSubmit = () => {
    const result = this.validateTemplate();
    const { onComplete } = this.props;

    if (!result.valid) {
      this.setState({ success: false, err: result.msg });
      return;
    } else {
      const t = this.state;
      this.setState({ err: "", isSubmitting: true, success: false });
      const template = {
        ...this.generateTemplate(),
        format: t.templateFormat,
        callToAction: t.callToAction.sort((a, b) => (a.type < b.type ? -1 : 1)),
        sampleCTAUrl: t.ctaUrlParam > 0 ? t.templateCTAUrl : "",
      };

      //filter action buttons
      const callToActionBtns = JSON.parse(
        JSON.stringify(template.callToAction)
      );
      for (let i = 0; i < callToActionBtns.length; i++) {
        if (
          callToActionBtns[i].type === "Phone Number" &&
          callToActionBtns[i].extension
        ) {
          callToActionBtns[i].buttonValue =
            callToActionBtns[i].extension + callToActionBtns[i].buttonValue;
        }
        // delete callToActionBtns[i]["extension"];
      }

      template.callToAction = callToActionBtns;

      let url = URL + "/superadmin/create-library-template";

      if (this.props.template) {
        template._id = this.props.template._id;
        url = URL + "/superadmin/update-library-template";
      }
      axios
        .post(url, template)
        .then((response) => {
          this.setState({
            err: "",
            isSubmitting: false,
            success: true,
          });

          typeof onComplete === "function" &&
            onComplete(this.state.templateName, this.state.templateType);
        })
        .catch((error) => {
          //   trackEvent({
          //     event: "template-submission-failed",
          //     properties: {
          //       reason: error?.response?.data?.errorMessage || "UNIDENTIFIED",
          //       from: "backend",
          //     },
          //   });
          console.log(error?.response?.data?.errorMessage);
          this.setState({
            err:
              error?.response?.data?.errorMessage ||
              "Oops! Something went wrong.",
            isSubmitting: false,
            success: false,
          });
        });
    }
  };

  render() {
    const { classes, inDialog, template } = this.props;
    return (
      <Box
        className={classes.root}
        py={{ xs: 2, md: inDialog ? 0 : 8 }}
        px={{ xs: 2, md: inDialog ? 2 : 6 }}
      >
        {/* Category and Language */}
        <Box my={4}>
          <Grid container spacing={6} justify="space-between">
            <Grid item xs={12} md={6} container>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Template Category
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  Your template should fall under one of these categories.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={TEMPLATE_CATEGORIES.map((option) => option)}
                  onChange={(e, value, reason) =>
                    this.handleAutoComplete("templateCategory", value, reason)
                  }
                  value={this.state.templateCategory}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      InputProps={{ ...params.InputProps }}
                      placeholder="Select message categories"
                      name="categories"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} container>
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  Template Language
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  You will need to specify the language in which message
                  template is submitted.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disableClearable
                  options={LANGUAGES.map((option) => option)}
                  onChange={(e, value, reason) =>
                    this.handleAutoComplete("templateLanguage", value, reason)
                  }
                  value={this.state.templateLanguage}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={classes.textField}
                      InputProps={{ ...params.InputProps }}
                      placeholder="Select message language"
                      name="language"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {/* Other fields and preview */}
        <Box>
          <Grid container spacing={6} justify="space-between">
            {/* Template Name  */}
            <Grid item xs={12} md={6} container>
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Preview Name
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your template preview name. Upto 20 characters are allowed.
                    <br /> e.g. - Abandoned Cart
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="templatePreviewName"
                    className={classes.textArea}
                    placeholder="Enter name"
                    onChange={this.handleInput}
                    value={this.state.templatePreviewName}
                    disabled={inDialog}
                    FormHelperTextProps={{
                      className: classes.characterCounter,
                    }}
                    helperText={`${this.state.templatePreviewName.length}/20`}
                    inputProps={{ maxLength: 20 }}
                  />
                </Grid>
              </Box>
              {/* Template ID  */}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template name
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your unique name.Only lowercase alphanumeric characters &
                    underscores without spaces are allowed.
                    <br /> e.g. - abandoned_cart.
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    name="templateName"
                    className={classes.textArea}
                    placeholder="Enter id here..."
                    onChange={this.handleInput}
                    value={this.state.templateName}
                    disabled={false}
                  />
                </Grid>
              </Box>
              {/* Icon URL */}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Icon URL
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your message preview icon. Only a valid url is allowed.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={1}
                    rowsMax={20}
                    name="templateIconUrl"
                    className={classes.textArea}
                    placeholder="Enter your url in here..."
                    disabled={false}
                    value={this.state.templateIconUrl}
                    onChange={this.handleInput}
                  />
                </Grid>
              </Box>
              {/* Template Header */}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Type
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your template type should fall under one of these
                    categories.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disableClearable
                    options={MSG_TYPES.map((option) => option.type)}
                    onChange={(e, value, reason) => {
                      this.setState({
                        templateMediaURL: "",
                        templateHeader: "",
                      });
                      this.handleAutoComplete("templateType", value, reason);
                    }}
                    value={this.state.templateType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select message type"
                      />
                    )}
                  />
                </Grid>
                {this.state.templateType === "TEXT" && (
                  <Box mt={8}>
                    <Grid item xs={12}>
                      <Typography variant="h4" gutterBottom>
                        Template Header Text
                        <span className={classes.optional}>(Optional)</span>
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        gutterBottom
                      >
                        Header Text is optional and upto 60 characters are
                        allowed.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="templateHeader"
                        className={classes.textArea}
                        placeholder="Enter header text here"
                        inputProps={{ maxLength: 60 }}
                        onChange={this.handleInput}
                        value={this.state.templateHeader}
                        disabled={inDialog}
                        style={{ marginTop: "8px" }}
                        FormHelperTextProps={{
                          className: classes.characterCounter,
                        }}
                        helperText={`${this.state.templateHeader.length}/60`}
                      />
                    </Grid>
                  </Box>
                )}
              </Box>

              {/* Tags */}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Tags
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your template tags separated by commas
                    <br /> e.g. - ecommerce,abandoned_cart
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={1}
                    rowsMax={20}
                    name="templateTags"
                    className={classes.textArea}
                    placeholder="Enter your tags in here..."
                    disabled={false}
                    value={this.state.templateTags}
                    onChange={this.handleInput}
                  />
                </Grid>
              </Box>
              {/* Banner*/}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Banner Text
                    <span className={classes.optional}>(Optional)</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your text which will appear as green banner to highlight a
                    special feature assosicated with this template
                    <br /> e.g. - High ROI
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="templateBanner"
                    className={classes.textArea}
                    placeholder="Enter features here"
                    onChange={this.handleInput}
                    inputProps={{ maxLength: 60 }}
                    value={this.state.templateBanner}
                    disabled={inDialog}
                  />
                </Grid>
              </Box>

              {/* BODY */}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Format
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your message content. Upto 1024 characters are allowed.
                    <br /> e.g. - Hi {`{{1}}`}, your membership will expire in{" "}
                    {`{{2}}`} days.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    rows={2}
                    rowsMax={20}
                    inputProps={{ maxLength: 1024 }}
                    name="templateFormat"
                    className={classes.textArea}
                    placeholder="Enter your message in here..."
                    disabled={false}
                    value={this.state.templateFormat}
                    onChange={this.extractParams}
                    FormHelperTextProps={{
                      className: classes.characterCounter,
                    }}
                    helperText={`${this.state.templateFormat.length}/1024`}
                  />
                </Grid>
              </Box>
              {/* Parameters */}
              {this.state.parameters.length > 0 && (
                <Box mb={4} my={4} width="100%">
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                      Sample values
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      gutterBottom
                    >
                      Specify sample values for your parameters
                      <br /> e.g. - {`{{1}}`}: Andy, {`{{2}}`}: 21
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container alignItems="center">
                      {this.state.parameters.map((param, index) => (
                        <>
                          <Grid item xs={2} key={index + "_1"}>
                            <Typography variant="h5">
                              {` {{${index + 1}}} `}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            style={{ marginBottom: 8 }}
                            key={index + "_2"}
                          >
                            <TextField
                              classes={{ root: classes.textFieldRoot }}
                              placeholder="Sample value"
                              name={index}
                              value={this.state.parameters[index]}
                              onChange={(e) => this.handleParamsInput(index, e)}
                            />
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                </Box>
              )}
              {/* Template Footer */}
              <Box my={4} width="100%">
                <Grid item xs={12}>
                  <Typography variant="h4" gutterBottom>
                    Template Footer{" "}
                    <span className={classes.optional}>(Optional)</span>
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Your message content. Upto 60 characters are allowed.
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name="templateFooter"
                    className={classes.textArea}
                    placeholder="Enter footer text here"
                    onChange={this.handleInput}
                    inputProps={{ maxLength: 60 }}
                    FormHelperTextProps={{
                      className: classes.characterCounter,
                    }}
                    helperText={`${this.state.templateFooter.length}/60`}
                    value={this.state.templateFooter}
                    disabled={inDialog}
                  />
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                className={classes.preview}
                style={{ display: window.innerWidth < 767 && "inline-block" }}
              >
                <div
                  style={{
                    minWidth: window.innerWidth >= 767 && "320px",
                    marginBottom: window.innerWidth < 767 && "20px",
                    border: "1px solid #f5f5f5",
                    height: "320px",
                    borderRadius: "5px",
                  }}
                >
                  <TemplatePreview template={this.generateTemplate()} />
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box my={0}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                Interactive Actions
              </Typography>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                In addition to your message, you can send actions with your
                message.
                <br />
                Maximum 20 characters are allowed in CTA button title & Quick
                Replies.
              </Typography>
            </Grid>
            <Grid item container xs={12}>
              <RadioGroup
                row
                name="action"
                value={this.state.actionType}
                defaultValue="None"
                onChange={this.handleRadioInput}
              >
                <FormControlLabel
                  value={"None"}
                  control={<Radio color="primary" />}
                  label="None"
                />
                <FormControlLabel
                  value={"CAT"}
                  control={<Radio color="primary" />}
                  label="Call to Actions"
                />
                <FormControlLabel
                  value={"QuickReplies"}
                  control={<Radio color="primary" />}
                  label="Quick Replies"
                />
              </RadioGroup>
            </Grid>
            <InteractiveActions
              classes={classes}
              actionType={this.state.actionType}
              quickReplies={this.state.quickReplies}
              callToAction={this.state.callToAction}
              catAutoComplete={this.catAutoComplete}
              catInput={this.catInput}
              addCAT={this.addCAT}
              removeCAT={this.removeCAT}
              quickReplyInput={this.quickReplyInput}
              addQuickReply={this.addQuickReply}
              removeQuickReply={this.removeQuickReply}
            />
          </Grid>
        </Box>

        <Box my={4} />
        {this.state.err ? (
          <Box>
            <Typography
              variant="body2"
              color="error"
              style={{ marginBottom: 12 }}
            >
              {this.state.err}
            </Typography>
            {this.state.err.includes("Floating") && (
              <Link
                href="https://m.aisensy.com/how-to-fix-floating-parameter-issue-in-template-message"
                target="_blank"
                color="primary"
                size="small"
              >
                <Typography variant="body2">
                  How to fix floating parameter issue?
                </Typography>
              </Link>
            )}
          </Box>
        ) : (
          ""
        )}
        {this.state.success ? (
          <Box>
            <Typography variant="h3" color="primary">
              Template successfully submitted
            </Typography>
          </Box>
        ) : (
          ""
        )}
        <Box my={4}>
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              {this.state.isSubmitting ? (
                <CircularProgress />
              ) : (
                <>
                  {!(inDialog && this.state.success) && (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={this.autoFillAndSubmit}
                      style={{ marginRight: 8 }}
                    >
                      {template ? "Update" : "Submit"}
                    </Button>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

function InteractiveActions(props) {
  const {
    classes,
    actionType,
    callToAction,
    quickReplies,
    catAutoComplete,
    catInput,
    addCAT,
    removeCAT,
    quickReplyInput,
    addQuickReply,
    removeQuickReply,
  } = props;
  function getOptions(e, index) {
    const options = ["Phone Number", "URL"];
    if (callToAction.length === 1) {
      return options;
    } else {
      return options.filter((k) => k !== callToAction[Number(!index)].type);
    }
  }
  switch (actionType) {
    case "None":
      return <div></div>;
    case "QuickReplies":
      return (
        <React.Fragment>
          {quickReplies.map((e, index) => {
            return (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                spacing={2}
                key={index}
              >
                <Grid item xs={12} md={"auto"}>
                  <Typography variant="h5">
                    Quick Reply {index + 1} :
                  </Typography>
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    name="quickReplies"
                    className={classes.textArea}
                    placeholder="Button title"
                    inputProps={{ maxLength: 20 }}
                    value={e}
                    onChange={(elem) => quickReplyInput(index, elem)}
                    FormHelperTextProps={{
                      className: classes.inlineCharacterCounter,
                    }}
                    helperText={`${e.length}/20`}
                  />
                </Grid>
                <Grid item xs={12} md={"auto"}>
                  <IconButton onClick={() => removeQuickReply(index)}>
                    <Clear />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
          <Grid item container xs={12} alignItems="center">
            <Box my={3}>
              {quickReplies.length < 3 ? (
                <Button
                  startIcon={<AddOutlined />}
                  variant="outlined"
                  onClick={addQuickReply}
                >
                  Quick Reply
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </React.Fragment>
      );
    case "CAT":
      return (
        <React.Fragment>
          {callToAction.map((e, index) => {
            console.log(e);
            return (
              <Grid
                item
                container
                xs={12}
                alignItems="center"
                spacing={2}
                key={index}
              >
                <Grid item xs={12} md={2} style={{ display: "contents" }}>
                  <Typography
                    variant="h5"
                    style={{
                      marginLeft: "8px",
                    }}
                  >
                    Call to Action {index + 1} :
                  </Typography>
                  {window.innerWidth < 769 && (
                    <IconButton
                      style={{ marginLeft: "auto" }}
                      onClick={() => removeCAT(index)}
                    >
                      <Clear />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <Autocomplete
                    disableClearable
                    options={getOptions(e, index).map((option) => option)}
                    onChange={(e, value, reason) =>
                      catAutoComplete(index, value, reason, false)
                    }
                    value={e.type}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={classes.textField}
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select action type"
                        name="callToAction"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    name="buttonTitle"
                    inputProps={{ maxLength: 20 }}
                    className={classes.textArea}
                    placeholder="Button title"
                    value={e.buttonTitle}
                    onChange={(elem) => catInput(index, elem)}
                    FormHelperTextProps={{
                      className: classes.inlineCharacterCounter,
                    }}
                    helperText={`${e.buttonTitle.length}/20`}
                  />
                </Grid>
                <Grid item xs={12} md={3} style={{ display: "flex" }}>
                  {e.type === "Phone Number" && (
                    <Autocomplete
                      disableClearable
                      defaultValue={e.extension}
                      options={dialCodes.map((option) => option.dialCode)}
                      onChange={(e, value, reason) =>
                        catAutoComplete(
                          index,
                          value.split("+")[1],
                          reason,
                          true
                        )
                      }
                      fullWidth={true}
                      style={{ marginRight: "8px", width: "92px" }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          style={{ width: "92px" }}
                          className={classes.textField}
                          InputProps={{ ...params.InputProps }}
                          placeholder="Select country code"
                          name="extension"
                        />
                      )}
                    />
                  )}
                  <TextField
                    name="buttonValue"
                    className={classes.textArea}
                    placeholder="Button value"
                    value={e.buttonValue}
                    onChange={(elem) => catInput(index, elem)}
                  />
                </Grid>
                {window.innerWidth > 768 && (
                  <Grid item xs={12} md={"auto"} style={{ width: 0 }}>
                    <IconButton onClick={() => removeCAT(index)}>
                      <Clear />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            );
          })}
          <Grid item container xs={12} alignItems="center">
            <Box my={2}>
              {callToAction.length < 2 ? (
                <Button
                  startIcon={<AddOutlined />}
                  variant="outlined"
                  onClick={addCAT}
                >
                  Call to Action
                </Button>
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </React.Fragment>
      );
    default:
      return "";
  }
}

const styles = (theme) => ({
  root: {
    background: "white",
    borderRadius: "8px",
    minHeight: "200px",
    position: "relative",
  },
  textField: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textArea: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  previewWrapper: {
    width: 350,
    [theme.breakpoints.down("sm")]: {
      width: 300,
    },
  },
  previewContainer: {
    minHeight: 160,
    background: "#EBF5F3",
    width: "100%",

    borderRadius: 8,
  },
  preview: {
    display: "inline-grid",
    width: "100%",
    justifyContent: "center",
    position: "sticky",
    top: "100px",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
  },
  optional: {
    fontSize: "14px",
    color: "#808080",
    fontWeight: "500",
  },
  inlineCharacterCounter: {
    marginLeft: "auto",
    height: 0,
    bottom: "25px",
    position: "absolute",
    right: "10px",
  },
  characterCounter: {
    height: 0,
    position: "absolute",
    right: 0,
    bottom: "-5px",
  },
});

export default withStyles(styles)(CreateTemplateLibraryForm);
