import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  withStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";

const Options = ["Reject", "Move to Void", "Limit approved"];
const RejectionOptions = [
  {
    value: 1,
    reason: "Legal or Trade Name on GST not matching with FBM Name",
  },
  { value: 2, reason: "Aadhaar name not matching with GST proprietor's name" },
  { value: 3, reason: "Facebook business manager already verified" },
  { value: 4, reason: "Website URL not Found" },
  { value: 5, reason: "WABA disabled" },
  { value: 6, reason: "WABA deleted" },
  { value: 7, reason: "Other" },
];
const RejectKyc = (props) => {
  const {
    classes,
    rejectionDialog,
    setRejectionDialog,
    rejectionReason,
    setRejectionReason,
    onRejectHandler,
  } = props;
  const [reason, setReason] = useState("");
  const [selectedReason, setSelectedReason] = useState({});
  return (
    <>
      <Dialog
        onClose={() => setRejectionDialog(!rejectionDialog)}
        open={rejectionDialog}
        // closeDialog={toggleBvbpDialog}
        maxWidth={"lg"}
      >
        <DialogTitle style={{ minWidth: "400px" }}>
          Edit submitted Kyc Step Count
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item style={{ width: "100%" }}>
              <Autocomplete
                disableClearable
                autoHighlight
                options={Options}
                onChange={(k, value, reason) => {
                  setReason(value);
                }}
                value={reason}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ padding: "0px 10px 0px 0px" }}
                    variant="outlined"
                    classes={{ root: classes.textFieldRoot }}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    placeholder="Select Reason"
                  />
                )}
              />
            </Grid>

            {reason == "Reject" && (
              <>
                <Grid item style={{ width: "100%", marginTop: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={RejectionOptions}
                    getOptionLabel={(option) => option?.reason || ""}
                    onChange={(k, value, reason) => {
                      setSelectedReason(value);
                      if (value.value != 7) {
                        setRejectionReason(value.reason);
                      } else {
                        setRejectionReason("");
                      }
                    }}
                    value={selectedReason}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ padding: "0px 10px 0px 0px" }}
                        variant="outlined"
                        classes={{ root: classes.textFieldRoot }}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        placeholder="Select Reason"
                      />
                    )}
                  />
                </Grid>
                {reason == "Reject" && selectedReason.value == 7 && (
                  <Grid item style={{ width: "100%", marginTop: "20px" }}>
                    <TextField
                      className={classes.textFieldRoot}
                      value={rejectionReason}
                      placeholder="Enter rejection reason"
                      onChange={(e) => setRejectionReason(e.target.value)}
                    />
                  </Grid>
                )}
              </>
            )}
          </Grid>
          <Grid container>
            <Grid
              item
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "20px",
              }}
            >
              <Button
                onClick={() => setRejectionDialog(!rejectionDialog)}
                color="primary"
                variant="outlined"
                style={{ marginRight: "10px" }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onRejectHandler(reason);
                }}
                variant="contained"
                color="primary"
                disabled={
                  reason
                    ? reason == "Reject"
                      ? rejectionReason.trim() == ""
                      : false
                    : true
                }
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

const styles = (theme) => ({
  textFieldRoot: {
    backgroundColor: "rgb(240,240,240)",
    outline: "none",
    border: "none",
    borderRadius: "8px",
    padding: "4px 12px",
    width: "100%",
    boxShadow: "none",
    boxSizing: "border-box",
    fontFamily: "Roboto, sans-serif",
    fontSize: "8px",
    fontWeight: 500,
  },
});

export default withStyles(styles)(RejectKyc);
