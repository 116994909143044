import React, { Component } from "react";
import {
  withStyles,
  WithStyles,
  Box,
  Dialog,
  CircularProgress,
  Typography,
  Avatar,
  Link,
} from "@material-ui/core";
import AffiliateDetails from "./AffiliateDetails";

import clsx from "clsx";
// import PartnerDetails from "./PartnerDetails";
import { formatCurrency } from "../../helpers/currency";

const fields = [
  {
    name: "Affiliate ID",
    key: "_id",
  },
  {
    name: "Brand Name",
    key: "brandName",
  },
  {
    name: "Company Name",
    key: "companyName",
  },
  {
    name: "Affiliate Name",
    key: "name",
  },
  {
    name: "Contact",
    key: "contact",
  },
  {
    name: "Commision type",
    key: "affiliatePlan",
  },
  {
    name: "Created At",
    key: "createdAt",
  },
  {
    name: "Login Username",
    key: "userName",
  },
  {
    name: "Affiliate Type",
    key: "affiliateType",
  },
  {
    name: "Currency",
    key: "currency",
  },
  {
    name: "Status",
    key: "status",
  },
];

class PartnersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      selectedPartnerIndex: null,
    };
  }

  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  openPartner = (e) => {
    const partners = this.props.partners;
    const foundIndex = partners.findIndex((x) => x._id == e._id);
    this.setState({ openDialog: true, selectedPartnerIndex: foundIndex });
  };
  render() {
    const { classes, isLoading, partners } = this.props;
    return (
      <div className={classes.root}>
        <table>
          <thead>
            <tr className={`${classes.row} ${classes.topbar}`}>
              {fields.map((field) => (
                <th
                  key={field.key}
                  className={clsx(classes.column, classes.dataColumn)}
                >
                  <Typography variant="h5" color="primary" align="center">
                    {field.name.toUpperCase()}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <th>
                  <Box my={4} display="flex" justifyContent="center">
                    <CircularProgress color="primary" />
                  </Box>
                </th>
              </tr>
            ) : (
              <React.Fragment>
                {partners.map((e, index) => (
                  <tr
                    key={e._id}
                    className={clsx(classes.row, classes.dataRow, {
                      [classes.alterRow]: index % 2 == 0,
                      [classes.stayToLeft]: e.key == "assistantName",
                    })}
                  >
                    {fields.map((field) => (
                      <th
                        key={field.key}
                        className={clsx(classes.column, classes.dataColumn)}
                        onClick={() => this.openPartner(e)}
                      >
                        {columnType(classes, e, field)}
                      </th>
                    ))}
                  </tr>
                ))}
              </React.Fragment>
            )}
          </tbody>
        </table>
        <Dialog
          open={this.state.openDialog}
          onClose={this.closeDialog}
          aria-labelledby="form-dialog-title"
          fullScreen
        >
          <AffiliateDetails
            partner={this.props.partners[this.state.selectedPartnerIndex]}
            onClose={this.closeDialog}
            setPartner={this.props.setPartner}
          />
        </Dialog>
      </div>
    );
  }
}

function columnType(classes, columnData, fieldName) {
  const key = fieldName.key;
  switch (key) {
    case "createdAt":
      return (
        <Typography variant="body1" align="center">
          {getFormattedDate(columnData[key])}
        </Typography>
      );
    case "centralBalance":
      const centralBalance = (columnData[key] ?? 0) / 100000;
      const currency = columnData["currency"];
      return (
        <Typography variant="body1" align="center">
          {formatCurrency(currency, centralBalance)}
        </Typography>
      );
    case "affiliatePlan":
      return (
        <Typography variant="body1" align="center">
          {columnData[key] === "tier0"
            ? "Regular (20%)"
            : columnData[key] === "tier1"
            ? "Growth (25%)"
            : columnData[key] === "tier2"
            ? "Premium (30%)"
            : "NA"}
        </Typography>
      );

    default:
      return (
        <Typography variant="body1" align="center">
          {columnData[key]}
        </Typography>
      );
  }
}

function getFormattedDate(date) {
  const d = new Date(date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return d.toLocaleDateString("en-US", options);
}
const styles = (theme) => ({
  root: {
    height: "100%",
    width: "100%",
    overflowX: "auto",
    overflowY: "auto",
    boxSizing: "border-box",
    borderRadius: "8px",
    position: "relative",
    border: "1px solid lightgrey",
    background: "white",
    [theme.breakpoints.down("sm")]: {},
    "& table": {
      borderCollapse: "collapse",
      borderSpacing: 0,
      width: "100%",
      background: "white",
    },
  },
  row: {
    background: "white",
    display: "flex",
  },
  alterRow: {
    background: "rgb(246,246,246)",
  },
  dataRow: {
    cursor: "pointer",
    transition: "0.3s",
    "&:hover": {
      background: "#EBF5F3",
    },
  },
  topbar: {
    background: "rgb(252,252,252)",
    position: "sticky",
    top: "0",
    zIndex: "100",
  },
  column: {
    fontWeight: "normal",
    height: "50px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    boxSizing: "border-box",
  },
  dataColumn: {
    width: "200px",
  },
  stayToLeft: {
    position: "sticky",
    left: 0,
    top: 0,
  },
  checkboxColumn: {
    width: "60px",
  },
  logo: {
    height: theme.spacing(3),
  },
});

export default withStyles(styles)(PartnersTable);
